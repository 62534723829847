import React, { useState, useEffect } from "react";
import { Card, Checkbox, Form } from "antd";
import { FormComponentProps } from "antd/lib/form";
import PageWrapper from "pages/wrapper/PageWrapper";
import ButtonCancel from "components/common/form/button/ButtonCancel";
import ButtonOnSave from "components/common/form/button/ButtonOnSave";
import RadioWithLabel from "components/common/form/input-with-label/RadioWithLabel";
import InputWithLabel from "components/common/form/input-with-label/InputWithLabel";
import SelectWithLabel from "components/common/form/input-with-label/SelectWithLabel";
import _ from "lodash";
import { match } from "react-router";
import { onSuccessAction, onFailAction } from "helpers/SwalCommon";
import {
    loading,
    loading as loadingHelper,
} from "components/common/loading/Loading";
import SelectDateWithLabel from "components/common/form/input-with-label/SelectDateWithLabel";
import moment from "moment";
import { RequestDigitalCertificatePersonalServices } from "../../services/request-digital-certificate-personal/RequestDigitalCertificatePersonalServices";
import InputFileUpload from "../../components/common/form/input-with-label/InputFileUpload";
import ModalDisplayFile from "../../components/common/modal/display-file/ModalDisplayFile";
import {
    formatMoney,
    handleDateData,
    handleFormatString,
} from "../../helpers/NewCaCrmHelper";
import { RequestCertificateGroupService } from "../../services/request-certificate-group/RequestCertificateGroupServices";
import { TextAreaWithLabel } from "../../components/common/form/input-with-label/TextAreaWithLabel";
import { TypePersonal } from "helpers/enum/ServiceEnum";
const { REACT_APP_BASE_API_URL } = window["runConfig"];
interface Props extends FormComponentProps {
    match: match<{ id: string }>;
    history: any;
    isUpdateForm?: boolean;
    onClickDownloadFile?: any;
    disable?: boolean;
    defaultFileLabel?: string;
}
const objectDate = {
    1: "passport_date",
};
const objectDate2 = {
    1: "date_expired_cert",
};

export const RequestCTSPersonalUpdate: React.FC<Props> = (props) => {
    const id = props.match.params.id;

    const [passportDate, setPassportDate] = useState("");
    const [status, setStatus] = useState(0);
    const [reason, setReason] = useState("");
    const [password, setPassword] = useState("");
    const [cateServicePackage, setCateServicePackage] = useState({});
    const [loadingModal, setLoadingModal] = useState(false);
    const [fileLabelPassport, setFileLabelPassport] = useState("");
    const [fileLabelOrgan, setFileLabelOrgan] = useState("");
    const [fileLabelRegister, setFileLabelRegister] = useState("");
    const [isFileDk02, setIsFileDk02] = useState(false);
    const [file, setFile] = useState("");
    const [visibleModal, setVisibleModal] = useState(false);
    const [province, setProvince] = useState({});
    const [district, setDistrict] = useState({});
    const [provider, setProvider] = useState({});
    const [labelCode, setLabelCode] = useState("Chứng minh nhân dân");
    const [versionToken, setVerionToken] = useState({});
    const [cateServicePackageAmount, setCateServicePackageAmount] = useState(
        []
    );
    const [typeUpload, setTypeUpload] = useState(1);
    const [showFilePassPort, setShowFilePassPort] = useState(true);
    const [typeObject] = useState(Number);
    const [isOrgan] = useState(Number);
    const [dateExpiredCert, setDateExpiredCert] = useState("");

    const [changeSetField, setChangeSetField] = useState(false);
    const [requiredTokenType, setRequiredTokenType] = useState(false);
    const [requiredSerialCts, setRequiredSerialCts] = useState(false);
    const [showEmail, setShowEmail] = useState(false);
    const [requireFileWhenExtend, setRequireFileWhenExtend] = useState(true);

    const update = (status) => {
        const { validateFields } = props.form;
        validateFields(async (errors, values) => {
            if (!errors) {
                try {
                    loadingHelper.runLoadingBlockUI();
                    const val = {
                        ...values,
                        status,
                        showEmail,
                    };
                    let valuesConvert = handleDateData(val, objectDate);
                    if (
                        val.date_expired_cert !== undefined &&
                        val.date_expired_cert !== null
                    ) {
                        valuesConvert = handleDateData(
                            valuesConvert,
                            objectDate2
                        );
                    }
                    const data =
                        await RequestDigitalCertificatePersonalServices.update(
                            id,
                            valuesConvert
                        );
                    if (data && Number(data.status) === 422) {
                        onFailAction("Có lỗi xảy ra khi cập nhật !");
                        _.forOwn(data.error, function (errors, key) {
                            props.form.setFields({
                                [key]: {
                                    errors: [new Error(errors.toString())],
                                },
                            });
                        });
                    } else if (data && Number(data.status) === 200) {
                        onSuccessAction("Cập nhập yêu cầu thành công", () => {
                            props.history.push("/yeu-cau-cts-ca-nhan");
                        });
                    } else {
                        onFailAction("Có lỗi xảy ra khi cập nhật yêu cầu!");
                    }
                } catch (error) {
                    onFailAction("Có lỗi xảy ra khi cập nhật yêu cầu!");
                } finally {
                    loadingHelper.stopRunLoading();
                }
            } else {
                onFailAction("Bạn chưa điền đủ thông tin!");
            }
        });
    };

    const fetchRequestPersonal = async () => {
        const requestCertificateData =
            await RequestDigitalCertificatePersonalServices.getModelToUpdate(
                id
            );
        const province =
            await RequestDigitalCertificatePersonalServices.getProvince();
        setProvince(_.mapValues(_.keyBy(province, "newtel_code"), "fullname"));
        const district =
            await RequestDigitalCertificatePersonalServices.getDistrictByProvince(
                requestCertificateData.data.province_code
            );
        setDistrict(
            _.mapValues(_.keyBy(district, "newtel_district_code"), "fullname")
        );
        let filePassportName = "";
        let fileRegisterName = "";
        let fileOrganName = "";
        requestCertificateData.data.list_document.forEach(function (value) {
            if (value["type"] === 1 && value["file"]) {
                filePassportName = value["file"].split("/");
            } else if (value["type"] === 2 && value["file"]) {
                fileOrganName = value["file"].split("/");
            } else if (value["type"] === 4 && value["file"]) {
                fileRegisterName = value["file"].split("/");
            } else if (value["type"] === 7) {
                setIsFileDk02(true);
            }
        });

        if (requestCertificateData.data.requestpersonal.tax_code) {
            props.form.setFieldsValue({
                tax_code: requestCertificateData.data.requestpersonal.tax_code,
            });
        }
        setDateExpiredCert(requestCertificateData.data.date_expired_cert);
        props.form.setFieldsValue({
            identity_type:
                requestCertificateData.data.requestpersonal.identity_type.toString(),
            passport: requestCertificateData.data.requestpersonal.passport,
            passport_place:
                requestCertificateData.data.requestpersonal.passport_place.toString(),
            fullname:
                requestCertificateData.data.requestpersonal.fullname.toString(),
            phone: requestCertificateData.data.requestpersonal.phone,
            email: requestCertificateData.data.requestpersonal.email,
            address: requestCertificateData.data.requestpersonal.address,
            is_organization:
                requestCertificateData.data.requestpersonal.is_organization.toString(),
            object: requestCertificateData.data.object.toString(),
            passport_upload_type: requestCertificateData.data.requestpersonal
                .passport_upload_type
                ? requestCertificateData.data.requestpersonal.passport_upload_type.toString()
                : null,
            type_docurment:
                requestCertificateData.data.type_docurment.toString(),
            // support_register: requestCertificateData.data.support_register.toString(),
            package_id: requestCertificateData.data.package_id.toString(),
            package_price: requestCertificateData.data.package_price,
            type_device: requestCertificateData.data.type_device.toString(),
            province_code: requestCertificateData.data.province_code.toString(),
            district_code: requestCertificateData.data.district_code.toString(),
            provider_id: requestCertificateData.data.provider_id.toString(),
            quantity: requestCertificateData.data.quantity,
            promotion_code: requestCertificateData.data.promotion_code,
        });
        setTypeUpload(
            requestCertificateData.data.requestpersonal.passport_upload_type
        );

        if (requestCertificateData.data.version_token_newtel) {
            props.form.setFieldsValue({
                version_token_newtel:
                    requestCertificateData.data.version_token_newtel.toString(),
            });
        }
        if (requestCertificateData.data.type_device === 2) {
            props.form.setFieldsValue({
                hsm_provider:
                    requestCertificateData.data.hsm_provider.toString(),
            });
        }
        if (
            Number(
                requestCertificateData.data.requestpersonal.identity_type
            ) === 1
        ) {
            setLabelCode("Chứng minh nhân dân");
        }
        if (
            Number(
                requestCertificateData.data.requestpersonal.identity_type
            ) === 2
        ) {
            setLabelCode("Hộ chiếu");
        }
        if (
            Number(
                requestCertificateData.data.requestpersonal.identity_type
            ) === 3
        ) {
            setLabelCode("Căn cước công dân");
        }
        if (
            Number(
                requestCertificateData.data.requestpersonal.identity_type
            ) === 4
        ) {
            setLabelCode("Mã bảo hiểm xã hội");
        }
        if (
            Number(
                requestCertificateData.data.requestpersonal.identity_type
            ) === 5
        ) {
            setLabelCode("Mã nhân viên");
        }
        setPassportDate(
            requestCertificateData.data.requestpersonal.passport_date
        );
        setStatus(requestCertificateData.data.status);
        setShowEmail(requestCertificateData.data.show_email);
        setReason(requestCertificateData.data.reason);
        setPassword(requestCertificateData.data.password);
        setFileLabelPassport(filePassportName[3]);
        setFileLabelRegister(fileRegisterName[3]);
        if (
            Number(
                requestCertificateData.data.requestpersonal.is_organization
            ) === 1
        ) {
            props.form.setFieldsValue({
                organization_name:
                    requestCertificateData.data.requestpersonal
                        .organization_name,
                organization_department:
                    requestCertificateData.data.requestpersonal
                        .organization_department,
                organization_tax_code:
                    requestCertificateData.data.requestpersonal
                        .organization_tax_code,
                organization_position:
                    requestCertificateData.data.requestpersonal
                        .organization_position,
                organization_email:
                    requestCertificateData.data.requestpersonal
                        .organization_email,
                organization_phone:
                    requestCertificateData.data.requestpersonal
                        .organization_phone,
                organization_address:
                    requestCertificateData.data.requestpersonal
                        .organization_address,
            });
            setFileLabelOrgan(fileOrganName[3] ? fileOrganName[3] : "");
            props.form.setFieldsValue({
                organization_file: fileOrganName[3],
            });
        }
        // if (Number(requestCertificateData.data.support_register) === 1) {
        //   props.form.setFieldsValue({
        //     fullname_support: requestCertificateData.data.requestsupport.fullname,
        //     position_support: requestCertificateData.data.requestsupport.position,
        //     email_support: requestCertificateData.data.requestsupport.email,
        //     phone_support: requestCertificateData.data.requestsupport.phone,
        //   });
        // }
        if (
            Number(requestCertificateData.data.object) === 2 ||
            Number(requestCertificateData.data.object) === 3
        ) {
            if (Number(requestCertificateData.data.object) === 2) {
                setRequiredSerialCts(true);
                setRequiredTokenType(true);
            }
            if (requestCertificateData.data.serial_cts) {
                props.form.setFieldsValue({
                    serial_cts: requestCertificateData.data.serial_cts,
                });
            }
            if (requestCertificateData.data.token_type) {
                props.form.setFieldsValue({
                    token_type:
                        requestCertificateData.data.token_type.toString(),
                });
            }
        }
        let object = 1;
        if (
            Number(
                requestCertificateData.data.requestpersonal.is_organization
            ) === 1
        ) {
            object = 2;
        }
        const type_search = 4;
        const type = props.form.getFieldValue("object");
        const type_device = props.form.getFieldValue("type_device");
        let hsm_provider = props.form.getFieldValue("hsm_provider");
        const data = await getCateServicePackage(
            type_search,
            type,
            object,
            type_device,
            requestCertificateData.data.provider_id,
            hsm_provider
        );
        setCateServicePackage(_.mapValues(_.keyBy(data.data, "id"), "name"));
        setCateServicePackageAmount(data.data);
        if (Number(requestCertificateData.data.object) === 2) {
            setRequireFileWhenExtend(false);
        } else {
            setRequireFileWhenExtend(true);
        }
    };
    const getCateServicePackage = async (
        type_search,
        type,
        obj,
        type_device,
        provider_id,
        hsm_provider
    ) => {
        const data =
            await RequestDigitalCertificatePersonalServices.getListCateServicePackage(
                type_search,
                type,
                obj,
                type_device,
                provider_id,
                hsm_provider
            );
        return data;
    };
    const onChangeIsOrgan = async (e) => {
        if (e) {
            props.form.setFieldsValue({ type_device: undefined });
            props.form.setFieldsValue({ package_id: undefined });
            props.form.setFieldsValue({ package_price: undefined });
        }
    };
    const onChangTypeDevice = async (e) => {
        if (e) {
            loading.runLoadingBlockUI();
            props.form.setFieldsValue({ package_id: undefined });
            props.form.setFieldsValue({ package_price: undefined });
            props.form.setFieldsValue({ hsm_provider: undefined });
            let object = 1;
            if (Number(props.form.getFieldValue("is_organization")) === 1) {
                object = 2;
            }
            const type_search = 4;
            const type = props.form.getFieldValue("object");
            const type_device = e.target.value;
            let hsm_provider = props.form.getFieldValue("hsm_provider");
            const data = await getCateServicePackage(
                type_search,
                type,
                object,
                type_device,
                props.form.getFieldValue("provider_id"),
                hsm_provider
            );
            setCateServicePackage(
                _.mapValues(_.keyBy(data.data, "id"), "name")
            );
            setCateServicePackageAmount(data.data);
            loading.stopRunLoading();
        }
    };
    const onChangeHSMProvider = async (e) => {
        const type_search = 4;
        const type = props.form.getFieldValue("object");
        let object = 1;
        if (Number(props.form.getFieldValue("is_organization")) === 1) {
            object = 2;
        }
        let provider_id = props.form.getFieldValue("provider_id");
        let hsm_provider = e.target.value;
        const type_device = props.form.getFieldValue("type_device");
        const data =
            await RequestDigitalCertificatePersonalServices.getListCateServicePackage(
                type_search,
                type,
                object,
                type_device,
                provider_id,
                hsm_provider
            );
        setCateServicePackageAmount(data.data);
        setCateServicePackage(_.mapValues(_.keyBy(data.data, "id"), "name"));
        props.form.setFieldsValue({
            package_id: "",
            package_price: "",
        });
    };
    const onPreviewFile = async (type, typeUpload = "", location = "") => {
        try {
            let token = localStorage.getItem("currentUser");
            if (token) {
                let obj = JSON.parse(token);
                let link = `${REACT_APP_BASE_API_URL}qlbh/request-digital-certificate-personal/get-file/
                    ${password}/${type}?token=${obj.token}&typeUpload=${typeUpload}&location=${location}`;
                window.open(link);
            }
        } catch (error) {
            onFailAction("Có lỗi xảy ra khi xem file!");
        }
    };
    const onGenerateFile = async (typeFile) => {
        const { validateFields } = props.form;
        validateFields(async (errors, values) => {
            if (!errors) {
                try {
                    setVisibleModal(true);
                    setLoadingModal(true);
                    const val = {
                        ...values,
                        typeFile: typeFile,
                    };
                    let valuesConvert = handleDateData(val, objectDate);
                    if (
                        val.date_expired_cert !== undefined &&
                        val.date_expired_cert !== null
                    ) {
                        valuesConvert = handleDateData(
                            valuesConvert,
                            objectDate2
                        );
                    }
                    const data =
                        await RequestDigitalCertificatePersonalServices.generateFile(
                            valuesConvert
                        );
                    setFile(data.base64);
                } catch (error) {
                    onFailAction("Có lỗi xảy ra khi xem trước file!");
                    setVisibleModal(false);
                } finally {
                    setLoadingModal(false);
                }
            } else {
                onFailAction("Bạn chưa điền đủ thông tin!");
            }
        });
    };
    const onOkModal = () => {
        setVisibleModal(false);
        setFile("");
    };
    const getProvince = () => {
        const province =
            RequestDigitalCertificatePersonalServices.getProvince();
        setProvince(_.mapValues(_.keyBy(province, "newtel_code"), "fullname"));
    };
    const onChangeProvince = async (e) => {
        if (e) {
            const district =
                await RequestDigitalCertificatePersonalServices.getDistrictByProvince(
                    e
                );
            setDistrict(
                _.mapValues(
                    _.keyBy(district, "newtel_district_code"),
                    "fullname"
                )
            );
        } else {
            setDistrict({});
        }
        props.form.setFieldsValue({ district_code: undefined });
    };
    const getProvider = async () => {
        const data =
            await RequestDigitalCertificatePersonalServices.getAllProvider();
        setProvider(_.mapValues(_.keyBy(data, "id"), "name"));
    };
    const getVersionToken = async () => {
        const data = await RequestCertificateGroupService.getVersionToken();
        setVerionToken(_.mapValues(_.keyBy(data.data, "id"), "code"));
    };
    const onChangeProvider = (e) => {
        props.form.resetFields(["type_device"]);
    };
    const onChangeFormatInput = (name, value, type) => {
        const strFormart = handleFormatString(value, type);
        props.form.setFieldsValue({ [name]: strFormart });
    };
    const onChange = async (value) => {
        const selectedCate: any = _.find(cateServicePackageAmount, {
            id: parseInt(value),
        });
        props.form.setFieldsValue({
            package_price: selectedCate ? formatMoney(selectedCate.price) : "",
        });
    };
    const onChangePrice = (e) => {
        const price = formatMoney(e.target.value);
        props.form.setFieldsValue({ package_price: price });
    };
    const onChangePromotionCode = (e) => {
        if (e) {
            props.form.setFieldsValue({
                promotion_code: e.target.value,
            });
        }
    };

    const onChangeTypeExtend = async (e)=>{
        props.form.setFieldsValue({ type_extend: e.target.value });

        const serial_cts = props.form.getFieldValue("serial_cts");
         if (serial_cts){
            await onCheckSerial(serial_cts)
         }
     }

    const onChangeAdjourn = async (e) => {
        const serial_cts = handleFormatString(
            e.target.value.replace(/ /g, ""),
            3
        );
        props.form.setFieldsValue({ serial_cts: serial_cts });
        if (serial_cts !== "") {
            await onCheckSerial(serial_cts)
        }
    };

    const onCheckSerial = async (serial_cts)=>{
        loading.runLoadingBlockUI();
            const identity_code = props.form.getFieldValue("passport");
            const type_extend = props.form.getFieldValue("type_extend");

            const dataRequestCert =
                await RequestDigitalCertificatePersonalServices.getRequestBySerial(
                    {serial_cts, identity_code, type_extend}
                );
            if (dataRequestCert.status === 200) {
                if (
                    dataRequestCert.data.status === 1 ||
                    dataRequestCert.data.status === 9
                ) {
                    // setDisableField(false);

                    props.form.setFieldsValue({
                        serial_cts: serial_cts,
                        passport:
                            dataRequestCert.data.requestcertificate
                                .requestpersonal.passport,
                        passport_place:
                            dataRequestCert.data.requestcertificate.requestpersonal.passport_place.toString(),
                        fullname:
                            dataRequestCert.data.requestcertificate.requestpersonal.fullname.toString(),
                        phone: dataRequestCert.data.requestcertificate
                            .requestpersonal.phone,
                        email: dataRequestCert.data.requestcertificate
                            .requestpersonal.email,
                        address:
                            dataRequestCert.data.requestcertificate
                                .requestpersonal.address,
                        tax_code:
                            dataRequestCert.data.requestcertificate
                                .requestpersonal.tax_code,
                        passport_date: moment(
                            dataRequestCert.data.requestcertificate
                                .requestpersonal.passport_date
                        ),
                        is_organization:
                            dataRequestCert.data.requestcertificate.requestpersonal.is_organization.toString(),
                        // support_register: dataRequestCert.data.requestcertificate.support_register.toString(),
                        type_device:
                            dataRequestCert.data.requestcertificate.type_device.toString(),
                        provider_id:
                            dataRequestCert.data.requestcertificate.provider_id.toString(),
                        identity_type:
                            dataRequestCert.data.requestcertificate.identity_type.toString(),
                            certificateEnd: dataRequestCert.data.certificateEnd,
                    });
                    // if (Number(dataRequestCert.data.requestcertificate.support_register) === 1) {
                    //   props.form.setFieldsValue({
                    //     phone_support: dataRequestCert.data.requestcertificate.requestsupport.phone,
                    //     email_support: dataRequestCert.data.requestcertificate.requestsupport.email,
                    //     fullname_support: dataRequestCert.data.requestcertificate.requestsupport.fullname,
                    //     position_support: dataRequestCert.data.requestcertificate.requestsupport.positon,
                    //   });
                    // }
                    if (
                        Number(
                            dataRequestCert.data.requestcertificate.type_device
                        ) === 2
                    ) {
                        props.form.setFieldsValue({
                            hsm_provider:
                                dataRequestCert.data.requestcertificate.hsm_provider.toString(),
                        });
                    }
                    if (
                        Number(
                            dataRequestCert.data.requestcertificate
                                .requestpersonal.is_organization
                        ) === 1
                    ) {
                        props.form.setFieldsValue({
                            organization_name:
                                dataRequestCert.data.requestcertificate
                                    .requestpersonal.organization_name,
                            organization_department:
                                dataRequestCert.data.requestcertificate
                                    .requestpersonal.organization_department,
                            organization_tax_code:
                                dataRequestCert.data.requestcertificate
                                    .requestpersonal.organization_tax_code,
                            organization_position:
                                dataRequestCert.data.requestcertificate
                                    .requestpersonal.organization_position,
                            organization_email:
                                dataRequestCert.data.requestcertificate
                                    .requestpersonal.organization_email,
                            organization_phone:
                                dataRequestCert.data.requestcertificate
                                    .requestpersonal.organization_phone,
                            organization_address:
                                dataRequestCert.data.requestcertificate
                                    .requestpersonal.organization_address,
                            certificateEnd: dataRequestCert.data.certificateEnd,
                        });
                    }
                    // setDisableTypeDevice(true);
                    // setDisableHSMProvider(true);
                    const type_search = 4;
                    const type = typeObject;
                    let obj = 1;
                    let type_device = props.form.getFieldValue("type_device");
                    if (Number(isOrgan) === 1) {
                        obj = 2;
                    }
                    let provider_id = props.form.getFieldValue("provider_id");
                    let hsm_provider = props.form.getFieldValue("hsm_provider");
                    const catePackage = await getCateServicePackage(
                        type_search,
                        type,
                        obj,
                        type_device,
                        provider_id,
                        hsm_provider
                    );
                    setCateServicePackageAmount(catePackage.data);
                    setCateServicePackage(
                        _.mapValues(_.keyBy(catePackage.data, "id"), "name")
                    );
                }
            }
            if (dataRequestCert.status === 101 || Number(dataRequestCert.status) === 102) {
                let mess= "Serial chứng thư số không hợp lệ!";
                if (Number(dataRequestCert.status) === 102){
                    mess = dataRequestCert.message;
                }
                onFailAction(
                    mess,
                    () => {
                        props.form.setFieldsValue({
                            serial_cts: "",
                        });
                    },
                    "warning",
                    "Cảnh báo"

                );
            }
            if (dataRequestCert.status === 103) {
                if (
                    dataRequestCert.data.status === 1 ||
                    dataRequestCert.data.status === 9
                ) {
                    // setDisableField(false);
                    props.form.setFieldsValue({
                        type_device:
                            dataRequestCert.data.requestcertificate.type_device.toString(),
                            certificateEnd: dataRequestCert.data.certificateEnd,
                    });
                    // setDisableTypeDevice(true);
                    // setDisableHSMProvider(true);
                    let type_search = 4;
                    let type = typeObject;
                    let obj = 1;
                    let type_device = props.form.getFieldValue("type_device");
                    if (Number(isOrgan) === 1) {
                        obj = 2;
                    }
                    let provider_id = props.form.getFieldValue("provider_id");
                    let hsm_provider = props.form.getFieldValue("hsm_provider");
                    const catePackage = await getCateServicePackage(
                        type_search,
                        type,
                        obj,
                        type_device,
                        provider_id,
                        hsm_provider
                    );
                    setCateServicePackageAmount(catePackage.data);
                    setCateServicePackage(
                        _.mapValues(_.keyBy(catePackage.data, "id"), "name")
                    );
                }
            }
            if (dataRequestCert.status === 104) {
                // setDisableField(false);
                props.form.setFieldsValue({
                    type_device: "1",
                    certificateEnd: dataRequestCert.data.certificateEnd,
                });
                // setDisableTypeDevice(true);
                // setDisableHSMProvider(true);
                let type_search = 4;
                let type = typeObject;
                let obj = 1;
                let type_device = 1;
                if (Number(isOrgan) === 1) {
                    obj = 2;
                }
                let provider_id = props.form.getFieldValue("provider_id");
                let hsm_provider = props.form.getFieldValue("hsm_provider");
                const catePackage = await getCateServicePackage(
                    type_search,
                    type,
                    obj,
                    type_device,
                    provider_id,
                    hsm_provider
                );
                setCateServicePackageAmount(catePackage.data);
                setCateServicePackage(
                    _.mapValues(_.keyBy(catePackage.data, "id"), "name")
                );
            }
    }

    useEffect(() => {
        getProvince();
        fetchRequestPersonal();
        getProvider();
        getVersionToken();
        // eslint-disable-next-line
    }, []);
    const changeObject = async (e) => {
        if (props.form.getFieldValue("passport").trim() === "") {
            onFailAction("Bạn chưa nhập định danh.");
        }
        if (
            Number(e.target.value) === 1 ||
            Number(e.target.value) === 2 ||
            Number(e.target.value) === 3
        ) {
            if (Number(e.target.value) === 2) {
                setRequiredSerialCts(true);
                setRequiredTokenType(true);
            } else if (Number(e.target.value) === 3) {
                setRequiredSerialCts(false);
                setRequiredTokenType(false);
            }
            let type_search = 4;
            let type = e.target.value;
            let obj = 1;
            let type_device = props.form.getFieldValue("type_device");
            if (Number(props.form.getFieldValue("is_organization")) === 1) {
                obj = 2;
            }
            let provider_id = props.form.getFieldValue("provider_id");
            let hsm_provider = props.form.getFieldValue("hsm_provider");
            const catePackage = await getCateServicePackage(
                type_search,
                type,
                obj,
                type_device,
                provider_id,
                hsm_provider
            );
            setCateServicePackageAmount(catePackage.data);
            setCateServicePackage(
                _.mapValues(_.keyBy(catePackage.data, "id"), "name")
            );
        }
        if (Number(e.target.value) === 2) {
            setRequireFileWhenExtend(false);
        } else {
            setRequireFileWhenExtend(true);
        }
        props.form.setFieldsValue({
            package_id: "",
            package_price: "",
        });
    };
    const STATUS_TU_CHOI = 7;
    const onChangeTypeUpload = (e) => {
        const type_upload = parseInt(e.target.value);
        setTypeUpload(type_upload);
        if (type_upload !== typeUpload) {
            setShowFilePassPort(false);
        }
    };
    const onChangeTypeDoc = (e) => {
        if (
            Number(e.target.value) === 1 &&
            (Number(props.form.getFieldValue("object")) === 2 ||
                Number(props.form.getFieldValue("object")) === 3)
        ) {
            setChangeSetField(true);
        } else {
            setChangeSetField(false);
        }
    };
    const onChangeCheckBox = (e) => {
        setShowEmail(e.target.checked);
    };
    

    return (
        <PageWrapper title="Cập nhật yêu cầu CTS cá nhân ">
            <Form>
                <ModalDisplayFile
                    titleModal="File"
                    visibleModal={visibleModal}
                    loadingModal={loadingModal}
                    fileBase64={file}
                    onOkModal={() => onOkModal()}
                />
                <InputWithLabel
                    wrapClass={""}
                    name={"isUpdate"}
                    label={""}
                    form={props.form}
                    defaultValue={2}
                    hidden={true}
                />
                <InputWithLabel
                    wrapClass={""}
                    name={"id"}
                    label={""}
                    form={props.form}
                    defaultValue={id}
                    hidden={true}
                />
                {status === STATUS_TU_CHOI ? (
                    <TextAreaWithLabel
                        label={"Lý do từ chối"}
                        name="reason-deny"
                        rows={0}
                        form={props.form}
                        defaultValue={reason}
                        wrapClass={"col-md"}
                        isDisabled={true}
                    />
                ) : (
                    ""
                )}
                <Card
                    className="container-fluid"
                    title={
                        <label>
                            Trường hợp cá nhân thuộc tổ chức doanh nghiệp{" "}
                        </label>
                    }
                    size="small"
                >
                    {/* <div className="input-group"> */}
                    <div className=" input-group">
                        <RadioWithLabel
                            label={
                                "Trường hợp cá nhân thuộc tổ chức doanh nghiệp"
                            }
                            options={{ 1: "Có", 2: "Không" }}
                            name="is_organization"
                            wrappedClass="col-md-6"
                            form={props.form}
                            isRequired={true}
                            onChange={onChangeIsOrgan}
                        />
                    </div>
                    {props.form.getFieldValue("is_organization") === "1" ? (
                        <React.Fragment>
                            <div className="input-group">
                                <InputWithLabel
                                    form={props.form}
                                    label="Tên tổ chức"
                                    name="organization_name"
                                    wrapClass="col-md-6 col-lg-3"
                                    isRequired={true}
                                    maxLength={255}
                                    onBlur={(e) =>
                                        onChangeFormatInput(
                                            "organization_name",
                                            e.target.value,
                                            3
                                        )
                                    }
                                />
                                <InputWithLabel
                                    form={props.form}
                                    label="Tên phòng ban"
                                    name="organization_department"
                                    wrapClass="col-md-6 col-lg-3"
                                    maxLength={255}
                                />
                                <InputWithLabel
                                    form={props.form}
                                    label="MST tổ chức/doanh nghiệp"
                                    name="organization_tax_code"
                                    wrapClass="col-md-6 col-lg-3"
                                    maxLength={16}
                                />
                                <InputWithLabel
                                    form={props.form}
                                    label="Chức vụ"
                                    name="organization_position"
                                    wrapClass="col-md-6 col-lg-3"
                                    maxLength={255}
                                />
                            </div>
                            <div className="input-group">
                                <InputWithLabel
                                    form={props.form}
                                    label="Email"
                                    name="organization_email"
                                    wrapClass="col-md-6 col-lg-3"
                                    isRequired={true}
                                    maxLength={255}
                                />
                                <InputWithLabel
                                    form={props.form}
                                    label="Số điện thoại"
                                    name="organization_phone"
                                    wrapClass="col-md-6 col-lg-3"
                                    isRequired={true}
                                    maxLength={16}
                                />
                                <InputWithLabel
                                    form={props.form}
                                    label="Địa chỉ tổ chức/doanh nghiệp"
                                    name="organization_address"
                                    wrapClass="col-md-6 col-lg-3"
                                    isRequired={true}
                                    maxLength={255}
                                />
                                <InputFileUpload
                                    nameFiledSetValue={""}
                                    defaultLabel={fileLabelOrgan}
                                    classWrapped="col-md-6 col-lg-3"
                                    label="File xác nhận tổ chức/doanh nghiệp"
                                    name="organization_file"
                                    form={props.form}
                                    isRequired={requireFileWhenExtend}
                                    onClickDownloadFile={() => onPreviewFile(2)}
                                    extentionsAllow={[
                                        "pdf",
                                        "PDF",
                                        "png",
                                        "jpg",
                                    ]}
                                    accept={[".pdf", ".png", ".jpg", ".jpeg"]}
                                />
                            </div>
                        </React.Fragment>
                    ) : (
                        ""
                    )}

                    {/* </div> */}
                </Card>
                <Card
                    className="container-fluid mt-4"
                    title={<label>Đăng ký dịch vụ chứng thư số</label>}
                    size="small"
                >
                    <div className="input-group">
                        <SelectWithLabel
                            options={provider}
                            name="provider_id"
                            wrappedClass="col-md-6 col-lg-2"
                            form={props.form}
                            label={"Nhà cung cấp"}
                            isRequired={true}
                            onChange={onChangeProvider}
                        />
                        {props.form.getFieldValue("is_organization") === "1" ? (
                            <SelectWithLabel
                                options={{
                                    1: "Chứng minh nhân dân",
                                    2: "Hộ chiếu",
                                    3: "Căn cước công dân",
                                    4: "Mã bảo hiểm xã hội",
                                    5: "Mã nhân viên",
                                }}
                                name="identity_type"
                                wrappedClass="col-md-6 col-lg-2"
                                form={props.form}
                                label={"Loại định danh"}
                                isRequired={true}
                                isDisabled={true}
                            />
                        ) : (
                            <SelectWithLabel
                                options={TypePersonal.TYPE_IDENTITY}
                                name="identity_type"
                                wrappedClass="col-md-6 col-lg-2"
                                form={props.form}
                                label={"Loại định danh"}
                                isRequired={true}
                                isDisabled={true}
                            />
                        )}
                        <InputWithLabel
                            form={props.form}
                            label={labelCode}
                            name="passport"
                            isRequired={true}
                            wrapClass="col-md-6 col-lg-2"
                            maxLength={16}
                            isDisabled={true}
                        />
                        <RadioWithLabel
                            options={{
                                1: "Cấp mới",
                                2: "Gia hạn",
                                3: "Chuyển đổi",
                            }}
                            label="Đối tượng"
                            name="object"
                            wrappedClass="col-md-6 col-lg-3 select-doi-tuong"
                            form={props.form}
                            isRequired={true}
                            onChange={changeObject}
                        />
                        {/* {Number(props.form.getFieldValue("object")) === 2 &&
                                <RadioWithLabel
                                    options={{
                                        1: "Trước hạn",
                                        2: "Quá hạn",
                                    }}
                                    label="Loại gia hạn"
                                    name="type_extend"
                                    wrappedClass="col-md-6 col-lg-3"
                                    form={props.form}
                                    isRequired={true}
                                    onChange={onChangeTypeExtend}
                                />
                                } */}
                        
                    </div>
                    <div className="input-group">
                        <RadioWithLabel
                            options={{
                                1: "Token",
                                2: "HSM",
                                // 3: "FastCA RS"
                            }}
                            label="Loại thiết bị đầu cuối thuê bao"
                            name="type_device"
                            wrappedClass="col-md-6 col-lg-2 select-doi-tuong"
                            form={props.form}
                            isRequired={true}
                            isDisabled={
                                Number(props.form.getFieldValue("object")) === 2
                            }
                            onChange={onChangTypeDevice}
                        />
                        {Number(props.form.getFieldValue("type_device")) ===
                        2 ? (
                            <RadioWithLabel
                                options={{ 1: "CyberLotus", 2: "Khác" }}
                                label="Nhà cung cấp HSM"
                                name="hsm_provider"
                                wrappedClass="col-md-6 col-lg-2 select-doi-tuong"
                                form={props.form}
                                isRequired={true}
                                onChange={onChangeHSMProvider}
                            />
                        ) : (
                            ""
                        )}
                        <SelectWithLabel
                            options={cateServicePackage}
                            name="package_id"
                            wrappedClass="col-md-6 col-lg-2 "
                            form={props.form}
                            label={"Gói dịch vụ"}
                            isRequired={true}
                            onChange={onChange}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Giá bán"
                            name="package_price"
                            wrapClass="col-md-6 col-lg-2"
                            isRequired={true}
                            onBlur={onChangePrice}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Số lượng"
                            name="quantity"
                            wrapClass="col-md-6 col-lg-1"
                            isRequired={true}
                            type={"number"}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Mã CTKM"
                            name="promotion_code"
                            wrapClass="col-md-6 col-lg-2"
                            maxLength={255}
                            isRequired={false}
                            onChange={onChangePromotionCode}
                        />
                    </div>
                    <div className={"input-group"}>
                        {Number(props.form.getFieldValue("object")) === 2 ||
                        Number(props.form.getFieldValue("object")) === 3 ? (
                            <React.Fragment>
                                <InputWithLabel
                                    form={props.form}
                                    label="Serial CTS"
                                    name="serial_cts"
                                    wrapClass="col-md-6 col-lg-4"
                                    isRequired={requiredSerialCts}
                                    onBlur={onChangeAdjourn}
                                />
                                <SelectWithLabel
                                    options={{ 1: "Token mới", 2: "Token cũ" }}
                                    name="token_type"
                                    wrappedClass="col-md-6 col-lg-2"
                                    form={props.form}
                                    label={"Loại token"}
                                    isRequired={requiredTokenType}
                                />
                            </React.Fragment>
                        ) : (
                            ""
                        )}
                        {Number(props.form.getFieldValue("provider_id")) ===
                            2 &&
                        Number(props.form.getFieldValue("type_device")) ===
                            1 ? (
                            <SelectWithLabel
                                options={versionToken}
                                name="version_token_newtel"
                                wrappedClass="col-md-6 col-lg-2"
                                form={props.form}
                                label={"Version token"}
                            />
                        ) : (
                            ""
                        )}
                        {Number(props.form.getFieldValue("object")) === 2 ||
                        Number(props.form.getFieldValue("object")) === 3 ? (
                            <SelectDateWithLabel
                                name="date_expired_cert"
                                form={props.form}
                                wrapClass="col-md-2 col-lg-2"
                                label="Ngày hết hạn"
                                defaultValue={
                                    dateExpiredCert
                                        ? moment(dateExpiredCert)
                                        : null
                                }
                            />
                        ) : (
                            ""
                        )}
                    </div>
                    <div className="input-group">
                        <div className={"col-md-12"}>
                            <Form.Item>
                                <Checkbox onChange={onChangeCheckBox}>
                                    Hiển thị Email
                                </Checkbox>
                                <span className={"text-danger font-italic"}>
                                    ( Chú ý: Nếu chọn Email sẽ hiển thị trong
                                    thông tin CTS )
                                </span>
                            </Form.Item>
                        </div>
                    </div>
                </Card>
                <Card
                    className="container-fluid mt-4"
                    title={<label>Thông tin cá nhân đăng ký</label>}
                    size="small"
                >
                    <div className="input-group">
                        <div className="input-group">
                            <SelectDateWithLabel
                                name="passport_date"
                                form={props.form}
                                isRequired={true}
                                wrapClass="col-md-6 col-lg-2"
                                label="Ngày cấp"
                                defaultValue={
                                    passportDate ? moment(passportDate) : null
                                }
                                rules={[
                                    {
                                        validator: function (
                                            rule,
                                            value,
                                            callback
                                        ) {
                                            if (value && value > moment()) {
                                                callback(
                                                    "Ngày cấp phải nhỏ hơn ngày hiện tại"
                                                );
                                            } else {
                                                callback();
                                            }
                                        },
                                        message:
                                            "Ngày cấp phải nhỏ hơn ngày hiện tại",
                                    },
                                ]}
                            />
                            <InputWithLabel
                                form={props.form}
                                label="Nơi cấp"
                                name="passport_place"
                                isRequired={true}
                                wrapClass="col-md-6 col-lg-2"
                                maxLength={255}
                            />
                            <InputWithLabel
                                form={props.form}
                                label="MST (nếu có)"
                                name="tax_code"
                                wrapClass="col-md-6 col-lg-2"
                                maxLength={16}
                            />
                            <RadioWithLabel
                                options={{
                                    1: "Upload 1 file",
                                    2: "Upload 2 file",
                                }}
                                label="Hình thức upload"
                                name="passport_upload_type"
                                wrappedClass="col-lg-2 col-md-6 radio_register_support"
                                form={props.form}
                                isRequired={requireFileWhenExtend}
                                onChange={onChangeTypeUpload}
                            />

                            {typeUpload === 1 ? (
                                <InputFileUpload
                                    nameFiledSetValue={""}
                                    defaultLabel={fileLabelPassport}
                                    classWrapped={
                                        "col-md-6 col-lg-4 white-space-initial"
                                    }
                                    label={"File CMND/Hộ chiếu"}
                                    name={"passport_file"}
                                    form={props.form}
                                    isDisabled={props.disable}
                                    onClickDownloadFile={
                                        showFilePassPort
                                            ? () => onPreviewFile(1, "", "")
                                            : false
                                    }
                                    isPassport={true}
                                    note={true}
                                    extentionsAllow={[
                                        "pdf",
                                        "PDF",
                                        "png",
                                        "jpg",
                                        "jpeg",
                                        "PNG",
                                        "JPG",
                                        "JPEG",
                                    ]}
                                    accept={[".pdf", ".png", ".jpg", ".jpeg"]}
                                />
                            ) : (
                                <div className={"col-md-6 col-lg-4 d-flex"}>
                                    <InputFileUpload
                                        nameFiledSetValue={""}
                                        defaultLabel={fileLabelPassport}
                                        classWrapped={
                                            "col-md-6 col-lg-6 no-padding-left"
                                        }
                                        label={"CMND (Mặt trước)"}
                                        name={"passport_front_side"}
                                        form={props.form}
                                        isDisabled={props.disable}
                                        onClickDownloadFile={
                                            showFilePassPort
                                                ? () =>
                                                      onPreviewFile(
                                                          1,
                                                          "2",
                                                          "front"
                                                      )
                                                : false
                                        }
                                        isPassport={true}
                                        note={true}
                                        extentionsAllow={[
                                            "png",
                                            "jpg",
                                            "jpeg",
                                            "PNG",
                                            "JPG",
                                            "JPEG",
                                        ]}
                                        accept={[".png", ".jpg", ".jpeg"]}
                                    />
                                    <InputFileUpload
                                        nameFiledSetValue={""}
                                        defaultLabel={fileLabelPassport}
                                        classWrapped="col-md-6 col-lg-6 no-padding-right"
                                        label="CMND (Mặt sau)"
                                        name="passport_back_side"
                                        form={props.form}
                                        isDisabled={props.disable}
                                        onClickDownloadFile={
                                            showFilePassPort
                                                ? () =>
                                                      onPreviewFile(
                                                          1,
                                                          "2",
                                                          "back"
                                                      )
                                                : false
                                        }
                                        isPassport={true}
                                        note={true}
                                        extentionsAllow={[
                                            "png",
                                            "jpg",
                                            "jpeg",
                                            "PNG",
                                            "JPG",
                                            "JPEG",
                                        ]}
                                        accept={[".png", ".jpg", ".jpeg"]}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="input-group">
                            <InputWithLabel
                                form={props.form}
                                label="Họ tên cá nhân"
                                name="fullname"
                                wrapClass="col-md-6 col-lg-4 "
                                isRequired={true}
                                maxLength={255}
                                onBlur={(e) =>
                                    onChangeFormatInput(
                                        "fullname",
                                        e.target.value,
                                        3
                                    )
                                }
                            />
                            <InputWithLabel
                                form={props.form}
                                label="Số điện thoại"
                                name="phone"
                                wrapClass="col-md-6 col-lg-4"
                                isRequired={true}
                                maxLength={16}
                            />
                            <InputWithLabel
                                form={props.form}
                                label="Email"
                                name="email"
                                wrapClass="col-md-6 col-lg-4"
                                isRequired={true}
                                maxLength={255}
                            />
                        </div>
                        <div className="input-group">
                            {/*<RadioWithLabel*/}
                            {/*    options={{ 1: "Có đăng ký", 2: "Không đăng ký" }}*/}
                            {/*    label="ĐK hỗ trợ khẩn cấp"*/}
                            {/*    name="support_register"*/}
                            {/*    wrappedClass="col-md-6 col-lg-4 radio_register_support"*/}
                            {/*    form={props.form}*/}
                            {/*    isRequired={true}*/}
                            {/*/>*/}
                            <InputWithLabel
                                form={props.form}
                                label="Địa chỉ thường trú"
                                name="address"
                                wrapClass="col-md-6 col-lg-4"
                                maxLength={255}
                            />
                            <SelectWithLabel
                                options={province}
                                name="province_code"
                                wrappedClass="col-md-6 col-lg-2"
                                form={props.form}
                                label={"Tỉnh thành"}
                                isRequired={true}
                                isProvince={true}
                                onChange={onChangeProvince}
                            />
                            <SelectWithLabel
                                options={district}
                                name="district_code"
                                wrappedClass="col-md-6 col-lg-2"
                                form={props.form}
                                label={"Quận huyện"}
                                isRequired={true}
                            />
                            <div className="font-size-12 text-danger col-12">
                                Lưu ý: Thông tin Số điện thoại và Email bắt buộc
                                nhập chính xác để xác minh khách hàng và nhận hỗ
                                trợ dịch vụ từ NCC
                            </div>
                        </div>
                    </div>
                </Card>

                {/*<Card className="container-fluid mt-4" title={<label>Đăng ký sử dụng dịch vụ hỗ trợ trường hợp khẩn cấp (nếu cần) </label>} size="small">*/}
                {/*{props.form.getFieldValue("support_register") === "1" ? (*/}
                {/*    <React.Fragment>*/}
                {/*      <div className="input-group">*/}
                {/*        <div className="label-thong-tin">*/}
                {/*          <label>Đăng ký sử dụng dịch vụ hỗ trợ trường hợp khẩn cấp (nếu cần)</label>*/}
                {/*        </div>*/}
                {/*        <div className="input-group">*/}
                {/*          <InputWithLabel*/}
                {/*              form={props.form}*/}
                {/*              label="Họ tên đầu mối"*/}
                {/*              name="fullname_support"*/}
                {/*              wrapClass="col-md-6 col-lg-3"*/}
                {/*              isRequired={true}*/}
                {/*              maxLength={255}*/}
                {/*              onBlur={(e) =>  onChangeFormatInput('fullname_support', e.target.value, 3)}*/}
                {/*          />*/}
                {/*          <InputWithLabel*/}
                {/*              form={props.form}*/}
                {/*              label="Chức vụ"*/}
                {/*              name="position_support"*/}
                {/*              wrapClass="col-md-6 col-lg-3"*/}
                {/*              maxLength={255}*/}
                {/*          />*/}
                {/*          <InputWithLabel*/}
                {/*              form={props.form}*/}
                {/*              label="Email"*/}
                {/*              name="email_support"*/}
                {/*              wrapClass="col-md-6 col-lg-3"*/}
                {/*              isRequired={true}*/}
                {/*              maxLength={255}*/}
                {/*          />*/}
                {/*          <InputWithLabel*/}
                {/*              form={props.form}*/}
                {/*              label="Số điện thoại"*/}
                {/*              name="phone_support"*/}
                {/*              wrapClass="col-md-6 col-lg-3"*/}
                {/*              isRequired={true}*/}
                {/*              maxLength={255}*/}
                {/*          />*/}

                {/*        </div>*/}
                {/*      </div>*/}
                {/*    </React.Fragment>*/}
                {/*) : (*/}
                {/*    ""*/}
                {/*)}*/}
                {/*</Card>*/}
                <Card
                    className="container-fluid mt-4"
                    title={<label>Mẫu đăng ký</label>}
                    size="small"
                >
                    <div className="input-group">
                        <RadioWithLabel
                            options={{ 1: "Điện tử", 2: "Giấy", 3: "Scan" }}
                            label="Loại hồ sơ"
                            name="type_docurment"
                            wrappedClass="col-md-6 col-lg-3 select-doi-tuong "
                            form={props.form}
                            isRequired={true}
                            onChange={onChangeTypeDoc}
                        />
                        <div className="col-md-6 col-lg-3 mb-2">
                            <label className="invisible label-hide">
                                11111998
                            </label>
                            <button
                                onClick={() => onGenerateFile(1)}
                                className="btn btn-outline-success form-control"
                            >
                                Tải xuống DK-01.02
                            </button>
                        </div>
                        {Number(props.form.getFieldValue("provider_id")) ===
                        2 ? (
                            <div className="col-md-6 col-lg-3 mb-2">
                                <label className="invisible label-hide">
                                    11111998
                                </label>
                                <button
                                    onClick={() => onGenerateFile(2)}
                                    className="btn btn-outline-primary form-control"
                                >
                                    Tải xuống DK-02
                                </button>
                            </div>
                        ) : (
                            ""
                        )}

                        {/*<div className="col-md-6 col-lg-3 mb-2">*/}
                        {/*  <label className="invisible label-hide">11111998</label>*/}
                        {/*  <button onClick={() => onGenerateFile(3)} className="btn btn-outline-primary form-control">*/}
                        {/*    Tải xuống DK-03*/}
                        {/*  </button>*/}
                        {/*</div>*/}
                    </div>
                    <div className="input-group m-t-xs-4">
                        <InputFileUpload
                            defaultLabel={fileLabelRegister}
                            classWrapped="col-md-6 col-lg-3 input-upload-file"
                            label="Tải file DK 01.02"
                            name="file_register_paper"
                            form={props.form}
                            onClickDownloadFile={() => onPreviewFile(4)}
                            extentionsAllow={["pdf", "PDF", "png", "jpg"]}
                            accept={[".pdf", ".png", ".jpg", ".jpeg"]}
                            onChangeSetField={changeSetField}
                            nameFiledSetValue={"date_expired_cert"}
                        />
                        {Number(props.form.getFieldValue("provider_id")) ===
                        2 ? (
                            <InputFileUpload
                                nameFiledSetValue={""}
                                classWrapped="col-md-6 col-lg-3 input-upload-file"
                                label="Tải file DK-02"
                                name="file_dk_02"
                                form={props.form}
                                onClickDownloadFile={
                                    isFileDk02 ? () => onPreviewFile(7) : ""
                                }
                                extentionsAllow={["pdf", "PDF", "png", "jpg"]}
                                accept={[".pdf", ".png", ".jpg", ".jpeg"]}
                            />
                        ) : (
                            ""
                        )}
                        {/*<InputFileUpload*/}
                        {/*    nameFiledSetValue={""}*/}
                        {/*    classWrapped="col-md-6 col-lg-3 input-upload-file"*/}
                        {/*    label="Tải file DK-03"*/}
                        {/*    name="file_dk_03"*/}
                        {/*    form={props.form}*/}
                        {/*    onClickDownloadFile={isFileDk03 ? ()=>onPreviewFile(14) : ''}*/}
                        {/*    extentionsAllow={['pdf', 'PDF', 'png', 'jpg']}*/}
                        {/*    accept={[".pdf", ".png", ".jpg", ".jpeg"]}*/}
                        {/*/>*/}
                    </div>
                </Card>
            </Form>
            <div className="input-group d-flex justify-content-center p-5 mt-2">
                <div className="mt-2">
                    <ButtonOnSave
                        onClick={() => {
                            update(1);
                        }}
                        label="Lưu nháp"
                        className={"btn btn-primary btn-sm"}
                    />
                </div>
                <div className="mt-2">
                    <ButtonOnSave
                        onClick={() => {
                            update(2);
                        }}
                        label="Trình duyệt"
                        className={"btn btn-success btn-sm"}
                    />
                </div>
                <div className="mt-2">
                    <ButtonCancel
                        onClick={() => {
                            props.history.goBack();
                        }}
                        className={"btn btn-default btn-sm"}
                    />
                </div>
            </div>
        </PageWrapper>
    );
};

const WrappedRequestCTSPersonalUpdate = Form.create<Props>({
    name: "RequestCTSPersonalUpdate",
})(RequestCTSPersonalUpdate);

export default WrappedRequestCTSPersonalUpdate;
