import React, {Component} from "react";
import {Modal, Switch, Input} from 'antd';
import InputUploadFile from "../../components/common/form/input-with-label/InputUploadFile";
import LabelInput from "../../components/common/form/input-with-label/label/LabelInput";

const {TextArea} = Input;

interface Props {
    handleCancel?: any;
    handleImport?: any;
    value?: string;
    visible: boolean;
    loading?: boolean;
    title?: string;
    onChange?: any;
    error?: string;
    onchangeFileUpload?: any;
    onChangeText?: any;

    fileNamePassport?: string;
    fileNamePresentPassport?: string;
    fileNameCompany?: string;
    fileNameDK01?: string;
    fileNameDK02?: string;
    valueTextArea?: string;

    filePassport?: boolean;
    filePresentPassport?: boolean;
    fileCompany?: boolean;
    fileDk01?: boolean;
    fileDk02?: boolean;

    statusFileDK01?: boolean;
    statusFileDK02?: boolean;
    statusFilePassport?: boolean;
    statusFilePresentPassport?: boolean;
    statusFileCompany?: boolean;

    rows?: number;
}

interface State {
    visible: boolean;
}

export default class ModalUploadFile extends Component<Props, State> {
    render() {
        return (
            <div>
                <Modal
                    title={this.props.title ? this.props.title : 'Tải file hồ sơ'}
                    visible={this.props.visible}
                    onOk={this.props.handleImport}
                    onCancel={this.props.handleCancel}
                    destroyOnClose={true}
                    okText="Tải lên"
                    cancelText="Đóng"
                    className="modal-xs"
                    width="40%"
                    closable={false}
                >
                    {
                        this.props.filePassport ?
                            <div className={"d-flex justify-content-between"}>
                                <Switch
                                    disabled={true}
                                    className={"mt-4"}
                                    checkedChildren="Hợp lệ"
                                    unCheckedChildren="Không hợp lệ"
                                    checked={this.props.statusFilePassport}
                                    style={(this.props.statusFilePassport === true) ? ({backgroundColor: "green"}) : ({backgroundColor: "red"})}
                                />
                                <InputUploadFile
                                    name="file_passport"
                                    classWrapped="col-md-10 custom-input-file"
                                    label="CMND/Hộ chiếu"
                                    filename={this.props.fileNamePassport}
                                    onChangeFileUpload={this.props.onchangeFileUpload}
                                    extentionsAllow={['pdf']}
                                    accept={".pdf"}
                                />
                            </div>

                            : ""
                    }
                    {
                        this.props.fileCompany ?
                            <div className={"d-flex justify-content-between"}>
                                <Switch
                                    className={"mt-4"}
                                    checkedChildren="Hợp lệ"
                                    unCheckedChildren="Không hợp lệ"
                                    checked={this.props.statusFileCompany}
                                    disabled={true}
                                    style={(this.props.statusFileCompany === true) ? ({backgroundColor: "green"}) : ({backgroundColor: "red"})}
                                />
                                <InputUploadFile
                                    name="file_company"
                                    classWrapped="col-md-10 custom-input-file"
                                    label="File giấy tờ pháp lý"
                                    filename={this.props.fileNameCompany}
                                    onChangeFileUpload={this.props.onchangeFileUpload}
                                    extentionsAllow={['pdf']}
                                    accept={".pdf"}
                                />
                            </div>

                            : ""
                    }
                    {
                        this.props.filePresentPassport ?
                            <div className={"d-flex justify-content-between"}>
                                <Switch
                                    className={"mt-4"}
                                    checkedChildren="Hợp lệ"
                                    unCheckedChildren="Không hợp lệ"
                                    disabled={true}
                                    checked={this.props.statusFilePresentPassport}
                                    style={(this.props.statusFilePresentPassport === true) ? ({backgroundColor: "green"}) : ({backgroundColor: "red"})}

                                />
                                <InputUploadFile
                                    name="file_present_passport"
                                    classWrapped="col-md-10 custom-input-file"
                                    label="File cmnd/hộ chiếu người đại diện"
                                    filename={this.props.fileNamePresentPassport}
                                    onChangeFileUpload={this.props.onchangeFileUpload}
                                    extentionsAllow={['pdf']}
                                    accept={".pdf"}
                                />
                            </div>

                            : ""
                    }
                    {
                        this.props.fileDk01 ?
                            <div className={"d-flex justify-content-between"}>
                                <Switch
                                    className={"mt-4"}
                                    checkedChildren="Hợp lệ"
                                    unCheckedChildren="Không hợp lệ"
                                    checked={this.props.statusFileDK01}
                                    disabled={true}
                                    style={(this.props.statusFileDK01 === true) ? ({backgroundColor: "green"}) : ({backgroundColor: "red"})}
                                />
                                <InputUploadFile
                                    name="file_dk01"
                                    classWrapped="col-md-10 custom-input-file"
                                    label="Mẫu DK-01.01"
                                    filename={this.props.fileNameDK01}
                                    onChangeFileUpload={this.props.onchangeFileUpload}
                                    extentionsAllow={['pdf', 'PDF']}
                                    accept={[".pdf", ".PDF"]}
                                />
                            </div>

                            : ""
                    }
                    {
                        this.props.fileDk02 ?
                            <div className={"d-flex justify-content-between"}>
                                <Switch
                                    className={"mt-4"}
                                    checkedChildren="Hợp lệ"
                                    unCheckedChildren="Không hợp lệ"
                                    checked={this.props.statusFileDK02}
                                    style={(this.props.statusFileDK02 === true) ? ({backgroundColor: "green"}) : ({backgroundColor: "red"})}
                                    disabled={true}
                                />
                                <InputUploadFile
                                    name="file_dk02"
                                    classWrapped="col-md-10 custom-input-file"
                                    label="Mẫu DK-01.02"
                                    filename={this.props.fileNameDK02}
                                    onChangeFileUpload={this.props.onchangeFileUpload}
                                    extentionsAllow={['pdf', 'PDF']}
                                    accept={[".pdf", ".PDF"]}
                                />
                            </div>

                            : ""
                    }
                    <div>
                        <LabelInput
                            nameFor="reason_refuse"
                            label="Lý do từ chối của nghiệp vụ"
                        />
                        <TextArea
                            className="form-control"
                            value={this.props.valueTextArea ? this.props.valueTextArea : ""}
                            onChange={this.props.onChangeText}
                            disabled={true}
                        />
                    </div>

                    {
                        this.props.error ?
                            <div className="col-md-12 text-center mt-1">
                                <p className="text-danger">{this.props.error}</p>
                            </div> : ""
                    }
                </Modal>
            </div>
        );
    }
}
