import BaseServices from "../base/BaseServices";

const getFileDk03CustomerSign = async (data) => {
    return await BaseServices.request(
        `get-file-dk03-customer-sign`,
        data,
        "POST"
    );
};

const getCertInToken = async (dataSend) => {
    return await BaseServices.requestGenCert(
        "getcert",
        dataSend,
        "GET"
    );
};

const updateFileDk03Signed = async (data) => {
    return await BaseServices.request(
        `update-signed-dk03`,
        data,
        "POST"
    );
};

export const SignDk03Services = {
    getFileDk03CustomerSign,
    updateFileDk03Signed,
    getCertInToken,
};
