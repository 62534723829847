import React, { Fragment, useEffect, useState } from "react";
import { Card, Checkbox, Form, Tag } from "antd";
import { FormComponentProps } from "antd/lib/form";
import PageWrapper from "pages/wrapper/PageWrapper";
import ButtonCancel from "components/common/form/button/ButtonCancel";
import InputWithLabel from "components/common/form/input-with-label/InputWithLabel";
import { match } from "react-router";
import SelectWithLabel from "../../components/common/form/input-with-label/SelectWithLabel";
import { FILE_TYPE_OPTIONS } from "./Enum";
import RadioWithLabel from "components/common/form/input-with-label/RadioWithLabel";
import {onFailAction} from "helpers/SwalCommon";
import { ChangeInfoCertServices } from '../../services/change-info-cert/ChangeInfoCertServices';
import AntModal from "components/common/modal/AntModal";
import { Table as AntTable } from "antd";
import TableActionButton from "./../../components/common/table/action-button/TableActionButton";
import { RequestDigitalCertificatePersonalServices } from "../../services/request-digital-certificate-personal/RequestDigitalCertificatePersonalServices";
import _ from "lodash";
import { formatReadBase64 } from "helpers/NewCaCrmHelper";
import { TYPE_DEVICE } from "helpers/enum/TypeDevice";
import { PERSONAL_TYPE_IDENTITY, TypePersonal } from "helpers/enum/ServiceEnum";

interface Props extends FormComponentProps {
    match: match<{ id: string }>;
    history: any;
}

export const PreviewPersonal: React.FC<Props> = (props) => {
    const id = props.match.params.id;
    const [isOrganization, setIsOrganization] = useState(true);
    const [visibleModal, setVisibleModal] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);
    const [file, setFile] = useState("");
    const [customerCert, setCustomerCert] = useState([]);
    const [status, setStatus] = useState(0);
    const [reason, setReason] = useState("");
    const [province, setProvince] = useState({});
    const [district, setDistrict] = useState({});
    const [labelCode, setLabelCode] = useState("Chứng minh nhân dân");
    const [showEmail, setShowEmail] = useState(false);
    const [linkSignRequestChange, setLinkSignRequestChange] = useState("");
    const [password, setPassword] = useState("");

    const fetchCustomer = async () => {
        const result = await ChangeInfoCertServices.getInfo(id);

        const province =
            await RequestDigitalCertificatePersonalServices.getProvince();
        setProvince(_.mapValues(_.keyBy(province, "newtel_code"), "fullname"));
        const district =
            await RequestDigitalCertificatePersonalServices.getDistrictByProvince(
                result.data.province_code
            );
        setDistrict(
            _.mapValues(_.keyBy(district, "newtel_district_code"), "fullname")
        );

        props.form.setFieldsValue({
            uid: result.data.uid.toString(),
            code: result.data.code,
            fullname: result.data.fullname,
            email: result.data.email,
            contact_name: result.data.contact_name,
            contact_position: result.data.contact_position,
            contact_phone: result.data.contact_phone,
            contact_mobile: result.data.contact_mobile,
            contact_email: result.data.contact_email,
            contact_fax: result.data.contact_fax,
            contact_address: result.data.contact_address,
            promotion_code: result.data.promotion_code,
            change_type: result.data.type_change.toString(),
            organization_name: result.data.or_company,
            organization_position: result.data.or_position,
            organization_department: result.data.or_department,
            is_organization: result.data.or_is.toString(),
            identity_type: result.data.identity_type.toString(),
            province_code: result.data.province_code.toString(),
            district_code: result.data.district_code.toString(),
        });
        const arr: any = [];
        arr.push(result.data);
        setCustomerCert(arr);
        setStatus(result.data.status);
        setReason(result.data.reason);
        setShowEmail(result.data.show_email);
        setLinkSignRequestChange(result.data.link_sign_request_change);
        setPassword(result.data.password);

        if (result.data.or_is.toString() === "1") {
            setIsOrganization(false);
        } else {
            setIsOrganization(true);
        }

        if (Number(result.data.type) === 1) {
            if (Number(result.data.identity_type) === 1) {
                setLabelCode("Mã số thuế");
            }
            if (Number(result.data.identity_type) === 2) {
                setLabelCode("Mã ngân sách");
            }
            if (Number(result.data.identity_type) === 3) {
                setLabelCode("Số quyết định");
            }
            if (Number(result.data.identity_type) === 4) {
                setLabelCode("Mã bảo hiểm xã hội");
            }
            if (Number(result.data.identity_type) === 5) {
                setLabelCode("Giáy phép đầu tư");
            }
        } else {
            switch (Number(result.data.identity_type)) {
                case PERSONAL_TYPE_IDENTITY.CMND:
                    setLabelCode(
                        TypePersonal.TYPE_IDENTITY[PERSONAL_TYPE_IDENTITY.CMND]
                    );
                    break;
                case PERSONAL_TYPE_IDENTITY.HC:
                    setLabelCode(
                        TypePersonal.TYPE_IDENTITY[PERSONAL_TYPE_IDENTITY.HC]
                    );
                    break;
                case PERSONAL_TYPE_IDENTITY.CCCD:
                    setLabelCode(
                        TypePersonal.TYPE_IDENTITY[PERSONAL_TYPE_IDENTITY.CCCD]
                    );
                    break;
                case PERSONAL_TYPE_IDENTITY.MBHXH:
                    setLabelCode(
                        TypePersonal.TYPE_IDENTITY[PERSONAL_TYPE_IDENTITY.MBHXH]
                    );
                    break;
            }
        }
    };
    const onOkModal = () => {
        setVisibleModal(false);
    };

    const onViewFileDoc = async (type) => {
        try {
            setVisibleModal(true);
            setLoadingModal(true);
            const data = await ChangeInfoCertServices.getFileChange(id, type);
            let contentRead = formatReadBase64(data.base64);
            setFile(contentRead);
        } catch (error) {
            onFailAction("Có lỗi xảy ra khi xem trước file!");
            setVisibleModal(false);
        } finally {
            setLoadingModal(false);
        }
    };

    useEffect(() => {
        fetchCustomer();
        // eslint-disable-next-line
    }, []);

    const renderActionButton = (text, record, index) => {
        return record.status === 5 ? (
            <TableActionButton
                onClickGenCert={() => window.open("https://gen.fastca.vn/")}
            />
        ) : (
            ""
        );
    };
    const renderActionTypeDevice = (e) => {
        return (
            <Tag color={`${TYPE_DEVICE[e.type_device].class}`}>
                {TYPE_DEVICE[e.type_device].label}
            </Tag>
        );
    };

    const columns = [
        {
            title: "SubjectDN",
            dataIndex: "",
            render: (e) => {
                if (e.info_certificate) {
                    return e.info_certificate.subjectDN
                }else if (e.info_compensation_cert) {
                    return e.info_compensation_cert.subjectdn
                }else {
                    return "";
                }
            }
        },
        {
            title: "Serial CTS",
            dataIndex: "",
            render: (e) => {
                if (e.info_certificate) {
                    return e.info_certificate.certificateSerial
                }else if (e.info_compensation_cert) {
                    return e.info_compensation_cert.serial_cert
                }else {
                    return "";
                }
            }
        },
        {
            title: "Đối tượng",
            dataIndex: "",
            render: (e) => {
                if (e.info_certificate) {
                    if(Number(e.info_certificate.object) === 1){
                        return <p>Cấp mới</p>;
                    } else if (Number(e.info_certificate.object) === 2) {
                        return <p>Gia hạn</p>;
                    } else if (Number(e.info_certificate.object) === 3) {
                        return <p>Chuyển đổi</p>;
                    } else if (Number(e.info_certificate.object) === 4) {
                        return <p>Thay đổi thông tin</p>;
                    } else if (Number(e.info_certificate.object) === 5) {
                        return <p>Cấp lại</p>;
                    } else {
                        return <p>Bảo hành</p>;
                    }
                }else {
                    return <p>Cấp bù</p>;
                }
            },
        },
        {
            title: 'Loại  thiết bị',
            dataIndex: "",
            render: renderActionTypeDevice,
        },
        {
            title: "Tác vụ",
            render: renderActionButton,
        },
    ];

    const renderRowClass = () => {
        return "table-extra-info";
    };

    return (
        <PageWrapper title="Xem chi tiết Yêu cầu điều chỉnh thông tin cá nhân">
            <Form>
                {linkSignRequestChange ? (
                    <div className="input-group">
                        <Fragment>
                            <InputWithLabel
                                label="Link ký xác nhận điều chỉnh thông tin"
                                form={props.form}
                                defaultValue={linkSignRequestChange}
                                name="link"
                                isDisabled={true}
                                wrapClass="col-md-6 pl-0"
                            />
                            <InputWithLabel
                                label="Mã xác thực"
                                form={props.form}
                                name="password"
                                defaultValue={password}
                                isDisabled={true}
                                wrapClass="col-md-6 pr-0"
                            />
                        </Fragment>
                    </div>
                ) : (
                    ""
                )}
                <Card
                    className="m-r-15-i m-l-15-i"
                    title={<label>Thông tin điều chỉnh</label>}
                    size="small"
                >
                    {status === 4 || status === 2 ? (
                        <div className="input-group">
                            {" "}
                            <InputWithLabel
                                label="Lý do từ chối"
                                form={props.form}
                                name="reason-deny"
                                isDisabled={true}
                                wrapClass="col-md"
                                defaultValue={reason}
                            />{" "}
                        </div>
                    ) : (
                        ""
                    )}
                    <div className="input-group">
                        <InputWithLabel
                            form={props.form}
                            label="Mã khách hàng"
                            name="uid"
                            wrapClass="col-md-3"
                            maxLength={255}
                            isDisabled={true}
                        />
                        <SelectWithLabel
                            options={TypePersonal.TYPE_IDENTITY}
                            name="identity_type"
                            wrappedClass="col-md-3"
                            form={props.form}
                            label={"Loại định danh"}
                            isRequired={true}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label={labelCode}
                            name="code"
                            wrapClass="col-md-3"
                            isDisabled={true}
                            isRequired={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Tên khách hàng"
                            name="fullname"
                            wrapClass="col-md-3"
                            maxLength={255}
                            isDisabled={true}
                            isRequired={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Email"
                            name="email"
                            wrapClass="col-md-3"
                            isDisabled={true}
                            isRequired={true}
                        />
                        <SelectWithLabel
                            options={province}
                            name="province_code"
                            wrappedClass="col-md-3"
                            form={props.form}
                            label={"Tỉnh thành"}
                            isDisabled={true}
                            isProvince={true}
                        />
                        <SelectWithLabel
                            options={district}
                            name="district_code"
                            wrappedClass="col-md-3"
                            form={props.form}
                            label={"Quận huyện"}
                            isDisabled={true}
                        />
                        <div className="col-md-3">
                            <label className="hidden">label</label>
                            <button
                                className="btn btn-outline-primary form-control"
                                onClick={() => onViewFileDoc(4)}
                            >
                                Xem file CMND/Hộ chiếu
                            </button>
                        </div>
                        <RadioWithLabel
                            label={
                                "Trường hợp cá nhân thuộc tổ chức doanh nghiệp"
                            }
                            options={{ 1: "Có", 2: "Không" }}
                            name="is_organization"
                            wrappedClass="col-md-3 radio-to-chuc"
                            form={props.form}
                            isRequired={true}
                            isDisabled={true}
                        />
                        <React.Fragment>
                            <div
                                className="input-group"
                                hidden={isOrganization}
                            >
                                <InputWithLabel
                                    form={props.form}
                                    label="Tên tổ chức"
                                    name="organization_name"
                                    wrapClass="col-md-3"
                                    isRequired={true}
                                    isDisabled={true}
                                    maxLength={255}
                                />
                                <InputWithLabel
                                    form={props.form}
                                    label="Tên phòng ban"
                                    name="organization_department"
                                    wrapClass="col-md-3"
                                    maxLength={255}
                                    isDisabled={true}
                                />
                                <InputWithLabel
                                    form={props.form}
                                    label="Chức vụ"
                                    name="organization_position"
                                    wrapClass="col-md-3"
                                    maxLength={255}
                                    isDisabled={true}
                                />
                                <div className="col-md-3">
                                    <label className="hidden">label</label>
                                    <button
                                        className="btn btn-outline-primary form-control"
                                        onClick={() => onViewFileDoc(5)}
                                    >
                                        Xem File xác nhận tổ chức
                                    </button>
                                </div>
                            </div>
                        </React.Fragment>
                        <div className={"col-md-4"}>
                            <Form.Item>
                                <Checkbox checked={showEmail}>
                                    Hiển thi Email
                                </Checkbox>
                            </Form.Item>
                        </div>
                    </div>
                </Card>
                <Card
                    className="m-r-15-i m-l-15-i mt-1"
                    title={<label>Thông tin liên hệ</label>}
                    size="small"
                >
                    <div className="input-group">
                        <InputWithLabel
                            form={props.form}
                            label="Họ và tên"
                            name="contact_name"
                            wrapClass="col-md-3"
                            maxLength={255}
                            isDisabled={true}
                            isRequired={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Chức vụ"
                            name="contact_position"
                            wrapClass="col-md-3"
                            maxLength={255}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Số điện thoại"
                            name="contact_phone"
                            wrapClass="col-md-3"
                            maxLength={255}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Số di động"
                            name="contact_mobile"
                            wrapClass="col-md-3"
                            maxLength={255}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Email"
                            name="contact_email"
                            wrapClass="col-md-3"
                            maxLength={255}
                            isDisabled={true}
                            isRequired={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Fax"
                            name="contact_fax"
                            wrapClass="col-md-3"
                            maxLength={255}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Địa chỉ"
                            name="contact_address"
                            wrapClass="col-md-6"
                            maxLength={255}
                            isDisabled={true}
                        />
                    </div>
                </Card>
                <Card
                    className="m-r-15-i m-l-15-i mt-1"
                    title={<label>File điều chỉnh thông tin</label>}
                    size="small"
                >
                    <div className="input-group">
                        <SelectWithLabel
                            options={FILE_TYPE_OPTIONS}
                            form={props.form}
                            label="Loại điều chỉnh"
                            name="change_type"
                            isRequired={true}
                            wrappedClass="col-md-2"
                            defaultValue={""}
                            isDisabled={true}
                            placeholder="Chọn loại điều chỉnh"
                        />
                        <div className="col-md-3">
                            <label className="hidden">label</label>
                            <button
                                className="btn btn-outline-primary form-control"
                                onClick={() => onViewFileDoc(3)}
                            >
                                File yêu cầu điều chỉnh thông tin
                            </button>
                        </div>
                        <InputWithLabel
                            form={props.form}
                            label="Mã CTKM"
                            name="promotion_code"
                            wrapClass="col-md-3"
                            maxLength={255}
                        />
                    </div>
                </Card>
                <Card
                    className="m-r-15-i m-l-15-i mt-4"
                    title={<label>Danh sách chứng thư số cần gen Cert </label>}
                    size="small"
                >
                    <AntTable
                        columns={columns}
                        style={{ width: "100%" }}
                        dataSource={customerCert}
                        pagination={false}
                        rowKey={"id"}
                        rowClassName={renderRowClass}
                        bordered
                    />
                </Card>
            </Form>
            <AntModal
                visible={visibleModal}
                loading={loadingModal}
                className="w-75 h-75"
                bodyStyle={{ height: "700px" }}
                style={{ top: "20px" }}
                onCLickOk={onOkModal}
            >
                <iframe
                    title="File giấy tờ"
                    src={`${file}`}
                    height="100%"
                    width="100%"
                />
            </AntModal>
            <div className="input-group d-flex justify-content-center p-5 mt-4">
                <div className="">
                    <ButtonCancel
                        onClick={() => {
                            props.history.goBack();
                        }}
                        className={"btn btn-default btn-sm"}
                    />
                </div>
            </div>
        </PageWrapper>
    );
};

const WrappedPreviewPersonal = Form.create<Props>({
    name: "PreviewPersonal",
})(PreviewPersonal);

export default WrappedPreviewPersonal;
