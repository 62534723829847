import React, { Component } from 'react'
import { Statistic, Icon } from 'antd';
import AmChartColumn from "../../components/common/chart/AmChartColumn";
import { DashboardService } from "../../services/dashboard/DashboardServices";
import ListTop from "./ListTop";
import '../../public/js/index';
import $ from "jquery";
import moment from "moment";
interface Props {

}
interface State {

}

export default class SaleDashboard extends Component<Props, State> {
    state = {
        dataSourceColumnChart: [{
            object: 0,
            currentMonth: 0,
            lastMonth: 0,
        }],
        currentMonth: '',
        lastMonth: '',
        totalAgency: 0,
        totalContributor: 0,
        totalReqDigitalCert: 0,
        totalReqTokenCTS: 0,
        colorCurrentMonth: '',
        colorLastMonth: '',
    };

    fetchData = async () => {
        const resultTotalReqByObject = await DashboardService.totalByObject();
        const totalDataForSale = await DashboardService.totalDataForSale();
        this.setState({
            dataSourceColumnChart: resultTotalReqByObject.dataForSale,
            currentMonth: resultTotalReqByObject.currentMonth,
            lastMonth: resultTotalReqByObject.lastMonth,
            colorCurrentMonth: resultTotalReqByObject.colorCurrentMonth,
            colorLastMonth: resultTotalReqByObject.colorLastMonth,
            totalAgency: totalDataForSale.totalAgency,
            totalContributor: totalDataForSale.totalContributor,
            totalReqDigitalCert: totalDataForSale.totalReqDigitalCert,
            totalReqTokenCTS: totalDataForSale.totalReqTokenCTS,
        });
    };
    componentDidMount() {
        this.fetchData();
    }
    componentDidUpdate() {
        var listDiv = $("#root").nextAll();
        listDiv.each(function (index) {
            if ($(this).prop("tagName") === 'DIV') {
                listDiv[index].setAttribute('hidden', 'hidden')
            }
        });
    }
    formatDateTime = (date: string) => {
        if (date) {
            return moment(date).format("DD/MM/YYYY");
        } else {
            return "";
        }
    };

    render() {
        return (
            <div className="SaleDashboard Dashboard container-fluid card_background">
                <div className="row card-dashboard card_background" >
                    <div className="col-md-6 col-lg-3 py-3">
                        <div className={"box-shadow Dashboard__box Dashboard__box--orange"}>
                            <div className="p-3 h--65">
                                <Statistic
                                    title="Số lượng đại lý"
                                    value={this.state.totalAgency}
                                    valueStyle={{ color: '#fff', fontSize: '20px', fontWeight: 'bold' }}
                                    style={{ color: '#fff' }}
                                />
                            </div>
                            <div className="pl-3 pt-2 Dashboard__box--time">
                                <Statistic
                                    value={'update: ' + moment(new Date()).format("DD/MM/YYYY").toLocaleString()}
                                    valueStyle={{ color: '#fff', fontSize: '13px' }}
                                    prefix={<Icon type="clock-circle" />}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3 py-3">
                        <div className={"box-shadow Dashboard__box Dashboard__box--green"}>
                            <div className="p-3 h--65">
                                <Statistic
                                    title="Số lượng cộng tác viên"
                                    value={this.state.totalContributor}
                                    valueStyle={{ color: '#fff', fontSize: '20px', fontWeight: 'bold' }}
                                    style={{ color: '#fff' }}
                                />
                            </div>
                            <div className="pl-3 pt-2 Dashboard__box--time">
                                <Statistic
                                    value={'update: ' + moment(new Date()).format("DD/MM/YYYY").toLocaleString()}
                                    valueStyle={{ color: '#fff', fontSize: '13px' }}
                                    prefix={<Icon type="clock-circle" />}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3 py-3">
                        <div className={"box-shadow Dashboard__box Dashboard__box--red"}>
                            <div className="p-3 h--65">
                                <Statistic
                                    title="Số lượng yêu cầu chứng thư số"
                                    value={this.state.totalReqDigitalCert}
                                    valueStyle={{ color: '#fff', fontSize: '20px', fontWeight: 'bold' }}
                                    style={{ color: '#fff' }}
                                />
                            </div>
                            <div className="pl-3 pt-2 Dashboard__box--time">
                                <Statistic
                                    value={'update: ' + moment(new Date()).format("DD/MM/YYYY").toLocaleString()}
                                    valueStyle={{ color: '#fff', fontSize: '13px' }}
                                    prefix={<Icon type="clock-circle" />}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3 py-3">
                        <div className={"box-shadow Dashboard__box Dashboard__box--blue"}>
                            <div className="p-3 h--65">
                                <Statistic
                                    title="Số lượng yêu cầu tài liệu bán hàng"
                                    value={this.state.totalReqTokenCTS}
                                    valueStyle={{ color: '#fff', fontSize: '20px', fontWeight: 'bold' }}
                                    style={{ color: '#fff' }}
                                />
                            </div>
                            <div className="pl-3 pt-2 Dashboard__box--time">
                                <Statistic
                                    value={'update: ' + moment(new Date()).format("DD/MM/YYYY").toLocaleString()}
                                    valueStyle={{ color: '#fff', fontSize: '13px' }}
                                    prefix={<Icon type="clock-circle" />}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="chartHome">
                    <div className="row chartHome">
                        <div className="col-md-12 py-3">
                            <div className="card box-shadow">
                                <div className={"card-body "} id={"columnChart"} />
                                <AmChartColumn data={this.state.dataSourceColumnChart}
                                    currentMonth={this.state.currentMonth}
                                    lastMonth={this.state.lastMonth}
                                    colorLastMonth={this.state.colorLastMonth}
                                    colorCurrentMonth={this.state.colorCurrentMonth} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8 py-3 ">
                            <div className="card box-shadow h--100">
                                <ListTop />
                            </div>
                        </div>
                        <div className="col-md-4 py-3 ">
                            <div className="card box-shadow wrap-banner-QC h--100
                             "
                            >
                                <img
                                    src={"/images/ctkmnew.png"}
                                    width="100%"
                                    height="100%"
                                    className="bannerQC"
                                    alt="ctkm"
                                />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

