import React, { Component } from "react";
import { Layout } from "antd";
import MyHeader from "./header/Header";
import MyContent from "./content/Content";
import MyFooter from "./footer/Footer";
import { PopupNoti } from "./content/PopupNoti";

interface Props {
}
interface State {}

export default class RightPageSide extends Component<Props, State> {
  state = {};

  render() {
    return (
      <Layout>
        <MyHeader/>
        <PopupNoti />
        <MyContent/>
        <MyFooter/>
      </Layout>
    );
  }
}
