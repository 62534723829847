import { Dropdown, Tag, Tooltip } from "antd";
import { formatDateTime } from "helpers/NewCaCrmHelper";
import { onFailAction, onSuccessAction } from "helpers/SwalCommon";
import React, { useState } from "react";
import { RouteComponentProps } from "react-router";
import { loading } from "../../components/common/loading/Loading";
import Table from "../../components/common/table/Table";
import BaseServices from "../../services/base/BaseServices";
import { DocLegalManagerService } from "../../services/doc-legal-manager/DocLegalManagerService";
import PageWrapper from "../wrapper/PageWrapper";
import ModalDeny from './../../components/common/form/ModalDeny';
import MenuDropDoc from "./MenuDropDoc";
import ModalUploadFile from "./ModalUploadFile";
import DocLegalManagerSearchForm from "./search/DocLegalManagerSearchForm";

interface Props extends RouteComponentProps {
}

export const DocLegalManager: React.FC<Props> = props => {
    const [file, setFile] = useState("");
    const [id, setId] = useState("");

    const [filePassportCN, setFilePassportCN] = useState(false);
    const [fileOrganizationCN, setFileOrganizationCN] = useState(false);
    const [fileOrganizationTC, setFileOrganizationTC] = useState(false);
    const [fileDeputyPassPortTC, setFileDeputyPassPortTC] = useState(false);
    const [fileDK0101, setFileDK0101] = useState(false);
    const [fileDK0102, setFileDK0102] = useState(false);
    const [fileDK02, setFileDK02] = useState(false);

    const [statusFilePassportCN, setStatusFilePassportCN] = useState(false);
    const [statusFileOrganizationCN, setStatusFileOrganizationCN] = useState(false);
    const [statusFileOrganizationTC, setStatusFileOrganizationTC] = useState(false);
    const [statusFileDeputyPassPortTC, setStatusFileDeputyPassPortTC] = useState(false);
    const [statusFileDK0101, setStatusFileDK0101] = useState(false);
    const [statusFileDK0102, setStatusFileDK0102] = useState(false);
    const [statusFileDK02, setStatusFileDK02] = useState(false);

    const [fileNamePassportCN, setFileNamePassportCN] = useState("");
    const [fileNameOrganizationCN, setFileNameOrganizationCN] = useState("");
    const [fileNameOrganizationTC, setFileNameOrganizationTC] = useState("");
    const [fileNameDeputyPassPortTC, setFileNameDeputyPassPortTC] = useState("");
    const [fileNameDK0101, setFileNameDK0101] = useState("");
    const [fileNameDK0102, setFileNameDK0102] = useState("");
    const [fileNameDK02, setFileNameDK02] = useState("");
    const { REACT_APP_BASE_API_URL } = window['runConfig'];
    const [modalUpload, setModalUpload] = useState({
        visible_modal_upload: false,
        loading: false,
        title_modal: "Cập nhật hồ sơ",
        base64: "",
        type_file: "",
        idDoc: "",
        text_deny: "",
        error_text_deny: "",
        object: null,
        customer_type: null,
        reason_refuse: "",
    });

    const [modalDestroy, setModalDestroy] = useState({
        visible_modal_deny: false,
        loading: false,
        title_modal: "",
        id: "",
        type: "",
        reason: "",
        error_reason: "",
    });

    const handleModalDenyCancel = () => {
        setModalDestroy({ ...modalDestroy, 'visible_modal_deny': false })
    };

    // nút xem file
    const renderActionViewFile = (text, record, index) => {
        let listDoc: Array<number> = [];
        record.list_document.forEach(function (value) {
            listDoc.push(value.type);
        });
        return (
            <div className="row justify-content-center">
                <Dropdown
                    overlay={
                        <MenuDropDoc onClick={(index) => onPreviewFile(record.password, index)} link={file} dataDoc={listDoc} />
                    }
                    trigger={["click"]}
                    placement="bottomLeft"
                >
                    <button className="btn btn-outline-primary btn-sm w-100 ml-2 mr-2">Xem file <i className="fas fa-angle-down" /></button>
                </Dropdown>
            </div>
        );
    };
    // nút tác vụ
    const renderActionButton = (text, record, index) => {
        let listDoc: Array<number> = [];
        record.list_document.forEach(function (value) {
            listDoc.push(value.type);
        });
        return (
            <div className="row justify-content-center">
                {record.file_status === 3 ?
                    <button type="button" title="Tải lại hồ sơ" className="text-primary ml-2" onClick={() => onClickUpload(record)}><i className="fas fa-upload fa-lg" /></button> : ""
                }

                {record.file_status === 1 || record.file_status === 2 ?
                    <button type="button" title="Yêu cầu được tải lại hồ sơ" className="text-warning ml-2" onClick={() => clickRequestUploadFile(record.id)}><i className="fas fa-exclamation-triangle fa-lg" /></button> : ""
                }
            </div>
        );
    };

    const clickRequestUploadFile = (id) => {
        var title_modal = 'Nhập lý do cập nhật hồ sơ';
        setModalDestroy({ ...modalDestroy, 'visible_modal_deny': true, 'title_modal': title_modal, 'error_reason': '', 'id': id });
    };
    //Click xem file
    const onPreviewFile = async (password, type) => {
        try {
            let token = localStorage.getItem("currentUser");
            if (token) {
                let obj = JSON.parse(token);
                let link = `${REACT_APP_BASE_API_URL}qlbh/quan-ly-ho-so/previewFile/${password}/${type}?token=${obj.token}`;
                setFile(link);
            }

        } catch (error) {
            onFailAction("Có lỗi xảy ra!");
        }
    };
    //Tải lại file
    const handleModalImportCancel = () => {
        setModalUpload({...modalUpload, visible_modal_upload: false, error_text_deny: ""});
        setFilePassportCN(false);
        setFileOrganizationCN(false);
        setFileOrganizationTC(false);
        setFileDeputyPassPortTC(false);
        setFileDK0101(false);
        setFileDK0102(false);
        setFileDK02(false);

        setFileNamePassportCN("");
        setFileNameOrganizationCN("");
        setFileNameOrganizationTC("");
        setFileNameDeputyPassPortTC("");
        setFileNameDK0101("");
        setFileNameDK0102("");
        setFileNameDK02("");
    };
    const confirmImport = async () => {
        if (!fileNamePassportCN && !fileNameOrganizationCN && !fileNameOrganizationTC && !fileNameDeputyPassPortTC && !fileNameDK0101 && !fileNameDK0102 && !fileNameDK02) {
            setModalUpload({...modalUpload, error_text_deny: "Bạn chưa tải hồ sơ lên!"});
            return false;
        }
        const data = {
            file_passport_CN: fileNamePassportCN,
            file_organization_CN: fileNameOrganizationCN,
            file_organization_TC: fileNameOrganizationTC,
            file_deputy_passport_TC: fileNameDeputyPassPortTC,
            file_dk_01_01: fileNameDK0101,
            file_dk_01_02: fileNameDK0102,
            file_dk02: fileNameDK02,
            customer_type: modalUpload.customer_type,
            id: id,
        };
        const result = await DocLegalManagerService.uploadFile(data);
        await setModalUpload({...modalUpload, visible_modal_upload: false});
        if (result && Number(result.status) === 200) {
            onSuccessAction("Cập nhật hồ sơ thành công!", () => window.location.reload());
        } else {
            onFailAction(result.error, () => {
                window.location.reload();
            });
        }

    };
    const onChangeFile = async e => {
        const files = e.target.files[0];
        const name = e.target.name;
        if (files) {
            if (files.type !== "application/pdf") {
                setModalUpload({...modalUpload, 'error_text_deny': "Chỉ chấp nhận định dạng file PDF"});
                return false;
            }
            const fileSize = files.size / 1024 / 1024;
            if (fileSize > 5.1) {
                setModalUpload({...modalUpload, 'error_text_deny': "Giới hạn dung lượng 5MB"});
                return false;
            } else {
                const formData = new FormData();
                formData.append("file", files);
                loading.runLoadingBlockUI();
                const fileData = await BaseServices.axiosUpLoadFile(formData);
                if (fileData) {
                    if (name === "file_passport_CN") {
                        setFileNamePassportCN(fileData.file);
                        setModalUpload({...modalUpload, error_text_deny: ""})
                    }
                    if (name === "file_organization_CN") {
                        setFileNameOrganizationCN(fileData.file);
                        setModalUpload({...modalUpload, error_text_deny: ""})
                    }
                    if (name === "file_organization_TC") {
                        setFileNameOrganizationTC(fileData.file);
                        setModalUpload({...modalUpload, error_text_deny: ""})
                    }
                    if (name === "file_deputy_passport_TC") {
                        setFileNameDeputyPassPortTC(fileData.file);
                        setModalUpload({...modalUpload, error_text_deny: ""})
                    }
                    if (name === "file_dk_01_01") {
                        setFileNameDK0101(fileData.file);
                        setModalUpload({...modalUpload, error_text_deny: ""})
                    }
                    if (name === "file_dk_01_02") {
                        setFileNameDK0102(fileData.file);
                        setModalUpload({...modalUpload, error_text_deny: ""})
                    }
                    if (name === "file_dk02") {
                        setFileNameDK02(fileData.file);
                        setModalUpload({...modalUpload, error_text_deny: ""})
                    }
                }
                loading.stopRunLoading();
            }
        } else {
            if (name === "file_passport_CN") setFileNamePassportCN("");
            if (name === "file_organization_CN") setFileNameOrganizationCN("");
            if (name === "file_organization_TC") setFileNameOrganizationTC("");
            if (name === "file_deputy_passport_TC") setFileNameDeputyPassPortTC("");
            if (name === "file_dk_01_01") setFileNameDK0101("");
            if (name === "file_dk_01_02") setFileNameDK0102("");
            if (name === "file_dk02") setFileNameDK02("");
        }
    };
    const onClickUpload = async (record) => {
        let result = getListDoc(record);
        let modelDocument = result.modelDocument;
        setModalUpload({
            ...modalUpload,
            visible_modal_upload: true,
            object: record.object,
            idDoc: modelDocument["id"],
            customer_type: record.type_request,
            reason_refuse: record.reason_refusal_file

        });

        if (Number(record.status === 5)) {
            setFileDK02(true);
            setStatusFileDK02(record.status_file_dk_02)
        }
        if (Number(record.type_request) === 2) {
            setFilePassportCN(true);
            setFileDK0102(true);
            setStatusFileDK0102(record.status_file_dk_01_02);
            setStatusFilePassportCN(record.status_file_passport_CN);
            if (record.requestpersonal){
                setFileOrganizationCN(true);
                setStatusFileOrganizationCN(record.status_file_organization_CN);
            }
        } else {
            setFileOrganizationTC(true);
            setFileDeputyPassPortTC(true);
            setFileDK0101(true);
            setStatusFileOrganizationTC(record.status_file_organization_TC);
            setStatusFileDeputyPassPortTC(record.status_file_deputy_passport_TC);
            setStatusFileDK0101(record.status_file_dk_01_01);
        }
        setId(record.id);
    };
    const getListDoc = (record) => {
        let model = Object;
        let modelDocument = Object;
        let modelDocumentArr = record.list_document;
        let listDocFile: Array<any> = [];
        modelDocumentArr.map((value) => {
            model = record;
            if (Number(value.type) === 1) {
                listDocFile.push("file_passport_CN");
                modelDocument = value
            }
            if (Number(value.type) === 2) {
                listDocFile.push("file_organization_CN");
                modelDocument = value
            }
            if (Number(value.type) === 3) {
                listDocFile.push("file_dk_01_01");
                modelDocument = value
            }
            if (Number(value.type) === 4) {
                listDocFile.push("file_dk_01_02");
                modelDocument = value
            }
            if (Number(value.type) === 5) {
                listDocFile.push("file_organization_TC");
                modelDocument = value
            }
            if (Number(value.type) === 6) {
                listDocFile.push("file_deputy_passport_TC");
                modelDocument = value
            }
            if (Number(value.type) === 7) {
                listDocFile.push("file_dk_02");
                modelDocument = value
            }
            return [listDocFile, model, modelDocument]
        });

        return {listDocFile: listDocFile, model: model, modelDocument: modelDocument}
    };
    const columns = [
        {
            title: "Mã khách hàng",
            dataIndex: "",
            render: (e) => {
                if (e.type_request === 1) {
                    return e.organization.uid;
                } else {
                    return e.requestpersonal.uid;
                }
            }
        },
        {
            title: 'Mã yêu cầu',
            dataIndex: "code"
        },
        {
            title: "Mã DN/CMND",
            dataIndex: "",
            render: (e) => {
                if (e.type_request === 1) {
                    return e.organization.code;
                } else {
                    return e.requestpersonal.passport;
                }
            }
        },
        {
            title: "Loại yêu cầu",
            dataIndex: "",
            render: (e) => {
                if (e.object === 1) {
                    return <Tag color="green">Cấp mới</Tag>
                }
                if (e.object === 2) {
                    return <Tag color="blue">Gia hạn</Tag>
                }
                if (e.object === 3) {
                    return <Tag color="red">Chuyển đổi</Tag>
                }
            }
        },
        {
            title: "Đối tượng",
            dataIndex: "",
            render: (e) => {
                if (e.type_request === 1) {
                    return <Tag color="green">Tổ chức</Tag>
                }
                if (e.type_request === 2) {
                    return <Tag color="blue">Cá nhân</Tag>
                }
            }
        },
        {
            title: "Trạng thái",
            dataIndex: "",
            render: (e) => {
                if (e.file_status === 1) {
                    return <Tag color="orange">Chờ xác nhận</Tag>
                }
                if (e.file_status === 2) {
                    return <Tag color="green">Hợp lệ</Tag>
                }
                if (e.file_status === 3) {
                    return (
                        <Tooltip title={e.reason_refusal_file} placement="topLeft">
                            <Tag color="magenta">Không hợp lệ</Tag>
                        </Tooltip>
                    )
                }
                if (e.file_status === 4) {
                    return (
                        <Tooltip title={e.reason_request_update_file} placement="topLeft">
                            <Tag color="geekblue">Chờ xác nhận cho phép cập nhật</Tag>
                        </Tooltip>
                    )
                }
                if (e.file_status === 5) {
                    return <Tag color="purple">Chờ xác nhận(admin cập nhật)</Tag>
                }
            }
        },
        {
            title: "Loại hồ sơ",
            dataIndex: "",
            render: (e) => {
                if (e.type_docurment === 1) {
                    return <Tag color="orange">Điện tử</Tag>
                }
                if (e.type_docurment === 2) {
                    return <Tag color="green">Giấy</Tag>
                }
                if (e.type_docurment === 3) {
                    return <Tag color="magenta">Scan</Tag>
                }
            }
        },
        {
            title: "File",
            render: renderActionViewFile
        },
        {
            title: "Ngày cập nhật hồ sơ",
            className: "text-center",
            render: (e) => {
                if (e.update_profile_at) {
                    return formatDateTime(e.update_profile_at)
                }
            }
        },
        {
            title: "Trạng thái duyệt",
            className: "",
            render: (e) => {
                if (e.user_approve_doc){
                    return "Đã duyệt"
                }
            }
        },
        {
            title: "Tác vụ",
            render: renderActionButton
        }
    ];
    const fetch = async (params = {}) => {
        try {
            return await DocLegalManagerService.list(params);
        } catch (error) {
            console.log(error);
            onFailAction();
        }
    };

    const confirmDeny = async () => {
        var error_reason = 'Lý do không được trống';
        if (modalDestroy.reason.trim() === "") {
            setModalDestroy({ ...modalDestroy, 'error_reason': error_reason });
            return false;
        }

        const data = { reason: modalDestroy.reason, id: modalDestroy.id };

        const result = await DocLegalManagerService.requestUpdateFile(data);
        await setModalDestroy({ ...modalDestroy, 'visible_modal_deny': false });

        if (result && Number(result.status) === 200) {
            onSuccessAction("Gửi yêu cầu thành công!", () => {
                window.location.reload();
            });
        } else if (result && Number(result.status) === 422) {
            onFailAction("Có lỗi xảy ra trong quá trình yêu cầu!");
        } else {
            onFailAction(result.error);
        }
    }

    const onChangeDataDeny = ({ target: { value } }) => {
        setModalDestroy({ ...modalDestroy, 'reason': value });
    };

    return (
        <PageWrapper title="Quản lý hồ sơ pháp lý">
            <DocLegalManagerSearchForm />
            <Table columns={columns} onFetchData={fetch} />
            <ModalDeny
                visible={modalDestroy.visible_modal_deny}
                handleCancel={handleModalDenyCancel}
                handleDeny={confirmDeny}
                value={modalDestroy.reason}
                onChange={onChangeDataDeny}
                error={modalDestroy.error_reason}
                title={modalDestroy.title_modal}
                okText={'Xác nhận'}
                placeholder={"Nhập lí do cập nhật hồ sơ"}
            />
            <ModalUploadFile
                visible={modalUpload.visible_modal_upload}
                handleCancel={handleModalImportCancel}
                handleImport={confirmImport}
                value={modalUpload.text_deny}
                error={modalUpload.error_text_deny}
                onchangeFileUpload={onChangeFile}
                valueTextArea={modalUpload.reason_refuse}

                filePassport_CN={filePassportCN}
                fileOrganization_CN={fileOrganizationCN}
                fileOrganization_TC={fileOrganizationTC}
                fileDeputyPassPort_TC={fileDeputyPassPortTC}
                fileDK01_01={fileDK0101}
                fileDK01_02={fileDK0102}
                fileDK02={fileDK02}

                fileNamePassport_CN={fileNamePassportCN}
                fileNameOrganization_CN={fileNameOrganizationCN}
                fileNameOrganization_TC={fileNameOrganizationTC}
                fileNameDeputyPassPort_TC={fileNameDeputyPassPortTC}
                fileNameDK01_01={fileNameDK0101}
                fileNameDK01_02={fileNameDK0102}
                fileNameDK02={fileNameDK02}

                statusFilePassport_CN={statusFilePassportCN}
                statusFileOrganization_CN={statusFileOrganizationCN}
                statusFileOrganization_TC={statusFileOrganizationTC}
                statusFileDeputyPassPort_TC={statusFileDeputyPassPortTC}
                statusFileDK01_01={statusFileDK0101}
                statusFileDK01_02={statusFileDK0102}
                statusFileDK02={statusFileDK02}
            />
        </PageWrapper>
    );
};
