import React, { Component } from "react";
import { Form, Icon, Input, Button, Alert, Modal, Select } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { authenticationService } from "../../../services/authentication/AuthenticationService";
import { History } from "history";
import { onFailAction } from "../../../helpers/SwalCommon";
import { loading } from "../../../components/common/loading/Loading";
import { UserServices } from "services/user/UserServies";
import Axios from "axios";
import { permissionMenu } from "helpers/NewCaCrmHelper";
import LabelInput from "../../../components/common/form/input-with-label/label/LabelInput";
import axios from "axios";
const { REACT_APP_BASE_API_URL } = window['runConfig'];
const { REACT_APP_BASE_GET_TOKEN_URL } = window['runConfig'];
interface Props extends FormComponentProps {
  history: History;
  setUser: any;
  onResetPass: boolean;
  handleOpenResetPass: any;
}

interface State {
  message: string;
  isDisplayMessage: boolean;
  isVisitable: boolean;
  dataTokens: any;
  isReset: boolean;
  dataTokenSelect: {
    CN: string;
    IssuedBy: string;
    STT: string;
    Serial: string;
    ValidFrom: string;
    ValidTo: string;
  };
}

const initialState = {
  message: "",
  isDisplayMessage: false,
  isVisitable: false,
  dataTokens: [],
  isReset: false,
  dataTokenSelect: {
    CN: "",
    IssuedBy: "",
    STT: "",
    Serial: "",
    ValidFrom: "",
    ValidTo: "",
  },
};

class LoginForm extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  reset() {
    this.setState(initialState);
  }

  loginByCert = () => {
    Axios.get(window.location.origin + "/index.php")
      .then(async (res) => {
        const info = res.data;
        if (info !== null && info.cert !== false && info.seri !== false) {
          const user = await authenticationService.loginByCert(
            info.cert,
            info.seri
          );
          if (user && user.token) {
            this.props.setUser(user.data);
            window.location.reload();
          } else if (user && user.status === 1) {
            this.setState({
              message: user.message,
              isDisplayMessage: true,
            });
          }
        } else {
          this.setState({
            message: "Thông tin chứng thư số không đầy đủ",
            isDisplayMessage: true,
          });
        }
      })
      .catch((error) => {
        this.setState({
          message: "Trình duyệt hiện tại không thể đăng nhập chứng thư số",
          isDisplayMessage: true,
        });
      });
  };

  loginByToken = async () => {
    // Step 1: Get data form plugin
    // const baseUrl = "http://localhost:6706/api/certificate/getcertstore"
    loading.runLoadingBlockUI();
    this.reset();
    const baseUrl = `${REACT_APP_BASE_GET_TOKEN_URL}certificate/getcertstore`;
    const dataRawStr = await axios
      .get(baseUrl, { timeout: 2000 })
      .then((response) => {
        if (response && response.data.length > 0) {
          return response.data;
        } else {
          this.setState({
            message: "Bạn chưa có CTS!",
            isDisplayMessage: true,
          });
          return;
        }
      })
      .catch((error) => {
        this.setState({
          message: "Bạn cần cài đặt plugin Token!",
          isDisplayMessage: true,
        });
        return;
      });
    // Step2: Show modal
    let dataTokenArr = [];
    if (dataRawStr != null) {
      dataTokenArr = JSON.parse(dataRawStr);
      this.setState({
        isVisitable: true,
        dataTokens: dataTokenArr,
      });
    }
    loading.stopRunLoading();
  };

  onCancel = () => {
    this.reset();
  };

  downloadPlugin = () => {
    try {
      const link = `${REACT_APP_BASE_API_URL}gen-cts-customer/get-file-plugin`;
      window.open(link);
    } catch (error) {
      onFailAction("Có lỗi xảy ra!");
    }
  };

  onOK = async () => {
    let { dataTokenSelect } = this.state;
    const user = await authenticationService.loginByCert(
      dataTokenSelect.CN,
      dataTokenSelect.Serial
    );
    if (user && user.token) {
      this.props.setUser(user.data);
      window.location.reload();
    } else {
      this.setState({
        isVisitable: false,
        message: "Thông tin chứng thư số bị thiếu hoặc không đúng!",
        isDisplayMessage: true,
      });
    }
  };

  handleChange = (index) => {
    const { dataTokens } = this.state;
    this.setState({
      dataTokenSelect: dataTokens[index],
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { validateFields, getFieldValue } = this.props.form;
    validateFields(async (err, values) => {
      if (!err) {
        try {
          loading.runLoadingBlockUI();
          const user = await authenticationService.login(
            getFieldValue("username"),
            getFieldValue("password")
          );
          if (user && user.token) {
            const userService = new UserServices();
            const userResult = await userService.getUserAuth();
            let perList = await permissionMenu(userResult.data.permission_list);
            userResult.data.permission_list = perList;
            this.props.setUser(userResult.data);
            this.props.history.push("/");
            window.location.reload();
          } else if (user && user.status === 1) {
            this.setState({
              message: user.message,
              isDisplayMessage: true,
            });
          }
        } catch (error) {
          console.log(error);
          onFailAction();
        } finally {
          loading.stopRunLoading();
        }
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { Option } = Select;
    const { message, isDisplayMessage, dataTokens } = this.state;
    return (
      <Form onSubmit={this.handleSubmit} className="login-form w-50">
        <Modal
          title={
            <div className={"login100-form-title text-center"}>
              <div className={"text-center mt-3"}>
                <img
                  src={"/images/logo.png"}
                  width="auto"
                  height="40"
                  className="d-inline-block align-center"
                  alt=""
                />
              </div>
            </div>
          }
          visible={this.state.isVisitable}
          onCancel={this.onCancel}
          onOk={this.onOK}
          centered={true}
          footer={[
            <Button key="back" onClick={this.onCancel} style={{ width: "5vw" }}>
              Hủy bỏ
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={this.onOK}
              style={{ width: "5vw" }}
            >
              Đăng nhập
            </Button>,
          ]}
        >
          <LabelInput label={"Chọn chứng thư số đăng nhập"} nameFor={""} />
          <Select
            className={"form-control customSelectLogin mb-3"}
            value={
              this.state.dataTokenSelect.CN
                ? this.state.dataTokenSelect.CN
                : "---Lựa chọn Token---"
            }
            onChange={this.handleChange}
          >
            {dataTokens.length > 0
              ? dataTokens.map((data, index) => (
                  <Option key={index} value={index}>
                    {data["CN"]}
                  </Option>
                ))
              : ""}
          </Select>
          <LabelInput label={"Tên Token"} nameFor={""} />
          <Input
            className={"form-control mb-3"}
            value={this.state.dataTokenSelect.CN}
            type={"text"}
            name={"CN"}
            disabled={true}
          />

          <LabelInput label={"Mã Serial"} nameFor={""} />
          <Input
            className={"form-control"}
            value={this.state.dataTokenSelect.Serial}
            type={"text"}
            name={"serial"}
            disabled={true}
          />
        </Modal>

        <span className="p-b-11" style={{ fontWeight: "bolder" }}>
          Tài khoản
        </span>
        <Form.Item>
          {getFieldDecorator("username", {
            rules: [
              { required: true, message: "Tài khoản không thể bỏ trống!" },
            ],
          })(
            <Input
              prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="Username"
              className="form-control input100"
            />
          )}
        </Form.Item>
        <span className="p-b-11" style={{ fontWeight: "bolder" }}>
          Mật khẩu
        </span>
        <Form.Item>
          {getFieldDecorator("password", {
            rules: [
              { required: true, message: "Mật khẩu không thể bỏ trống!" },
            ],
          })(
            <Input.Password
              prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
              // type="password"
              placeholder="Password"
              className="form-control input100"
              autoComplete="off"
            />
          )}
        </Form.Item>
        <Form.Item>
          {isDisplayMessage ? (
            <Alert message={message} type="error" showIcon />
          ) : (
            ""
          )}
          {/* <Link className="login-form-forgot" to="/"
            Forgot password
          </Link> */}
          <div className="container-login100-form-btn text-center justify-content-center pt-1">
            <Button htmlType="submit" className="login100-form-btn w-100 mb-2">
              Đăng nhập
            </Button>
            <Button
              htmlType="button"
              className="login100-form-btn w-100"
              onClick={this.loginByToken}
            >
              Login by Token
            </Button>
            <div>
              <Button
                key="link"
                type="link"
                onClick={this.downloadPlugin}
                className={"mt-3"}
              >
                <span>
                  <i className=" fa fa-download fa-lg" />
                  Tải xuống plugin kết nối token
                </span>
              </Button>
              <div className="forget-pass cursor-pointer">
                <span
                  onClick={(e) => {
                    this.props.handleOpenResetPass();
                  }}
                >
                  Quên mật khẩu?
                </span>
              </div>
            </div>
          </div>
        </Form.Item>
      </Form>
    );
  }
}

const WrappedNormalLoginForm = Form.create<Props>({ name: "Login" })(LoginForm);

export default WrappedNormalLoginForm;
