import {Member} from "../../../pages/member/Member";
import MemberUpdateForm from "../../../pages/member/MemberUpdate";
import MemberPreviewForm from "../../../pages/member/MemberPreview";

const MEMBER_ROUTE = [
    {
        component: Member,
        link: "/quan-ly-thanh-vien",
        permission: "",
        isExact: true
    },
    {
        component: MemberUpdateForm,
        permission: "",
        link: "/quan-ly-thanh-vien/cap-nhat/:id",
        isExact: false
    },
    {
        component: MemberPreviewForm,
        permission: "",
        link: "/quan-ly-thanh-vien/xem-chi-tiet/:id",
        isExact: false
    }
];

export default MEMBER_ROUTE;