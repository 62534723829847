import React, {useEffect, useState} from "react";
import PageWrapper from "../wrapper/PageWrapper";
import {Card, Form} from "antd";
import {FormComponentProps} from "antd/lib/form";
import InputWithLabel from "components/common/form/input-with-label/InputWithLabel";
import {TextAreaWithLabel} from "components/common/form/input-with-label/TextAreaWithLabel";
import ButtonOnSave from "components/common/form/button/ButtonOnSave";
import ButtonCancel from "components/common/form/button/ButtonCancel";
import {loading} from "components/common/loading/Loading";
import _ from "lodash";
import {onFailAction, onSuccessAction} from "helpers/SwalCommon";
import SelectDateWithLabel from "components/common/form/input-with-label/SelectDateWithLabel";
import {RequestChangeInfoService} from "../../services/request-change-info/RequestChangeInfoService";
import InputFileUpload from "../../components/common/form/input-with-label/InputFileUpload";
import moment from "moment";
import Store from "../../store/store";

interface Props extends FormComponentProps {
    user: any;
    history: any;
}

const TYPE_AGENCY = 7;
export const RequestCertificateGroup: React.FC<Props> = props => {

    const user = Store.getState().authReducer;

    const userType = user.type;

    const [isRequiredFile, setIsRequiredFile] = useState(false);

    const [isRequiredFilePassport, setIsRequiredFilePassport] = useState(false);

    const storeRequest = () => {
        const {validateFields} = props.form;
        validateFields(async (errors, values) => {
            if (!errors) {
                try {
                    loading.runLoadingBlockUI();
                    const payload = {...values, type: userType, owner_id: user.owner_id};
                    const data = await RequestChangeInfoService.store(payload);
                    if (data && data.status === 422) {
                        _.forOwn(data.error, function (errors, key) {
                            props.form.setFields({
                                [key]: {
                                    errors: [new Error(errors.toString())]
                                }
                            });
                        });
                    } else if (data && data.status === 200) {
                        onSuccessAction("Lưu yêu cầu thành công", () => {
                            props.history.push("/yeu-cau-thay-doi-thong-tin");
                        });
                    }
                } catch (error) {
                    onFailAction("Có lỗi xảy ra !");
                } finally {
                    loading.stopRunLoading();
                }
            } else {
                onFailAction("Bạn chưa điền đủ thông tin!");
            }
        });
    };

    const getData = async () => {
        loading.runLoadingBlockUI();
        const result = await RequestChangeInfoService.getData();
        if (result && result.status === 200) {
            if (Number(userType) === TYPE_AGENCY) {
                props.form.setFieldsValue({
                    agency_fullname: result.data.fullname,
                    agency_tax_code: result.data.tax_code,
                    agency_sort_name: result.data.sortname,
                    agency_deputy: result.data.deputy,
                    agency_deputy_position: result.data.deputy_position,
                    agency_email: result.data.email,
                    agency_phone: result.data.phone,
                    agency_address: result.data.address,
                    agency_office_address: result.data.office_address,
                    agency_bank_number: result.data.bank_number,
                    agency_bank_branch: result.data.bank_branch,
                });
            } else {
                props.form.setFieldsValue({
                    contributor_passport: result.data.passport,
                    contributor_fullname: result.data.fullname,
                    contributor_sort_name: result.data.sortname,
                    contributor_email: result.data.email,
                    contributor_phone: result.data.phone,
                    contributor_address: result.data.address,
                    contributor_birthday: moment(result.data.birthday),
                });
            }
            loading.stopRunLoading();
        } else {
            onFailAction('Có lỗi xảy ra123!');
        }
    };

    useEffect(() => {
        getData().then();
        // eslint-disable-next-line
    }, []);


    const onchangeValueAgency = () => {
        setIsRequiredFile(true);
    }

    const onchangeValueContributor = () => {
        setIsRequiredFilePassport(true);
    }

    return (
        <PageWrapper title="Yêu cầu thay đổi thông tin">
            <Form>
                <Card className="m-r-15-i m-l-15-i mt-4" title={<label>Thông tin thay đổi</label>} size="small">
                    <div className="input-group">
                        <TextAreaWithLabel
                            label={"Lý do thay đổi thông tin"}
                            form={props.form}
                            wrapClass="col-md-12"
                            name="reason_customer"
                            rows={4}
                            isRequired={true}
                        />
                    </div>
                    {
                        Number(userType) === TYPE_AGENCY ? (
                            <div>
                                <div className="input-group">
                                    <InputWithLabel
                                        form={props.form}
                                        label="Mã số thuế"
                                        name="agency_tax_code"
                                        wrapClass="col-md-3"
                                        isRequired={true}
                                        isDisabled={true}
                                        maxLength={255}
                                    />
                                </div>
                                <div className="input-group">
                                    <InputWithLabel
                                        form={props.form}
                                        label="Tên đại lý"
                                        name="agency_fullname"
                                        isRequired={true}
                                        wrapClass="col-md-3"
                                        onChange={onchangeValueAgency}
                                        maxLength={255}
                                    />
                                    <InputWithLabel
                                        form={props.form}
                                        label="Tên viết tắt"
                                        name="agency_sort_name"
                                        isRequired={true}
                                        wrapClass="col-md-3"
                                        maxLength={255}
                                    />
                                    <InputWithLabel
                                        form={props.form}
                                        label="Email"
                                        name="agency_email"
                                        isRequired={true}
                                        wrapClass="col-md-3"
                                        maxLength={255}
                                    />
                                    <InputWithLabel
                                        form={props.form}
                                        label="Số điện thoại"
                                        name="agency_phone"
                                        isRequired={true}
                                        wrapClass="col-md-3"
                                        maxLength={255}
                                    />
                                </div>
                                <div className="input-group">
                                    <InputWithLabel
                                        form={props.form}
                                        label="Người đại diện"
                                        name="agency_deputy"
                                        isRequired={true}
                                        onChange={onchangeValueAgency}
                                        wrapClass="col-md-3"
                                        maxLength={255}
                                    />
                                    <InputWithLabel
                                        form={props.form}
                                        label="Chức vụ người đại diện"
                                        name="agency_deputy_position"
                                        isRequired={true}
                                        wrapClass="col-md-3"
                                        maxLength={255}
                                    />
                                    <InputWithLabel
                                        form={props.form}
                                        label="Trụ sở chính"
                                        name="agency_address"
                                        wrapClass="col-md-6"
                                        onChange={onchangeValueAgency}
                                        maxLength={255}
                                        isRequired={true}
                                    />
                                </div>
                                <div className="input-group">
                                    <InputWithLabel
                                        form={props.form}
                                        label="Văn phòng giao dịch"
                                        name="agency_office_address"
                                        wrapClass="col-md-6"
                                        onChange={onchangeValueAgency}
                                        maxLength={255}
                                        isRequired={true}
                                    />
                                    <InputWithLabel
                                        form={props.form}
                                        label="Số tài khoản ngân hàng"
                                        name="agency_bank_number"
                                        wrapClass="col-md-3"
                                        maxLength={255}
                                    />
                                    <InputWithLabel
                                        form={props.form}
                                        label="Chi nhánh"
                                        name="agency_bank_branch"
                                        wrapClass="col-md-3"
                                        maxLength={255}
                                    />
                                </div>
                                <div className="input-group">
                                    <InputFileUpload
                                        nameFiledSetValue={""}
                                        classWrapped="col-md-6"
                                        label="Tải file ĐKKD (pdf)"
                                        name="file"
                                        form={props.form}
                                        isRequired={isRequiredFile}
                                        extentionsAllow={['pdf', 'PDF']}
                                        accept={".pdf"}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div>
                                <div className="input-group">
                                    <InputWithLabel
                                        form={props.form}
                                        label="CMND/HC"
                                        name="contributor_passport"
                                        wrapClass="col-md-3"
                                        isRequired={true}
                                        isDisabled={true}
                                        maxLength={255}
                                    />
                                </div>
                                <div className="input-group">
                                    <InputWithLabel
                                        form={props.form}
                                        label="Tên cộng tác viên"
                                        name="contributor_fullname"
                                        isRequired={true}
                                        wrapClass="col-md-3"
                                        onChange={onchangeValueContributor}
                                        maxLength={255}
                                    />
                                    <InputWithLabel
                                        form={props.form}
                                        label="Tên CTV viết tắt"
                                        name="contributor_sort_name"
                                        isRequired={true}
                                        wrapClass="col-md-3"
                                        maxLength={255}
                                    />
                                    <InputWithLabel
                                        form={props.form}
                                        label="Email"
                                        name="contributor_email"
                                        isRequired={true}
                                        wrapClass="col-md-3"
                                        maxLength={255}
                                    />
                                    <InputWithLabel
                                        form={props.form}
                                        label="Số điện thoại"
                                        name="contributor_phone"
                                        wrapClass="col-md-3"
                                        maxLength={255}
                                        isRequired={true}
                                    />
                                </div>
                                <div className="input-group">
                                    <InputWithLabel
                                        form={props.form}
                                        label="Địa chỉ"
                                        name="contributor_address"
                                        wrapClass="col-md-9"
                                        onChange={onchangeValueContributor}
                                        maxLength={255}
                                        isRequired={true}
                                    />
                                    <SelectDateWithLabel
                                        form={props.form}
                                        label="Ngày sinh"
                                        name="contributor_birthday"
                                        wrapClass="col-md-3"
                                        isRequired={true}
                                    />
                                </div>
                                <div className="input-group">
                                    <InputFileUpload
                                        nameFiledSetValue={""}
                                        classWrapped="col-md-6"
                                        label="Tải file CMT/HC"
                                        name="file"
                                        form={props.form}
                                        isRequired={isRequiredFilePassport}
                                        extentionsAllow={['pdf', 'PDF']}
                                        // extentionsAllow={['pdf', 'PDF', 'img', 'IMG', 'jpg', 'JPG', 'jpeg', 'JPEG']}
                                        accept={[".pdf", ".img"]}
                                        // accept={[".pdf", ".img", ".jpg", ".jpeg", ".PDF", ".JPG", ".JPEG", ".IMG"]}
                                    />
                                </div>
                            </div>
                        )
                    }
                </Card>
            </Form>
            <div className="input-group d-flex justify-content-center p-5 mt-5">
                <div className="">
                    <ButtonOnSave
                        onClick={() => {
                            storeRequest();
                        }}
                        label="Gửi yêu cầu"
                        className={"btn btn-success btn-sm"}
                    />
                </div>
                <div className="">
                    <ButtonCancel
                        onClick={() => {
                            props.history.push("/yeu-cau-thay-doi-thong-tin");
                        }}
                        className={"btn btn-default btn-sm"}
                    />
                </div>
            </div>
        </PageWrapper>
    );
};

const WrappedRequestCertificateGroupCreate = Form.create<Props>({
    name: "RequestCertificateGroup"
})(RequestCertificateGroup);

export default WrappedRequestCertificateGroupCreate;
