export const TYPE_DEVICE = {
    1: {
        label: "USB Token",
        class: "magenta",
    },
    2: {
        label: "HSM",
        class: "green",
    },
    3: {
        label: "FASTCA RS",
        class: "red",
    }
};
