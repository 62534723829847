import BaseServices from "../base/BaseServices";

const getFileDk02 = async (data) => {
    return await BaseServices.request(
        `getFileDk02`,
        data,
        "POST"
    );
};


const getCaptcha = async (codeUi) => {
    return await BaseServices.request(
        `get-captcha/${codeUi}`,
        {},
        "GET"
    );
};

const getCertificate = async (id, type) => {
    return await BaseServices.request(
        `getCertificateReSign/${id}/${type}`,
        {},
        "GET"
    );
};

const updateFileSigned = async (data) => {
    return await BaseServices.request(
        `updateSignedContractReSign`,
        data,
        "POST"
    );
};
const getTimeServer = async () => {
    return await BaseServices.request(
        `get-time-server-sign`,
        {},
        "GET"
    );
};

export const ReSignDk02Services = {
    getFileDk02,
    getCertificate,
    updateFileSigned,
    getTimeServer,
    getCaptcha
};
