import React, {Component} from 'react'
import {Button} from "antd";

interface Props {
    onClick: any,
    btnClass?: string,
    btnText?: string,
}

interface State {

}

export default class SearchButton extends Component<Props, State> {
    state = {}

    render() {
        return (
            <Button
                onClick={this.props.onClick}
                htmlType={"submit"}
                className={this.props.btnClass || 'bg-primary text-white'}>
                <i className="fas fa-search mr-1"
                />
                {this.props.btnText || "Tìm kiếm"}
            </Button>
        )
    }
}
