import React, { } from "react";
import PageWrapper from "../wrapper/PageWrapper";
import Table from "../../components/common/table/Table";
import { onFailAction } from "helpers/SwalCommon";
import { RouteComponentProps } from "react-router";
import TableActionButton from "components/common/table/action-button/TableActionButton";
import {
    STATUS_REQUEST,
    STATUS_CERTIFICATE
} from "../../helpers/enum/request-token/RequestTokenEnums";
import WrappedGenCertSearchForm from "./search/InfoCertSearchForm";
import { GenCertServices } from "../../services/gen-cert/GenCertServices";
import { Tag } from "antd";
import './infor-certificate.scss';
import { formatDateTime, formatDateNotTime } from "helpers/NewCaCrmHelper";
const { REACT_APP_BASE_API_URL } = window['runConfig'];
interface Props extends RouteComponentProps {
}

export const InfoCertList: React.FC<Props> = props => {

    const renderActionButton = (text, record, index) => {
        return (
            <TableActionButton
                onClickPreviewButton={() =>
                    props.history.push(`info-certificate/xem/${record.id}`)
                }
                onClickDownloadCertificate={() => downloadCert(record.id)
                }
                permissionDownloadCert={checkPermissionStatus(record.status)}
            />
        );
    };
    const checkPermissionStatus = (status) => {
        if (Number(status) === 1) {
            return true;
        } else {
            return false;
        }
    };
    const downloadCert = async (id) => {
        try {
            let token = localStorage.getItem("currentUser");
            if (token) {
                let obj = JSON.parse(token);
                let link = `${REACT_APP_BASE_API_URL}qlbh/gen-cts/download-cert/${id}?token=${obj.token}`;
                window.open(link);
            }

        } catch (error) {
            onFailAction("Có lỗi xảy ra!");
        }
    };

    const renderStatusColumn = (text, record, index) => {
        return (
            <Tag color={`${STATUS_CERTIFICATE[text].class}`}>
                {STATUS_CERTIFICATE[text].label}
            </Tag>
        );
    };
    const renderStatusRequest = (text, record, index) => {
        return (
            <Tag color={`${STATUS_REQUEST[text].class}`}>
                {STATUS_REQUEST[text].label}
            </Tag>
        );
    };

    const columns = [
      {
        title: "Mã định danh",
        dataIndex: "",
        render: (e) => {
          if (e) {
            if (
              Number(e.object) === 1 ||
              Number(e.object) === 2 ||
              Number(e.object) === 3
            ) {
              if (Number(e.customer_type) === 1) {
                return <p>{e.requestcertificate.organization.code}</p>;
              } else {
                return <p>{e.requestcertificate.requestpersonal.passport}</p>;
              }
            } else if (Number(e.object) === 4) {
              return <p>{e.request_change_info.code}</p>;
            } else if (Number(e.object) === 5 || Number(e.object) === 6) {
              return <p>{e.request_renew_and_warranty.identity}</p>;
            }
          }
        },
      },
      {
        title: "Mã bảo mật",
        dataIndex: "",
        render: (e) => {
          if (
            Number(e.object) === 1 ||
            Number(e.object) === 2 ||
            Number(e.object) === 3
          ) {
            return <p>{e.requestcertificate.secret_code}</p>;
          } else if (Number(e.object) === 4) {
            return <p>{e.request_change_info.secret_code}</p>;
          } else if (Number(e.object) === 5 || Number(e.object) === 6) {
            return <p>{e.request_renew_and_warranty.secret_code}</p>;
          }
        },
      },
      {
        title: "Đối tượng",
        dataIndex: "",
        render: (e) => {
          if (e) {
            if (Number(e.object) === 1) {
              return <p>Cấp mới</p>;
            } else if (Number(e.object) === 2) {
              return <p>Gia hạn</p>;
            } else if (Number(e.object) === 3) {
              return <p>Chuyển đổi</p>;
            } else if (Number(e.object) === 4) {
              return <p>Thay đổi thông tin</p>;
            } else if (Number(e.object) === 5) {
              return <p>Cấp lại</p>;
            } else {
              return <p>Bảo hành</p>;
            }
          }
        },
      },
      {
        title: "Thời gian tạo",
        dataIndex: "created_at",
        render: (text, record, index) => formatDateTime(text),
      },
      {
        title: "Ngày hiệu lực",
        dataIndex: "certificateBegin",
        render: (text, record, index) => formatDateNotTime(text),
      },
      {
        title: "Ngày hết hạn",
        dataIndex: "certificateEnd",
        render: (text, record, index) => formatDateNotTime(text),
      },

      {
        title: "Loại yêu cầu",
        dataIndex: "object",
        render: renderStatusRequest,
      },
      {
        title: "Trạng thái",
        dataIndex: "status",
        render: renderStatusColumn,
      },
      {
        title: "Tác vụ",
        render: renderActionButton,
      },
    ];
    const fetch = async (params = {}) => {
        try {
            const data = await GenCertServices.getListIndex(params);
            return data;
        } catch (error) {
            console.log(error);
            onFailAction();
        }
    };

    return (
        <PageWrapper title="Danh sách chứng thư số">
            <WrappedGenCertSearchForm />
            <Table columns={columns} onFetchData={fetch} />
        </PageWrapper>
    );
};
