import React, {useState, useEffect} from "react";
import {Form} from "antd";
import {FormComponentProps} from "antd/lib/form";
import {match} from "react-router";
import ButtonCancel from "../../components/common/form/button/ButtonCancel";
import ButtonOnSave from "../../components/common/form/button/ButtonOnSave";
import {History} from "history";
import PageWrapper from "./../wrapper/PageWrapper";
import InputWithLabel from '../../components/common/form/input-with-label/InputWithLabel';
import SelectWithLabel from "../../components/common/form/input-with-label/SelectWithLabel";
import {UserServices} from "../../services/user/UserServies";

interface Props extends FormComponentProps {
    history: History;
    location: Location;
    match: match<{
        id: string;
    }>;
    user: any;
}

const MemberUpdate: React.FC<Props> = (props) => {
    const [loading, setLoading] = useState(false);
    const id = props.match.params.id;

    useEffect(() => {
        const fetchModel = async () => {
            // Todo: Non API yet
            setLoading(true);
            const data = {id: id}
            const userService = new UserServices();
            const result = await userService.getUserMemberPreview(data); //
            const user = result.data;
            setLoading(false);

            props.form.setFieldsValue({
                fullname: user.fullname,
                username: user.username,
                email: user.email,
                phone: user.phone,
                role: user.role,
                status: user.status,
            });
        };
        fetchModel().then();
    });

    return (
        <PageWrapper title="Cập nhật thông tin người dùng" loading={loading}>
            <Form>
                <div className="input-group">
                    <InputWithLabel
                        form={props.form}
                        label="Họ và tên"
                        name="fullname"
                        wrapClass="col-md-3"
                    />
                    <InputWithLabel
                        form={props.form}
                        label="Tên tài khoản"
                        name="username"
                        isRequired={true}
                        wrapClass="col-md-3"
                    />
                    <InputWithLabel
                        form={props.form}
                        label="Email"
                        name="email"
                        isRequired={true}
                        wrapClass="col-md-3"
                    />
                </div>
                <div className="input-group">
                    <InputWithLabel
                        form={props.form}
                        label="Số điện thoại"
                        name="phone"
                        isRequired={true}
                        wrapClass="col-md-3"
                        type="number"
                    />
                    <SelectWithLabel
                        options={{1: 'Ad', 2: 'b'}}
                        name="role"
                        wrappedClass="col-md-3"
                        form={props.form}
                        placeholder="Vai trò"
                        label="Vai trò"
                        isRequired={true}
                    />
                    <SelectWithLabel
                        options={{1: 'Hoạt động', 2: 'Không hoạt động'}}
                        name="status"
                        wrappedClass="col-md-3"
                        form={props.form}
                        placeholder="Trạng thái"
                        label="Trạng thái"
                        isRequired={true}
                    />

                </div>
            </Form>
            <div className="input-group pb-5 pt-2">
            </div>
            <div className="input-group d-flex justify-content-center p-5">
                <div className="">
                    <ButtonOnSave
                        onClick={() => {
                        }}
                        label="Lưu"
                        className={"btn btn-success btn-sm"}
                    />
                    <ButtonCancel
                        // onClick={() => {
                        //   props.history.push("/yeu-cau-token-cts");
                        // }}
                        onClick={() => {
                            props.history.goBack();
                        }}
                        className={"btn btn-default btn-sm"}
                    />
                </div>
            </div>
        </PageWrapper>
    );
};

const MemberUpdateForm = Form.create<Props>({
    name: "MemberUpdateForm",
})(MemberUpdate);

export default MemberUpdateForm;
