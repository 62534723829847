import React from "react";
import PageWrapper from "../wrapper/PageWrapper";
import Table from "../../components/common/table/Table";
import { onFailAction } from "helpers/SwalCommon";
import { RouteComponentProps } from "react-router";
import TableActionButton from "components/common/table/action-button/TableActionButton";
import SearchForm from "./search/SearchForm";
import { Tag } from "antd";
import { RequestGatewayServices } from "services/request-gateway/RequestGatewayServices";
import { STATUS_LABEL_CLASS, TYPE_REQUEST_LABEL_CLASS } from "./Enum";
import { formatDateTimeAll } from "helpers/NewCaCrmHelper";

interface Props extends RouteComponentProps {}

export const ListRequestGateway: React.FC<Props> = (props) => {
    const renderActionButton = (text, record) => {
        return (
            <TableActionButton
                onClickPreviewButton={() =>
                    props.history.push(
                        `yeu-cau-dich-vu-tu-kh/chi-tiet/${record.id}`
                    )
                }
            />
        );
    };
    const columns = [
        {
            title: "Mã Token",
            dataIndex: "token_code",
        },
        {
            title: "Mã định danh",
            dataIndex: "tin",
        },
        {
            title: "Tên khách hàng",
            dataIndex: "customer_name",
        },
        {
            title: "Loại yêu cầu",
            render: (e) => {
                return (
                    <Tag
                        color={
                            TYPE_REQUEST_LABEL_CLASS[Number(e.reason)]["class"]
                        }
                    >
                        {TYPE_REQUEST_LABEL_CLASS[Number(e.reason)]["label"]}
                    </Tag>
                );
            },
        },
        {
            title: "Ngày gửi yêu cầu",
            dataIndex: "",
            render: (e) => formatDateTimeAll(e.created_date),
        },
        {
            title: "Ngày cập nhật",
            dataIndex: "",
            render: (e) =>
                e.modified_date
                    ? formatDateTimeAll(e.modified_date)
                    : formatDateTimeAll(e.created_date),
        },
        {
            title: "Trạng thái yêu cầu",
            render: (e) => {
                return (
                    <Tag color={STATUS_LABEL_CLASS[Number(e.status)]["class"]}>
                        {STATUS_LABEL_CLASS[Number(e.status)]["label"]}
                    </Tag>
                );
            },
        },
        {
            title: "Tác vụ",
            render: renderActionButton,
        },
    ];
    const fetch = async (params = {}) => {
        try {
            const result = await RequestGatewayServices.getListIndex(params);
            if (result && Number(result.status) === 200) {
                return result;
            }
        } catch (error) {
            console.log(error);
            onFailAction();
        }
    };

    return (
        <PageWrapper title="Yêu cầu dịch vụ">
            <SearchForm />
            <Table columns={columns} onFetchData={fetch} />
        </PageWrapper>
    );
};
