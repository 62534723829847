import BaseServices from "../base/BaseServices";

const PREFIX_SERVICES = "qlbh/yeu-cau-dich-vu-tu-kh/";

const getListIndex = async (data: object) => {
    return await BaseServices.request(
        PREFIX_SERVICES + "index",
        data,
        "GET"
    );
};
const find = async (id) => {
    return await BaseServices.request(
        PREFIX_SERVICES + `find/${id}`,
        {},
        "GET"
    );
};
const updateStatus = async (data) => {
    return await BaseServices.request(
        PREFIX_SERVICES + "updateStatus",
        data,
        "POST"
    );
};
export const RequestGatewayServices = {
    getListIndex,
    find,
    updateStatus
}
