import BaseServices from "../base/BaseServices";

const PREFIX_SERVICES = "qlbh/yeu-cau-thay-doi-thong-tin/";

const getData = async () => {
    return await BaseServices.request(
        PREFIX_SERVICES + "getData",
        {},
        "GET"
    );
};

const store = async data => {
    return await BaseServices.request(
        PREFIX_SERVICES + "store",
        data,
        "POST"
    );
};

const index = async (data) => {
    return await BaseServices.request(
        PREFIX_SERVICES + "index",
        data,
        "GET"
    );
};

const del = async (id) => {
    return await BaseServices.request(
        PREFIX_SERVICES + `del/${id}`,
        {},
        "DELETE"
    );
};

const find = async (id) => {
    return await BaseServices.request(
        PREFIX_SERVICES + `find/${id}`,
        {},
        "GET"
    );
};

const getFilePassport = async (id) => {
    return await BaseServices.request(
        PREFIX_SERVICES + `getFilePassport/${id}`,
        {},
        "GET"
    );
};

const update = async (data) => {
    return await BaseServices.request(
        PREFIX_SERVICES + `update`,
        data,
        "POST"
    );
};

const checkAddRequest = async () => {
    return await BaseServices.request(
        PREFIX_SERVICES + `checkAddRequest`,
        {},
        "GET"
    );
};

const businessSupportApproveRequest = async id =>{
    return await BaseServices.request(
        PREFIX_SERVICES + "business-support-approve-request/" + id,
        {},
        "GET"
    );
}

const businessSupportDenyRequest = async data => {
    return await BaseServices.request(
        PREFIX_SERVICES + "business-support-deny-request",
        data,
        "POST"
    );
};


export const RequestChangeInfoService = {
    getData,
    store,
    index,
    del,
    find,
    getFilePassport,
    update,
    checkAddRequest,
    businessSupportApproveRequest,
    businessSupportDenyRequest,
};
