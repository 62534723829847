import React from "react";
import PageWrapper from "../wrapper/PageWrapper";
import Table from "../../components/common/table/Table";
import { onFailAction } from "helpers/SwalCommon";
import { RouteComponentProps } from "react-router";
import { Tag } from "antd";
import { formatDate } from "helpers/NewCaCrmHelper";
import MemberSearchForm from "./search/MemberSearchForm";
import TableActionButton from "../../components/common/table/action-button/TableActionButton";
import { UserServices } from "../../services/user/UserServies";

interface Props extends RouteComponentProps {
}

export const Member: React.FC<Props> = props => {
    const renderActionButton = (text, record) => {
        return (
            <TableActionButton
                permissionUpdate=""
                onClickPreviewButton={
                    () => {
                        props.history.push(`/quan-ly-thanh-vien/xem-chi-tiet/${record.id}`);
                    }
                }
            />
        );
    };

    const columns = [
        {
            title: "Họ và tên",
            dataIndex: "fullname",
        },
        {
            title: "Tài khoản",
            dataIndex: "username",
        },
        {
            title: "Email",
            dataIndex: "email",
        },
        {
            title: "Số điện thoại",
            dataIndex: "phone",
        },
        {
            title: "Loại đăng nhập",
            dataIndex: "",
            render: (e) => {
                if (Number(e.login_type) === 1) {
                    return <Tag color="green">Mật khẩu</Tag>
                }
                if (Number(e.login_type) === 2) {
                    return <Tag color="orange">Token RA</Tag>
                } else {
                    return ""
                }
            }
        },
        {
            title: "Loại người dùng",
            dataIndex: "",
            render: (e) => {
                if (Number(e.type) === 1) {
                    return <p>Admin</p>
                }
                if (Number(e.type) === 2) {
                    return <p>Giám đốc</p>
                }
                if (Number(e.type) === 3) {
                    return <p>Kế toán</p>
                }
                if (Number(e.type) === 4) {
                    return <p>Nghiệp vụ</p>
                }
                if (Number(e.type) === 5) {
                    return <p>Nhân viên kinh doanh</p>
                }
                if (Number(e.type) === 6) {
                    return <p>Hỗ trợ kinh doanh</p>
                }
                if (Number(e.type) === 7) {
                    return <p>Đại lý</p>
                }
                if (Number(e.type) === 8) {
                    return <p>Cộng tác viên</p>
                } else {
                    return ""
                }
            }
        },
        {
            title: "Thời gian tạo",
            dataIndex: "created_at",
            render: (text) => formatDate(text),
        },
        {
            title: "Trạng thái",
            dataIndex: "",
            render: (e) => {
                if (Number(e.status) === 0) {
                    return <Tag color="green">Hoạt động</Tag>
                }
                if (Number(e.status) === 1) {
                    return <Tag color="orange">Tạm dừng</Tag>
                }
                if (Number(e.status) === 2) {
                    return <Tag color="red">Không hoạt động</Tag>
                } else {
                    return ""
                }
            }
        },
        {
            title: "Tác vụ",
            render: renderActionButton
        }
    ];
    const fetch = async (params = {}) => {
        try {
            const userService = new UserServices();
            const userResult = await userService.getUserAuth();
            const userID = userResult.data.id
            let data = { ...params, userID }

            return await userService.getListUserMember(data);
        } catch (error) {
            console.log(error);
            onFailAction();
        }
    };

    return (
        <PageWrapper title="Quản lý thành viên">
            <MemberSearchForm />
            <Table columns={columns} onFetchData={fetch} />
        </PageWrapper>
    );
};
