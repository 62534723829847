import {RequestCTSPersonal} from "../../../pages/request-cts-personal/RequestCTSPersonal";
import WrappedRequestCTSPersonalUpdate from "../../../pages/request-cts-personal/RequestCTSPersonalUpdate";
import WrappedRequestCTSPersonalPreview from "../../../pages/request-cts-personal/RequestCTSPersonalPreview";
import WrappedRequestCTSPersonalCreate from "../../../pages/request-cts-personal/RequestCTSPersonalCreate";
import { REQUEST_DIGITAL_OZ_CERTIFICATE_PERMISSION } from "helpers/enum/PermissionEnums";

const REQUEST_CTS_PERSONAL_ROUTES = [
    {
        component: RequestCTSPersonal,
        link: "/yeu-cau-cts-ca-nhan",
        permission: REQUEST_DIGITAL_OZ_CERTIFICATE_PERMISSION.LIST,
        isExact: true
    },
    {
        component: WrappedRequestCTSPersonalCreate,
        link: "/yeu-cau-cts-ca-nhan/them-moi",
        permission: REQUEST_DIGITAL_OZ_CERTIFICATE_PERMISSION.ADD,
        isExact: true
    },
    {
        component: WrappedRequestCTSPersonalUpdate,
        link: "/yeu-cau-cts-ca-nhan/cap-nhat/:id",
        permission: REQUEST_DIGITAL_OZ_CERTIFICATE_PERMISSION.EDIT,
        isExact: true
    },
    {
        component: WrappedRequestCTSPersonalPreview,
        link: "/yeu-cau-cts-ca-nhan/xem/:id",
        permission: REQUEST_DIGITAL_OZ_CERTIFICATE_PERMISSION.VIEW,
        isExact: true
    },
];

export default REQUEST_CTS_PERSONAL_ROUTES;
