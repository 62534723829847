import React, { Component } from "react";
import {Input, Modal, Switch} from 'antd';
import InputUploadFile from "../../components/common/form/input-with-label/InputUploadFile";
import LabelInput from "../../components/common/form/input-with-label/label/LabelInput";

const {TextArea} = Input;

interface Props {
    handleCancel?: any;
    handleImport?: any;
    value?: string;
    visible: boolean;
    loading?: boolean;
    title?: string;
    onChange?: any;
    error?: string;
    onchangeFileUpload?: any;
    onChangeText?: any;

    valueTextArea?: string;

    fileNamePassport_CN?: string;
    fileNameOrganization_CN?: string;
    fileNameOrganization_TC?: string;
    fileNameDeputyPassPort_TC?: string;
    fileNameDK01_01?: string;
    fileNameDK01_02?: string;
    fileNameDK02?: string;

    filePassport_CN?: boolean;
    fileOrganization_CN?: boolean;
    fileOrganization_TC?: boolean;
    fileDeputyPassPort_TC?: boolean;
    fileDK01_01?: boolean;
    fileDK01_02?: boolean;
    fileDK02?: boolean;

    statusFilePassport_CN?: boolean;
    statusFileOrganization_CN?: boolean;
    statusFileOrganization_TC?: boolean;
    statusFileDeputyPassPort_TC?: boolean;
    statusFileDK01_01?: boolean;
    statusFileDK01_02?: boolean;
    statusFileDK02?: boolean;
}
interface State {
    visible: boolean;
}

export default class ModalUploadFile extends Component<Props, State> {
    render() {
        return (
            <div>
                <Modal
                    title={this.props.title ? this.props.title : 'Tải file hồ sơ'}
                    visible={this.props.visible}
                    onOk={this.props.handleImport}
                    onCancel={this.props.handleCancel}
                    destroyOnClose={true}
                    okText="Tải lên"
                    cancelText="Đóng"
                    className="modal-xs"
                    width="60%"
                    closable={false}
                    centered={true}
                >
                    {
                        this.props.filePassport_CN ?
                            (
                                <div className={"d-flex justify-content-between"}>
                                    <Switch
                                        disabled={true}
                                        className={"mt-4"}
                                        checkedChildren="Hợp lệ"
                                        unCheckedChildren="Không hợp lệ"
                                        style={(this.props.statusFilePassport_CN === true) ? ({backgroundColor: "green"}) : ({backgroundColor: "red"})}
                                        checked={this.props.statusFilePassport_CN}

                                    />
                                    <InputUploadFile
                                        name="file_passport_CN"
                                        classWrapped="col-md-10 custom-input-file"
                                        label="File CMND/Hộ chiếu"
                                        filename={this.props.fileNamePassport_CN}
                                        onChangeFileUpload={this.props.onchangeFileUpload}
                                        extentionsAllow={['pdf', 'PDF']}
                                        accept={[".pdf", ".PDF"]}
                                    />
                                </div>
                            )
                            : ""
                    }
                    {
                        this.props.fileOrganization_CN ?
                            (
                                <div className={"d-flex justify-content-between"}>
                                    <Switch
                                        className={"mt-4"}
                                        checkedChildren="Hợp lệ"
                                        unCheckedChildren="Không hợp lệ"
                                        checked={this.props.statusFileOrganization_CN}
                                        disabled={true}
                                        style={(this.props.statusFileOrganization_CN === true) ? ({backgroundColor: "green"}) : ({backgroundColor: "red"})}
                                    />
                                    <InputUploadFile
                                        name="file_organization_CN"
                                        classWrapped="col-md-10 custom-input-file"
                                        label="File giấy tờ pháp lý tổ chức (Cá nhân)"
                                        filename={this.props.fileNameOrganization_CN}
                                        onChangeFileUpload={this.props.onchangeFileUpload}
                                        extentionsAllow={['pdf', 'PDF']}
                                        accept={[".pdf", ".PDF"]}
                                    />
                                </div>
                            )

                            : ""
                    }
                    {
                        this.props.fileOrganization_TC ?
                            (
                                <div className={"d-flex justify-content-between"}>
                                    <Switch
                                        className={"mt-4"}
                                        checkedChildren="Hợp lệ"
                                        unCheckedChildren="Không hợp lệ"
                                        disabled={true}
                                        checked={this.props.statusFileOrganization_TC}
                                        style={(this.props.statusFileOrganization_TC === true) ? ({backgroundColor: "green"}) : ({backgroundColor: "red"})}

                                    />
                                    <InputUploadFile
                                        name="file_organization_TC"
                                        classWrapped="col-md-10 custom-input-file"
                                        label="File giấy tờ pháp lý tổ chức"
                                        filename={this.props.fileNameOrganization_TC}
                                        onChangeFileUpload={this.props.onchangeFileUpload}
                                        extentionsAllow={['pdf', 'PDF']}
                                        accept={[".pdf", ".PDF"]}
                                    />
                                </div>
                            )

                            : ""
                    }
                    {
                        this.props.fileDeputyPassPort_TC ?
                            (
                                <div className={"d-flex justify-content-between"}>
                                    <Switch
                                        className={"mt-4"}
                                        checkedChildren="Hợp lệ"
                                        unCheckedChildren="Không hợp lệ"
                                        checked={this.props.statusFileDeputyPassPort_TC}
                                        disabled={true}
                                        style={(this.props.statusFileDeputyPassPort_TC === true) ? ({backgroundColor: "green"}) : ({backgroundColor: "red"})}
                                    />
                                    <InputUploadFile
                                        name="file_deputy_passport_TC"
                                        classWrapped="col-md-10 custom-input-file"
                                        label="File CMND/Hộ chiếu người đại diện"
                                        filename={this.props.fileNameDeputyPassPort_TC}
                                        onChangeFileUpload={this.props.onchangeFileUpload}
                                        extentionsAllow={['pdf', 'PDF']}
                                        accept={[".pdf", ".PDF"]}
                                    />
                                </div>
                            )
                            : ""
                    }
                    {
                        this.props.fileDK01_01 ?
                            (
                                <div className={"d-flex justify-content-between"}>
                                    <Switch
                                        className={"mt-4"}
                                        checkedChildren="Hợp lệ"
                                        unCheckedChildren="Không hợp lệ"
                                        checked={this.props.statusFileDK01_01}
                                        style={(this.props.statusFileDK01_01 === true) ? ({backgroundColor: "green"}) : ({backgroundColor: "red"})}
                                        disabled={true}
                                    />
                                    <InputUploadFile
                                        name="file_dk_01_01"
                                        classWrapped="col-md-10 custom-input-file"
                                        label="File DK-01.01"
                                        filename={this.props.fileNameDK01_01}
                                        onChangeFileUpload={this.props.onchangeFileUpload}
                                        extentionsAllow={['pdf', 'PDF']}
                                        accept={[".pdf", ".PDF"]}
                                    />
                                </div>
                            )
                            : ""
                    }
                    {
                        this.props.fileDK01_02 ?
                            (
                                <div className={"d-flex justify-content-between"}>
                                    <Switch
                                        className={"mt-4"}
                                        checkedChildren="Hợp lệ"
                                        unCheckedChildren="Không hợp lệ"
                                        checked={this.props.statusFileDK01_02}
                                        style={(this.props.statusFileDK01_02 === true) ? ({backgroundColor: "green"}) : ({backgroundColor: "red"})}
                                        disabled={true}
                                    />
                                    <InputUploadFile
                                        name="file_dk_01_02"
                                        classWrapped="col-md-10 custom-input-file"
                                        label="File DK-01.02"
                                        filename={this.props.fileNameDK01_02}
                                        onChangeFileUpload={this.props.onchangeFileUpload}
                                        extentionsAllow={['pdf', 'PDF']}
                                        accept={[".pdf", ".PDF"]}
                                    />
                                </div>
                            )
                            : ""
                    }
                    {
                        this.props.fileDK02 ?
                            (
                                <div className={"d-flex justify-content-between"}>
                                    <Switch
                                        className={"mt-4"}
                                        checkedChildren="Hợp lệ"
                                        unCheckedChildren="Không hợp lệ"
                                        checked={this.props.statusFileDK02}
                                        style={(this.props.statusFileDK02 === true) ? ({backgroundColor: "green"}) : ({backgroundColor: "red"})}
                                        disabled={true}
                                    />
                                    <InputUploadFile
                                        name="file_dk02"
                                        classWrapped="col-md-10 custom-input-file"
                                        label="Mẫu ký xác nhận DK-02"
                                        filename={this.props.fileNameDK02}
                                        onChangeFileUpload={this.props.onchangeFileUpload}
                                        extentionsAllow={['pdf', 'PDF']}
                                        accept={[".pdf", ".PDF"]}
                                    />
                                </div>
                            )
                            : ""
                    }
                    <div>
                        <LabelInput
                            nameFor="reason_refuse"
                            label="Lý do từ chối của nghiệp vụ"
                        />
                        <TextArea
                            className="form-control"
                            value={this.props.valueTextArea ? this.props.valueTextArea : ""}
                            onChange={this.props.onChangeText}
                            disabled={true}
                        />
                    </div>
                    {
                        this.props.error ?
                            <div className="col-md-12 text-center">
                                <p className="text-danger">{this.props.error}</p>
                            </div> : ""
                    }
                </Modal>
            </div>
        );
    }
}
