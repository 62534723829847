import React, { useState } from "react";
import PageWrapper from "../wrapper/PageWrapper";
import Table from "../../components/common/table/Table";
import { onFailAction, onSuccessAction } from "helpers/SwalCommon";
import { RouteComponentProps } from "react-router";
import DocChangeRenewWarrantyManagerSearchForm from "./search/DocChangeRenewWarrantyManagerSearchForm";
import MenuDropDoc from "./MenuDropDoc";
import { Dropdown, Tag } from "antd";
import { loading } from "../../components/common/loading/Loading";
import BaseServices from "../../services/base/BaseServices";
import ModalUploadFile from "./ModalUploadFile";
import { checkPermission, formatDateTime } from "helpers/NewCaCrmHelper";
import ModalDeny from "./../../components/common/form/ModalDeny";
import { DocChangeRenewWarrantyManagerService } from "../../services/doc-change-renew-warranty-manager/DocChangeRenewWarrantyManagerService";
import {
    STATUS_DOC_CHANGE_RENEW_WARRANTY_LABEL,
    STATUS_FILE_DOC_CHANGE_RENEW_WARRANTY_LABEL,
} from "../../helpers/enum/doc-change-renew-warranty-manager/DocChangeRenewWarrantyManagerEnums";
import { DOC_CHANGE_RENEW_WARRANTY_MANAGER_PERMISSION_SALE } from "../../helpers/enum/PermissionEnums";
const { REACT_APP_BASE_API_URL } = window["runConfig"];
interface Props extends RouteComponentProps {}

export const DocChangeRenewWarrantyManager: React.FC<Props> = (props) => {
    const [file, setFile] = useState("");
    const [id, setId] = useState("");
    const [fileDK01, setFileDK01] = useState(false);
    const [fileDK02, setFileDK02] = useState(false);
    const [filePassport, setFilePassport] = useState(false);
    const [fileCompany, setFileCompany] = useState(false);
    const [filePresentPassport, setFilePresentPassport] = useState(false);

    const [fileNameDK01, setFileNameDK01] = useState("");
    const [fileNameDK02, setFileNameDK02] = useState("");
    const [fileNamePassport, setFileNamePassport] = useState("");
    const [fileNameCompany, setFileNameCompany] = useState("");
    const [fileNamePresentPassport, setFileNamePresentPassport] = useState("");

    const [statusFileDK01, setStatusFileDK01] = useState(false);
    const [statusFileDK02, setStatusFileDK02] = useState(false);
    const [statusFilePassport, setStatusFilePassport] = useState(false);
    const [statusFileCompany, setStatusFileCompany] = useState(false);
    const [statusFilePresentPassport, setStatusFilePresentPassport] =
        useState(false);

    /**
     * modal deny
     */
    const [modalDestroy, setModalDestroy] = useState({
        visible_modal_deny: false,
        loading: false,
        title_modal: "",
        id: "",
        type: "",
        reason: "",
        error_reason: "",
    });
    const handleModalDenyCancel = () => {
        setModalDestroy({
            ...modalDestroy,
            visible_modal_deny: false,
            reason: "",
            error_reason: "",
        });
    };
    const onChangeDataDeny = ({ target: { value } }) => {
        setModalDestroy({ ...modalDestroy, reason: value });
    };
    const confirmDeny = async () => {
        let error_reason = "Lý do cập nhật hồ sơ không được  để trống";
        if (modalDestroy.reason.trim() === "") {
            setModalDestroy({ ...modalDestroy, error_reason: error_reason });
            return false;
        }
        const data = { reason: modalDestroy.reason, id: modalDestroy.id };
        const result =
            await DocChangeRenewWarrantyManagerService.requestUpdateFile(data);
        await setModalDestroy({
            ...modalDestroy,
            visible_modal_deny: false,
            reason: "",
        });

        if (result && Number(result.status) === 200) {
            onSuccessAction("Gửi yêu cầu thành công!", () =>
                window.location.reload()
            );
        } else if (result && Number(result.status) === 422) {
            onFailAction("Có lỗi xảy ra trong quá trình yêu cầu!");
        } else {
            onFailAction(result.error);
        }
    };

    /**
     * modal upload
     */
    const [modalImport, setModalImport] = useState({
        visible_modal_upload: false,
        loading: false,
        title_modal: "Cập nhật hồ sơ",
        base64: "",
        type_file: "",
        text_deny: "",
        idDoc: "",
        error_text_deny: "",
        reason_refuse: "",
        object: null,
        customer_type: null,
    });
    const handleModalImportCancel = () => {
        setModalImport({
            ...modalImport,
            visible_modal_upload: false,
            error_text_deny: "",
        });
        setFileDK01(false);
        setFileDK02(false);
        setFilePassport(false);
        setFilePresentPassport(false);
        setFileCompany(false);

        setFileNameDK01("");
        setFileNameDK02("");
        setFileNamePassport("");
        setFileNamePresentPassport("");
        setFileNameCompany("");
    };
    const confirmImport = async () => {
        if (
            !fileNamePassport &&
            !fileNameCompany &&
            !fileNameDK01 &&
            !fileNameDK02 &&
            !fileNamePresentPassport
        ) {
            setModalImport({
                ...modalImport,
                error_text_deny: "Bạn chưa tải hồ sơ lên!",
            });
            return false;
        }
        const data = {
            file_dk_01: fileNameDK01,
            file_dk_02: fileNameDK02,
            file_company: fileNameCompany,
            file_present_passport: fileNamePresentPassport,
            file_passport: fileNamePassport,
            object: modalImport.object,
            customer_type: modalImport.customer_type,
            idDoc: modalImport.idDoc,
            id: id,
        };
        const result = await DocChangeRenewWarrantyManagerService.uploadFile(
            data
        );
        await setModalImport({
            ...modalImport,
            visible_modal_upload: false,
            error_text_deny: "",
        });
        if (result && Number(result.status) === 200) {
            onSuccessAction("Tải thành công!", () => window.location.reload());
        } else {
            onFailAction(result.error, () => {
                props.history.push(
                    "/quan-ly-ho-so-thay-doi-thong-tin-cap-lai-bao-hanh"
                );
                setFileNameDK01("");
            });
        }
    };

    const onChangeFile = async (e) => {
        const files = e.target.files[0];
        const name = e.target.name;
        if (files) {
            if (files.type !== "application/pdf") {
                setModalImport({
                    ...modalImport,
                    error_text_deny: "Chỉ chấp nhận định dạng file PDF",
                });
                return false;
            }
            const fileSize = files.size / 1024 / 1024;
            if (fileSize > 5.1) {
                setModalImport({
                    ...modalImport,
                    error_text_deny: "Giới hạn dung lượng 5MB",
                });
                return false;
            } else {
                const formData = new FormData();
                formData.append("file", files);
                loading.runLoadingBlockUI();
                const fileData = await BaseServices.axiosUpLoadFile(formData);
                if (fileData) {
                    if (name === "file_dk01") {
                        setFileNameDK01(fileData.file);
                        setModalImport({ ...modalImport, error_text_deny: "" });
                    }
                    if (name === "file_dk02") {
                        setFileNameDK02(fileData.file);
                        setModalImport({ ...modalImport, error_text_deny: "" });
                    }
                    if (name === "file_passport") {
                        setFileNamePassport(fileData.file);
                        setModalImport({ ...modalImport, error_text_deny: "" });
                    }
                    if (name === "file_present_passport") {
                        setFileNamePresentPassport(fileData.file);
                        setModalImport({ ...modalImport, error_text_deny: "" });
                    }
                    if (name === "file_company") {
                        setFileNameCompany(fileData.file);
                        setModalImport({ ...modalImport, error_text_deny: "" });
                    }
                }
                loading.stopRunLoading();
            }
        } else {
            if (name === "file_dk01") setFileNameDK01("");
            if (name === "file_dk02") setFileNameDK02("");
            if (name === "file_passport") setFileNamePassport("");
            if (name === "file_present_passport")
                setFileNamePresentPassport("");
            if (name === "file_company") setFileNameCompany("");
        }
    };
    const onClickUpload = async (record) => {
        let result = getListDoc(record);
        let modelDocument = result.modelDocument;
        let model = result.model;
        setModalImport({
            ...modalImport,
            visible_modal_upload: true,
            object: record.object,
            customer_type: record.customer_type,
            idDoc: modelDocument["id"],
            reason_refuse: model["reason_refuse"],
        });
        if (Number(record.object === 5) || Number(record.object === 6)) {
            setFileDK01(true);
            setStatusFileDK01(
                record.request_renew_and_warranty.status_file_dk01
            );
        }
        if (record.object === 4) {
            if (Number(record.customer_type) === 2) {
                setFilePassport(true);
                setFileDK01(true);
                setStatusFileDK01(record.request_change_info.status_file_dk01);
                setStatusFilePassport(
                    record.request_change_info.status_file_passport
                );
            } else {
                setFileCompany(true);
                setFilePresentPassport(true);
                setFileDK01(true);
                setStatusFileCompany(
                    record.request_change_info.status_file_company
                );
                setStatusFilePresentPassport(
                    record.request_change_info.status_file_present_passport
                );
                setStatusFileDK01(record.request_change_info.status_file_dk01);
            }
        }
        setId(record.id);
        setFileDK02(true);
        setStatusFileDK02(modelDocument["status_file"]);
    };
    const getListDoc = (record) => {
        let model = Object;
        let modelDocument = Object;
        let modelDocumentArr = record.request_doc;
        let listDocFile: Array<any> = [];
        modelDocumentArr.map((value) => {
            if (Number(record.object) === 5 && Number(value.type) === 15) {
                model = record.request_renew_and_warranty;
                modelDocument = value;
            }
            if (Number(record.object) === 6 && Number(value.type) === 16) {
                model = record.request_renew_and_warranty;
                modelDocument = value;
            }
            if (Number(record.object) === 4) {
                model = record.request_change_info;
                if (
                    Number(record.customer_type) === 1 &&
                    Number(value.type) === 12
                ) {
                    modelDocument = value;
                } else if (
                    Number(record.customer_type) === 2 &&
                    Number(value.type) === 13
                ) {
                    modelDocument = value;
                }
            }
            return [model, modelDocument];
        });

        if (Number(record.customer_type) === 1) {
            if (Number(record.object) === 4) {
                listDocFile = [
                    "file_change",
                    "file_legal",
                    "file_present_passport",
                    "dk02",
                ];
            }else{
                listDocFile = [
                    "file",
                    "dk02",
                ];
            }
        }
        if (Number(record.customer_type) === 2) {
            if (Number(record.object) === 4) {
                listDocFile = [
                    "file_change",
                    "file_passport",
                    "dk02",
                ];
            }else{
                listDocFile = [
                    "file",
                    "dk02",
                ];
            }
        }

        return {
            listDocFile: listDocFile,
            model: model,
            modelDocument: modelDocument,
        };
    };

    /**
     * Button xem file
     */
    const renderActionViewFile = (text, record) => {
        let result = getListDoc(record);
        let idCert = record.id;
        return (
            <div className="row justify-content-center">
                <Dropdown
                    overlay={
                        <MenuDropDoc
                            onClick={(index) => onPreviewFile(idCert, index)}
                            link={file}
                            dataDoc={result.listDocFile}
                        />
                    }
                    trigger={["click"]}
                    placement="bottomLeft"
                >
                    <button className="btn btn-outline-primary btn-sm w-100 ml-2 mr-2 text-nowrap">
                        Xem file 1 <i className="fas fa-angle-down" />
                    </button>
                </Dropdown>
            </div>
        );
    };
    const clickRequestUploadFile = (id) => {
        let title_modal = "Yêu cầu cập nhật hồ sơ";
        setModalDestroy({
            ...modalDestroy,
            visible_modal_deny: true,
            title_modal: title_modal,
            error_reason: "",
            id: id,
        });
    };

    /**
     * Click xem file
     */
    const onPreviewFile = async (id, type) => {
        try {
            let token = localStorage.getItem("currentUser");
            if (token) {
                let obj = JSON.parse(token);
                let link = `${REACT_APP_BASE_API_URL}qlbh/quan-ly-ho-so-thay-doi-thong-tin-cap-lai-bao-hanh/previewFile/${id}/${type}?token=${obj.token}`;
                setFile(link);
            }
        } catch (error) {
            console.log(error);
            onFailAction("Có lỗi xảy ra!");
        }
    };

    /**
     *  Button tác vụ
     */
    const renderActionButton = (text, record) => {
        let object = text.object;
        let fileStatus = Number;
        if (Number(object) === 4) {
            if (text.request_change_info) {
                fileStatus = text.request_change_info.file_status;
            }
        } else {
            if (text.request_renew_and_warranty) {
                fileStatus = text.request_renew_and_warranty.file_status;
            }
        }
        if (
            (Number(fileStatus) === 1 || Number(fileStatus) === 2) &&
            checkPermission(
                DOC_CHANGE_RENEW_WARRANTY_MANAGER_PERMISSION_SALE.SEND_REQ
            )
        ) {
            return (
                <div className="row justify-content-center">
                    <button
                        type="button"
                        title="Yêu cầu cập nhật hồ sơ"
                        className="text-warning ml-2"
                        onClick={() => clickRequestUploadFile(record.id)}
                    >
                        <i className="fas fa-exclamation-triangle fa-lg" />
                    </button>
                </div>
            );
        } else if (
            Number(fileStatus) === 3 &&
            checkPermission(
                DOC_CHANGE_RENEW_WARRANTY_MANAGER_PERMISSION_SALE.UPDATE_FILE
            )
        ) {
            return (
                <div className="row justify-content-center">
                    <button
                        type="button"
                        title="Tải lên hồ sơ"
                        className="text-primary ml-2"
                        onClick={() => onClickUpload(record)}
                    >
                        <i className="fas fa-upload fa-lg" />
                    </button>
                </div>
            );
        }
    };
    const renderFileStatusCol = (text) => {
        let object = text.object;
        let key = 1;
        if (Number(object) === 4) {
            if (text.request_change_info) {
                key = text.request_change_info.file_status;
            }
        } else {
            if (text.request_renew_and_warranty) {
                key = text.request_renew_and_warranty.file_status;
            }
        }
        return (
            <Tag
                color={`${STATUS_FILE_DOC_CHANGE_RENEW_WARRANTY_LABEL[key].class}`}
            >
                {STATUS_FILE_DOC_CHANGE_RENEW_WARRANTY_LABEL[key].label}
            </Tag>
        );
    };
    const renderStatus = (text) => {
        let object = text.object;
        let key = 1;
        if (Number(object) === 4) {
            if (text.request_change_info) {
                key = text.request_change_info.status;
            }
        } else {
            if (text.request_renew_and_warranty) {
                key = text.request_renew_and_warranty.status;
            }
        }

        return (
            <Tag color={`${STATUS_DOC_CHANGE_RENEW_WARRANTY_LABEL[key].class}`}>
                {STATUS_DOC_CHANGE_RENEW_WARRANTY_LABEL[key].label}
            </Tag>
        );
    };

    const fetch = async (params = {}) => {
        try {
            let result = await DocChangeRenewWarrantyManagerService.list(
                params
            );
            if (result && Number(result.status) === 200) {
                return result;
            } else {
                onFailAction("Có lỗi xảy ra!");
            }
        } catch (error) {
            console.log(error);
            onFailAction("Có lỗi xảy ra!");
        }
    };
    const columns = [
        {
            title: "Mã định danh",
            dataIndex: "",
            render: (e) => {
                if (e.object === 4) {
                    if (e.request_change_info) {
                        return e.request_change_info.code
                            ? e.request_change_info.code
                            : "";
                    } else return "";
                } else {
                    if (e.request_renew_and_warranty) {
                        return e.request_renew_and_warranty.identity
                            ? e.request_renew_and_warranty.identity
                            : "";
                    } else return "";
                }
            },
        },
        {
            title: "Tên khách hàng",
            dataIndex: "",
            render: (e) => {
                if (e.object === 4) {
                    if (e.request_change_info) {
                        return e.request_change_info.fullname
                            ? e.request_change_info.fullname
                            : "";
                    } else return "";
                } else {
                    if (e.request_renew_and_warranty) {
                        return e.request_renew_and_warranty.fullname
                            ? e.request_renew_and_warranty.fullname
                            : "";
                    } else return "";
                }
            },
        },
        {
            title: "Đối tượng",
            dataIndex: "",
            render: (e) => {
                if (e.customer_type === 1) {
                    return <Tag color="green">Tổ chức</Tag>;
                }
                if (e.customer_type === 2) {
                    return <Tag color="blue">Cá nhân</Tag>;
                }
            },
        },
        {
            title: "Ngày tạo",
            className: "text-center",
            render: (e) => {
                if (e.object === 4) {
                    if (e.request_change_info) {
                        return formatDateTime(
                            e.request_change_info.created_at
                                ? e.request_change_info.created_at
                                : ""
                        );
                    } else return "";
                } else {
                    if (e.request_renew_and_warranty) {
                        return formatDateTime(
                            e.request_renew_and_warranty.created_at
                                ? e.request_renew_and_warranty.created_at
                                : ""
                        );
                    } else return "";
                }
            },
        },
        {
            title: "Trạng thái yêu cầu",
            dataIndex: "",
            render: renderStatus,
        },
        {
            title: "Loại yêu cầu",
            dataIndex: "",
            render: (e) => {
                if (e.object === 4) {
                    return <Tag color="green">Thay đổi thông tin</Tag>;
                }
                if (e.object === 5) {
                    return <Tag color="blue">Cấp lại</Tag>;
                }
                if (e.object === 6) {
                    return <Tag color="red">Bảo hành</Tag>;
                }
            },
        },
        {
            title: "Trạng thái hồ sơ",
            dataIndex: "",
            render: renderFileStatusCol,
        },
        {
            title: "File",
            render: renderActionViewFile,
        },
        {
            title: "Ngày cập nhật hồ sơ",
            className: "text-center",
            render: (e) => {
                if (e.object === 4) {
                    if (e.request_change_info) {
                        return formatDateTime(
                            e.request_change_info.update_file_at
                                ? e.request_change_info.update_file_at
                                : ""
                        );
                    } else return "";
                } else {
                    if (e.request_renew_and_warranty) {
                        return formatDateTime(
                            e.request_renew_and_warranty.update_file_at
                                ? e.request_renew_and_warranty.update_file_at
                                : ""
                        );
                    } else return "";
                }
            },
        },
        {
            title: "Người duyệt",
            className: "text-center",
            render: (e) => {
                if (e.object === 4) {
                    if (e.request_change_info) {
                        if (e.request_change_info.user_approve_file)
                            return e.request_change_info.user_approve_file
                                .fullname
                                ? e.request_change_info.user_approve_file
                                      .fullname
                                : "";
                    } else return "";
                } else {
                    if (e.request_renew_and_warranty) {
                        if (e.request_renew_and_warranty.user_approve_file)
                            return e.request_renew_and_warranty
                                .user_approve_file.fullname
                                ? e.request_renew_and_warranty.user_approve_file
                                      .fullname
                                : "";
                    } else return "";
                }
            },
        },
        {
            title: "Tác vụ",
            render: renderActionButton,
        },
    ];

    return (
        <PageWrapper title="Quản lý hồ sơ thay đổi thông tin, cấp lại, bảo hành">
            <DocChangeRenewWarrantyManagerSearchForm />
            <Table columns={columns} onFetchData={fetch} />
            <ModalDeny
                visible={modalDestroy.visible_modal_deny}
                handleCancel={handleModalDenyCancel}
                handleDeny={confirmDeny}
                value={modalDestroy.reason}
                onChange={onChangeDataDeny}
                error={modalDestroy.error_reason}
                title={modalDestroy.title_modal}
                placeholder={"Nhập lý do cập nhật hồ sơ"}
                okText={"Xác nhận"}
            />
            <ModalUploadFile
                handleCancel={handleModalImportCancel}
                handleImport={confirmImport}
                onchangeFileUpload={onChangeFile}
                value={modalImport.text_deny}
                error={modalImport.error_text_deny}
                visible={modalImport.visible_modal_upload}
                title={modalImport.title_modal}
                valueTextArea={modalImport.reason_refuse}
                fileDk01={fileDK01}
                fileDk02={fileDK02}
                filePassport={filePassport}
                filePresentPassport={filePresentPassport}
                fileCompany={fileCompany}
                fileNameDK01={fileNameDK01}
                fileNameDK02={fileNameDK02}
                fileNamePassport={fileNamePassport}
                fileNamePresentPassport={fileNamePresentPassport}
                fileNameCompany={fileNameCompany}
                statusFileDK01={statusFileDK01}
                statusFileDK02={statusFileDK02}
                statusFilePassport={statusFilePassport}
                statusFilePresentPassport={statusFilePresentPassport}
                statusFileCompany={statusFileCompany}
            />
        </PageWrapper>
    );
};
