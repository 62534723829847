import BaseServices from "../base/BaseServices";

const PREFIX_SERVICES = "qlbh/yeu-cau-cap-lai-bao-hanh-serial/";

const getCertBySerial = async (data) => {
    return await BaseServices.request(
        PREFIX_SERVICES + "get-info-by-serial",
        data,
        "GET"
    );
};

const requestReNewAndWarrantySerial = async (data) => {
    return await BaseServices.request(
        PREFIX_SERVICES + 'request-renew-warranty-serial',
        data,
        'POST'
    );
};

export const RequestRenewAndWarrantySupportSerialService = {
    getCertBySerial,
    requestReNewAndWarrantySerial,
};
