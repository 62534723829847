import React, { Component } from "react";
import { Form, Icon, Input, Button, Row, Col } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { authenticationService } from "../../../services/authentication/AuthenticationService";
import { History } from "history";
import { onFailAction, onSuccessAction } from "../../../helpers/SwalCommon";
import { loading } from "../../../components/common/loading/Loading";
interface Props extends FormComponentProps {
  history: History;
  setUser: any;
  onResetPass: boolean;
  handleOpenResetPass: any;
}

interface State {
  message: string;
  isDisplayMessage: boolean;
  email: string;
}

const initialState = {
  message: "",
  isDisplayMessage: false,
  email: "",
};

class SendEmailChangePass extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const timeCurrent = Date.now() / 1000;
    const { validateFields, getFieldValue } = this.props.form;
    validateFields(async (err, values) => {
      if (!err) {
        try {
          loading.runLoadingBlockUI();
          const response = await authenticationService.sendEmail(
            getFieldValue("email"),
            timeCurrent
          );
          if (response.status === 1) {
            onFailAction(response.message);
          } else {
            onSuccessAction(response.message, () => {
              this.props.history.push("/login");
            });
          }
        } catch (error) {
          console.log(error);
          onFailAction();
        } finally {
          loading.stopRunLoading();
        }
      }
    });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit} className="login-form w-50">
        <span className="txt1 p-b-11" style={{ fontWeight: "bolder" }}>
          Email
        </span>
        <Form.Item>
          {getFieldDecorator("email", {
            rules: [{ required: true, message: "Email không thể bỏ trống!" }],
          })(
            <Input
              prefix={<Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="Nhập email..."
              className="form-control input100"
            />
          )}
        </Form.Item>
        <Form.Item>
          <Row>
            <Col>
              <Button
                htmlType="submit"
                className="login100-form-btn w-100 mb-2"
                style={{ display: "inline" }}
              >
                Tiếp tục <Icon type="arrow-right" />
              </Button>
            </Col>
            <Col>
              <Button
                htmlType="button"
                className="login100-form-btn w-100 mb-2 button-back"
                onClick={(e) => {
                  this.props.handleOpenResetPass();
                }}
              >
                <Icon type="arrow-left" />
                Quay lại
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    );
  }
}

const WrappedNormalSendEmailChangePass = Form.create<Props>({
  name: "SendEmailChangePass",
})(SendEmailChangePass);

export default WrappedNormalSendEmailChangePass;
