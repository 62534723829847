import {
    AgencyPermission,
    CHANGE_MANAGER_CUSTOMER,
    CollaboratorsPermission,
    CHANGE_INFO,
     REQUEST_GEN_TOKEN_CERTIFICATE_PERMISSION,
    CONTRACT_MANAGER, DOC_LEGAL_MANAGER, DOC_CHANGE_RENEW_WARRANTY_MANAGER_PERMISSION_SALE,
    REQUEST_DIGITAL_PER_CERTIFICATE_PERMISSION,
    REQUEST_DIGITAL_OZ_CERTIFICATE_PERMISSION,
    REQUEST_CHANGE_INFO_CERT,
    REQUEST_RENEW_AND_WARRANTY_CERT
} from "../../helpers/enum/PermissionEnums";
import {
    REQUEST_STOP_COOP,
    REQUEST_TOKEN_PERMISSION
} from "helpers/enum/request-token/RequestTokenEnums";
import {SystemPermission} from "../../helpers/enum/SystemEnums";

export const MENU_CONSTANT = [
    {
        link: "manager-co-operate",
        icon: "shop",
        displayName: "Quản lý hợp tác",
        key: "system",
        permission: "menu-manager-co-operate",
        childMenu: [
            {
                link: "/quan-ly-dai-ly",
                childIcon: "cluster",
                displayName: "Quản lý đại lý",
                permission: AgencyPermission.LIST,
                key: "agency"
            },
            {
                link: "/quan-ly-ctv",
                childIcon: "branches",
                displayName: "Quản lý CTV",
                permission: CollaboratorsPermission.LIST,
                key: "ctv"
            },
            {
                link: "/quan-ly-thanh-vien",
                childIcon: "solution",
                displayName: "Quản lý thành viên",
                permission: "manager-account-qlbh",
                key: "member"
            },
            {
                link: "/yeu-cau-token",
                childIcon: "user-add",
                displayName: "Yêu cầu tạo tài khoản",
                permission: REQUEST_TOKEN_PERMISSION.RA,
                key: "token"
            },
            {
                link: "/danh-sach-yeu-cau-dung-hop-tac",
                childIcon: "solution",
                displayName: "Danh sách yc dừng hợp tác",
                permission: REQUEST_STOP_COOP.ALL,
                key: "list-request-stop"
            },
            {
                link: "/yeu-cau-chuyen-nguoi-quan-ly",
                childIcon: "solution",
                displayName: "Thay đổi người quản lý",
                permission: CHANGE_MANAGER_CUSTOMER.ALL,
                key: "transfer-business-employee"
            },
            {
                link: "/yeu-cau-thay-doi-thong-tin",
                childIcon: "solution",
                displayName: "Thay đổi thông tin",
                permission: CHANGE_INFO.ALL,
                key: "change info agency"
            },
            {
                link: "/yeu-cau-dung-hop-tac",
                childIcon: "solution",
                displayName: "Yêu cầu dừng hợp tác",
                permission: 'stop-coop',
                key: "stop-coop"
            }
        ]
    },
    {
        link: "",
        icon: "shopping-cart",
        displayName: "Quản lý bán hàng",
        key: "system-qlbh",
        permission: "menu-manager-sale",
        childMenu: [
            {
                link: "/yeu-cau-cts-ca-nhan",
                childIcon: "solution",
                displayName: "Yêu cầu CTS cá nhân",
                permission: REQUEST_DIGITAL_PER_CERTIFICATE_PERMISSION.LIST,
                key: "request-cts-personal"
            },
            {
                link: "/yeu-cau-cts-to-chuc",
                childIcon: "solution",
                displayName: "Yêu cầu CTS tổ chức",
                permission: REQUEST_DIGITAL_OZ_CERTIFICATE_PERMISSION.LIST,
                key: "request-cts-group"
            },
            {
                link: "/info-certificate",
                childIcon: "solution",
                displayName: "Danh sách chứng thư số",
                permission: REQUEST_GEN_TOKEN_CERTIFICATE_PERMISSION.ALL,
                key: "gen-cts"
            },
            // {
            //     link: "/giay-chung-nhan",
            //     childIcon: "solution",
            //     displayName: "QL giấy chứng nhận",
            //     permission: CREATE_CERTIFICATE_PAPER.ALL,
            //     key: "certificate-paper"
            // },
            {
                link: "/danh-sach-dieu-chinh-thong-tin",
                childIcon: "solution",
                displayName: "QL điều chỉnh thông tin",
                permission: REQUEST_CHANGE_INFO_CERT.LIST,
                key: "list-change-info-cert"
            },
            {
                link: "/danh-sach-yeu-cau-cap-lai-bao-hanh",
                childIcon: "solution",
                displayName: "Danh sách yêu cầu cấp lại, bảo hành",
                permission: REQUEST_RENEW_AND_WARRANTY_CERT.LIST,
                key: "list-request-renew-warranty"
            }
        ]
    },
    {
        link: "support-sale",
        icon: "customer-service",
        displayName: "Hỗ trợ bán hàng",
        key: "support-sale",
        permission: "menu-support-sale",
        childMenu: [
            {
                link: "/yeu-cau-token-cts",
                childIcon: "usb",
                displayName: "Yêu cầu tài liệu bán hàng",
                permission: 'request-token-all',
                key: "support-sale-request-token"
            },
            {
                link: "/yeu-cau-dao-tao",
                childIcon: "wechat",
                displayName: "Yêu cầu đào tạo",
                permission: 'request-training',
                key: "support-sale-training"
            }
        ]
    },
    {
        link: "",
        icon: "profile",
        displayName: "Quản lý hồ sơ",
        key: "manager-file-certificate",
        permission: "menu-manager-file",
        childMenu: [
            {
                link: "/quan-ly-hop-dong",
                childIcon: "file-text",
                displayName: "Quản lý hợp đồng",
                permission: CONTRACT_MANAGER.ALL,
                key: "manager-contract"
            },
            {
                link: "/quan-ly-ho-so-phap-ly",
                childIcon: "file-done",
                displayName: "Quản lý hồ sơ pháp lý",
                permission: DOC_LEGAL_MANAGER.ALL,
                key: "manager-file-legal"
            },
            {
                link: "/quan-ly-ho-so-thay-doi-thong-tin-cap-lai-bao-hanh",
                childIcon: "file-pdf",
                displayName: "Quản lý hồ sơ thay đổi thông tin, cấp lại, bảo hành",
                permission: DOC_CHANGE_RENEW_WARRANTY_MANAGER_PERMISSION_SALE.LIST,
                key: "manager-file-change-renew-warranty"
            }
        ]
    },
    {
        link: "",
        icon: "usergroup-add",
        displayName: "Quản lý khách hàng",
        key: "manager-customer",
        permission: "menu-manager-customer",
        childMenu: [
            {
                link: "/danh-sach-khach-hang",
                childIcon: "solution",
                displayName: "Danh sách khách hàng",
                permission: "list-customer",
                key: "manager-customer-list"
            },
            {
                link: "/yeu-cau-dich-vu-tu-kh",
                childIcon: "profile",
                displayName: "Yêu cầu dịch vụ",
                permission: "list-customer",
                key: "manager-request-service-gateway"
            }
        ]
    },
    {
        link: "",
        icon: "reconciliation",
        displayName: "Đối soát",
        key: "reconciliation ",
        permission: "menu-reconciliation",
        childMenu: [
            {
                link: "/danh-sach-doi-soat",
                childIcon: "reconciliation",
                displayName: "Danh sách đối soát",
                permission: "reconciliation",
                key: "reconciliation-list"
            },
        ]
    },
    {
        link: "",
        icon: "setting",
        displayName: "Hệ thống",
        key: "system-config",
        permission: "menu-system",
        childMenu: [
            {
                link: "/cau-hinh-cts",
                childIcon: "setting",
                displayName: "Cấu hình CTS",
                permission: String(SystemPermission.SIGN),
                key: "signature"
            }
        ]
    },
    {
        link: "",
        icon: "phone",
        displayName: "Hỗ trợ",
        key: "support",
        permission: "",
        childMenu: [
            {
                link: "/ho-tro-tai-lieu",
                childIcon: "book",
                displayName: "Văn bản",
                permission: "",
                key: "support-document"
            },
            {
                link: "/ho-tro-phan-mem",
                childIcon: "file-zip",
                displayName: "Phần mềm",
                permission: "",
                key: "support-software"
            }
        ]
    }
];
