import BaseServices from "../base/BaseServices";

const PREFIX_SERVICES = "/";

const getBannerActive = async () => {
    return BaseServices.request(
        PREFIX_SERVICES + 'get-banner-active',
        {},
        'GET'
    )
};

export const BannerServices = {
    getBannerActive
};
