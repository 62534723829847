import React, { Fragment,  useEffect, useState } from "react";
import { Card, Checkbox, Form, Radio } from "antd";
import { FormComponentProps } from "antd/lib/form";
import PageWrapper from "pages/wrapper/PageWrapper";
import ButtonCancel from "components/common/form/button/ButtonCancel";
import RadioWithLabel from "components/common/form/input-with-label/RadioWithLabel";
import InputWithLabel from "components/common/form/input-with-label/InputWithLabel";
import SelectWithLabel from "components/common/form/input-with-label/SelectWithLabel";
import _ from "lodash";
import { match } from "react-router";
import { onFailAction, onSuccessAction } from "helpers/SwalCommon";
import SelectDateWithLabel from "components/common/form/input-with-label/SelectDateWithLabel";
import moment from "moment";
import { RequestCertificateGroupService } from "../../services/request-certificate-group/RequestCertificateGroupServices";
import { loading } from "../../components/common/loading/Loading";
import { TextAreaWithLabel } from "../../components/common/form/input-with-label/TextAreaWithLabel";
import { RequestDigitalCertificatePersonalServices } from "services/request-digital-certificate-personal/RequestDigitalCertificatePersonalServices";
import ApproveButton from "components/common/form/button/ApproveButton";
import ModalConfirm from "./ModalConfirm";
import LabelInput from "components/common/form/label/LabelInput";
const { REACT_APP_BASE_API_URL } = window["runConfig"];
interface Props extends FormComponentProps {
    match: match<{ id: string }>;
    history: any;
}

export const RequestCTSPersonalUpdate: React.FC<Props> = (props) => {
    const id = props.match.params.id;

    const [provideDate, setProvideDate] = useState("");
    const [represenProvideDate, setRepresenProvideDate] = useState("");
    const [status, setStatus] = useState(0);
    const [reason, setReason] = useState("");
    const [dateExpiredCert, setDateExpiredCert] = useState("");
    const [cateServicePackage, setCateServicePackage] = useState({});
    const [file, setFile] = useState("");
    // const [secretCode, setSecretCode] = useState("");
    const [password, setPassword] = useState("");
    const [cateServicePackageAmount, setCateServicePackageAmount] = useState(
        []
    );
    const [versionToken, setVerionToken] = useState({});

    const [statusReqGen, setStatusReqGen] = useState(false);
    const [linkReSign, setLinkReSign] = useState(false);
    const [showEmail, setShowEmail] = useState(false);
    const [showViewFileLegal, setShowViewFileLegal] = useState(false);
    const [showViewFilePassport, setShowViewFilePassport] = useState(false);

    const [isFileDk03, setIsFileDk3] = useState(false);
    const [province, setProvince] = useState({});
    const [district, setDistrict] = useState({});
    const [provider, setProvider] = useState({});
    const [labelCode, setLabelCode] = useState("Mã số doanh nghiệp");
    const [isCheckDestroy, setIsCheckDestroy] = useState(false);
    const [isViewPenaltyValue, setIsViewPenaltyValue] = useState(false);
    const [valueDestroy, setValueDestroy] = useState(null);
    const [modal, setModal] = useState({
        visible_modal: false,
        loading: false,
        penalty_value: null,
    });

    const [dataView, setDataView] = useState({
        type_extend:null
    })
    const onChange = async (value) => {
        const selectdCate: any = _.find(cateServicePackageAmount, {
            id: parseInt(value),
        });
        if (selectdCate) {
            props.form.setFieldsValue({ package_price: selectdCate.price });
        }
    };

    const fetchRequestPersonal = async () => {
        const result =
            await RequestCertificateGroupService.getRequestCertificate(id);
        const province = await RequestCertificateGroupService.getProvince();
        setProvince(_.mapValues(_.keyBy(province, "newtel_code"), "fullname"));
        const district =
            await RequestCertificateGroupService.getDistrictByProvince(
                result.data.province_code
            );
        setDistrict(
            _.mapValues(_.keyBy(district, "newtel_district_code"), "fullname")
        );

        if (Number(result.data.status) === 5) {
            setStatusReqGen(true);
        }
        setDataView(result.data)
        setProvideDate(result.data.organization.provide_date);
        setRepresenProvideDate(result.data.organization.represen_passport_date);
        setReason(result.data.reason);
        setStatus(result.data.status);
        // setSecretCode(result.data.secret_code);
        setPassword(result.data.password);
        setDateExpiredCert(result.data.date_expired_cert);
        setShowEmail(result.data.show_email);
        setModal({
            ...modal,
            penalty_value: result.data.penaltyValue,
        });
        if (Number(result.data.status) === 30 || Number(result.data.status) === 31) {
            setIsViewPenaltyValue(true);
        }
        setIsCheckDestroy(result.data.isCheckDestroy);
        setValueDestroy(result.data.penalty_value);
        props.form.setFieldsValue({
            type_legal: result.data.organization.type_legal
                ? result.data.organization.type_legal.toString()
                : "",
            code: result.data.organization.code,
            provide_organization: result.data.organization.provide_organization,
            tax_code: result.data.organization.tax_code,
            fullname: result.data.organization.fullname,
            address: result.data.organization.address,
            email: result.data.organization.email,
            phone: result.data.organization.phone,
            promotion_code: result.data.promotion_code,
            object: result.data.object.toString(),
            type_docurment: result.data.type_docurment.toString(),
            type_device: result.data.type_device.toString(),
            package_id: result.data.package_id.toString(),
            package_price: result.data.package_price,
            represent_fullname: result.data.organization.represent_fullname,
            represen_passport: result.data.organization.represen_passport,
            represen_passport_place:
                result.data.organization.represen_passport_place,
            represen_position: result.data.organization.represen_position,
            represen_email: result.data.organization.represen_email,
            represen_phone: result.data.organization.represen_phone,
            province_code: result.data.province_code.toString(),
            district_code: result.data.district_code.toString(),
            provider_id: result.data.provider_id.toString(),
            identity_type: result.data.organization.identity_type.toString(),
            quantity: result.data.quantity,
            penalty_value: result.data.penalty_value,
            type_extend: result.data.type_extend
            // sp_fullname: result.data.requestsupport.fullname,
            // sp_position: result.data.requestsupport.position,
            // sp_email: result.data.requestsupport.email,
            // sp_phone: result.data.requestsupport.phone,
        });
        if (result.data.version_token_newtel) {
            props.form.setFieldsValue({
                version_token_newtel:
                    result.data.version_token_newtel.toString(),
            });
        }
        if (Number(result.data.type_device) === 2) {
            props.form.setFieldsValue({
                hsm_provider: result.data.hsm_provider.toString(),
            });
        }
        if (Number(result.data.organization.identity_type) === 1) {
            setLabelCode("Mã số thuế");
        }
        if (Number(result.data.organization.identity_type) === 2) {
            setLabelCode("Mã ngân sách");
        }
        if (Number(result.data.organization.identity_type) === 3) {
            setLabelCode("Số quyết định");
        }
        if (Number(result.data.organization.identity_type) === 4) {
            setLabelCode("Mã bảo hiểm xã hội");
        }
        if (Number(result.data.organization.identity_type) === 5) {
            setLabelCode("Giáy phép đầu tư");
        }
        if (
            Number(result.data.object) === 2 ||
            Number(result.data.object) === 3
        ) {
            if (result.data.serial_cts) {
                props.form.setFieldsValue({
                    serial_cts: result.data.serial_cts,
                });
            }
            if (result.data.token_type) {
                props.form.setFieldsValue({
                    token_type: result.data.token_type.toString(),
                });
            }
        }
        let arrAll: any = [];
        result.data.list_document.forEach(function (values) {
            arrAll[values.id] = values.type;
            if (
                Number(values.type) === 7 &&
                Number(values.number_signed !== 2)
            ) {
                setLinkReSign(true);
                props.form.setFieldsValue({
                    link:
                        result.domain +
                        "/view-info-dk02/" +
                        btoa(result.data.code) +
                        "/request",
                });
            }
            if (
                Number(values.type) === 5 &&
                values.file &&
                values.file.length > 0
            ) {
                setShowViewFileLegal(true);
            }
            if (
                Number(values.type) === 6 &&
                values.file &&
                values.file.length > 0
            ) {
                setShowViewFilePassport(true);
            }
        });
        arrAll.forEach(function (item) {
            if (Number(item) === 7) {
                setStatusReqGen(true);
            }
            if (Number(item) === 14) {
                setIsFileDk3(true);
            }
        });

        const type_search = 4;
        const type = props.form.getFieldValue("object");
        const object = 3;
        const type_device = props.form.getFieldValue("type_device");
        const hsm_provider = props.form.getFieldValue("hsm_provider");
        const data =
            await RequestCertificateGroupService.getListCateServicePackage(
                type_search,
                type,
                object,
                type_device,
                result.data.provider_id,
                hsm_provider
            );
        setCateServicePackageAmount(data.data);
        setCateServicePackage(_.mapValues(_.keyBy(data.data, "id"), "name"));
    };

    const getProvider = async () => {
        const data = await RequestCertificateGroupService.getAllProvider();
        setProvider(_.mapValues(_.keyBy(data, "id"), "name"));
    };
    const getVersionToken = async () => {
        const data = await RequestCertificateGroupService.getVersionToken();
        setVerionToken(_.mapValues(_.keyBy(data.data, "id"), "code"));
    };
    useEffect(() => {
        fetchRequestPersonal();
        // eslint-disable-next-line
    }, [id]);

    // useEffect(() => {
    //     props.form.setFieldsValue({ type_extend: dataView.type_extend });
    //     // eslint-disable-next-line
    // }, [dataView.type_extend])

    useEffect(() => {
        getProvider();
        getVersionToken();
        // eslint-disable-next-line
    }, []);
    const STATUS_TU_CHOI = 7;
    const onViewFileDoc = async (type) => {
        loading.runLoadingBlockUI();
        try {
            let token = localStorage.getItem("currentUser");
            if (token) {
                let obj = JSON.parse(token);
                let link = `${REACT_APP_BASE_API_URL}qlbh/yeu-cau-chung-thu-so-to-chuc/getFileDoc/${password}/${type}?token=${obj.token}`;
                setFile(link);
            }
        } catch (error) {
            onFailAction("Có lỗi xảy ra khi xem trước file!");
        }
        loading.stopRunLoading();
    };
    const viewModalConfirm = () => {
        setModal({ ...modal, visible_modal: true, loading: true });
    };
    const onCancelModelDecline = async () => {
        setModal({ ...modal, visible_modal: false });
    };
    const onOkModalDecline = async (e) => {
        const params = { id: id, penalty_value: modal.penalty_value };
        try {
            var result =
                await RequestDigitalCertificatePersonalServices.postRequestDestroy(
                    params
                );
            if (result["status"] === 200) {
                setValueDestroy(params.penalty_value);
                setIsCheckDestroy(false);
                setIsViewPenaltyValue(true);
                onSuccessAction("Tạo yêu cầu hủy thành công!");
            } else {
                onFailAction("Bạn không thể tạo yêu cầu hủy yêu cầu CTS");
            }
        } catch (error) {
            console.log(error);
            onFailAction("Có lỗi xảy ra trong quá trình hủy yêu cầu!");
        }
        setModal({ ...modal, visible_modal: false });
    };

  const  optionsTypeExtend= [
        { label: 'Trước hạn', value: 1 },
        { label: 'Quá hạn', value: 2 },
      ];


    return (
        <PageWrapper title="Xem chi tiết yêu cầu chứng thư số đối với tổ chức">
            <Form>
                {status === STATUS_TU_CHOI ? (
                    <div className="input-group">
                        <TextAreaWithLabel
                            label={"Lý do từ chối"}
                            name="reason-deny"
                            rows={0}
                            form={props.form}
                            defaultValue={reason}
                            wrapClass={"col-md"}
                            isDisabled={true}
                        />
                    </div>
                ) : (
                    ""
                )}
                <div className="input-group">
                    {linkReSign ? (
                        <Fragment>
                            <InputWithLabel
                                label="Link ký lại DK02"
                                form={props.form}
                                name="link"
                                isDisabled={true}
                                wrapClass="col-md-6 pl-0"
                            />
                            <InputWithLabel
                                label="Mã xác thực"
                                form={props.form}
                                name="password"
                                defaultValue={password}
                                isDisabled={true}
                                wrapClass="col-md-6 pr-0"
                            />
                        </Fragment>
                    ) : (
                        ""
                    )}
                    {/*{secretCode ? (*/}
                    {/*    <Fragment>*/}
                    {/*        <InputWithLabel*/}
                    {/*            label="Mã bảo mật"*/}
                    {/*            form={props.form}*/}
                    {/*            name="secret_code"*/}
                    {/*            isDisabled={true}*/}
                    {/*            wrapClass="col-md-2"*/}
                    {/*            defaultValue={secretCode}*/}
                    {/*        />*/}
                    {/*    </Fragment>*/}
                    {/*) : (*/}
                    {/*    ""*/}
                    {/*)}*/}
                </div>
                <Card
                    className="container-fluid "
                    title={<label>Thông tin gói chứng thư số</label>}
                    size="small"
                >
                    {/*line 1*/}
                    <div className="input-group">
                        <SelectWithLabel
                            options={provider}
                            name="provider_id"
                            wrappedClass="col-md-6 col-lg-2"
                            form={props.form}
                            label={"Nhà cung cấp"}
                            isDisabled={true}
                        />
                        <SelectWithLabel
                            options={{
                                1: "Mã số thuế",
                                2: "Mã ngân sách",
                                3: "Số quyết định",
                                4: "Mã bảo hiểm xã hội",
                                5: "Giấy phép đầu tư",
                            }}
                            name="identity_type"
                            wrappedClass="col-md-6 col-lg-2"
                            form={props.form}
                            label={"Loại định danh"}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label={labelCode}
                            name="code"
                            wrapClass="col-md-6 col-lg-2"
                            maxLength={16}
                            isDisabled={true}
                        />
                        <RadioWithLabel
                            options={{
                                1: "Cấp mới",
                                2: "Gia hạn",
                                3: "Chuyển đổi",
                            }}
                            label="Đối tượng"
                            name="object"
                            wrappedClass="col-md-6 col-lg-3 select-doi-tuong"
                            form={props.form}
                            isDisabled={true}
                        />
                        {/* {Number(props.form.getFieldValue("object")) === 2 ?
                            <div className={`form-group col-md-6 col-lg-3`}>
                                <LabelInput nameFor={"type_extend"} label={"Loại gia hạn"} />
                                <Form.Item>
                                    <Radio.Group
                                    disabled={true}
                                    options={optionsTypeExtend}
                                    value={dataView.type_extend}
                                    >
                                    </Radio.Group>
                                </Form.Item>
                            </div>
                            :<>
                            </>
                        } */}
                    </div>
                    <div className="input-group">
                        <RadioWithLabel
                            options={{
                                1: "Token",
                                2: "HSM",
                                // 3: "FastCA RS"
                            }}
                            label="Thiết bị đầu cuối thuê bao"
                            name="type_device"
                            wrappedClass="col-md-6 col-lg-2 select-doi-tuong"
                            form={props.form}
                            isDisabled={true}
                        />
                        {Number(props.form.getFieldValue("type_device")) ===
                        2 ? (
                            <RadioWithLabel
                                options={{ 1: "CyberLotus", 2: "Khác" }}
                                label="Nhà cung cấp HSM"
                                name="hsm_provider"
                                wrappedClass="col-md-6 col-lg-2 select-doi-tuong"
                                form={props.form}
                                isDisabled={true}
                            />
                        ) : (
                            ""
                        )}

                        <SelectWithLabel
                            options={cateServicePackage}
                            name="package_id"
                            wrappedClass="col-md-6 col-lg-2"
                            form={props.form}
                            label={"Gói dịch vụ"}
                            isDisabled={true}
                            onChange={onChange}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Giá bán"
                            name="package_price"
                            wrapClass="col-md-6 col-lg-2"
                            isDisabled={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Số lượng"
                            name="quantity"
                            wrapClass="col-md-6 col-lg-1"
                            isRequired={true}
                            type={"number"}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Mã CTKM"
                            name="promotion_code"
                            wrapClass="col-md-6 col-lg-2"
                            maxLength={255}
                            isRequired={false}
                            isDisabled={true}
                        />
                    </div>
                    <div className="input-group">
                        {Number(props.form.getFieldValue("object")) === 2 ||
                        Number(props.form.getFieldValue("object")) === 3 ? (
                            <React.Fragment>
                                <InputWithLabel
                                    form={props.form}
                                    label="Serial CTS"
                                    name="serial_cts"
                                    wrapClass="col-md-6 col-lg-4"
                                    isDisabled={true}
                                />
                                <SelectWithLabel
                                    options={{ 1: "Token mới", 2: "Token cũ" }}
                                    name="token_type"
                                    wrappedClass="col-md-6 col-lg-2"
                                    form={props.form}
                                    label={"Loại token"}
                                    isDisabled={true}
                                />
                            </React.Fragment>
                        ) : (
                            ""
                        )}
                        {Number(props.form.getFieldValue("provider_id")) ===
                            2 &&
                        Number(props.form.getFieldValue("type_device")) ===
                            1 ? (
                            <SelectWithLabel
                                options={versionToken}
                                name="version_token_newtel"
                                wrappedClass="col-md-6 col-lg-2"
                                form={props.form}
                                label={"Version token"}
                                isDisabled={true}
                            />
                        ) : (
                            ""
                        )}
                        {Number(props.form.getFieldValue("object")) === 2 ||
                        Number(props.form.getFieldValue("object")) === 3 ? (
                            <SelectDateWithLabel
                                name="date_expired_cert"
                                form={props.form}
                                wrapClass="col-md-2 col-lg-2"
                                label="Ngày hết hạn"
                                defaultValue={
                                    dateExpiredCert
                                        ? moment(dateExpiredCert)
                                        : null
                                }
                                isDisabled={true}
                            />
                        ) : (
                            ""
                        )}
                    </div>
                    <div className="input-group">
                        <div className={"col-md-4"}>
                            <Form.Item>
                                <Checkbox disabled={true} checked={showEmail}>
                                    Hiển thị Email
                                </Checkbox>
                            </Form.Item>
                        </div>
                    </div>
                </Card>
                <Card
                    className="container-fluid mt-4"
                    title={<label>Thông tin doanh nghiệp</label>}
                    size="small"
                >
                    {/*line 1*/}
                    <div className="input-group">
                        <RadioWithLabel
                            options={{
                                1: "Giấy ĐKKD",
                                2: "Giấy phép đầu tư",
                                3: "Quyết định thành lập",
                            }}
                            label="Giấy tờ pháp lý"
                            name="type_legal"
                            wrappedClass="col-md-6 col-lg-5 select-doi-tuong "
                            form={props.form}
                            isDisabled={true}
                        />
                        {showViewFileLegal ? (
                            <div className="col-md-6 col-lg-3">
                                <label className="hidden label-hide">
                                    12121
                                </label>
                                <a
                                    href={file}
                                    className={
                                        "btn btn-outline-primary form-control button-a"
                                    }
                                    type={"button"}
                                    target={"_blank"}
                                    rel="noopener noreferrer"
                                    onClick={() => onViewFileDoc(1)}
                                >
                                    Xem file pháp lý
                                </a>
                            </div>
                        ) : (
                            ""
                        )}

                        <SelectDateWithLabel
                            name="provide_date"
                            form={props.form}
                            wrapClass="col-md-6 col-lg-2"
                            label="Ngày cấp"
                            defaultValue={
                                provideDate ? moment(provideDate) : null
                            }
                            isDisabled={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Nơi cấp"
                            name="provide_organization"
                            wrapClass="col-md-6 col-lg-2"
                            maxLength={255}
                            isDisabled={true}
                        />
                    </div>
                    {/*line2*/}
                    <div className="input-group">
                        <InputWithLabel
                            form={props.form}
                            label="MST (nếu có)"
                            name="tax_code"
                            wrapClass="col-md-6 col-lg-2"
                            maxLength={16}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Tên doanh nghiệp"
                            name="fullname"
                            wrapClass="col-md-6 col-lg-4"
                            maxLength={255}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Số điện thoại"
                            name="phone"
                            wrapClass="col-md-6 col-lg-2"
                            isDisabled={true}
                            maxLength={16}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Email"
                            name="email"
                            wrapClass="col-md-6 col-lg-4"
                            isDisabled={true}
                            maxLength={255}
                        />
                    </div>
                    <div className="input-group">
                        <InputWithLabel
                            form={props.form}
                            label="Địa chỉ"
                            name="address"
                            wrapClass="col-md-8"
                            maxLength={255}
                            isDisabled={true}
                        />
                        <SelectWithLabel
                            options={province}
                            name="province_code"
                            wrappedClass="col-md-6 col-lg-2"
                            form={props.form}
                            label={"Tỉnh thành"}
                            isDisabled={true}
                            isProvince={true}
                        />
                        <SelectWithLabel
                            options={district}
                            name="district_code"
                            wrappedClass="col-md-6 col-lg-2"
                            form={props.form}
                            label={"Quận huyện"}
                            isDisabled={true}
                        />
                    </div>
                </Card>
                <Card
                    className="container-fluid mt-4"
                    title={<label>Người đại diện </label>}
                    size="small"
                >
                    {/*line 1*/}
                    <div className="input-group">
                        <InputWithLabel
                            form={props.form}
                            label="Họ và tên"
                            name="represent_fullname"
                            wrapClass="col-md-6 col-lg-4"
                            isDisabled={true}
                            maxLength={255}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Số CMND"
                            name="represen_passport"
                            wrapClass="col-md-6 col-lg-3"
                            isDisabled={true}
                            maxLength={16}
                        />
                        {showViewFilePassport ? (
                            <div className="col-md-6 col-lg-3">
                                <label className="hidden label-hide">
                                    12121
                                </label>
                                <a
                                    href={file}
                                    className={
                                        "btn btn-outline-warning form-control button-a"
                                    }
                                    type={"button"}
                                    target={"_blank"}
                                    rel="noopener noreferrer"
                                    onClick={() => onViewFileDoc(2)}
                                >
                                    Xem file CMND/Hộ chiếu
                                </a>
                            </div>
                        ) : (
                            ""
                        )}

                        <SelectDateWithLabel
                            name="represen_passport_date"
                            form={props.form}
                            isDisabled={true}
                            wrapClass="col-md-6 col-lg-2"
                            label="Ngày cấp"
                            defaultValue={
                                represenProvideDate
                                    ? moment(represenProvideDate)
                                    : null
                            }
                        />
                    </div>
                    <div className="input-group">
                        <InputWithLabel
                            form={props.form}
                            label="Nơi cấp"
                            name="represen_passport_place"
                            wrapClass="col-md-6 col-lg-3"
                            isDisabled={true}
                            maxLength={255}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Chức vụ"
                            name="represen_position"
                            wrapClass="col-md-6 col-lg-3"
                            isDisabled={true}
                            maxLength={255}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Email"
                            name="represen_email"
                            wrapClass="col-md-6 col-lg-3"
                            isDisabled={true}
                            maxLength={255}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Số điện thoại"
                            name="represen_phone"
                            wrapClass="col-md-6 col-lg-3"
                            isDisabled={true}
                            maxLength={16}
                        />
                    </div>
                </Card>
                {/*<Card className="container-fluid mt-4" title={<label>Thông tin đầu mối quản lý sử dụng</label>} size="small">*/}
                {/*        <React.Fragment>*/}
                {/*            <div className="input-group">*/}
                {/*                <div className="input-group">*/}
                {/*                    <InputWithLabel*/}
                {/*                        form={props.form}*/}
                {/*                        label="Họ tên đầu mối"*/}
                {/*                        name="sp_fullname"*/}
                {/*                        wrapClass="col-md-6 col-lg-3"*/}
                {/*                        isDisabled={true}*/}
                {/*                        maxLength={255}*/}
                {/*                    />*/}
                {/*                    <InputWithLabel*/}
                {/*                        form={props.form}*/}
                {/*                        label="Chức vụ"*/}
                {/*                        name="sp_position"*/}
                {/*                        wrapClass="col-md-6 col-lg-3"*/}
                {/*                        isDisabled={true}*/}
                {/*                        maxLength={255}*/}
                {/*                    />*/}
                {/*                    <InputWithLabel*/}
                {/*                        form={props.form}*/}
                {/*                        label="Email"*/}
                {/*                        name="sp_email"*/}
                {/*                        wrapClass="col-md-6 col-lg-3"*/}
                {/*                        isDisabled={true}*/}
                {/*                        maxLength={255}*/}
                {/*                    />*/}
                {/*                    <InputWithLabel*/}
                {/*                        form={props.form}*/}
                {/*                        label="Số điện thoại"*/}
                {/*                        name="sp_phone"*/}
                {/*                        wrapClass="col-md-6 col-lg-3"*/}
                {/*                        isDisabled={true}*/}
                {/*                        maxLength={16}*/}
                {/*                    />*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </React.Fragment>*/}
                {/*</Card>*/}
                <Card
                    className="container-fluid mt-4"
                    title={<label>Mẫu đăng ký</label>}
                    size="small"
                >
                    <div className="input-group">
                        <RadioWithLabel
                            options={{ 1: "Điện tử", 2: "Giấy", 3: "Scan" }}
                            label="Loại hồ sơ"
                            name="type_docurment"
                            wrappedClass="col-md-6 col-lg-3 select-doi-tuong"
                            form={props.form}
                            isRequired={true}
                            isDisabled={true}
                        />
                        {status === STATUS_TU_CHOI ? (
                            ""
                        ) : (
                            <React.Fragment>
                                <div className="col-md-6 col-lg-2">
                                    <label className="invisible label-hide">
                                        11111998
                                    </label>
                                    <a
                                        href={file}
                                        className={
                                            "btn btn-outline-success form-control button-a"
                                        }
                                        type={"button"}
                                        target={"_blank"}
                                        rel="noopener noreferrer"
                                        onClick={() => onViewFileDoc(3)}
                                    >
                                        Tải xuống DK-01.01
                                    </a>
                                </div>
                            </React.Fragment>
                        )}
                        {statusReqGen ? (
                            <div className="col-md-6 col-lg-2">
                                <label className="invisible label-hide">
                                    11111998
                                </label>
                                <a
                                    href={file}
                                    className={
                                        "btn btn-outline-primary form-control button-a"
                                    }
                                    type={"button"}
                                    target={"_blank"}
                                    rel="noopener noreferrer"
                                    onClick={() => onViewFileDoc(4)}
                                >
                                    Tải xuống DK-02
                                </a>
                            </div>
                        ) : (
                            ""
                        )}
                        {isFileDk03 ? (
                            <div className="col-md-6 col-lg-2">
                                <label className="invisible label-hide">
                                    11111998
                                </label>
                                <a
                                    href={file}
                                    className={
                                        "btn btn-outline-warning form-control button-a"
                                    }
                                    type={"button"}
                                    target={"_blank"}
                                    rel="noopener noreferrer"
                                    onClick={() => onViewFileDoc(5)}
                                >
                                    Tải xuống DK-03
                                </a>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </Card>
                {isViewPenaltyValue ? (
                    <Card
                        className="container-fluid mt-4"
                        title={<label>Số tiền phạt</label>}
                        size="small"
                    >
                        <div className="input-group">
                            <InputWithLabel
                                form={props.form}
                                label="Số tiền phạt khi hủy yêu cầu(VNĐ)"
                                name="penalty_value"
                                wrapClass="col-md-6 col-lg-3"
                                isDisabled={true}
                                defaultValue={valueDestroy}
                            />
                        </div>
                    </Card>
                ) : (
                    <></>
                )}
            </Form>
            <ModalConfirm
                modalVisible={modal.visible_modal}
                handleCancel={onCancelModelDecline}
                onOkModalDecline={onOkModalDecline}
                loading={modal.loading}
                penalty_value={modal.penalty_value}
            />
            <div className="input-group d-flex justify-content-center p-5 mt-4">
                <div className="">
                    {isCheckDestroy ? (
                        <ApproveButton
                            btnText="Hủy yêu cầu"
                            onClick={() => viewModalConfirm()}
                        />
                    ) : (
                        <></>
                    )}
                    <ButtonCancel
                        onClick={() => {
                            props.history.goBack();
                        }}
                        className={"btn btn-default btn-sm"}
                    />
                </div>
            </div>
        </PageWrapper>
    );
};

const WrappedRequestCTSPersonalUpdate = Form.create<Props>({
    name: "RequestCTSPersonalUpdate",
})(RequestCTSPersonalUpdate);

export default WrappedRequestCTSPersonalUpdate;
