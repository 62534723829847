import BaseServices from "../base/BaseServices";

const PREFIX_SERVICES = "qlbh/quan-ly-ho-so-thay-doi-thong-tin-cap-lai-bao-hanh/";

const list = async (data: object) => {
    return await BaseServices.request(
        PREFIX_SERVICES + "list",
        data,
        "GET"
    );
};


const onPreviewFile = async (password, type) => {
    return await BaseServices.request(
        PREFIX_SERVICES + `previewFile/${password}/${type}`,
        {},
        "GET"
    );
};

const uploadFile = async (data) => {
    return await BaseServices.request(
        PREFIX_SERVICES + `updateFile`,
        data,
        "POST"
    );
};

const requestUpdateFile = async (data) => {
    return await BaseServices.request(
        PREFIX_SERVICES + `requestUpdateFile`,
        data,
        "POST"
    );
};

export const DocChangeRenewWarrantyManagerService = {
    list,
    onPreviewFile,
    uploadFile,
    requestUpdateFile
};
