import React from "react";
import PageWrapper from "../wrapper/PageWrapper";
import Table from "../../components/common/table/Table";
import {onFailAction, onSuccessAction} from "helpers/SwalCommon";
import {RouteComponentProps} from "react-router";
import {formatDate, reloadPage} from "helpers/NewCaCrmHelper";
import TableActionButton from "components/common/table/action-button/TableActionButton";
import {RequestChangeInfoService} from "../../services/request-change-info/RequestChangeInfoService";
import Store from "../../store/store";
import {Tag} from "antd";
import RequestChangeInfoSearch from "./search/RequestChangeInfoSearch";
import {STATUS_CHANGE_INFO_AGENCY_CONTRIBUTOR_ENUM} from "./enum/ReqChangeInfoAgencyContributorEnum";

interface Props extends RouteComponentProps {
}

const TYPE_AGENCY = 7;
const TYPE_CONTRIBUTOR = 8;

const STATUS_NV_DENY = 2;
const STATUS_BUSINESS_SUPPORT_DENY = 6;

export const RequestChangeInfo: React.FC<Props> = props => {
    const onDelete = async id => {
        const {location, history} = props;
        const result = await RequestChangeInfoService.del(id);
        if (result && result.status === 200) {
            onSuccessAction("Xóa yêu cầu thành công!");
            reloadPage(location, history);
        } else {
            onFailAction("Có lỗi xảy ra!");
        }
    };
    const user = Store.getState().authReducer;
    const userType = user.type;
    const renderActionButton = (text, record) => {
        return (
            <TableActionButton
                permissionUpdate=""
                // permissionDelete={true}
                onClickUpdate={
                    ([STATUS_NV_DENY, STATUS_BUSINESS_SUPPORT_DENY].indexOf(record.status) !== -1 && Number(record.created_by.id) === user.id)
                        ? () => props.history.push((Number(userType) === TYPE_AGENCY || Number(userType) === TYPE_CONTRIBUTOR)
                        ? `yeu-cau-thay-doi-thong-tin/dai-ly-ctv/cap-nhat/${record.id}`
                        : `yeu-cau-thay-doi-thong-tin/cap-nhat/${record.id}`)
                        : null
                }
                onClickDelete={
                    ([STATUS_NV_DENY, STATUS_BUSINESS_SUPPORT_DENY].indexOf(record.status) !== -1 && Number(record.created_by.id) === user.id)
                        ? () => onDelete(record.id)
                        : null
                }
                onClickPreviewButton={() =>
                    props.history.push((Number(record.type) === 1)
                        ? `yeu-cau-thay-doi-thong-tin/dai-ly/xem/${record.id}`
                        : `yeu-cau-thay-doi-thong-tin/ctv/xem/${record.id}`)
                }
            />
        );
    };
    const columns = [
        {
            title: "Mã yêu cầu",
            dataIndex: "code"
        },
        {
            title: "Loại",
            dataIndex: "",
            render: (e) => {
                if (Number(e.type) === 1) {
                    return (<Tag color="geekblue">Đại lý</Tag>);
                }
                if (Number(e.type) === 2) {
                    return (<Tag color="green">Cộng tác viên</Tag>);
                }
            }
        },
        {
            title: "Tên",
            dataIndex: "",
            render: (e) => {
                if (e.agency) {
                    return e.agency.fullname ? e.agency.fullname : ""
                }
                if (e.contributor) {
                    return e.contributor.fullname ? e.contributor.fullname : ""
                }
            }
        },
        {
            title: "Trạng thái",
            dataIndex: "",
            render: (e) => {
                return (
                    <Tag color={`${STATUS_CHANGE_INFO_AGENCY_CONTRIBUTOR_ENUM[e.status].class}`}>
                        {STATUS_CHANGE_INFO_AGENCY_CONTRIBUTOR_ENUM[e.status].label}
                    </Tag>
                )
            }
        },
        {
            title: "Lý do",
            dataIndex: "reason_customer"
        },
        {
            title: "Thời gian tạo",
            dataIndex: "",
            render: (e) => {
                if (e.created_at) {
                    return formatDate(e.created_at)
                }
            }
        },
        {
            title: "Người tạo",
            dataIndex: "",
            render: (e) => {
                if (e.created_by) {
                    return e.created_by.fullname
                }
            }
        },
        {
            title: "Tác vụ",
            render: renderActionButton
        },
    ];
    const fetch = async (params = {}) => {
        try {
            return await RequestChangeInfoService.index(params);
        } catch (error) {
            onFailAction();
        }
    };

    return (
        <PageWrapper title="Danh sách yêu cầu thay đổi thông tin đại lý - cộng tác viên">
            <RequestChangeInfoSearch/>
            <Table columns={columns} onFetchData={fetch}/>
        </PageWrapper>
    );
};
