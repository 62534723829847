import React, { useEffect, useState } from "react";
import { Form } from "antd";
import SelectWithLabel from "../../../components/common/form/input-with-label/SelectWithLabel";
import { FormComponentProps } from "antd/lib/form";
import ButtonSearch from "../../../components/common/form/button/ButtonSearch";
import ButtonCreate from "../../../components/common/form/button/ButtonCreate";
import InputWithLabel from "../../../components/common/form/input-with-label/InputWithLabel";
import SelectDateSearch from "../../../components/common/form/input-with-label/SelectDateSearch";
import { RequestDigitalCertificatePersonalServices } from "../../../services/request-digital-certificate-personal/RequestDigitalCertificatePersonalServices";
import _ from "lodash";
import { UserServices } from "../../../services/user/UserServies";
import ButtonTemplate from "components/common/form/button/ButtonTemplate";
import { onFailAction } from "helpers/SwalCommon";
import { loading } from "components/common/loading/Loading";
import moment from "moment";
import Axios from "axios";
import { RequestCertificateGroupService } from "../../../services/request-certificate-group/RequestCertificateGroupServices";
import { STATUS_REQUEST_DIGITAL_CERTIFICATE_LABEL } from "helpers/enum/request-digital-certificate/RequestDigitalCertificateEnums";
import { REQUEST_DIGITAL_OZ_CERTIFICATE_PERMISSION } from "helpers/enum/PermissionEnums";
import { checkPermission } from "helpers/NewCaCrmHelper";
const { REACT_APP_BASE_API_URL } = window["runConfig"];
interface Props extends FormComponentProps {}
const TYPE_BUSINESS_SUPPORT = 6;
const RequestCTSGroupSearchForm: React.FC<Props> = (props) => {
    const [cateServicePackage, setCateServicePackage] = useState({});
    const [userType, setUserType] = useState(0);
    const [listUser, setListUser] = useState({});
    const [listStatus, setListStatus] = useState({});
    const fetchUser = async () => {
        const user = new UserServices();
        const userInfo = await user.getUserAuth();
        const type = userInfo.data.type;
        setUserType(type);
    };
    const getListUserByLevel = async () => {
        const user = new UserServices();
        const userInfo = await user.getUserAuth();
        try {
            let result =
                await RequestCertificateGroupService.getListUserByLevel(
                    userInfo.data
                );
            if (result && Number(result.status) === 200) {
                setListUser(result.data);
            }
        } catch (error) {
            console.log(error);
            onFailAction();
        }
    };
    const onChangeObject = async (e) => {
        props.form.setFieldsValue({ package_id_search: null });
        const type_search = 1;
        const type = e;
        const data =
            await RequestDigitalCertificatePersonalServices.getListCateServicePackagePersonal(
                type_search,
                type
            );
        setCateServicePackage(_.mapValues(_.keyBy(data.data, "id"), "name"));
    };

    const onSubmitExportButton = async (e) => {
        e.preventDefault();
        if (
            !props.form.getFieldValue("created_at_from") ||
            !props.form.getFieldValue("created_at_to")
        ) {
            onFailAction("Bạn chưa điền đầy đủ thông tin thời gian tạo", ()=>{}, 'warning', 'Cảnh báo');
            return;
        }
        let time_from = moment(
            props.form.getFieldValue("created_at_from")
        ).format("YYYY-MM-DD");
        let time_to = moment(props.form.getFieldValue("created_at_to")).format(
            "YYYY-MM-DD"
        );
        loading.runLoadingBlockUI();
        const BASE_URL = REACT_APP_BASE_API_URL;
        let token = localStorage.getItem("currentUser");
        let fileName = `Danh sách yêu cầu CTS tổ chức.xlsx`;
        if (token) {
            let obj = JSON.parse(token);
            const url = `${BASE_URL}qlbh/xuat-du-lieu/yeu-cau-cts?token=${obj.token}&type=2&date_from=${time_from}&date_to=${time_to}`;
            Axios.get(`${url}`, {
                headers: {},
                responseType: "blob",
            })
                .then(function (response) {
                    const url = window.URL.createObjectURL(
                        new Blob([response.data])
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", fileName); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                    loading.stopRunLoading();
                })
                .catch(function () {
                    loading.stopRunLoading();
                });
        }
    };
    useEffect(() => {
        //fetchCateServicePackage();
        convertToObjectWithLabels(STATUS_REQUEST_DIGITAL_CERTIFICATE_LABEL);
        fetchUser();
        getListUserByLevel();
        // eslint-disable-next-line
    }, []);

    const convertToObjectWithLabels = (inputObj) => {
        const resultObj = {};
        for (const key in inputObj) {
            if (Object.prototype.hasOwnProperty.call(inputObj, key)) {
                resultObj[key] = inputObj[key].label;
            }
        }
        setListStatus(resultObj);
    };
    return (
        <Form>
            <div className="form-group col-md-2-4 mt-1 mb-3 nopadding-left">
                <ButtonSearch data={props.form.getFieldsValue()} />
                {
                    checkPermission(REQUEST_DIGITAL_OZ_CERTIFICATE_PERMISSION.EXPORT) 
                    && <ButtonTemplate
                    title="Xuất Excel"
                    icon="fas fa-file-excel mr-1"
                    onClick={onSubmitExportButton}
                />
                }
                
                {userType === TYPE_BUSINESS_SUPPORT ? (
                    ""
                ) : (
                    <>
                    {
                        checkPermission(REQUEST_DIGITAL_OZ_CERTIFICATE_PERMISSION.ADD) 
                        &&
                        <ButtonCreate
                            permission=""
                            toUrl="/yeu-cau-cts-to-chuc/them-moi"
                        />
                    }
                    </>

                )}
            </div>
            <div className="input-group">
                <InputWithLabel
                    form={props.form}
                    label=""
                    name="fullname_search"
                    placeholder={"Tên khách hàng"}
                    wrapClass="col-md-3 nopadding-left"
                />
                <SelectWithLabel
                    options={{ 1: "Cấp mới", 2: "Gia hạn", 3: "Chuyển đổi" }}
                    name="object_search"
                    wrappedClass="col-md-3 nopadding-left"
                    form={props.form}
                    placeholder="Đối tượng"
                    onChange={onChangeObject}
                />
                <SelectWithLabel
                    options={cateServicePackage}
                    name="package_id_search"
                    wrappedClass="col-md-2 nopadding-left"
                    form={props.form}
                    placeholder="Gói dịch vụ"
                />
                <SelectDateSearch
                    name="created_at_from"
                    form={props.form}
                    wrapClass="col-md-2 nopadding-left"
                    label=""
                    placeholder="Thời gian tạo: Từ ngày"
                />
                <SelectDateSearch
                    name="created_at_to"
                    form={props.form}
                    wrapClass="col-md-2 nopadding-left"
                    label=""
                    placeholder="Thời gian tạo: Đến ngày"
                />
            </div>
            <div className="input-group">
                <InputWithLabel
                    form={props.form}
                    label=""
                    name="tax_code_search"
                    placeholder={"Mã số/MST"}
                    wrapClass="col-md-3 nopadding-left"
                />
                <SelectWithLabel
                    wrappedClass="col-md-3 nopadding-left"
                    options={listStatus}
                    name="status_search"
                    form={props.form}
                    placeholder="Trạng thái"
                />
                <SelectWithLabel
                    wrappedClass="col-md-2 nopadding-left"
                    options={listUser}
                    name="created_by"
                    form={props.form}
                    placeholder="Người tạo"
                />
                 <SelectDateSearch
                    name="certificate_end_from"
                    form={props.form}
                    wrapClass="col-md-2 nopadding-left"
                    label=""
                    placeholder="Ngày hết hạn: Từ ngày"
                />
                <SelectDateSearch
                    name="certificate_end_to"
                    form={props.form}
                    wrapClass="col-md-2 nopadding-left"
                    label=""
                    placeholder="Ngày hết hạn: Đến ngày"
                />
            </div>
        </Form>
    );
};

const WrappedRequestCTSGroupSearchForm = Form.create<Props>({
    name: "WrappedRequestCTSGroupSearchForm",
})(RequestCTSGroupSearchForm);

export default WrappedRequestCTSGroupSearchForm;
