import React, {useEffect, useState} from "react";
import PageWrapper from "../wrapper/PageWrapper";
import {Card, Form} from "antd";
import {FormComponentProps} from "antd/lib/form";
import InputWithLabel from "components/common/form/input-with-label/InputWithLabel";
import {TextAreaWithLabel} from "components/common/form/input-with-label/TextAreaWithLabel";
import ButtonCancel from "components/common/form/button/ButtonCancel";
import {loading} from "components/common/loading/Loading";
import {onFailAction, onSuccessAction} from "helpers/SwalCommon";
import {RequestChangeInfoService} from "../../services/request-change-info/RequestChangeInfoService";
import {match} from "react-router";
import ModalDisplayFile from "../../components/common/modal/display-file/ModalDisplayFile";
import ApproveButton from "../../components/common/form/button/ApproveButton";
import DenyButton from "../../components/common/form/button/DenyButton";
import Store from "../../store/store";
import ModalDeny from "../../components/common/form/ModalDeny";

const STATUS_WAIT_BUSINESS_SUPPORT_APPROVE = 5;
const STATUS_BUSINESS_SUPPORT_DENY = 6;
const STATUS_NV_DENY = 2;
const TYPE_BUSINESS_SUPPORT = 6;

interface Props extends FormComponentProps {
    match: match<{ id: string }>;
    history: any;
}

export const RequestChangeInfo: React.FC<Props> = props => {

    const id = props.match.params.id;

    const userType = Store.getState().authReducer.type;

    const getData = async () => {
        loading.runLoadingBlockUI();
        const result = await RequestChangeInfoService.find(id);
        if (result && result.status === 200) {
            setStatus(result.data.status);
            let detail = result.data.change_info_detail_agency;
            props.form.setFieldsValue({
                fullname: detail.fullname,
                sortname: detail.sortname,
                code: result.data.agency.code,
                tax_code: result.data.agency.tax_code,
                passport_place: detail.passport_place,
                deputy: detail.deputy,
                deputy_position: detail.deputy_position,
                address: detail.address,
                email: detail.email,
                phone: detail.phone,
                office_address: detail.office_address,
                field_id: detail.field_id,
                job: detail.job,
                personnal_scale: detail.personnal_scale,
                supply_capacity: detail.supply_capacity,
                competitive_area: detail.competitive_area,
                bank_number: detail.bank_number,
                bank_branch: detail.bank_branch,
                reason_newca: result.data.reason_newca,
                reason_business_support: result.data.reason_business_support,
                reason_customer: result.data.reason_customer,
            });
            loading.stopRunLoading();
        } else {
            onFailAction('Có lỗi xảy ra!');
        }
    };

    useEffect(() => {
        getData().then();
        // eslint-disable-next-line
    }, []);

    const [status, setStatus] = useState();

    const [modalFilePassport, setModalFilePassport] = useState(false);

    const [loadingModal, setLoadingModal] = useState(false);

    const [filePassPort, setFilePassPort] = useState("");

    const onViewFilePassport = async () => {
        try {
            setModalFilePassport(true);
            setLoadingModal(true);
            const result = await RequestChangeInfoService.getFilePassport(id);
            if (result && result.status === 200) {
                setFilePassPort(result.base64);
            } else {
                onFailAction('Có lỗi xảy ra!');
            }
        } finally {
            setLoadingModal(false);
        }
    };

    const onOkModalPassPort = () => {
        setModalFilePassport(false);
    };

    const onBusinessApprove = async (id) => {
        const result = await RequestChangeInfoService.businessSupportApproveRequest(id);
        if (result && Number(result.status) === 200) {
            onSuccessAction("Duyệt yêu cầu thành công!", () => {
                props.history.goBack()
            })
        } else {
            onFailAction("Có lỗi xảy ra!")
        }
    };

    const onBusinessDeny = async (id) => {
        setModalDeny({ ...modalDeny, visible_modal_deny: true, id: id})
    };

    /**
     * modal deny
     */
    const [modalDeny, setModalDeny] = useState({
        visible_modal_deny: false,
        loading: false,
        title_modal: "",
        id: "",
        type: "",
        reason_business_support: "",
        error_reason: "",
    });
    const handleModalDenyCancel = () => {
        setModalDeny({ ...modalDeny, visible_modal_deny: false, reason_business_support: "", error_reason: "" })
    };
    const onChangeDataDeny = ({ target: { value } }) => {
        setModalDeny({ ...modalDeny, reason_business_support: value });
    };
    const confirmDeny = async () => {
        let error_reason = 'Lý do từ chối không được  để trống';
        if (modalDeny.reason_business_support.trim() === "") {
            setModalDeny({ ...modalDeny, error_reason: error_reason });
            return false;
        }
        const data = { reason_business_support: modalDeny.reason_business_support, id: modalDeny.id };
        const result = await RequestChangeInfoService.businessSupportDenyRequest(data);
        await setModalDeny({ ...modalDeny, visible_modal_deny: false, reason_business_support: "", error_reason: ""});

        if (result && Number(result.status) === 200) {
            onSuccessAction("Từ chối yêu cầu thành công!", () => window.location.reload());
        } else if (result && Number(result.status) === 422) {
            onFailAction("Có lỗi xảy ra trong quá trình yêu cầu!");
        } else {
            onFailAction(result.error);
        }
    }

    return (
        <PageWrapper title="Xem chi tiết yêu cầu thay đổi thông tin">
            <ModalDisplayFile
                titleModal="File CMND/Hộ chiếu"
                visibleModal={modalFilePassport}
                loadingModal={loadingModal}
                fileBase64={filePassPort}
                onOkModal={onOkModalPassPort}
            />
            <ModalDeny
                visible={modalDeny.visible_modal_deny}
                handleCancel={handleModalDenyCancel}
                handleDeny={confirmDeny}
                value={modalDeny.reason_business_support}
                onChange={onChangeDataDeny}
                error={modalDeny.error_reason}
                title={modalDeny.title_modal}
                placeholder={"Nhập lý do từ chối"}
                okText={'Xác nhận'}
            />
            <Form>
                <Card className="m-r-15-i m-l-15-i mt-4" title={<label>Thông tin đại lý</label>} size="small">
                    {props.form.getFieldValue('reason_newca') && Number(status) === STATUS_NV_DENY ? (
                        <div className="input-group">
                            <InputWithLabel
                                form={props.form}
                                label="Lý do từ chối của nghiệp vụ"
                                name="reason_newca"
                                wrapClass="col-md-12 text-danger"
                                maxLength={255}
                                isDisabled={true}
                            />
                        </div>
                    ) : ""}
                    {props.form.getFieldValue('reason_business_support') && Number(status) === STATUS_BUSINESS_SUPPORT_DENY ? (
                        <div className="input-group">
                            <InputWithLabel
                                form={props.form}
                                label="Lý do từ chối của HTKD"
                                name="reason_business_support"
                                wrapClass="col-md-12 text-danger"
                                maxLength={255}
                                isDisabled={true}
                            />
                        </div>
                    ) : ""}
                    <div className="input-group">
                        <TextAreaWithLabel
                            label={"Lý do thay đổi thông tin"}
                            form={props.form}
                            wrapClass="col-md-12"
                            name="reason_customer"
                            rows={4}
                            isDisabled={true}
                        />
                    </div>
                    <div className="input-group">
                        <InputWithLabel
                            form={props.form}
                            label="Tên đại lý"
                            name="fullname"
                            isRequired={true}
                            wrapClass="col-md-3"
                            maxLength={255}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Mã đại lý"
                            name="code"
                            isRequired={true}
                            wrapClass="col-md-3"
                            maxLength={255}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Tên viết tắt"
                            name="sortname"
                            isRequired={true}
                            wrapClass="col-md-3"
                            maxLength={255}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Mã số thuế"
                            name="tax_code"
                            isRequired={true}
                            wrapClass="col-md-3"
                            maxLength={16}
                            isDisabled={true}
                        />
                    </div>
                    <div className="input-group">
                        <InputWithLabel
                            form={props.form}
                            label="Người đại diện"
                            name="deputy"
                            isRequired={true}
                            wrapClass="col-md-3"
                            maxLength={255}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Chức vụ người đại diện"
                            name="deputy_position"
                            isRequired={true}
                            wrapClass="col-md-3"
                            maxLength={255}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Email"
                            name="email"
                            isRequired={true}
                            wrapClass="col-md-3"
                            maxLength={255}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Số điện thoại"
                            name="phone"
                            isRequired={true}
                            wrapClass="col-md-3"
                            maxLength={255}
                            isDisabled={true}
                        />
                    </div>
                    <div className="input-group">
                        <InputWithLabel
                            form={props.form}
                            label="Địa chỉ"
                            name="address"
                            wrapClass="col-md-6"
                            maxLength={16}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Số tài khoản ngân hàng"
                            name="bank_number"
                            wrapClass="col-md-3"
                            maxLength={255}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Chi nhánh"
                            name="bank_branch"
                            wrapClass="col-md-3"
                            maxLength={255}
                            isDisabled={true}
                        />
                    </div>
                    <div className="input-group">
                        <InputWithLabel
                            form={props.form}
                            label="Trụ sở chính"
                            name="office_address"
                            wrapClass="col-md-6"
                            maxLength={255}
                            isDisabled={true}
                        />
                    </div>
                    <div className="input-group col-md-3">
                        <button onClick={onViewFilePassport} className="btn btn-primary btn-sm form-control">
                            Xem file ĐKKD
                        </button>
                    </div>
                </Card>
            </Form>
            <div className="input-group d-flex justify-content-center p-5 mt-5">
                <ButtonCancel
                    onClick={() => {
                        props.history.push("/yeu-cau-thay-doi-thong-tin");
                    }}
                    className={"btn btn-default btn-sm"}
                />
                {
                    Number(userType) === TYPE_BUSINESS_SUPPORT && status === STATUS_WAIT_BUSINESS_SUPPORT_APPROVE ? (
                        <React.Fragment>
                            <ApproveButton
                                onClick={() => onBusinessApprove(id)}
                            />
                            <DenyButton
                                onClick={() => onBusinessDeny(id)}
                            />
                        </React.Fragment>
                    ) : ""
                }
            </div>
        </PageWrapper>
    );
};

const WrappedRequestChangeInfoAgencyView = Form.create<Props>({
    name: "RequestChangeInfo"
})(RequestChangeInfo);


export default WrappedRequestChangeInfoAgencyView;
