import React, {useState, useEffect} from "react";
import {Checkbox, Form} from "antd";
import {FormComponentProps} from "antd/lib/form";
import PageWrapper from "pages/wrapper/PageWrapper";
import ButtonCancel from "components/common/form/button/ButtonCancel";
import {TextAreaWithLabel} from "components/common/form/input-with-label/TextAreaWithLabel";
import RadioWithLabel from "components/common/form/input-with-label/RadioWithLabel";
import InputWithLabel from "components/common/form/input-with-label/InputWithLabel";
import SelectWithLabel from "components/common/form/input-with-label/SelectWithLabel";
import {AgencyService} from "services/agency/AgencyServices";
import _ from "lodash";
import {RequestTokenService} from "services/request-token/RequestTokenServices";
import {match} from "react-router";
import {onFailAction, onSuccessAction} from "helpers/SwalCommon";
import SelectDateWithLabel from "components/common/form/input-with-label/SelectDateWithLabel";
import moment from "moment";
import AntModal from "components/common/modal/AntModal";
import {checkPermission, formatReadBase64} from "helpers/NewCaCrmHelper";
import DenyButton from "../../components/common/form/button/DenyButton";
import ApproveButton from "../../components/common/form/button/ApproveButton";
import {BUSINESS_SUPPORT_PERMISSION} from "../../helpers/enum/PermissionEnums";

const STATUS_TU_CHOI = 3;
const STATUS_WAIT_BUSINESS_SUPPORT_APPROVE = 5;

interface Props extends FormComponentProps {
    match: match<{ id: string }>;
    history: any;
}

export const RequestTokenPreview: React.FC<Props> = props => {
    const [belongTo, setBelongTo] = useState(1);
    const [agency, setAgency] = useState([]);
    const [agencyOption, setAgencyOption] = useState({});
    const [passport, setPassport] = useState("");
    const [address, setAddress] = useState("");
    const [phone, setPhone] = useState("");
    const [loading, setLoading] = useState(true);
    const [receiveDate, setReceiveDate] = useState("");
    const [visibleModal, setVisibleModal] = useState(false);
    const [file, setFile] = useState("");
    const [loadingModal, setLoadingModal] = useState(false);
    const [status, setStatus] = useState(0);
    const [reason, setReason] = useState("");
    const [typeLogin, setTypeLogin] = useState("1");
    const [typeReceive, setTypeReceive] = useState("");
    const [checkBox, setCheckBox] = useState(false);
    const [tokenAdminNum, setTokenAdminNum] = useState("");
    const [boxNum, setBoxNum] = useState("");
    const [envelopeNum, setEnvelopeNum] = useState("");
    const [receiveFullname, setReceiveFullname] = useState("");

    const id = props.match.params.id;

    useEffect(() => {
        fetchAgencyOption().then();
        fetchRequestToken().then();
        // eslint-disable-next-line
    }, []);

    const fetchAgencyOption = async () => {
        const agencyService = new AgencyService();
        const data = await agencyService.getAgency();
        setAgency(data);
        setAgencyOption(_.mapValues(_.keyBy(data, "id"), "fullname"));
    };

    const onChangeAgency = async value => {
        const selectAgency: any = _.find(agency, {id: parseInt(value)});
        props.form.setFieldsValue({tax_code: selectAgency.tax_code});
    };

    const fetchRequestToken = async () => {
        const requestToken = await RequestTokenService.previewRequest(id);
        const data = requestToken.data;

        props.form.setFieldsValue({
            receive_type: data.receive_type ? data.receive_type.toString() : "",
            token_admin_num: data.token_admin_num,
            box_num: data.box_num,
            envelope_num: data.envelope_num,
            receive_fullname: data.receive_fullname,
            type_pay: data.type_pay ? data.type_pay.toString() : "",
            token_admin_note: data.token_admin_note,
            acc_full_name: data.acc_full_name,
            acc_user_name: data.acc_user_name,
            acc_email: data.acc_email,
            acc_phone: data.acc_phone,
            acc_type_login: data.acc_type_login ? data.acc_type_login.toString() : "1",
            acc_type: data.acc_type ? data.acc_type.toString() : ""
        });
        if (data.belong_to) {
            if (Number(data.belong_to) === 1) {
                props.form.setFieldsValue({
                    tax_code: data.agency.tax_code,
                    owner_id: data.agency.fullname,
                    level_agency: data.level_agency.toString()
                });
            } else if (Number(data.belong_to) === 2) {
                props.form.setFieldsValue({tax_code: data.contributor.passport, owner_id: data.contributor.fullname});
            } else {
                props.form.setFieldsValue({tax_code: ""});
            }
            setBelongTo(data.belong_to);
        }else {
            props.form.setFieldsValue({
                tax_code: data.agency.tax_code,
                owner_id: data.agency.fullname,
                level_agency: data.level_agency.toString()
            });
        }

        setReason(data.reason);
        setStatus(data.status);
        setPassport(data.receive_passport);
        setAddress(data.receive_address);
        setPhone(data.receive_phone);
        setReceiveDate(data.receive_date);
        setReceiveFullname(data.receive_fullname);
        setLoading(false);
        setTypeLogin(data.acc_type_login);
        setTypeReceive(data.receive_type ? data.receive_type.toString() : "");
        setCheckBox(data.resign_document);
        setTokenAdminNum(data.token_admin_num ? data.token_admin_num.toString() : "");
        setBoxNum(data.box_num ? data.box_num.toString() : "");
        setEnvelopeNum(data.envelope_num ? data.envelope_num.toString() : "");
        setReceiveFullname(data.receive_fullname);

    };

    const onPreviewFile = () => {
        const {validateFields} = props.form;
        validateFields(async (errors) => {
            if (!errors) {
                try {
                    setVisibleModal(true);
                    setLoadingModal(true);
                    const data = await RequestTokenService.previewFile(id);
                    let contentRead = formatReadBase64(data.base64);
                    setFile(contentRead)
                } catch (error) {
                    onFailAction("Có lỗi xảy ra khi xem trước file!");
                    setVisibleModal(false);
                } finally {
                    setLoadingModal(false);
                }
            } else {
                onFailAction("Bạn chưa điền đủ thông tin!");
            }
        });
    };

    const onBusinessApprove = async (id) => {
        const result = await RequestTokenService.businessSupportApproveRequest(id);
        if (result && Number(result.status) === 200) {
            onSuccessAction("Duyệt yêu cầu thành công!", () => {
                props.history.goBack()
            })
        } else {
            onFailAction("Có lỗi xảy ra!")
        }
    };

    const onBusinessDeny = async (id) => {
        const result = await RequestTokenService.businessSupportDenyRequest(id);
        if (result && Number(result.status) === 200) {
            onSuccessAction("Từ chối yêu cầu thành công!", () => {
                props.history.goBack()
            })
        } else {
            onFailAction("Có lỗi xảy ra!")
        }
    };

    const onOkModal = () => {
        setVisibleModal(false);
    };

    const {getFieldDecorator} = props.form;

    return (
        <PageWrapper title="Xem chi tiết yêu cầu tạo tài khoản" loading={loading}>
            <Form>
                {status === STATUS_TU_CHOI
                    ? (<div className="input-group">
                        {" "}
                        <InputWithLabel
                            label="Lý do từ chối"
                            form={props.form}
                            name="reason-deny"
                            isDisabled={true}
                            wrapClass="col-md"
                            labelClass="text-danger"
                            defaultValue={reason}
                        />{" "}
                    </div>)
                    : ("")}

                <div className="input-group">
                    {
                        (Number(belongTo) === 1)
                            ? (
                                <React.Fragment>
                                    <SelectWithLabel
                                        options={agencyOption}
                                        name="owner_id"
                                        wrappedClass="col-md-3"
                                        form={props.form}
                                        placeholder="Chọn đại lý"
                                        label="Tên đại lý"
                                        isRequired={true}
                                        onChange={onChangeAgency}
                                        isDisabled={true}
                                    />
                                    <InputWithLabel
                                        form={props.form}
                                        label="Mã số thuế"
                                        name="tax_code"
                                        wrapClass="col-md-3"
                                        isDisabled={true}
                                    />
                                </React.Fragment>
                            )
                            : (
                                <React.Fragment>
                                    <SelectWithLabel
                                        options={agencyOption}
                                        name="owner_id"
                                        wrappedClass="col-md-3"
                                        form={props.form}
                                        placeholder="Chọn cộng tác viên"
                                        label="Tên cộng tác viên"
                                        isRequired={true}
                                        isDisabled={true}
                                    />
                                    <InputWithLabel
                                        name="tax_code"
                                        form={props.form}
                                        label="CMND/ Hộ chiếu"
                                        wrapClass="col-md-3"
                                        isDisabled={true}
                                        isRequired={true}
                                    />
                                </React.Fragment>
                            )
                    }
                    <InputWithLabel
                        form={props.form}
                        label="Số lượng"
                        name="token_admin_num"
                        isRequired={true}
                        wrapClass="col-md-3 d-none"
                        type="number"
                        isDisabled={true}
                    />
                    <RadioWithLabel
                        options={{1: "Chuyển khoản", 2: "Tiền mặt"}}
                        label="Hình thức thanh toán"
                        name="type_pay"
                        wrappedClass="col-md-2"
                        form={props.form}
                        isRequired={true}
                        isDisabled={true}
                    />
                    <RadioWithLabel
                        options={{1: "Token RA", 2: "Mật khẩu"}}
                        label="Loại đăng nhập"
                        name="acc_type_login"
                        wrappedClass="col-md-2"
                        form={props.form}
                        isRequired={true}
                        isDisabled={true}
                    />
                </div>
                <div className="input-group">
                    <InputWithLabel
                        form={props.form}
                        label="Họ và tên"
                        name="acc_full_name"
                        wrapClass="col-md-3"
                        type="text"
                        maxLength={255}
                        isRequired={true}
                        isDisabled={true}
                    />
                    <InputWithLabel
                        form={props.form}
                        label="Email"
                        name="acc_email"
                        wrapClass="col-md-3"
                        type="text"
                        maxLength={255}
                        isRequired={true}
                        isDisabled={true}
                    />
                    <InputWithLabel
                        form={props.form}
                        label="Tên tài khoản"
                        name="acc_user_name"
                        wrapClass="col-md-2"
                        type="text"
                        maxLength={255}
                        isRequired={true}
                        isDisabled={true}
                    />
                    <InputWithLabel
                        form={props.form}
                        label="Số điện thoại"
                        name="acc_phone"
                        isRequired={true}
                        wrapClass="col-md-2"
                        type="number"
                        isDisabled={true}
                    />
                    {
                        (Number(belongTo) === 1)
                            ? (
                                <SelectWithLabel
                                    options={{1: "Admin", 2: "Cấp 2"}}
                                    name="level_agency"
                                    wrappedClass="col-md-2"
                                    form={props.form}
                                    placeholder="Phân quyền"
                                    label="Phân quyền"
                                    isRequired={true}
                                    isDisabled={true}
                                />
                            )
                            : ("")
                    }
                </div>
                {
                    (Number(typeLogin) === 2 && !checkBox) ? (
                        ""
                    ) : (
                        <div>
                            <div className="input-group">
                                <RadioWithLabel
                                    options={{"1": "Tại FastCA", "2": "Chuyển phát nhanh"}}
                                    label="Hình thức nhận"
                                    name="receive_type"
                                    wrappedClass="col-md-3"
                                    form={props.form}
                                    defaultValue={typeReceive}
                                    isRequired={true}
                                    isDisabled={true}
                                />
                                <InputWithLabel
                                    form={props.form}
                                    label="Số lượng vỏ hộp"
                                    name="box_num"
                                    wrapClass="col-md-4 d-none"
                                    type="number"
                                    isDisabled={true}
                                />
                                <InputWithLabel
                                    form={props.form}
                                    label="Số lượng phong bì"
                                    name="envelope_num"
                                    wrapClass="col-md d-none"
                                    type="number"
                                    isDisabled={true}
                                />
                            </div>
                            <div className="input-group">
                                <InputWithLabel
                                    form={props.form}
                                    label="Người nhận"
                                    name="receive_fullname"
                                    isRequired={true}
                                    wrapClass="col-md-3"
                                    type="text"
                                    defaultValue={receiveFullname}
                                    isDisabled={true}
                                />
                                <SelectDateWithLabel
                                    name="receive_date"
                                    form={props.form}
                                    isRequired={true}
                                    wrapClass="col-md-2"
                                    label="Ngày nhận"
                                    defaultValue={receiveDate ? moment(receiveDate) : null}
                                    isDisabled={true}
                                />
                                {typeReceive === "1" ? (
                                    <InputWithLabel
                                        form={props.form}
                                        label="Số CMND/Hộ chiếu"
                                        name="receive_passport"
                                        isRequired={true}
                                        wrapClass="col-md-2"
                                        type="text"
                                        defaultValue={passport}
                                        isDisabled={true}
                                    />
                                ) : (
                                    <React.Fragment>
                                        <InputWithLabel
                                            form={props.form}
                                            label="Địa chỉ nhận chuyển phát"
                                            name="receive_address"
                                            isRequired={true}
                                            wrapClass="col-md-4"
                                            type="text"
                                            defaultValue={address}
                                            isDisabled={true}
                                        />
                                        <InputWithLabel
                                            form={props.form}
                                            label="Số điện thoại người nhân"
                                            name="receive_phone"
                                            isRequired={true}
                                            wrapClass="col-md-3"
                                            isDisabled={true}
                                            type="text"
                                            defaultValue={phone}
                                        />
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                    )
                }
                <div className="input-group">
                    <div className={'col-md-4'}>
                        <Form.Item>
                            {getFieldDecorator('resign_document', {
                                valuePropName: 'checked',
                                initialValue: checkBox,
                            })(<Checkbox disabled={true}>Đăng ký tài liệu bán hàng</Checkbox>)}
                        </Form.Item>
                    </div>
                </div>
                {
                    checkBox ? (
                        <div className="input-group">
                            <InputWithLabel
                                form={props.form}
                                label="Số lượng token"
                                name="token_admin_num"
                                wrapClass="col-md-3"
                                type="number"
                                defaultValue={tokenAdminNum}
                                maxLength={4}
                                isRequired={true}
                                isDisabled={true}
                            />
                            <InputWithLabel
                                form={props.form}
                                label="Số lượng vỏ hộp"
                                name="box_num"
                                wrapClass="col-md-3"
                                type="number"
                                defaultValue={boxNum}
                                maxLength={4}
                                isRequired={true}
                                isDisabled={true}
                            />
                            <InputWithLabel
                                form={props.form}
                                label="Số lượng phong bì"
                                name="envelope_num"
                                wrapClass="col-md-3"
                                type="number"
                                defaultValue={envelopeNum}
                                maxLength={4}
                                isRequired={true}
                                isDisabled={true}
                            />
                        </div>
                    ) : ""
                }
                <div className="input-group">
                    <TextAreaWithLabel
                        form={props.form}
                        label="Ghi chú"
                        name="token_admin_note"
                        wrapClass="col-md"
                        rows={4}
                        isDisabled={true}
                    />
                </div>
            </Form>
            <div className="input-group pb-5 pt-2">
                <div className="col-md-2">
                    <button onClick={onPreviewFile} className="btn btn-primary btn-sm d-none">
                        Mẫu đề xuất
                    </button>
                    <AntModal
                        visible={visibleModal}
                        loading={loadingModal}
                        className="w-75 h-75"
                        bodyStyle={{height: "700px"}}
                        style={{top: "20px"}}
                        onCLickOk={onOkModal}
                    >
                        <iframe
                            title="Quản lý hợp đồng"
                            src={`${file}`}
                            height="100%"
                            width="100%"
                        />
                    </AntModal>
                </div>
            </div>
            <div className="input-group d-flex justify-content-center">
                <ButtonCancel
                    onClick={() => {
                        props.history.push("/yeu-cau-token");
                    }}
                />
                {
                    checkPermission(BUSINESS_SUPPORT_PERMISSION.APPROVE) && status === STATUS_WAIT_BUSINESS_SUPPORT_APPROVE ? (
                        <ApproveButton
                            onClick={() => onBusinessApprove(id)}
                        />) : ""
                }
                {
                    checkPermission(BUSINESS_SUPPORT_PERMISSION.DENY ) && status === STATUS_WAIT_BUSINESS_SUPPORT_APPROVE ? (
                        <DenyButton
                            onClick={() => onBusinessDeny(id)}
                        />
                    ) : ""
                }
            </div>
        </PageWrapper>
    );
};

const WrappedRequestTokenPreview = Form.create<Props>({
    name: "RequestTokenPreview"
})(RequestTokenPreview);

export default WrappedRequestTokenPreview;
