import { string } from '@amcharts/amcharts4/core';
import React, { FC, ReactElement } from 'react';
import Marquee from "react-fast-marquee";
import { UserServices } from '../../../../services/user/UserServies';
import { useState } from 'react';
import { useEffect } from 'react';
import { Button } from 'antd';
import './marquee.css'

type Props = {}
// eslint-disable-next-line
 export const PopupNoti: FC<Props> = ({}): ReactElement => {
    const [ noti, setNoti ] = useState(2);
    // const getNoti = async () => {
    //     const userService = new UserServices();
    //     const result = await userService.getUserNotiUpdate('user-info');
    //     setNoti(result.data.notify_upgrade)
    // }
    const updateNoti = async () => {
        const userService = new UserServices();
        await userService.getUserNotiUpdate('update-notify-upgrade');
        setNoti(2);
    }
    useEffect(() => {
        // getNoti();
    },[])
    let settings = {
        style : {
            marginTop : '10px',
        },
        className : 'noti-marquee',
        pauseOnHover : true,
        pauseOnClick : true,
        speed : 30,
        gradient : false,
        children : string,
      };
    return (
        <div>
            <Marquee {...settings}>
                {(Number(noti) === 1) ?
                <div>
                    Phần mềm có bản cập nhật mới&nbsp;
                    <Button size='small' shape='circle' type='danger' onClick={updateNoti} >x</Button>
                </div>
                : '' }
            </Marquee>
        </div>
    )
  };

