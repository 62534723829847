export const STATUS_FILE_DOC_CHANGE_RENEW_WARRANTY_LABEL = {
    1: {
        label: "Chờ xác nhận",
        class: "magenta",
    },
    2: {
        label: "Hợp lệ",
        class: "green",
    },
    3: {
        label: "Không hợp lệ",
        class: "red",
    },
    4: {
        label: "Chờ xác nhận cho phép cập nhật",
        class: "geekblue",
    },
    5:{
        label: "Chờ phê duyệt hồ sơ",
        class: "blue",
    },
};
export const STATUS_DOC_CHANGE_RENEW_WARRANTY_LABEL = {
    1: {
        label: "Chờ khách hàng ký",
        class: "magenta",
    },
    2: {
        label: " Khách hàng từ chối",
        class: "orange",
    },
    3: {
        label: "Chờ nghiệp vụ duyệt",
        class: "cyan",
    },
    4: {
        label: "Nghiệp vụ từ chối",
        class: "red",
    },
    5:{
        label: "Chờ gen",
        class: "blue",
    },
    6:{
        label: "Hoàn Thành",
        class: "green",
    },
};