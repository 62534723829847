import React, { useState} from "react";
import {match} from "react-router";
import Form, {FormComponentProps} from "antd/lib/form";
import {loading, loading as loadingHelper} from "../../components/common/loading/Loading";
import {onFailAction, onSuccessAction} from "../../helpers/SwalCommon";
import axios from "axios";
import {ReSignDk02Services} from "../../services/re-sign-dk02/ReSignDk02Services";
import {formatReadBase64, randomString} from "helpers/NewCaCrmHelper";
import ModalEnterPinCaptcha from "../../components/common/form/ModalEnterPinCaptcha";

interface Props extends FormComponentProps {
    match: match<{ id: string, type: string }>;
    history: any;
}
const ReSignDk02Page: React.FC<Props> = props => {
    const [file, setFile] = useState("");
    const [fileSign, setFileSign] = useState("");
    const [confirm, setConfirm] = useState(false);
    const [canSign, setCanSign] = useState(false);
    const dataSign = {
        "fieldName":"SignatureB [002]",
        "typeSign":"1",
        "page": "4",
        "px": "86",
        "py": "61",
        "pw": "180",
        "ph": "10"
    };

    const id = atob(props.match.params.id);
    const type = props.match.params.type;

    const getFileDk02 = async () => {
        loadingHelper.runLoadingBlockUI();
        const data = {
            id :id,
            type: type,
            pass: modalPin.password,
            captcha: modalPin.captcha,
            codeUi: codeUi
        }
        const result = await ReSignDk02Services.getFileDk02(data);
        if (result && result.status === 200){
            let contentRead = formatReadBase64(result.data);
            setFile(contentRead);
            setFileSign(result.data);
            if (result && result.number_signed === 1){
                setCanSign(true);
            }
        }

        loadingHelper.stopRunLoading();
        return result;
    };
    const getCertificate = async () => {
        loadingHelper.runLoadingBlockUI();
        const result = await ReSignDk02Services.getCertificate(id, type);
        loadingHelper.stopRunLoading();
        return result;
    };
    const getCaptcha = async (codeUi) => {
        loadingHelper.runLoadingBlockUI();
        const result = await ReSignDk02Services.getCaptcha(codeUi);
        loadingHelper.stopRunLoading();
        return result;
    };

    const initialStateModalPin = {
        visible_modal_password: false,
        loading: false,
        title_modal: "",
        base64: "",
        type_file: "",
        password: "",
        captcha: "",
        error_captcha: "",
        error_pass: "",
        fileBase64Captcha: "",
    };

    const [modalPin, setModalPin] = useState( initialStateModalPin );
    const [codeUi, setCodeUi] = useState('');

    const clearStateModalPin = () => {
        setModalPin({ ...initialStateModalPin });
    };

    const submitPin = async() => {
        const codeUi = randomString();
        const result = await getCaptcha(codeUi);
        setCodeUi(codeUi)
        setModalPin({
            ...modalPin,
            'visible_modal_password': true,
            'fileBase64Captcha': result.base64Img,
            "password": "",
            "captcha": "",
            "error_captcha": "",
            "error_pass": "",
        });
    };

    const reLoadCaptcha = async() => {
        const codeUi = randomString();
        const result = await getCaptcha(codeUi);
        setCodeUi(codeUi)
        setModalPin({
            ...modalPin,
            'visible_modal_password': true,
            'fileBase64Captcha': result.base64Img,
            "captcha": "",
            "error_captcha": "",
            "error_pass": "",
        });
    }

    const confirmPin = async () => {
        if (modalPin.password.trim() === "") {
            setModalPin({...modalPin,
                'error_captcha': '',
                'error_pass': 'Mã xác thực không được bỏ trống.'});
            return false;
        }
        if (modalPin.captcha.trim() === "") {
            setModalPin({...modalPin,
                'error_pass': '',
                'error_captcha': 'Mã captcha không được bỏ trống.'});
            return false;
        }

        const check =  await getFileDk02();
        loading.runLoadingBlockUI();
        if (check.status === 200){
            setConfirm(true);
            await setModalPin({...modalPin, 'visible_modal_password': false });
        }
        else if(check.status === 4001){
            setModalPin({...modalPin,
                'error_pass': '',
                'error_captcha': check.message
            });
            setTimeout( ()=> {reLoadCaptcha()},1000 * 2);
        }
        else {
            setConfirm(false);
            onFailAction(check.message);
            clearStateModalPin()
        }
        loading.stopRunLoading();
    };
    // @ts-ignore
    const onChangeDataPin = ({target: {value}}) => {
        setModalPin({...modalPin, 'password': value});
    };
    const onChangeDataCaptcha = ({target: {value}}) => {
        setModalPin({...modalPin, 'captcha': value});
    };
    const handleModalConnectCancel = () => {
        clearStateModalPin()
    };

    const onSignClick = async () => {
        let cert = await getCertificate();
        if (!cert.data){
            onFailAction("Không tìm thấy chứng thư số");
            return;
        }
        const time = await ReSignDk02Services.getTimeServer();
        let allDataSign = {...dataSign, "CertSerial":cert.data, "FileData":fileSign, "signDate": time};
        onSign(allDataSign);
    };

    const onSign = (allDataSign) => {
        axios.post(`http://localhost:6706/api/sign/signpdf`, allDataSign)
            .then((response) => {
                const data = response.data;
                if (!data.FileDataSigned) {
                    onFailAction('Có lỗi xảy ra trong quá trình ký!');
                    return ;
                }
                updateSignedContract({...data, "id":id, "type": type}).then();
            })
            .catch((error) => {
                onFailAction(error);
            });
    };

    const updateSignedContract = async (data) => {
        const result = await ReSignDk02Services.updateFileSigned(data);
        if (result && result.status === 200){
            onSuccessAction("Ký thành công!", () => {
                window.location.reload();
            });
        } else {
            onFailAction("Có lỗi xảy ra !");
        }
    };

    return(
        <div>
            <nav className="navbar navbar-light bg-light">
                <div className="row">
                    <div className="col-md-5">
                        <a className="navbar-brand" href="/#">
                            <img src={"/images/logo.png"} width="20%" alt="logo"/>
                        </a>
                    </div>
                </div>
            </nav>
            <div className="container mt-5">
                <div className="card">
                    <div className="card-header text-center text-uppercase">
                        <h5>Trang ký lại giấy đăng ký DK02</h5>
                    </div>
                    <div className="card-body">
                        <div className="col-md-12 text-center mt-3">
                            <Form>
                                <ModalEnterPinCaptcha
                                    visible={modalPin.visible_modal_password}
                                    handleCancel={handleModalConnectCancel}
                                    handleDeny={confirmPin}
                                    value={modalPin.password}
                                    valueCaptcha={modalPin.captcha}
                                    onChange={onChangeDataPin}
                                    onChangeValueCaptcha={onChangeDataCaptcha}
                                    errorPass={modalPin.error_pass}
                                    errorCaptcha={modalPin.error_captcha}
                                    fileBase64Captcha={modalPin.fileBase64Captcha}
                                    title={'Nhập mã xác thực'}
                                    isReload = {reLoadCaptcha}
                                />
                                {confirm ? (
                                    <iframe
                                        title="Biên bản thanh lý"
                                        src={`${file}`}
                                        height="1000px"
                                        width="90%"
                                    />
                                ):("")}

                            </Form>
                        </div>
                        {!confirm ? (
                            <div className="text-center">
                                <button className="btn btn-success" onClick={submitPin}><i className="fas fa-file-signature"/>Xác thực</button>
                            </div>
                        ) : ("")}

                        {canSign ? (
                            <div className="text-center">
                                <button className="btn btn-success" onClick={onSignClick}><i className="fas fa-file-signature"/> Ký giấy chứng nhận</button>
                            </div>
                        ) : ""}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReSignDk02Page;