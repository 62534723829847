export const STATUS_CERT_LABEL = {
        1:{
            label: "Hoạt động",
            class: "green"
        },
        2:{
            label: "Đã thu hồi",
            class: "orange"
        },
        3:{
            label: "Đã hủy",
            class: "purple"
        },
        4:{
            label: "Đã tạm dừng",
            class: "volcano"
        },

        8:{
            label: "Đã xóa",
            class: "red"
        },
        9: {
            label: "Đã hết hạn",
            class: "magenta"
        },
        10: {
            label: "Chờ khách hàng ký xác nhận thu hồi",
            class: "crimson"
        },
        11: {
            label: "Chờ nghiệp vụ duyệt thu hồi",
            class: "fuchsia"
        },

        15: {
            label: "Chờ khách hàng ký xác nhận hủy",
            class: "pea"
        },
        16: {
            label: "Chờ nghiệp vụ duyệt hủy",
            class: "olive"
        },
        20: {
            label: "Chờ khách hàng ký xác nhận tạm dừng",
            class: "cyan"
        },
        21: {
            label: "Chờ nghiệp vụ duyệt tạm dừng",
            class: "azure"
        },

        25: {
            label: "Chờ thay đổi thông tin",
            class: "cyan"
        },
        26: {
            label: "Chờ cấp lại thiết bị",
            class: "coral"
        },
        27: {
            label: "Chờ bảo hành thiết bị",
            class: "lime"
        },
        28: {
            label: "Chờ thu hồi NEAC",
            class: "coral"
        },

  };

  export const FILE_TYPE_OPTIONS = {
    1: "Ký điện tử",
    2: "Ký tươi"
  };