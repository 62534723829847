import BaseServices from "../base/BaseServices";

export class AgencyService {
  private PREFIX_AGENCY_API = "qlbh/agency";

  public async indexAgency(params) {
    return await BaseServices.request(
        `${this.PREFIX_AGENCY_API}/index`,
        params,
        "GET"
    );
  }

  public async storeDraftAgency(data) {
    return await BaseServices.request(
        `${this.PREFIX_AGENCY_API}/store-as-draft`,
        data,
        "POST"
    );
  }

  public async storeConfirmAgency(data) {
    return await BaseServices.request(
        `${this.PREFIX_AGENCY_API}/store-as-confirm`,
        data,
        "POST"
    );
  }

  public async getModelToUpdate(id) {
    return await BaseServices.request(
        `${this.PREFIX_AGENCY_API}/get-agency-to-update/` + id,
        {},
        "GET"
    );
  }

  public async getAgencyToPreview(id) {
    return await BaseServices.request(
        `${this.PREFIX_AGENCY_API}/get-agency-to-preview/` + id,
        {},
        "GET"
    );
  }

  public async acceptAgency(id) {
    return await BaseServices.request(
        `${this.PREFIX_AGENCY_API}/accept-agency/` + id,
        {},
        "PUT"
    );
  }

  public async declineAgency(id, data) {
    return await BaseServices.request(
        `${this.PREFIX_AGENCY_API}/decline-agency/` + id,
        data,
        "PUT"
    );
  }

  public async updateAgencyAsDraft(id, data) {
    return await BaseServices.request(
        `${this.PREFIX_AGENCY_API}/update-agency-as-draft/` + id,
        data,
        "POST"
    );
  }

  public async updateAgencyAsConfirm(id, data) {
    return await BaseServices.request(
        `${this.PREFIX_AGENCY_API}/update-agency-as-confirm/` + id,
        data,
        "POST"
    );
  }

  public async updateAgencyAsReactive(id, data) {
    return await BaseServices.request(
        `${this.PREFIX_AGENCY_API}/update-agency-as-reactive/` + id,
        data,
        "POST"
    );
  }

  public async getAgencyContract(data) {
    return await BaseServices.request(
        `${this.PREFIX_AGENCY_API}/agency-contract`,
        data,
        "POST"
    );
  }

  public async getAgencyReport(data) {
    return await BaseServices.request(
        `${this.PREFIX_AGENCY_API}/agency-report`,
        data,
        "POST"
    );
  }
  public async getAgencyAddendum(data, type) {
    return await BaseServices.request(
        `${this.PREFIX_AGENCY_API}/agency-addendum/${type}`,
        data,
        "POST"
    );
  }

  public async getManagerByAuth() {
    return await BaseServices.request(
        `${this.PREFIX_AGENCY_API}/get-manager-by-auth`,
        {},
        "GET"
    );
  }

  public async deleteAgency(id) {
    return await BaseServices.request(
        `${this.PREFIX_AGENCY_API}/delete-agency/${id}`,
        {},
        "DELETE"
    );
  }

  public async getDocument(agency, type, typeAddendum = 0) {
    return await BaseServices.request(
        `${this.PREFIX_AGENCY_API}/get-document/${agency}/${type}/${typeAddendum}`,
        {},
        "GET"
    );
  }

  public async getGPKD(agency) {
    return await BaseServices.request(
        `${this.PREFIX_AGENCY_API}/get-file-gpkd/${agency}`,
        {},
        "GET"
    );
  }
  
  public async nextContractCode(){
    return await BaseServices.request(
        `${this.PREFIX_AGENCY_API}/next-contract-code`,
        {},
        "GET"
    );
  }
  public async nextAgencyCode(){
    return await BaseServices.request(
        `${this.PREFIX_AGENCY_API}/next-agency-code`,
        {},
        "GET"
    );
  }

  public async getAgency(){
    return await BaseServices.request(
        `${this.PREFIX_AGENCY_API}/get-agency-active-list`,
        {},
        "GET"
    );
  }

  public async getContributor(){
    return await BaseServices.request(
        `${this.PREFIX_AGENCY_API}/get-contributor-active-list`,
        {},
        "GET"
    );
  }
    public async getAgencyByOwenId(owner_id){
      return await BaseServices.request(
            `${this.PREFIX_AGENCY_API}/get-agency-by-ownerid`,
            {owner_id},
            "GET"
        );
    }
    public async getContributorByOwenId(owner_id){
      return await BaseServices.request(
            `${this.PREFIX_AGENCY_API}/get-contributor-by-ownerid`,
            {owner_id},
            "GET"
        );
    }
  public async getAgencyContributor(type, sale_id) {
    return await BaseServices.request(
        "qlbh/agency-contributor-all",
        {type, sale_id},
        "GET"
    );
  }public async getAgencyContributorForStaff(type) {
    return await BaseServices.request(
        "qlbh/agency-contributor-all-for-staff",
        {type},
        "GET"
    );
  }

}
