import BaseServices from "../base/BaseServices";

const PREFIX_SERVICES = "qlbh/request-digital-certificate-personal/";

const getListIndex = async (data: object) => {
    return await BaseServices.request(
        PREFIX_SERVICES + "index",
        data,
        "GET"
    );
};

const store = async (data: object) => {
    return await BaseServices.request(
        PREFIX_SERVICES + "store",
        data,
        "POST"
    );
};

const update = async (id, data) => {
    return await BaseServices.request(
        PREFIX_SERVICES + "update",
        {id_request: id, ...data},
        "PUT"
    );
};

const previewRequestPersonal = async id =>{
    return await BaseServices.request(
        PREFIX_SERVICES + "preview-request-personal/" + id,
        {},
        "GET"
    );
};

const getModelToUpdate= async id =>{
    return await BaseServices.request(
        PREFIX_SERVICES + "get-model-to-update/" + id,
        {},
        "GET"
    );
};

const getListCateServicePackage = async (type_search,type, obj,type_device, provider_id = '', hsm_provider='') => {
    return await BaseServices.request(
        PREFIX_SERVICES + "get-cate-service-package",
        {type_search, type, obj, type_device, provider_id, hsm_provider},
        "GET"
    );
};

const deleteRequest = async (id, ) => {
    return await BaseServices.request(
        PREFIX_SERVICES + "delete",
        {id_request: id},
        "DELETE"
    );
};

const generateFile = async (data: object) => {
    return await BaseServices.request(
        PREFIX_SERVICES + "gen-file",
        data,
        "POST"
    );
};

const getFileDoc = (id, type) =>{
    return BaseServices.request(
        PREFIX_SERVICES + `get-file/${id}/${type}`,
        {},
        "GET"
    );
};

const getInfo = (passport, object,idenType) =>{
    return BaseServices.request(
        PREFIX_SERVICES + `getInfo`,
        {passport, object, idenType},
        "POST"
    );
};

const getListCateServicePackagePersonal = async (type_search, type, provider_id = '') => {
    return await BaseServices.request(
        PREFIX_SERVICES + "get-list-cate-service-package",
        {type_search, type, provider_id},
        "GET"
    );
};

const getProvince = () =>{
    return BaseServices.request(
        PREFIX_SERVICES + 'getProvince',
        {},
        "GET"
    )
};

const getDistrictByProvince = async (provinceCode) => {
    return await BaseServices.request(
        PREFIX_SERVICES + 'getDistrictByProvinceCode',
        {provinceCode},
        "GET"
    );
};

const getRequestBySerial = async (data) => {
    return await BaseServices.request(
        PREFIX_SERVICES + "getRequestBySerial",
        data,
        "GET"
    );
};

const getAllProvider = async () => {
    return await BaseServices.request(
        PREFIX_SERVICES + "all-provider",
        {},
        "GET"
    );
};

const getVersionToken = async () => {
    return await BaseServices.request(
        PREFIX_SERVICES + "get-version-token",
        {},
        "GET"
    );
};

const searchRequestByOrganTaxCode = async (data) => {
    return await BaseServices.request(
        PREFIX_SERVICES + "search-request-by-organ_taxcode",
        data,
        "POST"
    );
};

const getListUserByLevel = async (data: object) => {
    return await BaseServices.request(
        PREFIX_SERVICES + `getListUserByLevel`,
        data,
        "POST"
    );
};
const checkSerial = async (data) => {
    return await BaseServices.request(
        PREFIX_SERVICES + `check-serial`,
        data,
        "GET"
    );
};

const postRequestDestroy = async (data: object) => {
    return await BaseServices.request(
        PREFIX_SERVICES + `destroy`,
        data,
        "POST"
    );
};

export const RequestDigitalCertificatePersonalServices = {
    store,
    update,
    getListIndex,
    getModelToUpdate,
    previewRequestPersonal,
    getListCateServicePackage,
    deleteRequest,
    generateFile,
    getFileDoc,
    getInfo,
    getListCateServicePackagePersonal,
    getProvince,
    getDistrictByProvince,
    getRequestBySerial,
    getAllProvider,
    getVersionToken,
    searchRequestByOrganTaxCode,
    getListUserByLevel,
    checkSerial,
    postRequestDestroy
};
