import { Icon, Layout, Menu } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import { Location } from "history";
import _ from "lodash";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { checkPermission } from "../../../helpers/NewCaCrmHelper";
import store from "../../../store/store";
import Badges from "../../common/badge/Badges";
import { MENU_CONSTANT } from "../../routes/Menu";
const { Sider } = Layout;

interface Props {
  location: Location;
}
interface State {
  collapsed: boolean;
  typeUser: boolean;
}
export default class SideMenu extends Component<Props , State> {
  state = {
    collapsed: false,
    defaultOpenKey: "",
    typeUser: true,
  };

  onCollapse = collapsed => {
    this.setState({ collapsed });
  };

  componentDidMount() { }

  getSelectedMenu = () => {
    const pathName = this.props.location.pathname;
    let selectedKey = "";
    let openKey = "";
    _.forEach(MENU_CONSTANT, function (o) {
      if (_.startsWith(pathName, o.link)) selectedKey = o.key;
      _.forEach(o.childMenu, function (child) {
        if (_.startsWith(pathName, child.link)) {
          selectedKey = child.key;
          openKey = o.key;
        }
      });
    });
    return { selectedKey, openKey };
  };

  checkUser = (key) => {
    const user = store.getState().authReducer;
    if (user.type === 7) {
      if (key === 'agency' || key === "ctv") return false
    }
    if (user.type === 8) {
      if (key === 'token' || key === "ctv" || key === 'agency') return false
    }
    if (user.level_agency === 1) {
      if (key === 'transfer-business-employee') return false
    }
    if (user.level_agency === 2) {
      if (key === 'token' || key === 'transfer-business-employee') return false
    }

    return true;
  }

  render() {
    const user = store.getState().authReducer;
    return (
        <Sider
          collapsible
          width={277}
          collapsed={this.state.collapsed}
          onCollapse={this.onCollapse}
          theme="light"
          className="menu-side-left trigger"
          breakpoint="lg"
          collapsedWidth="0"
        >
          <a href="/" className="wrap-logo">
            <div className="logo cursor-pointer"/>
          </a>
          <Menu
            theme="light"
            mode="inline"
            defaultOpenKeys={[this.getSelectedMenu().openKey]}
            defaultSelectedKeys={[this.getSelectedMenu().selectedKey]}
          >
            {MENU_CONSTANT.map(menu => {
              if (menu.childMenu.length > 0) {
                return checkPermission(menu.permission) ? (
                  <SubMenu
                    key={menu.key}
                    title={
                      <span>
                        <Icon type={menu.icon} />
                        <span className="sider-label">{menu.displayName}</span>
                        {menu.link === 'manager-co-operate' && Number(user.number_total_manager_co_operate) > 0 ? <Badges count={Number(user.number_total_manager_co_operate)}/> : ''}
                        {menu.link === 'support-sale' && Number(user.number_total_support_sale) > 0 && Number(user.type) === 6 ? <Badges count={Number(user.number_total_support_sale)}/> : ''}
                        {menu.displayName === "Hỗ trợ" ? <Badges count={Number(user.number_new_document) + Number(user.number_new_software)} /> : ''}
                      </span>
                    }
                  >
                    {menu.childMenu.map(subItem => {
                      return checkPermission(subItem.permission) &&
                        this.checkUser(subItem.key) ? (
                        <Menu.Item
                          key={subItem.key}
                          title={subItem.displayName}
                        >
                          <Link to={subItem.link} />
                          <Icon type={subItem.childIcon} />
                          <span className="sider-label">
                            {subItem.displayName}
                          </span>

                          {/* ========== Quản lý hợp tác ============= */}
                          {subItem.link === "/quan-ly-dai-ly" &&
                          Number(user.number_manager_agency) &&
                          (Number(user.type) === 5 ||
                            Number(user.type) === 6 ||
                            Number(user.type) === 7 ||
                            Number(user.type) === 8) ? (
                            <Badges
                              count={Number(user.number_manager_agency)}
                            />
                          ) : (
                            ""
                          )}
                          {subItem.link === "/quan-ly-ctv" &&
                          Number(user.number_request_change_info_agency_ctv) &&
                          (Number(user.type) === 5 ||
                            Number(user.type) === 6 ||
                            Number(user.type) === 7 ||
                            Number(user.type) === 8) ? (
                            <Badges
                              count={Number(
                                user.number_request_change_info_agency_ctv
                              )}
                            />
                          ) : (
                            ""
                          )}

                          {subItem.link === "/yeu-cau-thay-doi-thong-tin" &&
                          Number(user.number_manager_contributor) &&
                          (Number(user.type) === 5 ||
                            Number(user.type) === 6 ||
                            Number(user.type) === 7 ||
                            Number(user.type) === 8) ? (
                            <Badges
                              count={Number(user.number_manager_contributor)}
                            />
                          ) : (
                            ""
                          )}
                          {subItem.link === "/yeu-cau-token" &&
                          Number(user.number_create_acc_qlbh) &&
                          Number(user.type) === 6 ? (
                            <Badges
                              count={Number(user.number_create_acc_qlbh)}
                            />
                          ) : (
                            ""
                          )}
                          {subItem.link === "/yeu-cau-chuyen-nguoi-quan-ly" &&
                          Number(user.number_change_manager_customer) ? (
                            <Badges
                              count={Number(
                                user.number_change_manager_customer
                              )}
                            />
                          ) : (
                            ""
                          )}
                          {subItem.link === "/yeu-cau-dung-hop-tac" &&
                          Number(user.number_request_stop_owner) &&
                          Number(user.type) === 7 ? (
                            <Badges
                              count={Number(user.number_request_stop_owner)}
                            />
                          ) : (
                            ""
                          )}
                          {subItem.link === "/yeu-cau-dung-hop-tac" &&
                          Number(user.number_request_stop_owner) &&
                          Number(user.type) === 8 ? (
                            <Badges
                              count={Number(user.number_request_stop_owner)}
                            />
                          ) : (
                            ""
                          )}
                          {subItem.link === "/danh-sach-yeu-cau-dung-hop-tac" &&
                          Number(user.number_request_stop) &&
                          Number(user.type) === 6 ? (
                            <Badges count={Number(user.number_request_stop)} />
                          ) : (
                            ""
                          )}

                          {/* ========== Quản lý bán hàng ============= */}
                          {subItem.link === "/yeu-cau-cts-ca-nhan" &&
                          Number(user.number_request_personal) ? (
                            <Badges
                              count={Number(user.number_request_personal)}
                            />
                          ) : (
                            ""
                          )}
                          {subItem.link === "/yeu-cau-cts-to-chuc" &&
                          Number(user.number_request_organization) ? (
                            <Badges
                              count={Number(user.number_request_organization)}
                            />
                          ) : (
                            ""
                          )}
                          {subItem.link === "/danh-sach-dieu-chinh-thong-tin" &&
                          Number(user.number_request_change_info) ? (
                            <Badges
                              count={Number(user.number_request_change_info)}
                            />
                          ) : (
                            ""
                          )}
                          {subItem.link ===
                            "/danh-sach-yeu-cau-cap-lai-bao-hanh" &&
                          Number(user.number_request_renew_warranty) ? (
                            <Badges
                              count={Number(user.number_request_renew_warranty)}
                            />
                          ) : (
                            ""
                          )}

                          {/* ========== Hỗ trợ bán hàng ============= */}
                          {subItem.link === "/yeu-cau-token-cts" &&
                          Number(user.number_request_token_doc) ? (
                            <Badges
                              count={Number(user.number_request_token_doc)}
                            />
                          ) : (
                            ""
                          )}
                          {subItem.link === "/yeu-cau-dao-tao" &&
                          Number(user.number_request_training) ? (
                            <Badges
                              count={Number(user.number_request_training)}
                            />
                          ) : (
                            ""
                          )}

                          {/* ========== Quản lý khách hàng ============= */}
                          {subItem.link === "/danh-sach-khach-hang" &&
                          Number(user.number_warning_customer) ? (
                            <Badges
                              count={Number(user.number_warning_customer)}
                            />
                          ) : (
                            ""
                          )}
                          {subItem.link === "/yeu-cau-dich-vu-tu-kh" &&
                          Number(user.number_request_service_gateway) ? (
                            <Badges
                              count={Number(
                                user.number_request_service_gateway
                              )}
                            />
                          ) : (
                            ""
                          )}

                          {/* ========== Hỗ trợ ============= */}
                          {subItem.link === "/ho-tro-tai-lieu" ? (
                            <Badges
                              count={Number(user.number_document_support)}
                            />
                          ) : (
                            ""
                          )}
                          {subItem.displayName === "Văn bản" ? (
                            <Badges count={Number(user.number_new_document)} />
                          ) : (
                            ""
                          )}
                          {subItem.displayName === "Phần mềm" ? (
                            <Badges count={Number(user.number_new_software)} />
                          ) : (
                            ""
                          )}
                        </Menu.Item>
                      ) : (
                        ""
                      );
                    })}
                  </SubMenu>
                ) : (
                  ""
                );
              } else {
                return checkPermission(menu.permission) ? (
                  <Menu.Item key={menu.key}>
                    <Link to={menu.link}/>
                    <Icon type={menu.icon}/>
                    <span>{menu.displayName}</span>
                  </Menu.Item>
                ) : (
                  ""
                );
              }
            })}
          </Menu>
        </Sider>
    );
  }
}