export const STATUS_CHANGE_INFO_AGENCY_CONTRIBUTOR_ENUM = {
    1: {
        label: "Chờ nghiệp vụ duyệt",
        class: "magenta"
    },
    2: {
        label: "Nghiệp vụ từ chối",
        class: "cyan"
    },
    3: {
        label: "Hoàn thành",
        class: "green"
    },
    4: {
        label: "Hủy",
        class: "red"
    },
    5: {
        label: "Chờ HTKD duyệt",
        class: "orange"
    },
    6: {
        label: "HTKD từ chối",
        class: "purple"
    },
};
export const TYPE_USER_ENUM = {
    1: "Đại lý",
    2: "Cộng tác viên",
};