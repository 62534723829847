import {Reconciliation} from "../../../pages/reconciliation/Reconciliation";


const RECONCILIATION = [
    {
        component: Reconciliation,
        link: "/danh-sach-doi-soat",
        permission: "",
        isExact: true
    },
];

export default RECONCILIATION;
