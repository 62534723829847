export const STATUS_LABEL_CLASS = {
    1: {
        label: "Đã gửi NVKD",
        class: "orange"
    },
    2: {
        label: "Xử lý thất bại",
        class: "red"
    },
    3: {
        label: "Thành công",
        class: "green"
    }
};

export const TYPE_REQUEST_LABEL_CLASS = {
    1: {
        label: "Đăng ký mới",
        class: "orange"
    },
    2: {
        label: "Gia hạn",
        class: "blue"
    },
    3: {
        label: "Chuyển đổi",
        class: "green"
    }
};
export const OBJECT_REQUEST_LABEL_CLASS = {
    1: {
        label: "Tổ chức",
        class: "orange"
    },
    2: {
        label: "Cá nhân",
        class: "blue"
    },
    3: {
        label: "Cá nhân thuộc tổ chức",
        class: "green"
    }
};

export const DURATION_REQUEST_LABEL_CLASS = {
    1: {
        label: "1 Năm",
        class: "blue"
    },
    2: {
        label: "2 Năm",
        class: "blue"
    },
    3: {
        label: "3 Năm",
        class: "blue"
    }
};
export const INIT_MODAL_DENY = {
    visible_modal_deny: false,
    loading: false,
    id: "",
    type: "",
    note: "",
    error_reason: ""
}

export const INIT_SERVICE = {
    reason: 1,
    object: 1,
    time: 1
}
