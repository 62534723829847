export const STATUS_LABEL_CLASS = {
    1: {
        label: "Chờ Khách hàng ký",
        class: "volcano"
    },
    2: {
        label: "Khách hàng từ chối",
        class: "red"
    },
    3: {
        label: "Chờ nghiệp vụ duyệt",
        class: "orange"
    },
    4: {
        label: "Nghiệp vụ từ chối",
        class: "red"
    },
    5: {
        label: "Chờ gen",
        class: "geekblue"
    },
    6: {
        label: "Đã sinh chứng thư số",
        class: "green"
    }
};

export const STATUS_OPTIONS = {
    1: "Chờ Khách hàng ký",
    2: "Khách hàng từ chối",
    3: "Chờ nghiệp vụ duyệt",
    4: "Nghiệp vụ từ chối",
    5: "Chờ gen",
    6: "Đã sinh chứng thư số",
};


