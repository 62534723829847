import React, { Component } from "react";
import { Layout } from "antd";
import SideMenu from "./side-menu/SideMenu";
import RightPageSide from "./right-page-side/RightPageSide";
import Login from "../../pages/login/Login";
import { Route, Switch, withRouter } from "react-router";
import SignPage from "../../pages/sign-coop-stop/index";
import WrappedGenPage from "../../pages/gen-cts-customer/gen";
import ReSignDk02Page from "../../pages/re-sign-dk02";
import SignRevokeFilePage from "../../pages/sign-file-revoke-destroy-pause";
import SignChangeInfoCertPage from "../../pages/sign-file-change-info-certificate/SignChangeInfoCertificatePage";
import SignRenewAndWarrantyFilePage from "../../pages/sign-filerenenew-and-warranty/SignRenewAndWarrantyPage";
import ChangePassByEmail from "../../pages/login/ChangePassByEmail";
import SignFileDk03Page from "../../pages/sign-dk03";

interface Props {}
interface State {}

export default class MainPage extends Component<Props, State> {
  state = {};

  render() {
    return (
      <Layout style={{ minHeight: "100vh" }}>
        <Switch>
          <Route
            exact
            path="/ky-hop-dong-/dung-hop-tac/:id"
            component={SignPage}
          />
          <Route
            exact
            path="/re-sign-dk02/:id/:type"
            component={ReSignDk02Page}
          />
          <Route
            exact
            path="/customer-sign/:secret"
            component={SignRevokeFilePage}
          />
          <Route
              exact
              path="/customer-sign-dk03/:secret"
              component={SignFileDk03Page}
          />
          <Route
            exact
            path="/customer-sign-change-info-cert/:secret"
            component={SignChangeInfoCertPage}
          />
          <Route
            exact
            path="/customer-sign-file-renew-and-warranty/:secret"
            component={SignRenewAndWarrantyFilePage}
          />
          <Route
            exact
            path="/change-password-by-email/:secret"
            component={ChangePassByEmail}
          />
          <Route exact path="/gen-cts-customer" component={WrappedGenPage} />
          <Route exact path="/login" component={LoginContainer} />
          <Route component={DefaultContainer} />
        </Switch>
      </Layout>
    );
  }
}

const LoginContainer = () => (
  <Route path="/login" component={(props) => <Login {...props} />} />
);

const SideMenuWithRouter = withRouter((props) => <SideMenu {...props} />);

const DefaultContainer = () => (
  <React.Fragment>
    <SideMenuWithRouter />
    <RightPageSide />
  </React.Fragment>
);
