import React, { useEffect, useState } from "react";
import PageWrapper from "../wrapper/PageWrapper";
import { Form } from "antd";
import { FormComponentProps } from "antd/lib/form";
import InputWithLabel from "components/common/form/input-with-label/InputWithLabel";
import SelectWithLabel from "components/common/form/input-with-label/SelectWithLabel";
import RadioWithLabel from "components/common/form/input-with-label/RadioWithLabel";
import { TextAreaWithLabel } from "components/common/form/input-with-label/TextAreaWithLabel";
import ButtonOnSave from "components/common/form/button/ButtonOnSave";
import ButtonCancel from "components/common/form/button/ButtonCancel";
import { AgencyService } from "services/agency/AgencyServices";
import { loading } from "components/common/loading/Loading";
import { RequestTokenService } from "services/request-token/RequestTokenServices";
import _ from "lodash";
import { onSuccessAction, onFailAction } from "helpers/SwalCommon";
import AntModal from "components/common/modal/AntModal";
import SelectDateWithLabel from "components/common/form/input-with-label/SelectDateWithLabel";
import {formatReadBase64, handleFormatString} from "helpers/NewCaCrmHelper";
import { Checkbox } from 'antd';
import {UserServices} from "../../services/user/UserServies";

interface Props extends FormComponentProps {
  user: any;
  history: any;
}
const TYPE_SALE = 5;
const RequestToken: React.FC<Props> = props => {
  const [userType, setUserType] = useState(0);
  const [agency, setAgency] = useState([]);
  const [agencyOption, setAgencyOption] = useState({});
  const [contributor, setContributor] = useState([]);
  const [contributorOption, setContributorOption] = useState({});
  const [visibleModal, setVisibleModal] = useState(false);
  const [file, setFile] = useState("");
  const [loadingModal, setLoadingModal] = useState(false);
  const [typeLogin, setTypeUser] = useState('1');
  const [belongTo, setBelongTo] = useState('1');
  const [checkBox, setCheckBox] = useState(false);

  useEffect(() => {
    fetchAgencyOption().then();
    fetchUser().then();
    // eslint-disable-next-line
  }, []);

  const fetchAgencyOption = async () => {
    const agencyService = new AgencyService();
    const data = await agencyService.getAgency();
    setAgency(data);
    setAgencyOption(_.mapValues(_.keyBy(data, "id"), "fullname"));
  };

  const getListContributor = async () => {
    const agencyService = new AgencyService();
    const data = await agencyService.getContributor();
    setContributor(data);
    setContributorOption(_.mapValues(_.keyBy(data, "id"), "fullname"));
  };

  const storeAgency = status => {
    const { validateFields } = props.form;
    validateFields(async (errors, values) => {
      if (!errors) {
        try {
          loading.runLoadingBlockUI();
          const data = await RequestTokenService.store({ ...values, status });
          if (data && Number(data.status) === 422) {
            _.forOwn(data.error, function(errors, key) {
              props.form.setFields({
                [key]: {
                  errors: [new Error(errors.toString())]
                }
              });
            });
          } else if (data && Number(data.status) === 200) {
            onSuccessAction("Lưu yêu cầu thành công", () => {
              props.history.push("/yeu-cau-token");
            });
          }
        } catch (error) {
          onFailAction("Có lỗi xảy ra khi lưu !");
        } finally {
          loading.stopRunLoading();
        }
      }else {
        onFailAction("Bạn chưa điền đủ thông tin!");
      }
    });
  };

  const fetchUser = async  () => {
    const user = new UserServices();
    const userInfo = await user.getUserAuth();
    const type = userInfo.data.type;
    setUserType(type);
  }

  const onPreviewFile = () => {
    const { validateFields } = props.form;
    validateFields(async (errors, values) => {
      if (!errors) {
        try {
          const data = await RequestTokenService.previewFileCreate(values);
          if (data && Number(data.status) === 422) {
            setVisibleModal(false);
            setLoadingModal(false);
            _.forOwn(data.error, function(errors, key) {
              props.form.setFields({
                [key]: {
                  errors: [new Error(errors.toString())]
                }
              });
            });
          }else {
            setVisibleModal(true);
            setLoadingModal(true);
            let contentRead = formatReadBase64(data);
            setFile(contentRead)
          }
        } catch (error) {
          onFailAction("Có lỗi xảy ra khi xem trước file!");
          setVisibleModal(false);
        } finally {
          setLoadingModal(false);
        }
      }else {
        onFailAction("Bạn chưa điền đủ thông tin!");
      }
    });
  };

  const onOkModal = () => {
    setVisibleModal(false);
  };

  const onChangeAgency = async value => {
    if (value) {
      const selectAgency: any = _.find(agency, { id: parseInt(value) });
      props.form.setFieldsValue({ tax_code: selectAgency.tax_code });
    }
  };

  const onChangeContributor = async value => {
    if (value) {
      const selectContributor: any = _.find(contributor, { id: parseInt(value) });
      props.form.setFieldsValue({ tax_code: selectContributor.passport });
    }
  };

  const onChangeBeLongTo = e => {
    setBelongTo(e);
    if (Number(e) === 2) {
      getListContributor().then();
    }
    props.form.setFieldsValue({ belong_to: e });
    props.form.setFieldsValue({ owner_id: "" });
    props.form.setFieldsValue({ tax_code: "" });
  };

  const onChangeTypeLogin = e => {
    setTypeUser(e.target.value)
  }

  const onChangeCheckBox = e => {
    setCheckBox(e.target.checked)
  }

  const onChangeUserName = e => {
    const userName = handleFormatString(e.target.value.replace(/ /g,''), 3);
    props.form.setFieldsValue({ acc_user_name : userName });
  }

  const { getFieldDecorator } = props.form;

  return (
    <PageWrapper title="Thêm mới yêu cầu tạo tài khoản">
      <Form>
        {
          (Number(userType) === TYPE_SALE) ? (
              <div className="input-group">
                <SelectWithLabel
                    options={{1: "Đại lý", 2 : "Cộng tác viên"}}
                    name="belong_to"
                    wrappedClass="col-md-2"
                    form={props.form}
                    placeholder="Chọn loaị hình hợp tác"
                    label="Loại hình hợp tác"
                    isRequired={true}
                    onChange={onChangeBeLongTo}
                />
                {
                  (Number(belongTo) === 1) ? (
                      <SelectWithLabel
                          options={agencyOption}
                          name="owner_id"
                          wrappedClass="col-md-2"
                          form={props.form}
                          placeholder="Chọn đại lý"
                          label="Tên đại lý"
                          isRequired={true}
                          onChange={onChangeAgency}
                      />
                  ) : (
                      <SelectWithLabel
                          options={contributorOption}
                          name="owner_id"
                          wrappedClass="col-md-2"
                          form={props.form}
                          placeholder="Chọn cộng tác viên"
                          label="Tên cộng tác viên"
                          isRequired={true}
                          onChange={onChangeContributor}
                      />
                  )
                }

                <InputWithLabel
                    form={props.form}
                    label={(Number(belongTo) === 1) ? "Mã số thuế" : "Hộ chiếu/CMND"}
                    name="tax_code"
                    wrapClass="col-md-2"
                    isDisabled={true}
                />
                <RadioWithLabel
                    options={{ 1: "Chuyển khoản", 2: "Tiền mặt" }}
                    label="Hình thức thanh toán"
                    name="type_pay"
                    wrappedClass="col-md-3"
                    form={props.form}
                    isRequired={true}
                />
                <RadioWithLabel
                    options={{ 1: "Token RA", 2: "Mật khẩu" }}
                    label="Loại đăng nhập"
                    defaultValue={typeLogin}
                    name="acc_type_login"
                    wrappedClass="col-md-3"
                    form={props.form}
                    onChange={onChangeTypeLogin}
                    isRequired={true}
                />
              </div>
          ) : (
              <div className="input-group">
                <SelectWithLabel
                    options={agencyOption}
                    name="owner_id"
                    wrappedClass="col-md-3"
                    form={props.form}
                    placeholder="Chọn đại lý"
                    label="Tên đại lý"
                    isRequired={true}
                    onChange={onChangeAgency}
                />
                <InputWithLabel
                    form={props.form}
                    label="Mã số thuế"
                    name="tax_code"
                    wrapClass="col-md-3"
                    isDisabled={true}
                />
                <RadioWithLabel
                    options={{ 1: "Chuyển khoản", 2: "Tiền mặt" }}
                    label="Hình thức thanh toán"
                    name="type_pay"
                    wrappedClass="col-md-3"
                    form={props.form}
                    isRequired={true}
                />
                <RadioWithLabel
                    options={{ 1: "Token RA", 2: "Mật khẩu" }}
                    label="Loại đăng nhập"
                    defaultValue={typeLogin}
                    name="acc_type_login"
                    wrappedClass="col-md-3"
                    form={props.form}
                    onChange={onChangeTypeLogin}
                    isRequired={true}
                />
              </div>
          )
        }
        <div className="input-group">
          <InputWithLabel
              form={props.form}
              label="Họ và tên"
              name="acc_full_name"
              wrapClass="col-md-3"
              type="text"
              maxLength={255}
              isRequired={true}
          />
          <InputWithLabel
              form={props.form}
              label="Email"
              name="acc_email"
              wrapClass="col-md-3"
              type="text"
              maxLength={100}
              isRequired={true}
              rules={[
                {
                  type: 'email',
                  message: 'Email không đúng định dạng!',
                },
              ]}
          />
          <InputWithLabel
              form={props.form}
              onBlur={onChangeUserName}
              label="Tên tài khoản"
              name="acc_user_name"
              wrapClass="col-md-2"
              type="text"
              maxLength={100}
              isRequired={true}
          />
          <InputWithLabel
              form={props.form}
              label="Số điện thoại"
              name="acc_phone"
              isRequired={true}
              wrapClass="col-md-2"
              type="number"
          />
          {
            (Number(belongTo) === 1) ? (
                <SelectWithLabel
                    options={{ 1: "Admin", 2: "Cấp 2" }}
                    name="level_agency"
                    wrappedClass="col-md-2"
                    form={props.form}
                    placeholder="Phân quyền"
                    label="Phân quyền"
                    isRequired={true}
                />
            ) : ""
          }
        </div>

        { (Number(typeLogin) === 2 && !checkBox) ? ("") : (
            <div>
              <div className="input-group">
                <RadioWithLabel
                    options={{ 1: "Tại FastCA", 2: "Chuyển phát nhanh" }}
                    label="Hình thức nhận"
                    name="receive_type"
                    wrappedClass="col-md-3"
                    form={props.form}
                    isRequired={true}
                />
              </div>
              <div className="input-group">
                <InputWithLabel
                    form={props.form}
                    label="Người nhận"
                    name="receive_fullname"
                    isRequired={true}
                    wrapClass="col-md-3"
                    type="text"
                />
                <SelectDateWithLabel
                    name="receive_date"
                    form={props.form}
                    isRequired={true}
                    wrapClass="col-md-3"
                    label="Ngày nhận"
                />
                {props.form.getFieldValue("receive_type") === "1" ? (
                    <InputWithLabel
                        form={props.form}
                        label="Số CMND/Hộ chiếu"
                        name="receive_passport"
                        isRequired={true}
                        wrapClass="col-md-3"
                    />
                ) : (
                    ""
                )}
                {props.form.getFieldValue("receive_type") === "2" ? (
                    <React.Fragment>
                      <InputWithLabel
                          form={props.form}
                          label="Địa chỉ nhận chuyển phát"
                          name="receive_address"
                          isRequired={true}
                          wrapClass="col-md-4"
                          type="text"
                      />
                      <InputWithLabel
                          form={props.form}
                          label="Số điện thoại người nhận"
                          name="receive_phone"
                          isRequired={true}
                          wrapClass="col-md-2"
                          type="number"
                      />
                    </React.Fragment>
                ) : (
                    ""
                )}
              </div>
            </div>
        )}
        <div className="input-group">
          <div className={'col-md-4'}>
            <Form.Item>
              {getFieldDecorator('resign_document', {
                valuePropName: 'checked',
                initialValue: checkBox,
              })(<Checkbox  onChange={onChangeCheckBox}>Đăng ký tài liệu bán hàng</Checkbox>)}
            </Form.Item>
          </div>
        </div>
        {
          checkBox ? (
              <div className="input-group">
                <InputWithLabel
                    form={props.form}
                    label="Số lượng token"
                    name="token_admin_num"
                    wrapClass="col-md-3"
                    type="number"
                    maxLength={4}
                    isRequired={true}
                />
                <InputWithLabel
                    form={props.form}
                    label="Số lượng vỏ hộp"
                    name="box_num"
                    wrapClass="col-md-3"
                    type="number"
                    maxLength={4}
                    isRequired={true}
                />
                <InputWithLabel
                    form={props.form}
                    label="Số lượng phong bì"
                    name="envelope_num"
                    wrapClass="col-md-3"
                    type="number"
                    maxLength={4}
                    isRequired={true}
                />
              </div>
          ) : ""
        }
        <div className="input-group">
          <TextAreaWithLabel
            form={props.form}
            label="Ghi chú"
            name="token_admin_note"
            wrapClass="col-md"
            rows={4}
          />
        </div>
      </Form>
      { (typeLogin === '1'  || checkBox ) ? (
          <div className="input-group pb-5 pt-2">
            <div className="col-md-2">
              <button onClick={onPreviewFile} className="btn btn-primary btn-sm">
                Mẫu đề xuất
              </button>
              <AntModal
                  visible={visibleModal}
                  loading={loadingModal}
                  className="w-75 h-75"
                  bodyStyle={{ height: "700px" }}
                  style={{ top: "20px" }}
                  onCLickOk={onOkModal}
              >
                <iframe
                    title="Quản lý hợp đồng"
                    src={`${file}`}
                    height="100%"
                    width="100%"
                />
              </AntModal>
            </div>
          </div>
      ) : ""}
      <div className="input-group d-flex justify-content-center">
          <ButtonOnSave
            onClick={() => {
              storeAgency(1);
            }}
            label="Lưu nháp"
            className={"btn btn-primary btn-sm"}
          />
          <ButtonOnSave
            onClick={() => {
              storeAgency(5);
            }}
            label="Trình duyệt"
            className={"btn btn-success btn-sm"}
          />
          <ButtonCancel
            // onClick={() => {
            //   props.history.push("/yeu-cau-token");
            // }}
              onClick={() => {
                props.history.goBack();
              }}
            className={"btn btn-default btn-sm"}
          />
      </div>
    </PageWrapper>
  );
};

const WrappedRequestTokenCreate = Form.create<Props>({
  name: "RequestToken"
})(RequestToken);

export default WrappedRequestTokenCreate;
