import { Card, Checkbox, Form } from "antd";
import { FormComponentProps } from "antd/lib/form";
import ButtonCancel from "components/common/form/button/ButtonCancel";
import InputWithLabel from "components/common/form/input-with-label/InputWithLabel";
import { loading } from "components/common/loading/Loading";
import { onFailAction } from "helpers/SwalCommon";
import _ from "lodash";
import PageWrapper from "pages/wrapper/PageWrapper";
import React, { Fragment, useEffect, useState } from "react";
import { match } from "react-router";
import RadioWithLabel from "../../components/common/form/input-with-label/RadioWithLabel";
import SelectWithLabel from "../../components/common/form/input-with-label/SelectWithLabel";
import { TextAreaWithLabel } from "../../components/common/form/input-with-label/TextAreaWithLabel";
import { RequestCertificateGroupService } from "../../services/request-certificate-group/RequestCertificateGroupServices";
import { RequestDigitalCertificatePersonalServices } from "../../services/request-digital-certificate-personal/RequestDigitalCertificatePersonalServices";
import { RequestRenewAndWarrantyServices } from "../../services/request-renew-warranty/RequestRenewAndWarrantyServices";
import { TypePersonal } from "helpers/enum/ServiceEnum";
const { REACT_APP_BASE_API_URL } = window["runConfig"];
interface Props extends FormComponentProps {
    match: match<{ id: string }>;
    history: any;
}

export const ReNewAndWarrantyDetail: React.FC<Props> = (props) => {
    const id = props.match.params.id;
    const STATUS_KH_TU_CHOI = 2;
    const STATUS_NV_TU_CHOI = 4;
    const STATUS_CHO_GEN = 5;
    const STATUS_DONE = 6;
    const [province, setProvince] = useState({});
    const [district, setDistrict] = useState({});
    const [labelCode, setLabelCode] = useState("Mã số doanh nghiệp");
    const [type, setType] = useState(0);
    const [status, setStatus] = useState(0);
    const [file, setFile] = useState("");
    const [password, setPassword] = useState("");
    const [reason, setReason] = useState("");
    const [secretCode, setSecretCode] = useState("");
    const [showEmail, setShowEmail] = useState(false);
    const [linkSignFileCustomer, setLinkSignFileCustomer] = useState("");

    const fetchCustomer = async () => {
        const dataSend = {
            id: id,
        };
        const result = await RequestRenewAndWarrantyServices.viewDetail(
            dataSend
        );
        console.log(result);

        setType(result.data.type);
        setPassword(result.data.password);
        setLinkSignFileCustomer(result.data.link_sign_file_customer);
        setStatus(result.data.status);
        setShowEmail(result.data.show_email);
        if (Number(result.data.status) === STATUS_NV_TU_CHOI) {
            setReason(result.data.reason_deny_request);
        }
        if (Number(result.data.status) === STATUS_CHO_GEN) {
            setSecretCode(result.data.secret_code);
        }
        const province = await RequestCertificateGroupService.getProvince();
        setProvince(_.mapValues(_.keyBy(province, "newtel_code"), "fullname"));
        const district =
            await RequestCertificateGroupService.getDistrictByProvince(
                result.data.province_code
            );
        setDistrict(
            _.mapValues(_.keyBy(district, "newtel_district_code"), "fullname")
        );
        if (Number(result.data.type) === 1) {
            if (Number(result.data.identity_type) === 1) {
                setLabelCode("Mã số thuế");
            }
            if (Number(result.data.identity_type) === 2) {
                setLabelCode("Mã ngân sách");
            }
            if (Number(result.data.identity_type) === 3) {
                setLabelCode("Số quyết định");
            }
            if (Number(result.data.identity_type) === 4) {
                setLabelCode("Mã bảo hiểm xã hội");
            }
            if (Number(result.data.identity_type) === 5) {
                setLabelCode("Giấy phép đầu tư");
            }
        }
        props.form.setFieldsValue({
            uid: Number(result.data.type_cert) === 1
                ? result.data.info_cert ? result.data.info_cert.customer_code.toString()  : ''
                : result.data.info_compensation_cert.uid.toString(),
            identity_type: result.data.identity_type.toString(),
            identity: result.data.identity,
            fullname: Number(result.data.type_cert) === 1
                ? result.data.fullname
                : result.data.info_compensation_cert.owner,
            email: result.data.email,
            address: result.data.address,
            token_code: Number(result.data.type_cert) === 1
                ? (result.data.token_manager ? result.data.token_manager.code  : "")
                :  result.data.info_compensation_cert.token_code,
            phone: result.data.phone,
            province_code: result.data.province_code.toString(),
            district_code: result.data.district_code.toString(),
            contact_name: result.data.contact_name,
            contact_position: result.data.contact_position,
            contact_phone: result.data.contact_phone,
            contact_email: result.data.contact_email,
            contact_address: result.data.contact_address,
            promotion_code: result.data.promotion_code,
            reason_renew_warranty_type:
                result.data.reason_renew_warranty_type.toString(),
            type_request: result.data.type_request.toString(),
        });
        if (Number(result.data.reason_renew_warranty_type) === 3) {
            props.form.setFieldsValue({
                reason_extend: result.data.reason_extend,
            });
        }
    };

    // const onChangeTypeSign = async (e) =>{
    //     props.form.setFieldsValue({
    //         type_customer_sign: e
    //     })
    // };
    const getProvince = async () => {
        const province =
            await RequestDigitalCertificatePersonalServices.getProvince();
        setProvince(_.mapValues(_.keyBy(province, "newtel_code"), "fullname"));
    };
    const onChangeProvince = async (e) => {
        if (e) {
            const district =
                await RequestDigitalCertificatePersonalServices.getDistrictByProvince(
                    e
                );
            setDistrict(
                _.mapValues(
                    _.keyBy(district, "newtel_district_code"),
                    "fullname"
                )
            );
        }
        props.form.setFieldsValue({
            district_code: undefined,
        });
    };
    const changeReason = async (e) => {
        props.form.setFieldsValue({
            reason_renew_warranty_type: e.target.value,
        });
    };
    useEffect(() => {
        fetchCustomer();
        getProvince();
        // eslint-disable-next-line
    }, []);

    // const storeRequest = () => {
    //     const {validateFields} = props.form;
    //     validateFields(async (errors, values) => {
    //         if (!errors) {
    //             try {
    //                 loadingHelper.runLoadingBlockUI();
    //                 const val= {
    //                     ...values,
    //                     id: id,
    //                     type: 1,
    //                     type_request: 1
    //                 };
    //                 const data = await RequestRenewAndWarrantyServices.saveRequestRenewAndWarranty(val);
    //                 if (data && Number(data.status) === 422) {
    //                     onFailAction("Có lỗi xảy ra khi yêu cầu !");
    //                     _.forOwn(data.error, function (errors, key) {
    //                         props.form.setFields({
    //                             [key]: {
    //                                 errors: [new Error(errors.toString())]
    //                             }
    //                         });
    //                     });
    //                 } else {
    //                     onSuccessAction("Yêu cầu thành công", () => {
    //                         props.history.push("/danh-sach-yeu-cau-cap-lai-bao-hanh");
    //                     });
    //                 }
    //             } catch (error) {
    //                 onFailAction("Có lỗi xảy ra!");
    //             } finally {
    //                 loadingHelper.stopRunLoading();
    //             }
    //         }else {
    //             onFailAction("Bạn chưa điền đủ thông tin!");
    //         }
    //     });
    // };

    const onViewFileDoc = async (type_file) => {
        loading.runLoadingBlockUI();
        try {
            let token = localStorage.getItem("currentUser");
            if (token) {
                let obj = JSON.parse(token);
                let link = `${REACT_APP_BASE_API_URL}qlbh/yeu-cau-cap-lai-bao-hanh/getFileDoc/${password}/${type_file}?token=${obj.token}`;
                setFile(link);
            }
        } catch (error) {
            onFailAction("Có lỗi xảy ra khi xem trước file!");
        }
        loading.stopRunLoading();
    };

    return (
        <PageWrapper title="Chi tiết yêu cầu cấp lại - bảo hành">
            <Form>
                {status === STATUS_KH_TU_CHOI ||
                status === STATUS_NV_TU_CHOI ? (
                    <div className="input-group">
                        <TextAreaWithLabel
                            label={"Lý do từ chối"}
                            name="reason-deny"
                            rows={0}
                            form={props.form}
                            defaultValue={reason}
                            wrapClass={"col-md px-0"}
                            isDisabled={true}
                        />
                    </div>
                ) : (
                    ""
                )}
                {linkSignFileCustomer ? (
                    <div className="input-group">
                        <Fragment>
                            <InputWithLabel
                                label="Link ký xác nhận thu hồi"
                                form={props.form}
                                defaultValue={linkSignFileCustomer}
                                name="link"
                                isDisabled={true}
                                wrapClass="col-md-6 pl-0"
                            />
                            <InputWithLabel
                                label="Mã xác thực"
                                form={props.form}
                                name="password"
                                defaultValue={password}
                                isDisabled={true}
                                wrapClass="col-md-6 pr-0"
                            />
                        </Fragment>
                    </div>
                ) : (
                    ""
                )}
                {status === STATUS_CHO_GEN ? (
                    <div className="input-group">
                        <InputWithLabel
                            label="Mã bảo mật"
                            form={props.form}
                            name="secret_code"
                            isDisabled={true}
                            wrapClass="col-md px-0"
                            defaultValue={secretCode}
                        />
                    </div>
                ) : (
                    ""
                )}
                <Card
                    className="m-r-15-i m-l-15-i"
                    title={<label>Thông tin cấp lại - bảo hành</label>}
                    size="small"
                >
                    <div className="input-group">
                        <InputWithLabel
                            form={props.form}
                            label="Mã khách hàng"
                            name="uid"
                            wrapClass="col-md-3"
                            maxLength={255}
                            isDisabled={true}
                        />
                        {Number(type) === 1 ? (
                            <SelectWithLabel
                                options={{
                                    1: "Mã số thuế",
                                    2: "Mã ngân sách",
                                    3: "Số quyết định",
                                    4: "Mã bảo hiểm xã hội",
                                    5: "Giấy phép đầu tư",
                                }}
                                name="identity_type"
                                wrappedClass="col-md-3"
                                form={props.form}
                                label={"Loại định danh"}
                                isDisabled={true}
                            />
                        ) : (
                            <SelectWithLabel
                                options={TypePersonal.TYPE_IDENTITY}
                                name="identity_type"
                                wrappedClass="col-md-3"
                                form={props.form}
                                label={"Loại định danh"}
                                isDisabled={true}
                            />
                        )}

                        <InputWithLabel
                            form={props.form}
                            label={labelCode}
                            name="identity"
                            wrapClass="col-md-3"
                            isDisabled={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Mã thiết bị"
                            name="token_code"
                            wrapClass="col-md-3"
                            isDisabled={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Tên khách hàng"
                            name="fullname"
                            wrapClass="col-md-6"
                            isDisabled={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Email"
                            name="email"
                            wrapClass="col-md-3"
                            isDisabled={false}
                            isRequired={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Số điện thoại"
                            name="phone"
                            wrapClass="col-md-3"
                            isDisabled={false}
                            isRequired={true}
                        />
                    </div>
                    <div className="input-group">
                        <SelectWithLabel
                            options={province}
                            name="province_code"
                            wrappedClass="col-md-3"
                            form={props.form}
                            label={"Tỉnh thành"}
                            onChange={onChangeProvince}
                            isRequired={true}
                            isDisabled={true}
                            isProvince={true}
                        />
                        <SelectWithLabel
                            options={district}
                            name="district_code"
                            wrappedClass="col-md-3"
                            form={props.form}
                            label={"Quận huyện"}
                            isRequired={true}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Địa chỉ"
                            name="address"
                            wrapClass="col-md-6"
                            maxLength={255}
                            isRequired={true}
                            isDisabled={true}
                        />
                    </div>
                    <div className="input-group">
                        <RadioWithLabel
                            options={{ 1: "Cấp lại", 2: "Bảo hành" }}
                            label="Loại yêu cầu"
                            name="type_request"
                            wrappedClass="col-md-3"
                            form={props.form}
                            isRequired={true}
                            isDisabled={true}
                        />
                        <RadioWithLabel
                            options={{ 1: "Mất", 2: "Hỏng", 3: "Khác" }}
                            label="Lý do cấp lại"
                            name="reason_renew_warranty_type"
                            wrappedClass="col-md-3"
                            form={props.form}
                            isRequired={true}
                            onChange={changeReason}
                            isDisabled={true}
                        />
                    </div>
                    {Number(
                        props.form.getFieldValue("reason_renew_warranty_type")
                    ) === 3 ? (
                        <div className="input-group">
                            <TextAreaWithLabel
                                label={"Lý do khác"}
                                name="reason_extend"
                                rows={0}
                                form={props.form}
                                wrapClass={"col-md-5"}
                                isRequired={
                                    Number(
                                        props.form.getFieldValue(
                                            "reason_renew_warranty_type"
                                        )
                                    ) === 3
                                }
                                isDisabled={true}
                            />
                        </div>
                    ) : (
                        ""
                    )}
                    <div className={"col-md-4"}>
                        <Form.Item>
                            <Checkbox checked={showEmail}>
                                Hiển thi Email
                            </Checkbox>
                        </Form.Item>
                    </div>
                </Card>
                <Card
                    className="m-r-15-i m-l-15-i mt-1"
                    title={<label>Thông tin liên hệ</label>}
                    size="small"
                >
                    <div className="input-group">
                        <InputWithLabel
                            form={props.form}
                            label="Họ và tên"
                            name="contact_name"
                            wrapClass="col-md-3"
                            maxLength={255}
                            isDisabled={true}
                            isRequired={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Chức vụ"
                            name="contact_position"
                            wrapClass="col-md-3"
                            maxLength={255}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Số điện thoại"
                            name="contact_phone"
                            wrapClass="col-md-3"
                            maxLength={20}
                            minLength={9}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Email"
                            name="contact_email"
                            wrapClass="col-md-3"
                            maxLength={255}
                            isDisabled={true}
                            isRequired={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Địa chỉ"
                            name="contact_address"
                            wrapClass="col-md-12"
                            maxLength={255}
                            isDisabled={true}
                        />
                    </div>
                </Card>
                <Card
                    className="m-r-15-i m-l-15-i mt-1"
                    title={<label>File giấy tờ</label>}
                    size="small"
                >
                    <div className="input-group">
                        <div className="col-md-3">
                            <label className="invisible label-hide">123</label>
                            <a
                                href={file}
                                className={
                                    "btn btn-outline-primary form-control button-a"
                                }
                                type={"button"}
                                target={"_blank"}
                                rel="noopener noreferrer"
                                onClick={() => onViewFileDoc(1)}
                            >
                                File cấp lại - bảo hành
                            </a>
                        </div>
                        {status === STATUS_DONE ? (
                            <div className="col-md-3">
                                <label className="invisible label-hide">
                                    123
                                </label>
                                <a
                                    href={file}
                                    className={
                                        "btn btn-outline-success form-control button-a"
                                    }
                                    type={"button"}
                                    target={"_blank"}
                                    rel="noopener noreferrer"
                                    onClick={() => onViewFileDoc(2)}
                                >
                                    File xác nhận (DK02)
                                </a>
                            </div>
                        ) : (
                            ""
                        )}

                        <InputWithLabel
                            form={props.form}
                            label="Mã CTKM"
                            name="promotion_code"
                            wrapClass="col-md-3"
                            maxLength={255}
                            isDisabled={true}
                        />
                    </div>
                </Card>
            </Form>
            <div className="input-group d-flex justify-content-center p-5 mt-4">
                {/*<div className="">*/}
                {/*    <ButtonOnSave*/}
                {/*        onClick={() => {*/}
                {/*            storeRequest();*/}
                {/*        }}*/}
                {/*        label="Trình duyệt"*/}
                {/*        className={"btn btn-success btn-sm"}*/}
                {/*    />*/}
                {/*</div>*/}
                <div className="">
                    <ButtonCancel
                        onClick={() => {
                            props.history.goBack();
                        }}
                        className={"btn btn-default btn-sm"}
                    />
                </div>
            </div>
        </PageWrapper>
    );
};

const WrappedReNewAndWarrantyDetail = Form.create<Props>({
    name: "ReNewAndWarrantyDetail",
})(ReNewAndWarrantyDetail);

export default WrappedReNewAndWarrantyDetail;
