import React, {useEffect, useState} from "react";
import PageWrapper from "../wrapper/PageWrapper";
import {Card, Form} from "antd";
import {FormComponentProps} from "antd/lib/form";
import InputWithLabel from "components/common/form/input-with-label/InputWithLabel";
import {TextAreaWithLabel} from "components/common/form/input-with-label/TextAreaWithLabel";
import ButtonOnSave from "components/common/form/button/ButtonOnSave";
import ButtonCancel from "components/common/form/button/ButtonCancel";
import {loading} from "components/common/loading/Loading";
import _ from "lodash";
import {onFailAction, onSuccessAction} from "helpers/SwalCommon";
import {RequestChangeInfoService} from "../../services/request-change-info/RequestChangeInfoService";
import InputFileUpload from "../../components/common/form/input-with-label/InputFileUpload";
import SelectWithLabel from "../../components/common/form/input-with-label/SelectWithLabel";
import {AgencyService} from "../../services/agency/AgencyServices";
import SelectDateWithLabel from "../../components/common/form/input-with-label/SelectDateWithLabel";
import moment from "moment";

interface Props extends FormComponentProps {
    user: any;
    history: any;
}

export const RequestCertificateGroup: React.FC<Props> = props => {
    const [type, setType] = useState("1");
    const [agency, setAgency] = useState([]);
    const [isRequiredFile, setIsRequiredFile] = useState(false);
    const [isRequiredFilePassport, setIsRequiredFilePassport] = useState(false);
    const [agencyOption, setAgencyOption] = useState({});
    const [contributor, setContributor] = useState([]);
    const [contributorOption, setContributorOption] = useState({});
    const [ownerId, setOwnerId] = useState(Number)

    const storeRequest = () => {
        const {validateFields} = props.form;
        validateFields(async (errors, values) => {
            if (!errors) {
                try {
                    loading.runLoadingBlockUI();
                    const payload = {...values, type: type, owner_id: ownerId};
                    const data = await RequestChangeInfoService.store(payload);
                    if (data && data.status === 422) {
                        _.forOwn(data.error, function (errors, key) {
                            props.form.setFields({
                                [key]: {
                                    errors: [new Error(errors.toString())]
                                }
                            });
                        });
                    } else if (data && data.status === 200) {
                        onSuccessAction("Lưu yêu cầu thành công", () => {
                            props.history.push("/yeu-cau-thay-doi-thong-tin");
                        });
                    }
                } catch (error) {
                    onFailAction("Có lỗi xảy ra !");
                } finally {
                    loading.stopRunLoading();
                }
            } else {
                onFailAction("Bạn chưa điền đủ thông tin!");
            }
        });
    };

    useEffect(() => {
        getListAgency().then();
        // eslint-disable-next-line
    }, []);

    const onChangeType = async (e) => {
        setType(e);
        if (Number(e) === 1) {
            getListAgency().then();
        } else {
            getListContributor().then();
        }
    }

    const getListContributor = async () => {
        const agencyService = new AgencyService();
        const data = await agencyService.getContributor();
        setContributor(data);
        setContributorOption(_.mapValues(_.keyBy(data, "id"), "fullname"));
    };

    const getListAgency = async () => {
        const agencyService = new AgencyService();
        const data = await agencyService.getAgency();
        setAgency(data);
        setAgencyOption(_.mapValues(_.keyBy(data, "id"), "fullname"));
    };

    const onChangeAgency = async e => {
        if (e) {
            const selectAgency: any = _.find(agency, {id: parseInt(e)});
            setOwnerId(parseInt(e));
            props.form.setFieldsValue({
                agency_fullname: selectAgency.fullname,
                agency_tax_code: selectAgency.tax_code,
                agency_sort_name: selectAgency.sortname,
                agency_deputy: selectAgency.deputy,
                agency_deputy_position: selectAgency.deputy_position,
                agency_email: selectAgency.email,
                agency_phone: selectAgency.phone,
                agency_address: selectAgency.address,
                agency_office_address: selectAgency.office_address,
                agency_bank_number: selectAgency.bank_number,
                agency_bank_branch: selectAgency.bank_branch,
            });
        }
    };

    const onChangeContributor = async e => {
        if (e) {
            const selectContributor: any = _.find(contributor, {id: parseInt(e)});
            setOwnerId(parseInt(e));
            props.form.setFieldsValue({
                contributor_fullname: selectContributor.fullname,
                contributor_passport: selectContributor.passport,
                contributor_sort_name: selectContributor.sortname,
                contributor_email: selectContributor.email,
                contributor_phone: selectContributor.phone,
                contributor_address: selectContributor.address,
                contributor_birthday: moment(selectContributor.birthday),
            });
        }
    };

    const onchangeValueAgency = () => {
        setIsRequiredFile(true);
    }

    const onchangeValueContributor = () => {
        setIsRequiredFilePassport(true);
    }

    return (
        <PageWrapper title="Yêu cầu thay đổi thông tin">
            <Form>
                <Card className="m-r-15-i m-l-15-i mt-4" title={<label>Thông tin thay đổi</label>} size="small">
                    <div className="input-group">
                        <TextAreaWithLabel
                            label={"Lý do thay đổi thông tin"}
                            form={props.form}
                            wrapClass="col-md-12"
                            name="reason_customer"
                            rows={4}
                            isRequired={true}
                        />
                    </div>
                    <div className="input-group">
                        <SelectWithLabel
                            name="type"
                            options={{1: "Đại lý", 2: "CTV"}}
                            form={props.form}
                            label="Loại"
                            wrappedClass="col-md-3"
                            defaultValue={type}
                            onChange={onChangeType}
                            isRequired={true}
                        />
                        {
                            (Number(type) === 1) ? (
                                <React.Fragment>
                                    <SelectWithLabel
                                        name="agency_id"
                                        options={agencyOption}
                                        form={props.form}
                                        label="Tên đại lý"
                                        wrappedClass="col-md-3"
                                        isRequired={true}
                                        onChange={onChangeAgency}
                                    />
                                    <InputWithLabel
                                        form={props.form}
                                        label="Mã số thuế"
                                        name="agency_tax_code"
                                        isRequired={true}
                                        wrapClass="col-md-3"
                                        onChange={onchangeValueAgency}
                                        isDisabled={true}
                                        maxLength={255}
                                    />
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <SelectWithLabel
                                        name="contributor_id"
                                        options={contributorOption}
                                        form={props.form}
                                        label="Tên cộng tác viên"
                                        wrappedClass="col-md-3"
                                        isRequired={true}
                                        onChange={onChangeContributor}
                                    />
                                    <InputWithLabel
                                        form={props.form}
                                        label="Hộ chiếu/CMND"
                                        name="contributor_passport"
                                        isRequired={true}
                                        wrapClass="col-md-3"
                                        onChange={onchangeValueAgency}
                                        isDisabled={true}
                                        maxLength={255}
                                    />
                                </React.Fragment>
                            )
                        }

                    </div>
                    {
                        Number(type) === 1 ? (
                            <div>
                                <div className="input-group">
                                    <InputWithLabel
                                        form={props.form}
                                        label="Tên đại lý"
                                        name="agency_fullname"
                                        isRequired={true}
                                        wrapClass="col-md-3"
                                        onChange={onchangeValueAgency}
                                        maxLength={255}
                                    />
                                    <InputWithLabel
                                        form={props.form}
                                        label="Tên viết tắt"
                                        name="agency_sort_name"
                                        isRequired={true}
                                        wrapClass="col-md-3"
                                        maxLength={255}
                                    />
                                    <InputWithLabel
                                        form={props.form}
                                        label="Email"
                                        name="agency_email"
                                        isRequired={true}
                                        wrapClass="col-md-3"
                                        maxLength={255}
                                    />
                                    <InputWithLabel
                                        form={props.form}
                                        label="Số điện thoại"
                                        name="agency_phone"
                                        isRequired={true}
                                        wrapClass="col-md-3"
                                        maxLength={255}
                                    />
                                </div>
                                <div className="input-group">
                                    <InputWithLabel
                                        form={props.form}
                                        label="Người đại diện"
                                        name="agency_deputy"
                                        isRequired={true}
                                        onChange={onchangeValueAgency}
                                        wrapClass="col-md-3"
                                        maxLength={255}
                                    />
                                    <InputWithLabel
                                        form={props.form}
                                        label="Chức vụ người đại diện"
                                        name="agency_deputy_position"
                                        isRequired={true}
                                        wrapClass="col-md-3"
                                        maxLength={255}
                                    />
                                    <InputWithLabel
                                        form={props.form}
                                        label="Trụ sở chính"
                                        name="agency_address"
                                        wrapClass="col-md-6"
                                        onChange={onchangeValueAgency}
                                        maxLength={255}
                                        isRequired={true}
                                    />
                                </div>
                                <div className="input-group">
                                    <InputWithLabel
                                        form={props.form}
                                        label="Văn phòng giao dịch"
                                        name="agency_office_address"
                                        wrapClass="col-md-6"
                                        onChange={onchangeValueAgency}
                                        maxLength={255}
                                        isRequired={true}
                                    />
                                    <InputWithLabel
                                        form={props.form}
                                        label="Số tài khoản ngân hàng"
                                        name="agency_bank_number"
                                        wrapClass="col-md-3"
                                        maxLength={255}
                                    />
                                    <InputWithLabel
                                        form={props.form}
                                        label="Chi nhánh"
                                        name="agency_bank_branch"
                                        wrapClass="col-md-3"
                                        maxLength={255}
                                    />
                                </div>
                                <div className="input-group">
                                    <InputFileUpload
                                        nameFiledSetValue={""}
                                        classWrapped="col-md-6"
                                        label="Tải file ĐKKD (pdf)"
                                        name="file"
                                        form={props.form}
                                        isRequired={isRequiredFile}
                                        extentionsAllow={['pdf', 'PDF']}
                                        accept={".pdf"}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div>
                                <div className="input-group">
                                    <InputWithLabel
                                        form={props.form}
                                        label="Tên cộng tác viên"
                                        name="contributor_fullname"
                                        isRequired={true}
                                        wrapClass="col-md-3"
                                        onChange={onchangeValueContributor}
                                        maxLength={255}
                                    />
                                    <InputWithLabel
                                        form={props.form}
                                        label="Tên CTV viết tắt"
                                        name="contributor_sort_name"
                                        isRequired={true}
                                        wrapClass="col-md-3"
                                        maxLength={255}
                                    />
                                    <InputWithLabel
                                        form={props.form}
                                        label="Email"
                                        name="contributor_email"
                                        isRequired={true}
                                        wrapClass="col-md-3"
                                        maxLength={255}
                                    />
                                    <InputWithLabel
                                        form={props.form}
                                        label="Số điện thoại"
                                        name="contributor_phone"
                                        wrapClass="col-md-3"
                                        maxLength={255}
                                        isRequired={true}
                                    />
                                </div>
                                <div className="input-group">
                                    <InputWithLabel
                                        form={props.form}
                                        label="Địa chỉ"
                                        name="contributor_address"
                                        wrapClass="col-md-9"
                                        onChange={onchangeValueContributor}
                                        maxLength={255}
                                        isRequired={true}
                                    />
                                    <SelectDateWithLabel
                                        form={props.form}
                                        label="Ngày sinh"
                                        name="contributor_birthday"
                                        wrapClass="col-md-3"
                                        isRequired={true}
                                    />
                                </div>
                                <div className="input-group">
                                    <InputFileUpload
                                        nameFiledSetValue={""}
                                        classWrapped="col-md-6"
                                        label="Tải file CMT/HC"
                                        name="file"
                                        form={props.form}
                                        isRequired={isRequiredFilePassport}
                                        // extentionsAllow={['pdf', 'PDF', 'img', 'IMG', 'jpg', 'JPG', 'jpeg', 'JPEG']}
                                        extentionsAllow={['pdf', 'PDF']}
                                        // accept={[".pdf", ".img", ".jpg", ".jpeg", ".PDF", ".JPG", ".JPEG", ".IMG"]}
                                        accept={[".pdf", ".img"]}
                                    />
                                </div>
                            </div>
                        )
                    }
                </Card>
            </Form>
            <div className="input-group d-flex justify-content-center p-5 mt-5">
                <div className="">
                    <ButtonOnSave
                        onClick={() => {
                            storeRequest();
                        }}
                        label="Gửi yêu cầu"
                        className={"btn btn-success btn-sm"}
                    />
                </div>
                <div className="">
                    <ButtonCancel
                        onClick={() => {
                            props.history.push("/yeu-cau-thay-doi-thong-tin");
                        }}
                        className={"btn btn-default btn-sm"}
                    />
                </div>
            </div>
        </PageWrapper>
    );
};

const WrappedRequestChangeInfoBusinessCreate = Form.create<Props>({
    name: "RequestCertificateGroup"
})(RequestCertificateGroup);

export default WrappedRequestChangeInfoBusinessCreate;
