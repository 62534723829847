import BaseServices from "../base/BaseServices";

const PREFIX_SERVICES = "qlbh/yeu-cau-dieu-chinh-thong-tin-serial/";

const getCertToChangeBySerial = async (data) => {
    return await BaseServices.request(
        PREFIX_SERVICES + "get-info-by-serial",
        data,
        "GET"
    );
};

const requestChangeInfoSerialOrganization = async (data) =>{
    return await BaseServices.request(
        PREFIX_SERVICES + 'request-change-info-serial-organization',
        data,
        'POST'
    );
};

const requestChangeInfoSerialPersonal = async (data) => {
    return await BaseServices.request(
        PREFIX_SERVICES + 'request-change-info-serial-personal',
        data,
        'POST'
    );
};

export const RequestChangeInfoSupportSerialService = {
    getCertToChangeBySerial,
    requestChangeInfoSerialOrganization,
    requestChangeInfoSerialPersonal
};
