import React, { useEffect } from "react";
import { Form } from "antd";
import SelectWithLabel from "../../../components/common/form/input-with-label/SelectWithLabel";
import { FormComponentProps } from "antd/lib/form";
import ButtonSearch from "../../../components/common/form/button/ButtonSearch";
import InputWithLabel from "../../../components/common/form/input-with-label/InputWithLabel";
import SelectDateSearch from "components/common/form/input-with-label/SelectDateSearch";
import { STATUS_LABEL_CLASS, TYPE_REQUEST_LABEL_CLASS } from "../Enum";
import { convertLabelClassObject } from "helpers/NewCaCrmHelper";

interface Props extends FormComponentProps {}
const SearchForm: React.FC<Props> = (props) => {
    useEffect(() => {
        // eslint-disable-next-line
    }, []);
    return (
        <Form>
            <div className="input-group">
                <InputWithLabel
                    form={props.form}
                    label=""
                    name="tokenCode"
                    placeholder={"Mã Token"}
                    wrapClass="col-md-3 nopadding-left"
                />
                <InputWithLabel
                    form={props.form}
                    label=""
                    name="code"
                    placeholder={"Mã định danh"}
                    wrapClass="col-md-3 nopadding-left"
                />
                <InputWithLabel
                    form={props.form}
                    label=""
                    name="fullname_search"
                    placeholder={"Tên khách hàng"}
                    wrapClass="col-md-3 nopadding-left"
                />
                <SelectWithLabel
                    options={convertLabelClassObject(TYPE_REQUEST_LABEL_CLASS)}
                    name="reason"
                    wrappedClass="col-md-3 nopadding-left"
                    form={props.form}
                    placeholder="Loại yêu cầu"
                    allowClear={false}
                />
                <SelectWithLabel
                    options={convertLabelClassObject(STATUS_LABEL_CLASS)}
                    name="status"
                    wrappedClass="col-md-3 nopadding-left"
                    form={props.form}
                    placeholder="Chọn trạng thái"
                />
                <SelectDateSearch
                    name="date_search"
                    form={props.form}
                    wrapClass="col-md-3 nopadding-left"
                    label=""
                    placeholder="Thời gian tạo"
                />

                <div className="form-group col-md-2 mt-1 nopadding-left">
                    <ButtonSearch data={props.form.getFieldsValue()} />
                </div>
            </div>
        </Form>
    );
};

const WrappedSearchForm = Form.create<Props>({
    name: "WrappedSearchForm",
})(SearchForm);

export default WrappedSearchForm;
