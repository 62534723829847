import BaseServices from "../base/BaseServices";

const PREFIX_SERVICES = "qlbh/yeu-cau-token/";

const getListIndex = async (data: object) => {
  return await BaseServices.request(
      PREFIX_SERVICES + "index",
      data,
      "GET"
  );
};

const store = async (data: object) => {
  return await BaseServices.request(
      PREFIX_SERVICES + "store",
      data,
      "POST"
  );
};

const getModelToUpdate = async id => {
  return await BaseServices.request(
      PREFIX_SERVICES + "get-to-update/" + id,
      {},
      "GET"
  );
};

const previewRequest = async id =>{
  return await BaseServices.request(
      PREFIX_SERVICES + "preview-request/" + id,
      {},
      "GET"
  );
}

const businessSupportApproveRequest = async id =>{
  return await BaseServices.request(
      PREFIX_SERVICES + "business-support-approve-request/" + id,
      {},
      "GET"
  );
}
const businessSupportDenyRequest = async id =>{
  return await BaseServices.request(
      PREFIX_SERVICES + "business-support-deny-request/" + id,
      {},
      "GET"
  );
}

const update = async (id, data) => {
  return await BaseServices.request(
      PREFIX_SERVICES + "update",
      {request_id: id, ...data},
      "PUT"
  );
};

const deleteRequest = async (id, ) => {
  return await BaseServices.request(
      PREFIX_SERVICES + "delete",
      {request_id: id},
      "DELETE"
  );
};

const previewFileCreate = async (data: object) => {
  return await BaseServices.request(
      PREFIX_SERVICES + "preview-file-register",
      data,
      "POST"
  );
};
const previewFile = async (id) => {
  return await BaseServices.request(
      PREFIX_SERVICES + "preview-file-preview",
      {id: id},
      "POST"
  );
};


export const RequestTokenService = {
  store,
  getListIndex,
  getModelToUpdate,
  previewRequest,
  update,
  deleteRequest,
  previewFile,
  previewFileCreate,
  businessSupportDenyRequest,
  businessSupportApproveRequest
};
