import { BehaviorSubject } from "rxjs";
import BaseServices from "../base/BaseServices";

const currentUserSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem("currentUser")!)
);

export const authenticationService = {
  login,
  logout,
  loginByCert,
  sendEmail,
  ChangePasswordByEmail,
  CheckLinkChangePassword,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject.value;
  },
};

async function login(username: string, password: string) {
  const data = {
    username,
    password,
    type_system: 2,
  };
  const user = await BaseServices.request("auth/login", data, "POST");
  if (user && user.token) {
    localStorage.setItem("currentUser", JSON.stringify(user));
    currentUserSubject.next(user);
  }
  return user;
}

async function loginByCert(username: string, token: string) {
  const data = {
    username,
    token,
    type_system: 2,
    type_login: 2,
  };
  const user = await BaseServices.request("auth/login", data, "POST");
  if (user && user.token) {
    localStorage.setItem("currentUser", JSON.stringify(user));
    currentUserSubject.next(user);
  }
  return user;
}

async function sendEmail(email: string, time: any) {
  const data = { email, time };
  const response = await BaseServices.request("sendEmail", data, "POST");
  return response;
}

async function ChangePasswordByEmail(secret: string, newpassword: string) {
  const data = { secret, newpassword };
  const response = await BaseServices.request(
    "ChangePasswordByEmail",
    data,
    "POST"
  );
  return response;
}

async function CheckLinkChangePassword(secret: string) {
  const data = { secret };
  const response = await BaseServices.request(
    "checklinkchangepassword",
    data,
    "POST"
  );
  return response;
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("currentUser");
  currentUserSubject.next(null);
}
