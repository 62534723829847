import React, {useEffect, useState} from "react";
import PageWrapper from "../wrapper/PageWrapper";
import {Card, Form} from "antd";
import {FormComponentProps} from "antd/lib/form";
import InputWithLabel from "components/common/form/input-with-label/InputWithLabel";
import {TextAreaWithLabel} from "components/common/form/input-with-label/TextAreaWithLabel";
import ButtonOnSave from "components/common/form/button/ButtonOnSave";
import ButtonCancel from "components/common/form/button/ButtonCancel";
import {loading} from "components/common/loading/Loading";
import _ from "lodash";
import {onFailAction, onSuccessAction} from "helpers/SwalCommon";
import SelectDateWithLabel from "components/common/form/input-with-label/SelectDateWithLabel";
import {RequestChangeInfoService} from "../../services/request-change-info/RequestChangeInfoService";
import InputFileUpload from "../../components/common/form/input-with-label/InputFileUpload";
import moment from "moment";
import {match} from "react-router";
import ModalDisplayFile from "../../components/common/modal/display-file/ModalDisplayFile";
import SelectWithLabel from "../../components/common/form/input-with-label/SelectWithLabel";
import {AgencyService} from "../../services/agency/AgencyServices";

interface Props extends FormComponentProps {
    match: match<{ id: string }>;
    history: any;
}

const STATUS_BUSINESS_SUPPORT_DENY = 6;
const STATUS_NV_DENY = 2;

export const RequestChangeInfo: React.FC<Props> = props => {
    const id = props.match.params.id;
    const [status, setStatus] = useState();
    const [type, setType] = useState("1");
    const [agency, setAgency] = useState([]);
    const [isRequiredFile, setIsRequiredFile] = useState(false);
    const [isRequiredFilePassport, setIsRequiredFilePassport] = useState(false);
    const [agencyOption, setAgencyOption] = useState({});
    const [contributor, setContributor] = useState([]);
    const [contributorOption, setContributorOption] = useState({});
    const [ownerId, setOwnerId] = useState(Number)


    const storeRequest = () => {
        const {validateFields} = props.form;
        validateFields(async (errors, values) => {
            if (!errors) {
                try {
                    loading.runLoadingBlockUI();
                    const payload = {...values, type: type, owner_id: ownerId, id: id};
                    const data = await RequestChangeInfoService.update(payload);
                    if (data && data.status === 422) {
                        _.forOwn(data.error, function (errors, key) {
                            props.form.setFields({
                                [key]: {
                                    errors: [new Error(errors.toString())]
                                }
                            });
                        });
                    } else if (data && data.status === 200) {
                        onSuccessAction("Lưu yêu cầu thành công", () => {
                            props.history.push("/yeu-cau-thay-doi-thong-tin");
                        });
                    }
                } catch (error) {
                    onFailAction("Có lỗi xảy ra !");
                } finally {
                    loading.stopRunLoading();
                }
            }else {
                onFailAction("Bạn chưa điền đủ thông tin!");
            }
        });
    };

    const getData = async () => {
        loading.runLoadingBlockUI();
        const result = await RequestChangeInfoService.find(id);
        if (result && result.status === 200){
            setStatus(result.data.status);
            setType(result.data.type.toString());
            if (Number(result.data.type) === 1) {
                let detail = result.data.change_info_detail_agency;

                setOwnerId(result.data.id_agency)
                props.form.setFieldsValue({
                    type: "1",
                    agency_id: detail.fullname,
                    agency_tax_code: result.data.agency.tax_code,
                    reason_customer: result.data.reason_customer,
                    reason_business_support: result.data.reason_business_support,
                    agency_fullname: detail.fullname,
                    agency_sort_name: detail.sortname,
                    agency_deputy: detail.deputy,
                    agency_deputy_position: detail.deputy_position,
                    agency_email: detail.email,
                    agency_phone: detail.phone,
                    agency_address: detail.address,
                    agency_office_address: detail.office_address,
                    agency_bank_number: detail.bank_number,
                    agency_bank_branch: detail.bank_branch,
                });
            }else {
                let detail = result.data.change_info_detail_contributor;
                setOwnerId(result.data.id_contributor)
                props.form.setFieldsValue({
                    type: "2",
                    contributor_id: detail.fullname,
                    contributor_passport: result.data.contributor.passport,
                    reason_newca: result.data.reason_newca,
                    reason_business_support: result.data.reason_business_support,
                    reason_customer: result.data.reason_customer,
                    contributor_fullname: detail.fullname,
                    contributor_sort_name: detail.sortname,
                    contributor_email: detail.email,
                    contributor_phone: detail.phone,
                    contributor_address: detail.address,
                    contributor_birthday: moment(detail.birthday),
                });
            }
            loading.stopRunLoading();
        }else{
            onFailAction('Có lỗi xảy ra!');
        }
    };

    const onChangeType = async (e) => {
        setType(e);
        if (Number(e) === 1) {
            getListAgency().then();
        } else {
            getListContributor().then();
        }
    }

    const getListContributor = async () => {
        const agencyService = new AgencyService();
        const data = await agencyService.getContributor();
        setContributor(data);
        setContributorOption(_.mapValues(_.keyBy(data, "id"), "fullname"));
    };

    const getListAgency = async () => {
        const agencyService = new AgencyService();
        const data = await agencyService.getAgency();
        setAgency(data);
        setAgencyOption(_.mapValues(_.keyBy(data, "id"), "fullname"));
    };

    const onchangeValueContributor = () => {
        setIsRequiredFilePassport(true);
    }

    const onchangeValueAgency = () => {
        setIsRequiredFile(true);
    }
    
    useEffect(() => {
        getData().then();
        getListAgency().then();
        getListContributor().then();
        // eslint-disable-next-line
    }, []);
    const [modalFilePassport, setModalFilePassport] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);
    const [filePassPort, setFilePassPort] = useState("");
    const onViewFilePassport = async () => {
        try {
            setModalFilePassport(true);
            setLoadingModal(true);
            const result = await RequestChangeInfoService.getFilePassport(id);
            if (result && result.status === 200){
                setFilePassPort(result.base64);
            } else {
                onFailAction('Có lỗi xảy ra!');
            }
        } finally {
            setLoadingModal(false);
        }
    };
    const onOkModalPassPort = () => {
        setModalFilePassport(false);
    };
    
    const onChangeAgency = async e => {
        if (e) {
            const selectAgency: any = _.find(agency, {id: parseInt(e)});
            setOwnerId(parseInt(e));
            props.form.setFieldsValue({
                agency_fullname: selectAgency.fullname,
                agency_tax_code: selectAgency.tax_code,
                agency_sort_name: selectAgency.sortname,
                agency_deputy: selectAgency.deputy,
                agency_deputy_position: selectAgency.deputy_position,
                agency_email: selectAgency.email,
                agency_phone: selectAgency.phone,
                agency_address: selectAgency.address,
                agency_office_address: selectAgency.office_address,
                agency_bank_number: selectAgency.bank_number,
                agency_bank_branch: selectAgency.bank_branch,
            });
        }
    };

    const onChangeContributor = async e => {
        if (e) {
            const selectContributor: any = _.find(contributor, {id: parseInt(e)});
            setOwnerId(parseInt(e));
            props.form.setFieldsValue({
                contributor_fullname: selectContributor.fullname,
                contributor_passport: selectContributor.passport,
                contributor_sort_name: selectContributor.sortname,
                contributor_email: selectContributor.email,
                contributor_phone: selectContributor.phone,
                contributor_address: selectContributor.address,
                contributor_birthday: moment(selectContributor.birthday),
            });
        }
    };
    
    return (
        <PageWrapper title="Cập nhật yêu cầu thay đổi thông tin">
            <ModalDisplayFile
                titleModal="File CMND/Hộ chiếu"
                visibleModal={modalFilePassport}
                loadingModal={loadingModal}
                fileBase64={filePassPort}
                onOkModal={onOkModalPassPort}
            />
            <Form>
                <Card className="m-r-15-i m-l-15-i mt-4" title={<label>Thông tin thay đổi</label>} size="small">
                    {props.form.getFieldValue('reason_newca') && Number(status) === STATUS_NV_DENY ? (
                        <div className="input-group">
                            <InputWithLabel
                                form={props.form}
                                label="Lý do từ chối của nghiệp vụ"
                                name="reason_newca"
                                wrapClass="col-md-12"
                                maxLength={255}
                                isDisabled={true}
                            />
                        </div>
                    ):""}
                    {props.form.getFieldValue('reason_business_support') && Number(status) === STATUS_BUSINESS_SUPPORT_DENY ? (
                        <div className="input-group">
                            <InputWithLabel
                                form={props.form}
                                label="Lý do từ chối của HTKD"
                                name="reason_business_support"
                                wrapClass="col-md-12 text-danger"
                                maxLength={255}
                                isDisabled={true}
                            />
                        </div>
                    ) : ""}
                    <div className="input-group">
                        <TextAreaWithLabel
                            label={"Lý do thay đổi thông tin"}
                            form={props.form}
                            wrapClass="col-md-12"
                            name="reason_customer"
                            rows={4}
                            isRequired={true}
                        />
                    </div>
                    <div className="input-group">
                        <SelectWithLabel
                            name="type"
                            options={{1: "Đại lý", 2: "CTV"}}
                            form={props.form}
                            label="Loại"
                            wrappedClass="col-md-3"
                            onChange={onChangeType}
                            isRequired={true}
                        />
                        {
                            (Number(type) === 1) ? (
                                <React.Fragment>
                                    <SelectWithLabel
                                        name="agency_id"
                                        options={agencyOption}
                                        form={props.form}
                                        label="Tên đại lý"
                                        wrappedClass="col-md-3"
                                        isRequired={true}
                                        onChange={onChangeAgency}
                                    />
                                    <InputWithLabel
                                        form={props.form}
                                        label="Mã số thuế"
                                        name="agency_tax_code"
                                        isRequired={true}
                                        wrapClass="col-md-3"
                                        onChange={onchangeValueAgency}
                                        isDisabled={true}
                                        maxLength={255}
                                    />
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <SelectWithLabel
                                        name="contributor_id"
                                        options={contributorOption}
                                        form={props.form}
                                        label="Tên cộng tác viên"
                                        wrappedClass="col-md-3"
                                        isRequired={true}
                                        onChange={onChangeContributor}
                                    />
                                    <InputWithLabel
                                        form={props.form}
                                        label="Hộ chiếu/CMND"
                                        name="contributor_passport"
                                        isRequired={true}
                                        wrapClass="col-md-3"
                                        onChange={onchangeValueAgency}
                                        isDisabled={true}
                                        maxLength={255}
                                    />
                                </React.Fragment>
                            )
                        }

                    </div>
                    {
                        Number(type) === 1 ? (
                            <div>
                                <div className="input-group">
                                    <InputWithLabel
                                        form={props.form}
                                        label="Tên đại lý"
                                        name="agency_fullname"
                                        isRequired={true}
                                        wrapClass="col-md-3"
                                        onChange={onchangeValueAgency}
                                        maxLength={255}
                                    />
                                    <InputWithLabel
                                        form={props.form}
                                        label="Tên viết tắt"
                                        name="agency_sort_name"
                                        isRequired={true}
                                        wrapClass="col-md-3"
                                        maxLength={255}
                                    />
                                    <InputWithLabel
                                        form={props.form}
                                        label="Email"
                                        name="agency_email"
                                        isRequired={true}
                                        wrapClass="col-md-3"
                                        maxLength={255}
                                    />
                                    <InputWithLabel
                                        form={props.form}
                                        label="Số điện thoại"
                                        name="agency_phone"
                                        isRequired={true}
                                        wrapClass="col-md-3"
                                        maxLength={255}
                                    />
                                </div>
                                <div className="input-group">
                                    <InputWithLabel
                                        form={props.form}
                                        label="Người đại diện"
                                        name="agency_deputy"
                                        isRequired={true}
                                        onChange={onchangeValueAgency}
                                        wrapClass="col-md-3"
                                        maxLength={255}
                                    />
                                    <InputWithLabel
                                        form={props.form}
                                        label="Chức vụ người đại diện"
                                        name="agency_deputy_position"
                                        isRequired={true}
                                        wrapClass="col-md-3"
                                        maxLength={255}
                                    />
                                    <InputWithLabel
                                        form={props.form}
                                        label="Trụ sở chính"
                                        name="agency_address"
                                        wrapClass="col-md-6"
                                        onChange={onchangeValueAgency}
                                        maxLength={255}
                                        isRequired={true}
                                    />
                                </div>
                                <div className="input-group">
                                    <InputWithLabel
                                        form={props.form}
                                        label="Văn phòng giao dịch"
                                        name="agency_office_address"
                                        wrapClass="col-md-6"
                                        onChange={onchangeValueAgency}
                                        maxLength={255}
                                        isRequired={true}
                                    />
                                    <InputWithLabel
                                        form={props.form}
                                        label="Số tài khoản ngân hàng"
                                        name="agency_bank_number"
                                        wrapClass="col-md-3"
                                        maxLength={255}
                                    />
                                    <InputWithLabel
                                        form={props.form}
                                        label="Chi nhánh"
                                        name="agency_bank_branch"
                                        wrapClass="col-md-3"
                                        maxLength={255}
                                    />
                                </div>
                                <div className="input-group">
                                    <InputFileUpload
                                        nameFiledSetValue={""}
                                        classWrapped="col-md-6"
                                        label="Tải file ĐKKD (pdf)"
                                        name="file"
                                        form={props.form}
                                        isRequired={isRequiredFile}
                                        extentionsAllow={['pdf', 'PDF']}
                                        accept={".pdf"}
                                        onClickDownloadFile={onViewFilePassport}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div>
                                <div className="input-group">
                                    <InputWithLabel
                                        form={props.form}
                                        label="Tên cộng tác viên"
                                        name="contributor_fullname"
                                        isRequired={true}
                                        wrapClass="col-md-3"
                                        onChange={onchangeValueContributor}
                                        maxLength={255}
                                    />
                                    <InputWithLabel
                                        form={props.form}
                                        label="Tên CTV viết tắt"
                                        name="contributor_sort_name"
                                        isRequired={true}
                                        wrapClass="col-md-3"
                                        maxLength={255}
                                    />
                                    <InputWithLabel
                                        form={props.form}
                                        label="Email"
                                        name="contributor_email"
                                        isRequired={true}
                                        wrapClass="col-md-3"
                                        maxLength={255}
                                    />
                                    <InputWithLabel
                                        form={props.form}
                                        label="Số điện thoại"
                                        name="contributor_phone"
                                        wrapClass="col-md-3"
                                        maxLength={255}
                                        isRequired={true}
                                    />
                                </div>
                                <div className="input-group">
                                    <InputWithLabel
                                        form={props.form}
                                        label="Địa chỉ"
                                        name="contributor_address"
                                        wrapClass="col-md-9"
                                        onChange={onchangeValueContributor}
                                        maxLength={255}
                                        isRequired={true}
                                    />
                                    <SelectDateWithLabel
                                        form={props.form}
                                        label="Ngày sinh"
                                        name="contributor_birthday"
                                        wrapClass="col-md-3"
                                        isRequired={true}
                                    />
                                </div>
                                <div className="input-group">
                                    <InputFileUpload
                                        nameFiledSetValue={""}
                                        classWrapped="col-md-6"
                                        label="Tải file CMT/HC"
                                        name="file"
                                        form={props.form}
                                        isRequired={isRequiredFilePassport}
                                        // extentionsAllow={['pdf', 'PDF', 'img', 'IMG', 'jpg', 'JPG', 'jpeg', 'JPEG']}
                                        extentionsAllow={['pdf', 'PDF']}
                                        // accept={[".pdf", ".img", ".jpg", ".jpeg", ".PDF", ".JPG", ".JPEG", ".IMG"]}
                                        accept={[".pdf", ".img"]}
                                        onClickDownloadFile={onViewFilePassport}
                                    />
                                </div>
                            </div>
                        )
                    }


                </Card>
            </Form>
            <div className="input-group d-flex justify-content-center p-5 mt-5">
                <div className="">
                    <ButtonOnSave
                        onClick={() => {
                            storeRequest();
                        }}
                        label="Gửi yêu cầu"
                        className={"btn btn-success btn-sm"}
                    />
                </div>
                <div className="">
                    <ButtonCancel
                        onClick={() => {
                            props.history.push("/yeu-cau-thay-doi-thong-tin");
                        }}
                        className={"btn btn-default btn-sm"}
                    />
                </div>
            </div>
        </PageWrapper>
    );
};

const WrappedRequestChangeInfoBusinessUpdate = Form.create<Props>({
    name: "RequestChangeInfo"
})(RequestChangeInfo);

export default WrappedRequestChangeInfoBusinessUpdate;
