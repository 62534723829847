import React, {useEffect, useState} from "react";
import PageWrapper from "../wrapper/PageWrapper";
import {Card, Form} from "antd";
import {FormComponentProps} from "antd/lib/form";
import InputWithLabel from "components/common/form/input-with-label/InputWithLabel";
import {TextAreaWithLabel} from "components/common/form/input-with-label/TextAreaWithLabel";
import ButtonOnSave from "components/common/form/button/ButtonOnSave";
import ButtonCancel from "components/common/form/button/ButtonCancel";
import {loading} from "components/common/loading/Loading";
import _ from "lodash";
import {onFailAction, onSuccessAction} from "helpers/SwalCommon";
import {RequestChangeInfoService} from "../../services/request-change-info/RequestChangeInfoService";
import InputFileUpload from "../../components/common/form/input-with-label/InputFileUpload";
import {match} from "react-router";
import ModalDisplayFile from "../../components/common/modal/display-file/ModalDisplayFile";
import SelectDateWithLabel from "../../components/common/form/input-with-label/SelectDateWithLabel";
import Store from "../../store/store";
import moment from "moment";

interface Props extends FormComponentProps {
    match: match<{ id: string }>;
    history: any;
}

const STATUS_BUSINESS_SUPPORT_DENY = 6;
const STATUS_NV_DENY = 2;

export const RequestChangeInfo: React.FC<Props> = props => {
    const user = Store.getState().authReducer;
    const id = props.match.params.id;
    const TYPE_AGENCY = 7;
    const userType = user.type;

    const [isRequiredFilePassport, setIsRequiredFilePassport] = useState(false);
    const [isRequiredFile, setIsRequiredFile] = useState(false);
    const [status, setStatus] = useState();
    const [modalFilePassport, setModalFilePassport] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);
    const [filePassPort, setFilePassPort] = useState("");

    const storeRequest = () => {
        const {validateFields} = props.form;
        validateFields(async (errors, values) => {
            if (!errors) {
                try {
                    loading.runLoadingBlockUI();
                    const payload = {...values, type: userType, owner_id: user.owner_id, id: id};
                    const data = await RequestChangeInfoService.update(payload);
                    if (data && data.status === 422) {
                        _.forOwn(data.error, function (errors, key) {
                            props.form.setFields({
                                [key]: {
                                    errors: [new Error(errors.toString())]
                                }
                            });
                        });
                    } else if (data && data.status === 200) {
                        onSuccessAction("Lưu yêu cầu thành công", () => {
                            props.history.push("/yeu-cau-thay-doi-thong-tin");
                        });
                    }
                } catch (error) {
                    onFailAction("Có lỗi xảy ra !");
                } finally {
                    loading.stopRunLoading();
                }
            } else {
                onFailAction("Bạn chưa điền đủ thông tin!");
            }
        });
    };

    const onchangeValueAgency = () => {
        setIsRequiredFile(true);
    }

    const onchangeValueContributor = () => {
        setIsRequiredFilePassport(true);
    }

    const getData = async () => {
        loading.runLoadingBlockUI();
        const result = await RequestChangeInfoService.find(id);
        if (result && result.status === 200) {
            setStatus(result.data.status);
            if (Number(result.data.type) === 1) {
                let detail = result.data.change_info_detail_agency;
                props.form.setFieldsValue({
                    reason_newca: result.data.reason_newca,
                    agency_tax_code: result.data.agency.tax_code,
                    reason_customer: result.data.reason_customer,
                    reason_business_support: result.data.reason_business_support,
                    agency_fullname: detail.fullname,
                    agency_sort_name: detail.sortname,
                    agency_deputy: detail.deputy,
                    agency_deputy_position: detail.deputy_position,
                    agency_email: detail.email,
                    agency_phone: detail.phone,
                    agency_address: detail.address,
                    agency_office_address: detail.office_address,
                    agency_bank_number: detail.bank_number,
                    agency_bank_branch: detail.bank_branch,
                });
            } else {
                let detail = result.data.change_info_detail_contributor;
                props.form.setFieldsValue({
                    contributor_passport: result.data.contributor.passport,
                    reason_newca: result.data.reason_newca,
                    reason_customer: result.data.reason_customer,
                    reason_business_support: result.data.reason_business_support,
                    contributor_fullname: detail.fullname,
                    contributor_sort_name: detail.sortname,
                    contributor_email: detail.email,
                    contributor_phone: detail.phone,
                    contributor_address: detail.address,
                    contributor_birthday: moment(detail.birthday),
                });
            }
            loading.stopRunLoading();
        } else {
            onFailAction('Có lỗi xảy ra!');
        }
    };

    useEffect(() => {
        getData().then();
        // eslint-disable-next-line
    }, []);

    const onViewFilePassport = async () => {
        try {
            setModalFilePassport(true);
            setLoadingModal(true);
            const result = await RequestChangeInfoService.getFilePassport(id);
            if (result && result.status === 200) {
                setFilePassPort(result.base64);
            } else {
                onFailAction('Có lỗi xảy ra!');
            }
        } finally {
            setLoadingModal(false);
        }
    };

    const onOkModalPassPort = () => {
        setModalFilePassport(false);
    };

    return (
        <PageWrapper title="Cập nhật yêu cầu thay đổi thông tin">
            <ModalDisplayFile
                titleModal="File CMND/Hộ chiếu"
                visibleModal={modalFilePassport}
                loadingModal={loadingModal}
                fileBase64={filePassPort}
                onOkModal={onOkModalPassPort}
            />
            <Form>
                <Card className="m-r-15-i m-l-15-i mt-4" title={<label>Thông tin thay đổi</label>} size="small">
                    {props.form.getFieldValue('reason_newca') && Number(status) === STATUS_NV_DENY ? (
                        <div className="input-group">
                            <InputWithLabel
                                form={props.form}
                                label="Lý do từ chối của nghiệp vụ"
                                name="reason_newca"
                                wrapClass="col-md-12"
                                maxLength={255}
                                isDisabled={true}
                            />
                        </div>
                    ) : ""}
                    {props.form.getFieldValue('reason_business_support') && Number(status) === STATUS_BUSINESS_SUPPORT_DENY ? (
                        <div className="input-group">
                            <InputWithLabel
                                form={props.form}
                                label="Lý do từ chối của HTKD"
                                name="reason_business_support"
                                wrapClass="col-md-12 text-danger"
                                maxLength={255}
                                isDisabled={true}
                            />
                        </div>
                    ) : ""}
                    <div className="input-group">
                        <TextAreaWithLabel
                            label={"Lý do thay đổi thông tin"}
                            form={props.form}
                            wrapClass="col-md-12"
                            name="reason_customer"
                            rows={4}
                            isRequired={true}
                        />
                    </div>
                    {
                        Number(userType) === TYPE_AGENCY ? (
                            <div>
                                <div className="input-group">
                                    <InputWithLabel
                                        form={props.form}
                                        label="Mã số thuế"
                                        name="agency_tax_code"
                                        wrapClass="col-md-3"
                                        isRequired={true}
                                        isDisabled={true}
                                        maxLength={255}
                                    />
                                </div>
                                <div className="input-group">
                                    <InputWithLabel
                                        form={props.form}
                                        label="Tên đại lý"
                                        name="agency_fullname"
                                        isRequired={true}
                                        wrapClass="col-md-3"
                                        onChange={onchangeValueAgency}
                                        maxLength={255}
                                    />
                                    <InputWithLabel
                                        form={props.form}
                                        label="Tên viết tắt"
                                        name="agency_sort_name"
                                        isRequired={true}
                                        wrapClass="col-md-3"
                                        maxLength={255}
                                    />
                                    <InputWithLabel
                                        form={props.form}
                                        label="Email"
                                        name="agency_email"
                                        isRequired={true}
                                        wrapClass="col-md-3"
                                        maxLength={255}
                                    />
                                    <InputWithLabel
                                        form={props.form}
                                        label="Số điện thoại"
                                        name="agency_phone"
                                        isRequired={true}
                                        wrapClass="col-md-3"
                                        maxLength={255}
                                    />
                                </div>
                                <div className="input-group">
                                    <InputWithLabel
                                        form={props.form}
                                        label="Người đại diện"
                                        name="agency_deputy"
                                        isRequired={true}
                                        onChange={onchangeValueAgency}
                                        wrapClass="col-md-3"
                                        maxLength={255}
                                    />
                                    <InputWithLabel
                                        form={props.form}
                                        label="Chức vụ người đại diện"
                                        name="agency_deputy_position"
                                        isRequired={true}
                                        wrapClass="col-md-3"
                                        maxLength={255}
                                    />
                                    <InputWithLabel
                                        form={props.form}
                                        label="Trụ sở chính"
                                        name="agency_address"
                                        wrapClass="col-md-6"
                                        onChange={onchangeValueAgency}
                                        maxLength={255}
                                        isRequired={true}
                                    />
                                </div>
                                <div className="input-group">
                                    <InputWithLabel
                                        form={props.form}
                                        label="Văn phòng giao dịch"
                                        name="agency_office_address"
                                        wrapClass="col-md-6"
                                        onChange={onchangeValueAgency}
                                        maxLength={255}
                                        isRequired={true}
                                    />
                                    <InputWithLabel
                                        form={props.form}
                                        label="Số tài khoản ngân hàng"
                                        name="agency_bank_number"
                                        wrapClass="col-md-3"
                                        maxLength={255}
                                    />
                                    <InputWithLabel
                                        form={props.form}
                                        label="Chi nhánh"
                                        name="agency_bank_branch"
                                        wrapClass="col-md-3"
                                        maxLength={255}
                                    />
                                </div>
                                <div className="input-group">
                                    <InputFileUpload
                                        nameFiledSetValue={""}
                                        classWrapped="col-md-6"
                                        label="Tải file ĐKKD (pdf)"
                                        name="file"
                                        form={props.form}
                                        isRequired={isRequiredFile}
                                        extentionsAllow={['pdf', 'PDF']}
                                        accept={".pdf"}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div>
                                <div className="input-group">
                                    <InputWithLabel
                                        form={props.form}
                                        label="CMND/HC"
                                        name="contributor_passport"
                                        wrapClass="col-md-3"
                                        isRequired={true}
                                        isDisabled={true}
                                        maxLength={255}
                                    />
                                </div>
                                <div className="input-group">
                                    <InputWithLabel
                                        form={props.form}
                                        label="Tên cộng tác viên"
                                        name="contributor_fullname"
                                        isRequired={true}
                                        wrapClass="col-md-3"
                                        onChange={onchangeValueContributor}
                                        maxLength={255}
                                    />
                                    <InputWithLabel
                                        form={props.form}
                                        label="Tên CTV viết tắt"
                                        name="contributor_sort_name"
                                        isRequired={true}
                                        wrapClass="col-md-3"
                                        maxLength={255}
                                    />
                                    <InputWithLabel
                                        form={props.form}
                                        label="Email"
                                        name="contributor_email"
                                        isRequired={true}
                                        wrapClass="col-md-3"
                                        maxLength={255}
                                    />
                                    <InputWithLabel
                                        form={props.form}
                                        label="Số điện thoại"
                                        name="contributor_phone"
                                        wrapClass="col-md-3"
                                        maxLength={255}
                                        isRequired={true}
                                    />
                                </div>
                                <div className="input-group">
                                    <InputWithLabel
                                        form={props.form}
                                        label="Địa chỉ"
                                        name="contributor_address"
                                        wrapClass="col-md-9"
                                        onChange={onchangeValueContributor}
                                        maxLength={255}
                                        isRequired={true}
                                    />
                                    <SelectDateWithLabel
                                        form={props.form}
                                        label="Ngày sinh"
                                        name="contributor_birthday"
                                        wrapClass="col-md-3"
                                        isRequired={true}
                                    />
                                </div>
                                <div className="input-group">
                                    <InputFileUpload
                                        nameFiledSetValue={""}
                                        classWrapped="col-md-6"
                                        label="Tải file CMT/HC"
                                        name="file"
                                        form={props.form}
                                        isRequired={isRequiredFilePassport}
                                        extentionsAllow={['pdf', 'PDF']}
                                        // extentionsAllow={['pdf', 'PDF', 'img', 'IMG', 'jpg', 'JPG', 'jpeg', 'JPEG']}
                                        accept={[".pdf", ".img"]}
                                        // accept={[".pdf", ".img", ".jpg", ".jpeg", ".PDF", ".JPG", ".JPEG", ".IMG"]}
                                        onClickDownloadFile={onViewFilePassport}
                                    />
                                </div>
                            </div>
                        )
                    }
                </Card>
            </Form>
            <div className="input-group d-flex justify-content-center p-5 mt-5">
                <div className="">
                    <ButtonOnSave
                        onClick={() => {
                            storeRequest();
                        }}
                        label="Gửi yêu cầu"
                        className={"btn btn-success btn-sm"}
                    />
                </div>
                <div className="">
                    <ButtonCancel
                        onClick={() => {
                            props.history.push("/yeu-cau-thay-doi-thong-tin");
                        }}
                        className={"btn btn-default btn-sm"}
                    />
                </div>
            </div>
        </PageWrapper>
    );
};

const WrappedRequestChangeInfoUpdate = Form.create<Props>({
    name: "RequestChangeInfo"
})(RequestChangeInfo);

export default WrappedRequestChangeInfoUpdate;
