import BaseServices from "../base/BaseServices";

const getFileCustomerSign = async (data) => {
    return await BaseServices.request(
        `get-file-customer-sign`,
        data,
        "POST"
    );
};
const denySignRevoke = async (data) => {
    return await BaseServices.request(
        `deny-sign-revoke`,
        data,
        "POST"
    );
};

const getCertificate = async (data) => {
    return await BaseServices.request(
        `get-certificate-sign`,
        data,
        "POST"
    );
};

const updateFileRevokeSigned = async (data) => {
    return await BaseServices.request(
        `update-signed-revoke`,
        data,
        "POST"
    );
};
const getTimeServer = async () => {
    return await BaseServices.request(
        `get-time-server-sign`,
        {},
        "GET"
    );
};

export const SignRevokeDestroyPauseServices = {
    getFileCustomerSign,
    denySignRevoke,
    getCertificate,
    updateFileRevokeSigned,
    getTimeServer
};
