import { Card, Checkbox, Form, Radio } from "antd";
import { FormComponentProps } from "antd/lib/form";
import ApproveButton from "components/common/form/button/ApproveButton";
import ButtonCancel from "components/common/form/button/ButtonCancel";
import InputWithLabel from "components/common/form/input-with-label/InputWithLabel";
import RadioWithLabel from "components/common/form/input-with-label/RadioWithLabel";
import SelectDateWithLabel from "components/common/form/input-with-label/SelectDateWithLabel";
import SelectWithLabel from "components/common/form/input-with-label/SelectWithLabel";
// import { reloadPage } from "helpers/NewCaCrmHelper";
import { onFailAction, onSuccessAction } from "helpers/SwalCommon";
import { TypePersonal } from "helpers/enum/ServiceEnum";
import { Location } from "history";
import _ from "lodash";
import moment from "moment";
import PageWrapper from "pages/wrapper/PageWrapper";
import React, { useEffect, useState } from "react";
import { match } from "react-router";
import InputFileUpload from "../../components/common/form/input-with-label/InputFileUpload";
import { TextAreaWithLabel } from "../../components/common/form/input-with-label/TextAreaWithLabel";
import { loading } from "../../components/common/loading/Loading";
import { RequestCertificateGroupService } from "../../services/request-certificate-group/RequestCertificateGroupServices";
import { RequestDigitalCertificatePersonalServices } from "../../services/request-digital-certificate-personal/RequestDigitalCertificatePersonalServices";
import ModalConfirm from "./ModalConfirm";
import LabelInput from "components/common/form/label/LabelInput";

const { REACT_APP_BASE_API_URL } = window["runConfig"];
interface Props extends FormComponentProps {
    location: Location;
    match: match<{ id: string }>;
    history: any;
    isUpdateForm?: boolean;
    onClickDownloadFile?: any;
    disable?: boolean;
    defaultFileLabel?: string;
}
export const RequestCTSPersonalPreview: React.FC<Props> = (props) => {
    const id = props.match.params.id;
    const STATUS_TU_CHOI = 7;
    const STATUS_WAIT_GEN = 6;
    const [passportDate, setPassportDate] = useState("");
    const [status, setStatus] = useState(0);
    const [reason, setReason] = useState("");
    const [password, setPassword] = useState("");
    const [secretCode, setSecretCode] = useState("");
    const [cateServicePackage, setCateServicePackage] = useState({});
    const [fileLabelPassport, setFileLabelPassport] = useState("");
    const [fileLabelOrgan, setFileLabelOrgan] = useState("");
    const [statusReqGen, setStatusReqGen] = useState(false);
    const [isFileDk02, setIsFileDk2] = useState(false);
    const [isFileDk03, setIsFileDk3] = useState(false);
    const [linkReSign, setLinkReSign] = useState(false);

    const [dateExpiredCert, setDateExpiredCert] = useState("");

    const [province, setProvince] = useState({});
    const [district, setDistrict] = useState({});
    const [provider, setProvider] = useState({});
    const [labelCode, setLabelCode] = useState("Chứng minh nhân dân");
    const [versionToken, setVerionToken] = useState({});
    const [showEmail, setShowEmail] = useState(false);
    const [isCheckDestroy, setIsCheckDestroy] = useState(false);
    const [isViewPenaltyValue, setIsViewPenaltyValue] = useState(false);
    const [valueDestroy, setValueDestroy] = useState(null);

    const [modal, setModal] = useState({
        visible_modal: false,
        loading: false,
        penalty_value: null,
    });

    const [dataView, setDataView] = useState({
        type_extend:null
    })

    const onPreviewFile = async (type) => {
        const { validateFields } = props.form;
        validateFields(async (errors, values) => {
            if (!errors) {
                loading.runLoadingBlockUI();
                try {
                    let token = localStorage.getItem("currentUser");
                    if (token) {
                        let obj = JSON.parse(token);
                        let link = `${REACT_APP_BASE_API_URL}qlbh/request-digital-certificate-personal/get-file/${password}/${type}?token=${obj.token}`;
                        window.open(link);
                    }
                } catch (error) {
                    onFailAction("Có lỗi xảy ra khi xem file!");
                }
                loading.stopRunLoading();
            }
        });
    };

    const fetchRequestPersonal = async () => {
        const requestCertificateData =
            await RequestDigitalCertificatePersonalServices.previewRequestPersonal(
                id
            );
        const province =
            await RequestDigitalCertificatePersonalServices.getProvince();
        setProvince(_.mapValues(_.keyBy(province, "newtel_code"), "fullname"));
        const district =
            await RequestDigitalCertificatePersonalServices.getDistrictByProvince(
                requestCertificateData.data.province_code
            );
        setDistrict(
            _.mapValues(_.keyBy(district, "newtel_district_code"), "fullname")
        );

        if (requestCertificateData.data.requestpersonal.tax_code) {
            props.form.setFieldsValue({
                tax_code: requestCertificateData.data.requestpersonal.tax_code,
            });
        }
        let filePassportName = "";
        let fileOrganName = "";
        requestCertificateData.data.list_document.forEach(function (value) {
            if (value["type"] === 1 && value["file"]) {
                filePassportName = value["file"].split("/");
            } else if (value["type"] === 2 && value["file"]) {
                fileOrganName = value["file"].split("/");
            }
        });
        setDataView(requestCertificateData.data)

        setModal({
            ...modal,
            penalty_value: requestCertificateData.data.penaltyValue,
        });
        setIsCheckDestroy(requestCertificateData.data.isCheckDestroy);
        setDateExpiredCert(requestCertificateData.data.date_expired_cert);
        if (
            Number(requestCertificateData.data.status) === 30 ||
            Number(requestCertificateData.data.status) === 31
        ) {
            setIsViewPenaltyValue(true);
        }
        setValueDestroy(requestCertificateData.data.penalty_value);
        props.form.setFieldsValue({
            identity_type:
                requestCertificateData.data.requestpersonal.identity_type.toString(),
            passport: requestCertificateData.data.requestpersonal.passport,
            passport_place:
                requestCertificateData.data.requestpersonal.passport_place.toString(),
            fullname:
                requestCertificateData.data.requestpersonal.fullname.toString(),
            phone: requestCertificateData.data.requestpersonal.phone,
            email: requestCertificateData.data.requestpersonal.email,
            address: requestCertificateData.data.requestpersonal.address,
            promotion_code: requestCertificateData.data.promotion_code,
            is_organization:
                requestCertificateData.data.requestpersonal.is_organization.toString(),
            object: requestCertificateData.data.object.toString(),
            type_docurment:
                requestCertificateData.data.type_docurment.toString(),
            // support_register: requestCertificateData.data.support_register.toString(),
            package_id: requestCertificateData.data.package_id.toString(),
            package_price: requestCertificateData.data.package_price,
            type_device: requestCertificateData.data.type_device.toString(),
            province_code: requestCertificateData.data.province_code.toString(),
            district_code: requestCertificateData.data.district_code.toString(),
            passport_file: filePassportName[3],
            provider_id: requestCertificateData.data.provider_id.toString(),
            quantity: requestCertificateData.data.quantity,
            penalty_value: requestCertificateData.data.penaltyValue,
            type_extend: requestCertificateData.data.type_extend,
        });
        if (requestCertificateData.data.version_token_newtel) {
            props.form.setFieldsValue({
                version_token_newtel:
                    requestCertificateData.data.version_token_newtel.toString(),
            });
        }
        if (
            Number(
                requestCertificateData.data.requestpersonal.identity_type
            ) === 1
        ) {
            setLabelCode("Chứng minh nhân dân");
        }
        if (
            Number(
                requestCertificateData.data.requestpersonal.identity_type
            ) === 2
        ) {
            setLabelCode("Hộ chiếu");
        }
        if (
            Number(
                requestCertificateData.data.requestpersonal.identity_type
            ) === 3
        ) {
            setLabelCode("Căn cước công dân");
        }
        if (
            Number(
                requestCertificateData.data.requestpersonal.identity_type
            ) === 4
        ) {
            setLabelCode("Mã bảo hiểm xã hội");
        }
        if (
            Number(
                requestCertificateData.data.requestpersonal.identity_type
            ) === 5
        ) {
            setLabelCode("Mã nhân viên");
        }
        if (Number(requestCertificateData.data.status) === 5) {
            setStatusReqGen(true);
        }
        if (
            Number(requestCertificateData.data.object) === 2 ||
            Number(requestCertificateData.data.object) === 3
        ) {
            if (requestCertificateData.data.serial_cts) {
                props.form.setFieldsValue({
                    serial_cts: requestCertificateData.data.serial_cts,
                });
            }
            if (requestCertificateData.data.token_type) {
                props.form.setFieldsValue({
                    token_type:
                        requestCertificateData.data.token_type.toString(),
                });
            }
        }

        let arrAll: any = [];
        requestCertificateData.data.list_document.forEach(function (values) {
            arrAll[values.id] = values.type;
            if (
                Number(values.type) === 7 &&
                Number(values.number_signed !== 2)
            ) {
                setLinkReSign(true);
                props.form.setFieldsValue({
                    link:
                        requestCertificateData.domain +
                        "/view-info-dk02/" +
                        btoa(requestCertificateData.data.code) +
                        "/request",
                });
            }
        });
        arrAll.forEach(function (item, index, array) {
            if (Number(item) === 7) {
                setStatusReqGen(true);
                setIsFileDk2(true);
            }
            if (Number(item) === 14) {
                setIsFileDk3(true);
            }
        });

        setPassportDate(
            requestCertificateData.data.requestpersonal.passport_date
        );
        setStatus(requestCertificateData.data.status);
        setShowEmail(requestCertificateData.data.show_email);
        setReason(requestCertificateData.data.reason);
        setPassword(requestCertificateData.data.password);
        setSecretCode(requestCertificateData.data.secret_code);
        setFileLabelPassport(filePassportName[3] ? filePassportName[3] : "");
        if (
            Number(
                requestCertificateData.data.requestpersonal.is_organization
            ) === 1
        ) {
            props.form.setFieldsValue({
                organization_name:
                    requestCertificateData.data.requestpersonal
                        .organization_name,
                organization_department:
                    requestCertificateData.data.requestpersonal
                        .organization_department,
                organization_tax_code:
                    requestCertificateData.data.requestpersonal
                        .organization_tax_code,
                organization_position:
                    requestCertificateData.data.requestpersonal
                        .organization_position,
                organization_email:
                    requestCertificateData.data.requestpersonal
                        .organization_email,
                organization_phone:
                    requestCertificateData.data.requestpersonal
                        .organization_phone,
                organization_address:
                    requestCertificateData.data.requestpersonal
                        .organization_address,
            });
            setFileLabelOrgan(fileOrganName[3] ? fileOrganName[3] : "");
            props.form.setFieldsValue({
                organization_file: fileOrganName[3],
            });
        }
        // if (Number(requestCertificateData.data.support_register) === 1) {
        //   props.form.setFieldsValue({
        //     fullname_support: requestCertificateData.data.requestsupport.fullname,
        //     position_support: requestCertificateData.data.requestsupport.position,
        //     email_support: requestCertificateData.data.requestsupport.email,
        //     phone_support: requestCertificateData.data.requestsupport.phone,
        //   });
        //
        // }
        if (Number(requestCertificateData.data.type_device) === 2) {
            props.form.setFieldsValue({
                hsm_provider:
                    requestCertificateData.data.hsm_provider.toString(),
            });
        }

        let object = 1;
        if (
            Number(
                requestCertificateData.data.requestpersonal.is_organization
            ) === 1
        ) {
            object = 2;
        }
        const type_search = 4;
        const type = props.form.getFieldValue("object");
        const type_device = props.form.getFieldValue("type_device");
        const data = await getCateServicePackage(
            type_search,
            type,
            object,
            type_device,
            requestCertificateData.data.provider_id
        );
        setCateServicePackage(_.mapValues(_.keyBy(data.data, "id"), "name"));
    };
    const getCateServicePackage = async (
        type_search,
        type,
        obj,
        type_device,
        provider_id
    ) => {
        return await RequestDigitalCertificatePersonalServices.getListCateServicePackage(
            type_search,
            type,
            obj,
            type_device,
            provider_id
        );
    };
    const getProvider = async () => {
        const data =
            await RequestDigitalCertificatePersonalServices.getAllProvider();
        setProvider(_.mapValues(_.keyBy(data, "id"), "name"));
    };
    const getVersionToken = async () => {
        const data = await RequestCertificateGroupService.getVersionToken();
        setVerionToken(_.mapValues(_.keyBy(data.data, "id"), "code"));
    };
    useEffect(() => {
        fetchRequestPersonal();
        // eslint-disable-next-line
    }, [id]);

    useEffect(() => {
        getProvider();
        getVersionToken();
        // eslint-disable-next-line
    }, []);

    const viewModalConfirm = () => {
        setModal({ ...modal, visible_modal: true, loading: true });
    };
    const onCancelModelDecline = async () => {
        setModal({ ...modal, visible_modal: false });
    };
    const onOkModalDecline = async (e) => {
        const params = { id: id, penalty_value: modal.penalty_value };
        try {
            var result =
                await RequestDigitalCertificatePersonalServices.postRequestDestroy(
                    params
                );
            if (result["status"] === 200) {
                setValueDestroy(params.penalty_value);
                setIsViewPenaltyValue(true);
                setIsCheckDestroy(false);
                onSuccessAction("Tạo yêu cầu hủy thành công!");
            } else {
                onFailAction("Bạn không thể tạo yêu cầu hủy yêu cầu CTS");
            }
        } catch (error) {
            console.log(error);
            onFailAction("Có lỗi xảy ra trong quá trình hủy yêu cầu!");
        }
        setModal({ ...modal, visible_modal: false });
    };

    const  optionsTypeExtend= [
        { label: 'Trước hạn', value: 1 },
        { label: 'Quá hạn', value: 2 },
      ];
      
    return (
        <PageWrapper title="Chi tiết yêu cầu CTS cá nhân ">
            <Form>
                {status === STATUS_TU_CHOI ? (
                    <TextAreaWithLabel
                        label={"Lý do từ chối"}
                        name="reason-deny"
                        rows={0}
                        form={props.form}
                        defaultValue={reason}
                        wrapClass={"col-md"}
                        isDisabled={true}
                    />
                ) : (
                    ""
                )}
                {status === STATUS_WAIT_GEN ? (
                    <Card
                        className="container-fluid"
                        title={<label>Mã bảo mật</label>}
                        size="small"
                    >
                        <div className="input-group">
                            {" "}
                            <InputWithLabel
                                label=""
                                form={props.form}
                                name="secret_code"
                                isDisabled={true}
                                wrapClass="col-md"
                                defaultValue={secretCode}
                            />{" "}
                        </div>
                    </Card>
                ) : (
                    ""
                )}
                {linkReSign ? (
                    <div className="input-group">
                        <InputWithLabel
                            label="Link ký lại DK02"
                            form={props.form}
                            name="link"
                            isDisabled={true}
                            wrapClass="col-md-6 pl-0"
                        />
                        <InputWithLabel
                            label="Mã xác thực"
                            form={props.form}
                            name="password"
                            defaultValue={password}
                            isDisabled={true}
                            wrapClass="col-md-6 pr-0"
                        />
                    </div>
                ) : (
                    ""
                )}
                <Card
                    className="container-fluid mt-4"
                    title={
                        <label>
                            Trường hợp cá nhân thuộc tổ chức doanh nghiệp{" "}
                        </label>
                    }
                    size="small"
                >
                    <div className="input-group">
                        <div className=" input-group">
                            <RadioWithLabel
                                label={
                                    "Trường hợp cá nhân thuộc tổ chức doanh nghiệp"
                                }
                                options={{ 1: "Có", 2: "Không" }}
                                name="is_organization"
                                wrappedClass="col-md-6"
                                form={props.form}
                                isDisabled={true}
                            />
                        </div>
                        {props.form.getFieldValue("is_organization") === "1" ? (
                            <React.Fragment>
                                <div className="input-group">
                                    <InputWithLabel
                                        form={props.form}
                                        label="Tên tổ chức"
                                        name="organization_name"
                                        wrapClass="col-md-6 col-lg-3"
                                        isDisabled={true}
                                    />
                                    <InputWithLabel
                                        form={props.form}
                                        label="Tên phòng ban"
                                        name="organization_department"
                                        wrapClass="col-md-6 col-lg-3"
                                        isDisabled={true}
                                    />
                                    <InputWithLabel
                                        form={props.form}
                                        label="MST tổ chức/doanh nghiệp"
                                        name="organization_tax_code"
                                        wrapClass="col-md-6 col-lg-3"
                                        isDisabled={true}
                                    />
                                    <InputWithLabel
                                        form={props.form}
                                        label="Chức vụ"
                                        name="organization_position"
                                        wrapClass="col-md-6 col-lg-3"
                                        isDisabled={true}
                                    />
                                </div>
                                <div className="input-group">
                                    <InputWithLabel
                                        form={props.form}
                                        label="Email"
                                        name="organization_email"
                                        wrapClass="col-md-6 col-lg-3"
                                        isDisabled={true}
                                    />
                                    <InputWithLabel
                                        form={props.form}
                                        label="Số điện thoại"
                                        name="organization_phone"
                                        wrapClass="col-md-6 col-lg-3"
                                        isDisabled={true}
                                    />
                                    <InputWithLabel
                                        form={props.form}
                                        label="Địa chỉ tổ chức/doanh nghiệp"
                                        name="organization_address"
                                        wrapClass="col-md-6 col-lg-3"
                                        isDisabled={true}
                                    />
                                    <InputFileUpload
                                        nameFiledSetValue={""}
                                        defaultLabel={fileLabelOrgan}
                                        classWrapped="col-md-6 col-lg-3"
                                        label="File xác nhận tổ chức/doanh nghiệp"
                                        name="organization_file"
                                        form={props.form}
                                        isDisabled={true}
                                        onClickDownloadFile={() =>
                                            onPreviewFile(2)
                                        }
                                        extentionsAllow={["pdf", "PDF"]}
                                        accept={".pdf"}
                                    />
                                </div>
                            </React.Fragment>
                        ) : (
                            ""
                        )}
                    </div>
                </Card>
                <Card
                    className="container-fluid mt-4"
                    title={<label>Đăng ký dịch vụ chứng thư số</label>}
                    size="small"
                >
                    <div className="input-group">
                        <SelectWithLabel
                            options={provider}
                            name="provider_id"
                            wrappedClass="col-md-6 col-lg-2"
                            form={props.form}
                            label={"Nhà cung cấp"}
                            isDisabled={true}
                        />
                        {props.form.getFieldValue("is_organization") === "1" ? (
                            <SelectWithLabel
                                options={{
                                    1: "Chứng minh nhân dân",
                                    2: "Hộ chiếu",
                                    3: "Căn cước công dân",
                                    4: "Mã bảo hiểm xã hội",
                                    5: "Mã nhân viên",
                                }}
                                name="identity_type"
                                wrappedClass="col-md-6 col-lg-2"
                                form={props.form}
                                label={"Loại định danh"}
                                isRequired={true}
                                isDisabled={true}
                            />
                        ) : (
                            <SelectWithLabel
                                options={TypePersonal.TYPE_IDENTITY}
                                name="identity_type"
                                wrappedClass="col-md-6 col-lg-2"
                                form={props.form}
                                label={"Loại định danh"}
                                isRequired={true}
                                isDisabled={true}
                            />
                        )}
                        <InputWithLabel
                            form={props.form}
                            label={labelCode}
                            name="passport"
                            wrapClass="col-md-6 col-lg-2"
                            isDisabled={true}
                        />
                        <RadioWithLabel
                            options={{
                                1: "Cấp mới",
                                2: "Gia hạn",
                                3: "Chuyển đổi",
                            }}
                            label="Đối tượng"
                            name="object"
                            wrappedClass="col-md-6 col-lg-3 select-doi-tuong"
                            form={props.form}
                            isDisabled={true}
                        />
                       {/* {Number(props.form.getFieldValue("object")) === 2 ?
                            <div className={`form-group col-md-6 col-lg-3`}>
                                <LabelInput nameFor={"type_extend"} label={"Loại gia hạn"} />
                                <Form.Item>
                                    <Radio.Group
                                    disabled={true}
                                    options={optionsTypeExtend}
                                    value={dataView.type_extend}
                                    >
                                    </Radio.Group>
                                </Form.Item>
                            </div>
                            :<>
                            </>
                        } */}
                    </div>
                    <div className="input-group">
                        <RadioWithLabel
                            options={{
                                1: "Token",
                                2: "HSM",
                                // 3: "FastCA RS"
                            }}
                            label="Loại thiết bị đầu cuối thuê bao"
                            name="type_device"
                            wrappedClass="col-md-6 col-lg-2 select-doi-tuong"
                            form={props.form}
                            isDisabled={true}
                        />
                        {Number(props.form.getFieldValue("type_device")) ===
                        2 ? (
                            <RadioWithLabel
                                options={{ 1: "CyberLotus", 2: "Khác" }}
                                label="Nhà cung cấp HSM"
                                name="hsm_provider"
                                wrappedClass="col-md-6 col-lg-2 select-doi-tuong"
                                form={props.form}
                                isRequired={true}
                                isDisabled={true}
                            />
                        ) : (
                            ""
                        )}
                        <SelectWithLabel
                            options={cateServicePackage}
                            name="package_id"
                            wrappedClass="col-md-6 col-lg-2 "
                            form={props.form}
                            label={"Gói dịch vụ"}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Giá bán"
                            name="package_price"
                            wrapClass="col-md-6 col-lg-2"
                            isDisabled={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Số lượng"
                            name="quantity"
                            wrapClass="col-md-6 col-lg-1"
                            isRequired={true}
                            type={"number"}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Mã CTKM"
                            name="promotion_code"
                            wrapClass="col-md-6 col-lg-2"
                            maxLength={255}
                            isRequired={false}
                            isDisabled={true}
                        />
                    </div>
                    <div className={"input-group"}>
                        {Number(props.form.getFieldValue("object")) === 2 ||
                        Number(props.form.getFieldValue("object")) === 3 ? (
                            <React.Fragment>
                                <InputWithLabel
                                    form={props.form}
                                    label="Serial CTS"
                                    name="serial_cts"
                                    wrapClass="col-md-6 col-lg-4"
                                    isRequired={false}
                                    isDisabled={true}
                                />
                                <SelectWithLabel
                                    options={{ 1: "Token mới", 2: "Token cũ" }}
                                    name="token_type"
                                    wrappedClass="col-md-6 col-lg-2"
                                    form={props.form}
                                    label={"Loại token"}
                                    isDisabled={true}
                                />
                            </React.Fragment>
                        ) : (
                            ""
                        )}
                        {Number(props.form.getFieldValue("provider_id")) ===
                            2 &&
                        Number(props.form.getFieldValue("type_device")) ===
                            1 ? (
                            <SelectWithLabel
                                options={versionToken}
                                name="version_token_newtel"
                                wrappedClass="col-md-6 col-lg-2"
                                form={props.form}
                                label={"Chọn version token"}
                                isRequired={true}
                                isDisabled={true}
                            />
                        ) : (
                            ""
                        )}
                         {Number(props.form.getFieldValue("object")) === 2 ||
                        Number(props.form.getFieldValue("object")) === 3 ? (
                            <SelectDateWithLabel
                                name="date_expired_cert"
                                form={props.form}
                                wrapClass="col-md-2 col-lg-2"
                                label="Ngày hết hạn"
                                defaultValue={
                                    dateExpiredCert
                                        ? moment(dateExpiredCert)
                                        : null
                                }
                                isDisabled={true}
                            />
                        ) : (
                            ""
                        )}
                    </div>
                    <div className="input-group">
                        <div className={"col-md-4"}>
                            <Form.Item>
                                <Checkbox disabled={true} checked={showEmail}>
                                    Hiển thi Email
                                </Checkbox>
                            </Form.Item>
                        </div>
                    </div>
                </Card>
                <Card
                    className="container-fluid  mt-4"
                    title={<label>Thông tin cá nhân đăng ký</label>}
                    size="small"
                >
                    <div className="input-group">
                        <div className="input-group">
                            <SelectDateWithLabel
                                name="passport_date"
                                form={props.form}
                                wrapClass="col-md-6 col-lg-2"
                                label="Ngày cấp"
                                isDisabled={true}
                                defaultValue={
                                    passportDate ? moment(passportDate) : null
                                }
                            />
                            <InputWithLabel
                                form={props.form}
                                label="Nơi cấp"
                                name="passport_place"
                                wrapClass="col-md-6 col-lg-2"
                                isDisabled={true}
                            />
                            <InputWithLabel
                                form={props.form}
                                label="MST (nếu có)"
                                name="tax_code"
                                wrapClass="col-md-6 col-lg-2"
                                isDisabled={true}
                            />
                            <InputFileUpload
                                nameFiledSetValue={""}
                                defaultLabel={fileLabelPassport}
                                classWrapped="col-md-6 col-lg-4 text-truncate"
                                label="File CMND/Hộ chiếu"
                                name="passport_file"
                                form={props.form}
                                isDisabled={true}
                                onClickDownloadFile={() => onPreviewFile(1)}
                                extentionsAllow={["pdf", "PDF"]}
                                accept={".pdf"}
                            />
                        </div>
                        <div className="input-group">
                            <InputWithLabel
                                form={props.form}
                                label="Họ tên cá nhân"
                                name="fullname"
                                wrapClass="col-md-6 col-lg-4 "
                                isDisabled={true}
                            />

                            <InputWithLabel
                                form={props.form}
                                label="Số điện thoại"
                                name="phone"
                                wrapClass="col-md-6 col-lg-4"
                                isDisabled={true}
                            />
                            <InputWithLabel
                                form={props.form}
                                label="Email"
                                name="email"
                                wrapClass="col-md-6 col-lg-4"
                                isDisabled={true}
                            />
                        </div>
                        <div className="input-group">
                            {/*<RadioWithLabel*/}
                            {/*    options={{ 1: "Có đăng ký", 2: "Không đăng ký" }}*/}
                            {/*    label="ĐK hỗ trợ khẩn cấp"*/}
                            {/*    name="support_register"*/}
                            {/*    wrappedClass="col-md-6 col-lg-4 radio_register_support"*/}
                            {/*    form={props.form}*/}
                            {/*    isDisabled={true}*/}
                            {/*/>*/}
                            <InputWithLabel
                                form={props.form}
                                label="Địa chỉ thường trú"
                                name="address"
                                wrapClass="col-md-6 col-lg-4"
                                isDisabled={true}
                            />
                            <SelectWithLabel
                                options={province}
                                name="province_code"
                                wrappedClass="col-md-6 col-lg-2"
                                form={props.form}
                                label={"Tỉnh thành"}
                                isDisabled={true}
                                isProvince={true}
                                // onChange={onchange}
                            />
                            <SelectWithLabel
                                options={district}
                                name="district_code"
                                wrappedClass="col-md-6 col-lg-2"
                                form={props.form}
                                label={"Quận huyện"}
                                isDisabled={true}
                            />
                        </div>
                    </div>
                </Card>

                {/*<Card className="container-fluid mt-4" title={<label>Đăng ký sử dụng dịch vụ hỗ trợ trường hợp khẩn cấp (nếu cần) </label>} size="small">*/}
                {/*{props.form.getFieldValue("support_register") === "1" ? (*/}
                {/*    <React.Fragment>*/}
                {/*      <div className="input-group">*/}
                {/*        <div className="label-thong-tin">*/}
                {/*          <label>Đăng ký sử dụng dịch vụ hỗ trợ trường hợp khẩn cấp (nếu cần)</label>*/}
                {/*        </div>*/}
                {/*        <div className="input-group">*/}
                {/*          <InputWithLabel*/}
                {/*              form={props.form}*/}
                {/*              label="Họ tên đầu mối"*/}
                {/*              name="fullname_support"*/}
                {/*              wrapClass="col-md-6 col-lg-3"*/}
                {/*              isDisabled={true}*/}
                {/*          />*/}
                {/*          <InputWithLabel*/}
                {/*              form={props.form}*/}
                {/*              label="Chức vụ"*/}
                {/*              name="position_support"*/}
                {/*              wrapClass="col-md-6 col-lg-3"*/}
                {/*              isDisabled={true}*/}
                {/*          />*/}
                {/*          <InputWithLabel*/}
                {/*              form={props.form}*/}
                {/*              label="Email"*/}
                {/*              name="email_support"*/}
                {/*              wrapClass="col-md-6 col-lg-3"*/}
                {/*              isDisabled={true}*/}
                {/*          />*/}
                {/*          <InputWithLabel*/}
                {/*              form={props.form}*/}
                {/*              label="Số điện thoại"*/}
                {/*              name="phone_support"*/}
                {/*              wrapClass="col-md-6 col-lg-3"*/}
                {/*              isDisabled={true}*/}
                {/*          />*/}

                {/*        </div>*/}
                {/*      </div>*/}
                {/*    </React.Fragment>*/}
                {/*) : (*/}
                {/*    ""*/}
                {/*)}*/}
                {/*</Card>*/}
                <Card
                    className="container-fluid mt-4"
                    title={<label>Mẫu đăng ký</label>}
                    size="small"
                >
                    <div className="input-group">
                        <RadioWithLabel
                            options={{ 1: "Điện tử", 2: "Giấy", 3: "Scan" }}
                            label="Loại hồ sơ"
                            name="type_docurment"
                            wrappedClass="col-md-6 col-lg-3 select-doi-tuong "
                            form={props.form}
                            isRequired={true}
                            isDisabled={true}
                        />
                        {status === STATUS_TU_CHOI ? (
                            ""
                        ) : (
                            <React.Fragment>
                                <div className="col-md-6 col-lg-2 mb-2">
                                    <label className="invisible label-hide">
                                        11111998
                                    </label>
                                    <button
                                        onClick={() => onPreviewFile(4)}
                                        className="btn btn-outline-success form-control"
                                    >
                                        Tải xuống DK-01.02
                                    </button>
                                </div>
                            </React.Fragment>
                        )}
                        {statusReqGen || isFileDk02 ? (
                            <div className="col-md-6 col-lg-2 mb-2">
                                <label className="invisible label-hide">
                                    11111998
                                </label>
                                <button
                                    onClick={() => onPreviewFile(7)}
                                    className="btn btn-outline-primary form-control"
                                >
                                    Tải xuống DK-02
                                </button>
                            </div>
                        ) : (
                            ""
                        )}
                        {isFileDk03 ? (
                            <div className="col-md-6 col-lg-2 mb-2">
                                <label className="invisible label-hide">
                                    11111998
                                </label>
                                <button
                                    onClick={() => onPreviewFile(14)}
                                    className="btn btn-outline-success form-control"
                                >
                                    Tải xuống DK-03
                                </button>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </Card>
                {isViewPenaltyValue ? (
                    <Card
                        className="container-fluid mt-4"
                        title={<label>Số tiền phạt</label>}
                        size="small"
                    >
                        <div className="input-group">
                            <InputWithLabel
                                form={props.form}
                                label="Số tiền phạt khi hủy yêu cầu(VNĐ)"
                                name="penalty_value"
                                wrapClass="col-md-6 col-lg-3"
                                isDisabled={true}
                                defaultValue={valueDestroy}
                            />
                        </div>
                    </Card>
                ) : (
                    <></>
                )}
            </Form>
            <ModalConfirm
                modalVisible={modal.visible_modal}
                handleCancel={onCancelModelDecline}
                onOkModalDecline={onOkModalDecline}
                loading={modal.loading}
                penalty_value={modal.penalty_value}
            />
            <div className="input-group d-flex justify-content-center p-5 mt-4">
                <div className="">
                    {isCheckDestroy ? (
                        <ApproveButton
                            btnText="Hủy yêu cầu"
                            onClick={() => viewModalConfirm()}
                        />
                    ) : (
                        <></>
                    )}
                    <ButtonCancel
                        onClick={() => {
                            props.history.goBack();
                        }}
                        className={"btn btn-default btn-sm"}
                    />
                </div>
            </div>
        </PageWrapper>
    );
};

const WrappedRequestCTSPersonalPreview = Form.create<Props>({
    name: "RequestCTSPersonalPreview",
})(RequestCTSPersonalPreview);

export default WrappedRequestCTSPersonalPreview;
