import {List} from "../../../pages/customer/List";
import {ListRequestGateway} from "../../../pages/request-gateway/ListRequestGateway";
import WrappedChangeInfoOrganization from "../../../pages/customer/ChangeInfoOrganization";
import WrappedChangeInfoPersonal from "../../../pages/customer/ChangeInfoPersonal";
import WrappedPreviewPersonal from "../../../pages/customer/PreviewPersonal";
import WrappedPreviewOrganization from "../../../pages/customer/PreviewOrganization";
import WrappedReNewOrganization from "../../../pages/customer/ReNewOrganization";
import WrappedReNewPersonal from "../../../pages/customer/ReNewPersonal";
import WrappedCreateRequestReNewPersonal from "../../../pages/customer/renew-support-serial/CreateRequestReNewPersonal";
import WrappedCreateRequestWarrantyPersonal
    from "../../../pages/customer/warranty-support-serial/CreateRequestWarrantyPersonal";
import WrappedCreateRequestReNewOrganization
    from "../../../pages/customer/renew-support-serial/CreateRequestReNewOrganization";
import WrappedCreateRequestWarrantyOrganization
    from "../../../pages/customer/warranty-support-serial/CreateRequestWarrantyOrganization";
import WrappedWarrantyOrganization from "../../../pages/customer/WarrantyOrganization";
import WrappedWarrantyPersonal from "../../../pages/customer/WarrantyPersonal";
import WrappedPreviewRequestGateway from "pages/request-gateway/PreviewRequestGateway";

const CUSTOMER_ROUTES = [
    {
        component: List,
        link: "/danh-sach-khach-hang",
        permission: '',
        isExact: true
    },
    {
        component: WrappedChangeInfoPersonal,
        link: "/thay-doi-thong-tin-ca-nhan/:id",
        permission: '',
        isExact: true
    },
    {
        component: WrappedChangeInfoOrganization,
        link: "/thay-doi-thong-tin-to-chuc/:id",
        permission: '',
        isExact: true
    },
    {
        component: WrappedPreviewPersonal,
        link: "/danh-sach-khach-hang/chi-tiet-ca-nhan/:id",
        permission: '',
        isExact: true
    },
    {
        component: WrappedPreviewOrganization,
        link: "/danh-sach-khach-hang/chi-tiet-to-chuc/:id",
        permission: '',
        isExact: true
    },

    {
        component: WrappedReNewOrganization,
        link: "/yeu-cau-cap-lai-thiet-bi-to-chuc/:id",
        permission: '',
        isExact: true
    },
    {
        component: WrappedReNewPersonal,
        link: "/yeu-cau-cap-lai-thiet-bi-ca-nhan/:id",
        permission: '',
        isExact: true
    },
    {
        component: WrappedWarrantyOrganization,
        link: "/yeu-cau-bao-hanh-thiet-bi-to-chuc/:id",
        permission: '',
        isExact: true
    },
    {
        component: WrappedWarrantyPersonal,
        link: "/yeu-cau-bao-hanh-thiet-bi-ca-nhan/:id",
        permission: '',
        isExact: true
    },
    {
        component: WrappedCreateRequestReNewPersonal,
        link: "/them-moi-yeu-cau-cap-lai-thiet-bi-ca-nhan",
        permission: '',
        isExact: true
    },
    {
        component: WrappedCreateRequestWarrantyPersonal,
        link: "/them-moi-yeu-cau-bao-hanh-thiet-bi-ca-nhan",
        permission: '',
        isExact: true
    },
    {
        component: WrappedCreateRequestReNewOrganization,
        link: "/them-moi-yeu-cau-cap-lai-thiet-bi-to-chuc",
        permission: '',
        isExact: true
    },
    {
        component: WrappedCreateRequestWarrantyOrganization,
        link: "/them-moi-yeu-cau-bao-hanh-thiet-bi-to-chuc",
        permission: '',
        isExact: true
    },
    {
        component: ListRequestGateway,
        link: "/yeu-cau-dich-vu-tu-kh",
        permission: '',
        isExact: true
    },
    {
        component: WrappedPreviewRequestGateway,
        link: "/yeu-cau-dich-vu-tu-kh/chi-tiet/:id",
        permission: '',
        isExact: true
    },

];

export default CUSTOMER_ROUTES;
