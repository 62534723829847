import { Card, Form, Tag } from "antd";
import { FormComponentProps } from "antd/lib/form";
import ButtonCancel from "components/common/form/button/ButtonCancel";
import InputWithLabel from "components/common/form/input-with-label/InputWithLabel";
import { onFailAction, onSuccessAction } from "helpers/SwalCommon";
import PageWrapper from "pages/wrapper/PageWrapper";
import React, { useEffect, useState } from "react";
import { match } from "react-router";
import { RequestGatewayServices } from "services/request-gateway/RequestGatewayServices";
import ApproveButton from "components/common/form/button/ApproveButton";
import DenyButton from "components/common/form/button/DenyButton";
import ModalDeny from "components/common/form/ModalDeny";
import {
    DURATION_REQUEST_LABEL_CLASS,
    INIT_MODAL_DENY,
    INIT_SERVICE,
    OBJECT_REQUEST_LABEL_CLASS,
    TYPE_REQUEST_LABEL_CLASS,
} from "./Enum";

interface Props extends FormComponentProps {
    match: match<{ id: string }>;
    history: any;
}

export const PreviewRequestGateway: React.FC<Props> = (props) => {
    const id = props.match.params.id;
    const [status, setStatus] = useState();
    const [infoService, setInfoService] = useState(INIT_SERVICE);
    /**
     * modal deny
     */

    const [modalDeny, setModalDeny] = useState(INIT_MODAL_DENY);

    const fetchRequest = async () => {
        const result = await RequestGatewayServices.find(id);
        props.form.setFieldsValue({
            uid: result.data.request.id.toString(),
            tin: result.data.request.tin,
            token_code: result.data.request.token_code,
            customer_name: result.data.request.customer_name,
            email: result.data.request.email,
            phone: result.data.request.phone,
            name: result.data.request.name,
            reason: result.data.request.reason,
            reason_refusal: result.data.request.reason_refusal,
            serial: result.data.request.serial,
            status: result.data.request.status,
            username: result.data.request.username,
        });
        setStatus(result.data.request.status);
        setInfoService((prevState) => ({
            ...prevState,
            reason: result.data.request.reason,
            object: result.data.request.object_type,
            time: result.data.request.duration,
        }));
    };

    useEffect(() => {
        fetchRequest();
        // eslint-disable-next-line
    }, []);
    const handleModalDenyCancel = () => {
        setModalDeny(INIT_MODAL_DENY);
    };
    const onChangeDataDeny = ({ target: { value } }) => {
        setModalDeny({ ...modalDeny, note: value });
    };
    const confirmDeny = async () => {
        let error_reason = "Lý do xử lý thất bại không được để trống";
        if (modalDeny.note.trim() === "") {
            setModalDeny({ ...modalDeny, error_reason: error_reason });
            return false;
        }
        const data = {
            note: modalDeny.note,
            id: modalDeny.id,
            status: 2,
        };
        const result = await RequestGatewayServices.updateStatus(data);
        setModalDeny(INIT_MODAL_DENY);

        if (result && Number(result.status) === 200) {
            onSuccessAction("Xác nhận yêu cầu thành công!", () =>
                window.location.reload()
            );
        } else if (result && Number(result.status) === 422) {
            onFailAction("Có lỗi xảy ra trong quá trình xử lý!");
        } else {
            onFailAction(result.error);
        }
    };

    const onBusinessApprove = async (id) => {
        const data = {
            status: 3,
            id: id,
            note: null,
        };
        const result = await RequestGatewayServices.updateStatus(data);
        if (result && Number(result.status) === 200) {
            onSuccessAction("Xác nhận xử lý thành công!", () => {
                props.history.goBack();
            });
        } else {
            onFailAction("Có lỗi xảy ra!");
        }
    };

    const onBusinessDeny = async (id) => {
        setModalDeny({ ...modalDeny, visible_modal_deny: true, id: id });
    };

    return (
        <PageWrapper title="Xem chi tiết yêu cầu dịch vụ">
            <ModalDeny
                visible={modalDeny.visible_modal_deny}
                handleCancel={handleModalDenyCancel}
                handleDeny={confirmDeny}
                value={modalDeny.note}
                onChange={onChangeDataDeny}
                error={modalDeny.error_reason}
                placeholder={"Nhập lý do xử lý thất bại"}
                okText={"Xác nhận"}
                title={"Lý do xử lý thất bại"}
            />
            <Form>
                <Card
                    className="m-r-15-i m-l-15-i"
                    title={<label>Thông tin Khách hàng</label>}
                    size="small"
                >
                    {/*line 1*/}
                    <div className="input-group">
                        <InputWithLabel
                            form={props.form}
                            label="Mã Token"
                            name="token_code"
                            wrapClass="col-md-3"
                            maxLength={255}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Mã định danh"
                            name="tin"
                            wrapClass="col-md-3"
                            maxLength={255}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Tên khách hàng"
                            name="customer_name"
                            wrapClass="col-md-6"
                            maxLength={255}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Mã chứng thư số"
                            name="serial"
                            wrapClass="col-md-6"
                            maxLength={255}
                            isDisabled={true}
                        />
                    </div>
                </Card>
                <Card
                    className="m-r-15-i m-l-15-i mt-4"
                    title={<label>Thôn tin liên hệ</label>}
                    size="small"
                >
                    <div className="input-group">
                        <InputWithLabel
                            form={props.form}
                            label="Họ và tên"
                            name="name"
                            wrapClass="col-md-4"
                            isDisabled={true}
                            maxLength={255}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Email"
                            name="email"
                            wrapClass="col-md-4"
                            isDisabled={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Số điện thoại"
                            name="phone"
                            wrapClass="col-md-4"
                            isDisabled={true}
                            maxLength={255}
                        />
                    </div>
                </Card>
                <Card
                    className="m-r-15-i m-l-15-i mt-4"
                    title={<label>Thông tin dịch vụ quan tâm</label>}
                    size="small"
                >
                    <div className="row">
                    <div className="ml-3">
                        <Tag
                            color={
                                infoService && TYPE_REQUEST_LABEL_CLASS[
                                    Number(infoService.reason)
                                ]["class"]
                            }
                        >
                            {
                                infoService && TYPE_REQUEST_LABEL_CLASS[
                                    Number(infoService.reason)
                                ]["label"]
                            }
                        </Tag>
                    </div>

                    <div className="ml-3">
                        <Tag
                            color={
                                infoService && OBJECT_REQUEST_LABEL_CLASS[
                                    Number(infoService.object)
                                ]["class"]
                            }
                        >
                            {
                                infoService && OBJECT_REQUEST_LABEL_CLASS[
                                    Number(infoService.object)
                                ]["label"]
                            }
                        </Tag>
                    </div>

                    <div className="ml-3">
                        <Tag
                            color={
                                infoService && DURATION_REQUEST_LABEL_CLASS[
                                    Number(infoService.time)
                                ]["class"]
                            }
                        >
                            {
                                infoService && DURATION_REQUEST_LABEL_CLASS[
                                    Number(infoService.time)
                                ]["label"]
                            }
                        </Tag>
                    </div>
                    </div>
                </Card>
            </Form>

            <div className="input-group d-flex justify-content-center p-5 mt-4">
                <div className="">
                    {Number(status) === 1 ? (
                        <React.Fragment>
                            <ApproveButton
                                btnText={"Xử lý yêu cầu thành công"}
                                onClick={() => onBusinessApprove(id)}
                            />
                            <DenyButton
                                btnText={"Xử lý yêu cầu thất bại"}
                                onClick={() => onBusinessDeny(id)}
                            />
                        </React.Fragment>
                    ) : (
                        ""
                    )}
                    <ButtonCancel
                        onClick={() => {
                            props.history.goBack();
                        }}
                        className={"btn btn-default btn-sm"}
                    />
                </div>
            </div>
        </PageWrapper>
    );
};

const WrappedPreviewRequestGateway = Form.create<Props>({
    name: "PreviewRequestGateway",
})(PreviewRequestGateway);

export default WrappedPreviewRequestGateway;

