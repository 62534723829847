import React, {Component} from "react";
import {Modal} from 'antd';
import {Input} from 'antd';
import ValidateMessageError from "./validate-message-error/ValidateMessageError";

interface Props {
    handleCancel?: any;
    handleDeny?: any;
    value?: string;
    valueCaptcha?: string;
    visible: boolean;
    loading?: boolean;
    title?: string;
    onChange?: any;
    onChangeValueCaptcha?: any;
    errorCaptcha?: string;
    errorPass?: string;
    fileBase64Captcha?: any;
    isReload?: any;
}

interface State {
    visible: boolean;
}

export default class ModalEnterPinCaptcha extends Component<Props, State> {

    render() {
        return (
            <div>
                <Modal
                    title={this.props.title ? this.props.title : 'Nhập mã pin'}
                    visible={this.props.visible}
                    onOk={this.props.handleDeny}
                    onCancel={this.props.handleCancel}
                    destroyOnClose={true}
                    okText="Đồng ý"
                    cancelText="Đóng"
                    className="modal-lg"
                    width='23%'
                    closable={false}
                    maskClosable={false}
                >
                    <Input
                        placeholder={this.props.title ? this.props.title : "Nhập mã pin"}
                        className={"form-control"}
                        required={true}
                        value={this.props.value}
                        onChange={this.props.onChange}
                        type={"password"}
                    />
                    <ValidateMessageError error={this.props.errorPass}/>
                        <div
                            style={{ display:'flex', alignItems: 'center', padding: '0 25px'}}
                            className={'mt-3 mb-3'}
                        >
                            <div style={{}}>
                                <img
                                    src={`data:application/pdf;base64,${this.props.fileBase64Captcha}`}
                                    height={'60px'}
                                    width={"100%"}
                                    alt={'captcha'}
                                />
                            </div>
                            <div style={{flex: 'auto', padding: '10px', textAlign: 'center'}}>
                                <a
                                    href={"# "}
                                    onClick={this.props.isReload}
                                >
                                    <i className="fas fa-sync-alt" style={{fontSize: '30px'}}/>
                                </a>
                            </div>
                    </div>
                    <Input
                        placeholder={"Nhập mã captcha"}
                        className={"form-control"}
                        required={true}
                        value={this.props.valueCaptcha}
                        onChange={this.props.onChangeValueCaptcha}
                    />
                    <ValidateMessageError error={this.props.errorCaptcha}/>
                </Modal>
            </div>
        );
    }
}
