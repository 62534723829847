import React, { } from "react";
import PageWrapper from "../wrapper/PageWrapper";
import Table from "../../components/common/table/Table";
import { onFailAction, onSuccessAction } from "helpers/SwalCommon";
import { RouteComponentProps } from "react-router";
import {
  formatDateTime,
  reloadPage,
  formatDateNotTime,
} from "helpers/NewCaCrmHelper";
import TableActionButton from "components/common/table/action-button/TableActionButton";
import WrappedRequestCTSPersonalSearchForm from "./search/RequestCTSPersonalSearchForm";
import { RequestDigitalCertificatePersonalServices } from "../../services/request-digital-certificate-personal/RequestDigitalCertificatePersonalServices";
import { STATUS_REQUEST_DIGITAL_CERTIFICATE_LABEL } from "../../helpers/enum/request-digital-certificate/RequestDigitalCertificateEnums";
import {Badge, Icon, Tag, Tooltip} from "antd";
import store from "../../store/store";
import { REQUEST_DIGITAL_PER_CERTIFICATE_PERMISSION} from "../../helpers/enum/PermissionEnums";
interface Props extends RouteComponentProps {
}

export const RequestCTSPersonal: React.FC<Props> = props => {
    const user = store.getState().authReducer;

    const onDeleteRequestDigital = async id => {
        const { location, history } = props;
        try {
            await RequestDigitalCertificatePersonalServices.deleteRequest(id);
            reloadPage(location, history);
            onSuccessAction("Xóa yêu cầu thành công!");
        } catch (error) {
            onFailAction("Có lỗi xảy ra khi xóa yêu cầu!");
        }
    };

    const renderActionButton = (text, record, index) => {
        if (text.created_by === user.id) {
            return (
                <TableActionButton
                    permissionUpdate={REQUEST_DIGITAL_PER_CERTIFICATE_PERMISSION.EDIT}
                    permissionPreview= {REQUEST_DIGITAL_PER_CERTIFICATE_PERMISSION.VIEW}
                    permissionDelete={REQUEST_DIGITAL_PER_CERTIFICATE_PERMISSION.DELETE}
                    onClickUpdate={
                        [1, 3, 7].indexOf(record.status) !== -1
                            ? () => props.history.push(`yeu-cau-cts-ca-nhan/cap-nhat/${record.id}`)
                            : null
                    }
                    onClickDelete={
                        [1, 3, 7].indexOf(record.status) !== -1
                            ? () => onDeleteRequestDigital(record.id)
                            : null
                    }
                    onClickPreviewButton={() =>
                        props.history.push(`yeu-cau-cts-ca-nhan/xem/${record.id}`)
                    }
                />
            );
        }
    };

    const renderStatusColumn = (text, record, index) => {
        if (Number(record.status) === 6 && record.is_expired_gen){
            return (
                <Tooltip title={"Mã token: "+record.token_code} placement="bottomLeft">
                    <Tooltip title={(record.nv_approve_at && record.is_expired_gen) ? record.is_expired_gen : "Yêu cầu chỉ có 15 ngày để gen kể từ ngày duyệt."} placement="topLeft">
                        <Badge count={<Icon type="warning" style={{ color: '#f5222d' }} />}>
                            <Tag color={`${STATUS_REQUEST_DIGITAL_CERTIFICATE_LABEL[text].class}`}>
                                {STATUS_REQUEST_DIGITAL_CERTIFICATE_LABEL[text].label}
                            </Tag>
                        </Badge>
                    </Tooltip>
                </Tooltip>
            );
        }else {
            return (
                <Tooltip title={"Mã token: "+record.token_code} placement="topRight">
                    <Tag color={`${STATUS_REQUEST_DIGITAL_CERTIFICATE_LABEL[text].class}`}>
                        {STATUS_REQUEST_DIGITAL_CERTIFICATE_LABEL[text].label}
                    </Tag>
                </Tooltip>
            );
        }
    };

    const columns = [
      {
        title: "Mã yêu cầu",
        dataIndex: "code",
      },
      {
        title: "Tên khách hàng",
        dataIndex: "requestpersonal.fullname",
      },
      {
        title: "Số CMND/Hộ chiếu",
        dataIndex: "requestpersonal.passport",
      },
      {
        title: "Đối tượng",
        dataIndex: "",
        render: (e) => {
          if (e) {
            if (Number(e.object) === 1) {
              return <Tag color="green">Cấp mới</Tag>;
            } else if (Number(e.object) === 2) {
              return <Tag color="orange">Gia hạn</Tag>;
            } else {
              return <Tag color="red">Chuyển đổi</Tag>;
            }
          }
        },
      },
      {
        title: "Gói dịch vụ",
        dataIndex: "cateservicespackage.name",
      },
      {
        title: "Thời gian tạo",
        dataIndex: "created_at",
        render: (text, record, index) => formatDateTime(text),
      },
      {
        title: "Ngày hiệu lực",
        dataIndex: "info_cert.certificateBegin",
        render: (text, record, index) => formatDateNotTime(text),
      },
      {
        title: "Ngày hết hạn",
        dataIndex: "info_cert.certificateEnd",
        render: (text, record, index) => formatDateNotTime(text),
      },

      {
        title: "Tên tài khoản",
        dataIndex: "",
        render: (e) => {
          return e.user.fullname;
        },
      },
      {
        title: "Trạng thái",
        dataIndex: "status",
        render: renderStatusColumn,
      },
      {
        title: "Mã bảo mật",
        dataIndex: "",
        render: (e) => {
          if (e.created_by === user.id) {
            if (Number(e.status) === 6 && e.secret_code) {
              return e.secret_code;
            }
          }
        },
      },
      {
        title: "Tác vụ",
        render: renderActionButton,
      },
    ];
    const fetch = async (params = {}) => {
        try {
            return await RequestDigitalCertificatePersonalServices.getListIndex(params);
        } catch (error) {
            console.log(error);
            onFailAction();
        }
    };

    return (
        <PageWrapper title="Danh sách yêu cầu chứng thư số cá nhân">
            <WrappedRequestCTSPersonalSearchForm />
            <Table columns={columns} onFetchData={fetch} />
        </PageWrapper>
    );
};
