import React, {Component} from 'react'
import {checkPermission} from "../../../../helpers/NewCaCrmHelper";

interface Props {
    onClick: any,
    btnClass?: string,
    btnText?: string,
    permission?: string;
}

interface State {

}

export default class DenyButton extends Component<Props, State> {
    state = {}

    render() {
        if (
            (this.props.permission && checkPermission(this.props.permission)) ||
            !this.props.permission
        )
            return (
                <button type="button" onClick={this.props.onClick}
                        className={this.props.btnClass || 'btn btn-danger btn-sm mx-1'}>
                    <i className="fa fa-ban mr-1"/>
                    {this.props.btnText || "Từ chối"}
                </button>
            )
    }
}
