import React from "react";
import {Form} from "antd";
import {FormComponentProps} from "antd/lib/form";
import ButtonCreate from "../../../components/common/form/button/ButtonCreate";
import Store from "../../../store/store";
import InputWithLabel from "../../../components/common/form/input-with-label/InputWithLabel";
import SelectWithLabel from "../../../components/common/form/input-with-label/SelectWithLabel";
import SelectDateSearch from "../../../components/common/form/input-with-label/SelectDateSearch";
import ButtonSearch from "../../../components/common/form/button/ButtonSearch";

interface Props extends FormComponentProps {
}

const TYPE_AGENCY = 7;
const TYPE_CONTRIBUTOR = 8;

const RequestChangeInfoSearchForm: React.FC<Props> = props => {
    const userType = Store.getState().authReducer.type;
    return (
        <Form>
            <div className="input-group">
                <InputWithLabel
                    name="code"
                    form={props.form}
                    label=""
                    placeholder="Mã yêu cầu"
                    wrapClass="pl-0 col-md-2"
                />
                <SelectDateSearch
                    name="created_at"
                    form={props.form}
                    wrapClass="pl-0 col-md-2"
                    label=""
                    placeholder="Ngày tạo"
                />
                <SelectWithLabel
                    name='status'
                    options={
                        {
                            1: 'Chờ nghiệp vụ duyệt',
                            2: 'Nghiệp vụ từ chối',
                            3: 'Hoàn thành',
                            4: 'Hủy',
                            5: 'Chờ HTKD duyệt',
                            6: 'HTKD từ chối',
                        }
                    }
                    form={props.form}
                    placeholder="Trạng thái"
                    wrappedClass='pl-0 col-md-2'
                />
                <ButtonSearch data={props.form.getFieldsValue()} className="mt-1 mx-1 bg-primary text-white"/>
                <ButtonCreate
                    permission=""
                    className="mt-1 mx-1 ant-btn bg-success text-white"
                    toUrl={
                        (Number(userType) === TYPE_AGENCY || Number(userType) === TYPE_CONTRIBUTOR)
                            ? "/yeu-cau-thay-doi-thong-tin/dai-ly/them-moi"
                            : "/yeu-cau-thay-doi-thong-tin/them-moi"
                    }
                    text="Thêm mới yêu cầu thay đổi thông tin"
                />
            </div>
        </Form>
    );
};

const RequestChangeInfoSearch = Form.create<Props>({
    name: "WrappedRequestCTSGroupSearchForm"
})(RequestChangeInfoSearchForm);

export default RequestChangeInfoSearch;
