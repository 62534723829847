import { Button, Carousel, Col, Form, Icon, Input, Row } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { History } from "history";
import React, { Component } from "react";
import { match } from "react-router";
import { loading } from "../../components/common/loading/Loading";
import { onFailAction, onSuccessAction } from "../../helpers/SwalCommon";
import { authenticationService } from "../../services/authentication/AuthenticationService";
import image1 from "../login/images/img1.png";
import image2 from "../login/images/img2.png";
import { LOGO } from './Enum';
import logonewca from "./images/logo_newca.png";
interface Props extends FormComponentProps {
  history: History;
  setUser: any;
  onResetPass: boolean;
  handleOpenResetPass: any;
  match: match<{ secret: string }>;
}

interface State {
  message: string;
  isDisplayMessage: boolean;
  email: string;
}

const initialState = {
  message: "",
  isDisplayMessage: false,
  email: "",
};
const images = [
  {
    id: 1,
    src: image1,
  },
  {
    id: 2,
    src: image2,
  },
];

class ChangePassByEmail extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { validateFields, getFieldValue } = this.props.form;
    validateFields(async (err, values) => {
      if (!err) {
        if (
          getFieldValue("newpassword") !== getFieldValue("confirm-newpassword")
        ) {
          onFailAction(
            "Nhập lại mật khẩu không chính xác",
            () => { },
            "error",
            "Error password"
          );
        } else {
          try {
            loading.runLoadingBlockUI();
            const response = await authenticationService.ChangePasswordByEmail(
              this.props.match.params.secret,
              getFieldValue("newpassword")
            );
            if (response.status === 200) {
              onSuccessAction(response.message, () => {
                this.props.history.push("/login");
              });
            } else {
              onFailAction(response.message);
            }
          } catch (error) {
            console.log(error);
            onFailAction();
          } finally {
            loading.stopRunLoading();
          }
        }
      }
    });
  };
  componentWillMount() {
    const link = this.props.match.params.secret;
    const history = this.props.history;
    async function checkChangePasswordPage(link, history) {
      try {
        loading.runLoadingBlockUI();
        const response = await authenticationService.CheckLinkChangePassword(
          link
        );
        if (response.status !== 200) {
          onFailAction(
            response.message,
            () => {
              history.push("/login");
            },
            "error",
            "Link Error"
          );
        }
      } catch (error) {
        console.log(error);
        onFailAction();
      } finally {
        loading.stopRunLoading();
      }
    }
    checkChangePasswordPage(link, history);
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        {/* <nav className="navbar navbar-light bg-light">
          <div className="row">
            <div className="col-md-5">
              <a className="navbar-brand" href="/#">
                <img src={logonewca} width="100%" alt="logo" />
              </a>
            </div>
          </div>
        </nav> */}
        <div className="login">
          <div className="login-wrapper d-flex align-items-center">
            <div className="left-item w-50 h-100">
              <Carousel autoplay>
                {images.map((image) => (
                  <img src={image.src} key={image.id} height={"700px"} alt="" />
                ))}
              </Carousel>
            </div>
            <div className="right-item w-50 px-5 d-flex flex-column justify-content-between h-100">
              <div className="header-right">
                <Row
                  style={{
                    borderBottom: "2px solid #d1d1d1",
                    paddingTop: "32px",
                    paddingBottom: "10px",
                  }}
                >
                  <Col span={12}>
                    <span className="login100-form-title p-b-32 text-center">
                      <div className="text-center mt-1">
                        <img
                          src={logonewca}
                          width="auto"
                          height="48"
                          className="d-inline-block align-center"
                          alt=""
                        />
                      </div>
                    </span>
                  </Col>
                  <Col
                    span={12}
                    className="text-center"
                    style={{ fontSize: "small" }}
                  >
                    <div>
                      <span className="mr-2">Tổng đài hỗ trợ:</span>
                      <b>
                        <Icon type="phone" className="mr-1" />
                        1900.2066
                      </b>
                    </div>
                    <div>
                      <span className="mr-2">Email:</span>
                      <span>
                        <Icon type="mail" className="mr-1" />
                        <a target="_blank" href="https://hotro@fastca.vn" rel="noopener noreferrer">hotro@fastca.vn</a>
                      </span>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="body-right d-flex justify-content-center mt-4">
                <div className="d-flex justify-content-center">
                  <div
                    className="w-50 mt-3 p-3 d-flex justify-content-center"
                    style={{ backgroundColor: "white" }}
                  >
                    <Form className="w-70 " onSubmit={this.handleSubmit}>
                      <div>
                        <span
                          className="txt1 p-b-11"
                          style={{ fontWeight: "bolder" }}
                        >
                          Mật khẩu mới
                        </span>
                        <Form.Item>
                          {getFieldDecorator("newpassword", {
                            rules: [
                              {
                                required: true,
                                message: "Mật khẩu mới không thể bỏ trống!",
                              },
                              {
                                validator: function (rule, value, callback) {
                                  if (value && value.length < 6) {
                                    callback(
                                      "Mật khẩu mới phải lớn hơn 6 ký tự"
                                    );
                                  } else {
                                    callback();
                                  }
                                },
                                message: "Mật khẩu mới phải lớn hơn 6 ký tự",
                              },
                            ],
                            validateTrigger: "onSubmit",
                          })(
                            <Input.Password
                              prefix={
                                <Icon
                                  type="lock"
                                  style={{ color: "rgba(0,0,0,.25)" }}
                                />
                              }
                              placeholder="Nhập mật khẩu mới..."
                              className="form-control input100 mt-3"
                              // type="password"
                              autoComplete="off"
                            />
                          )}
                        </Form.Item>
                      </div>
                      <div>
                        <span
                          className="txt1 p-b-11"
                          style={{ fontWeight: "bolder" }}
                        >
                          Nhập lại mật khẩu
                        </span>
                        <Form.Item>
                          {getFieldDecorator("confirm-newpassword", {
                            rules: [
                              {
                                required: true,
                                message: "Mật khẩu mới không thể bỏ trống!",
                              },
                            ],
                            validateTrigger: "onSubmit",
                          })(
                            <Input.Password
                              prefix={
                                <Icon
                                  type="lock"
                                  style={{ color: "rgba(0,0,0,.25)" }}
                                />
                              }
                              placeholder="Nhập lại mật khẩu..."
                              className="form-control input100 mt-3"
                              // type="password"
                              autoComplete="off"
                            />
                          )}
                        </Form.Item>
                      </div>

                      <div className="d-flex mt-3">
                        <Button
                          htmlType="button"
                          className="login100-form-btn w-50 mb-2 d-inline button-back"
                          onClick={() => {
                            this.props.history.push("/login");
                          }}
                        >
                          <Icon type="arrow-left" /> Quay lại
                        </Button>
                        <Button
                          htmlType="submit"
                          className="login100-form-btn w-50 mb-2 d-inline"
                        >
                          Lưu <Icon type="save" />
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
              <div className="footer-right">
                <div
                  style={{
                    borderBottom: "1px solid #d1d1d1",
                    fontSize: "14px",
                    fontStyle: "italic",
                  }}
                  className="pb-2"
                >
                  Sản phẩm
                </div>
                <div className="d-flex justify-content-between pt-4">
                  {LOGO.map((logo) => (
                    <a key={logo.id} href={logo.href} target="_blank" rel="noopener noreferrer">
                      <img alt="" src={logo.src} width="101" height="16" />
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const WrappedNormalChangePassByEmail = Form.create<Props>({
  name: "ChangePassByEmail",
})(ChangePassByEmail);

export default WrappedNormalChangePassByEmail;
