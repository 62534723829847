import React from "react";
import {Form} from "antd";
import {FormComponentProps} from "antd/lib/form";
import ButtonSearch from "../../../components/common/form/button/ButtonSearch";
import InputWithLabel from "../../../components/common/form/input-with-label/InputWithLabel";
import SelectWithLabel from "../../../components/common/form/input-with-label/SelectWithLabel";

interface Props extends FormComponentProps {
}

const MemberSearchForm: React.FC<Props> = props => {
    return (
        <Form>
            <div className="input-group">
                <InputWithLabel
                    form={props.form}
                    label=""
                    name="text_search"
                    placeholder={"Họ và tên/Tài khoản/SDT"}
                    wrapClass="col-md-2 nopadding-left"
                />
                <SelectWithLabel
                    options={{1: "Mật Khẩu", 2: "Token RA"}}
                    name="login_type"
                    wrappedClass="col-md-2"
                    form={props.form}
                    label={""}
                    isRequired={false}
                    placeholder={"Loại đăng nhập"}
                />
                <SelectWithLabel
                    options={{1: "Admin", 8: "Cộng tác viên", 5: 'Nhân viên kinh doanh'}}
                    name="type"
                    wrappedClass="col-md-2"
                    form={props.form}
                    label={""}
                    isRequired={false}
                    placeholder={"Loại người dùng"}
                />
                <SelectWithLabel
                    options={{0: "Hoạt động", 1: "Tạm dừng", 2: "Không hoạt động"}}
                    name="status"
                    wrappedClass="col-md-2"
                    form={props.form}
                    label={""}
                    isRequired={false}
                    placeholder={"Trạng thái"}
                />
                <div className="form-group col-md-2 mt-1">
                    <ButtonSearch data={props.form.getFieldsValue()}/>
                </div>
            </div>
        </Form>
    );
};

const WrappedMemberSearchForm = Form.create<Props>({
    name: "WrappedMemberSearchForm"
})(MemberSearchForm);

export default WrappedMemberSearchForm;
