import { Modal } from "antd";
import React, { useState } from "react";

interface Props {
    modalVisible: boolean;
    handleCancel?: () => void;
    onOkModalDecline: (value) => void;
    loading: boolean;
    penalty_value?: any;
}

export default function ModalConfirm(props: Props) {
    const [reason] = useState("");

    const onClickOK = () => {
        props.onOkModalDecline(reason);
    };

    return (
        <Modal
            title="Xác nhận hủy yêu cầu"
            visible={props.modalVisible}
            style={{ top: 20 }}
            zIndex={0}
            destroyOnClose={true}
            okText="Gửi yêu cầu hủy"
            className="modal-xl"
            width="auto"
            onOk={onClickOK}
            onCancel={props.handleCancel}
            cancelText="Đóng"
        >
            {"Khi hủy dịch vụ bạn có thể bị phạt " +
                String(props.penalty_value) +
                " VNĐ . Bạn có chắc chắn muốn hủy?"}
        </Modal>
    );
}
