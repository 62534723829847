import React, { Component } from "react";
import LabelInput from "./label/LabelInput";
import { Input, Form } from "antd";
import { WrappedFormUtils, ValidationRule } from "antd/lib/form/Form";
import BaseServices from "../../../../services/base/BaseServices";
import { loading } from "../../loading/Loading";
import _ from "lodash";
import moment from "moment";

interface Props {
  classWrapped: string;
  label: string;
  name: string;
  isRequired?: boolean;
  form: WrappedFormUtils;
  rules?: ValidationRule[];
  defaultLabel?: string;
  isDisabled?: boolean;
  onClickDownloadFile?: any;
  extentionsAllow?: string[];
  accept?: any;
  warning?: boolean;
  note?: boolean;
  isPassport?: boolean;
  nameFiledSetValue: string;
  onChangeSetField?: boolean;
  classFileUpload?: string;
}
interface State {
  labelOnInput: string;
}

export default class InputFileUpload extends Component<Props, State> {
  state = {
    labelOnInput: ""
  };

  onChangeFile = async e => {
    const files = e.target.files[0];
    const { extentionsAllow, form, name, isPassport, nameFiledSetValue } = this.props;

    const fileSize = files.size/1024/1024;

    if (isPassport){
      if(fileSize >6){
        form.setFields({
          [name]: {
            errors: [
              new Error(
                  "File tải lên không thể lớn hơn 6MB"
              )
            ]
          }
        });
        return;
      }
    }else {
      if(fileSize>5.1){
        form.setFields({
          [name]: {
            errors: [
              new Error(
                  "File tải lên không thể lớn hơn 5MB"
              )
            ]
          }
        });
        return;
      }
    }

    if (extentionsAllow) {
      const index = _.findIndex(extentionsAllow, function(o) {
        return _.endsWith(files.name, o);
      });
      if (index === -1) {
        form.setFields({
          [name]: {
            errors: [
              new Error(
                "File tải lên phải là " + extentionsAllow.toString()
              )
            ]
          }
        });
        return;
      }
    }

    const formData = new FormData();
    formData.append("file", files);
    loading.runLoadingBlockUI();
    const fileData = await BaseServices.axiosUpLoadFile(formData);
    form.setFieldsValue({
      [name]: fileData.file
    });
    if (this.props.onChangeSetField){
      form.setFieldsValue({
        [nameFiledSetValue]: fileData.timeExpired ? moment(fileData.timeExpired) : null
      });
    }
    loading.stopRunLoading();
    let fileName = files.name;
    if (fileName.length > 20) {
       fileName = fileName.slice(0, 20) + "..."
    }
    this.setState({
      labelOnInput: fileName
    });
  };

  render() {
    const {
      name,
      isRequired,
      form,
      defaultLabel,
      isDisabled,
      onClickDownloadFile,
    } = this.props;
    let rules = this.props.rules || [];
    const { getFieldDecorator } = form;
    return (
      <div className={`form-group ${this.props.classWrapped}`}>
        <LabelInput
          nameFor={this.props.name}
          label={this.props.label}
          isRequired={this.props.isRequired}
          warning={this.props.warning}
          additionText={
            onClickDownloadFile ? (
              <span
                onClick={onClickDownloadFile}
                className="cursor-pointer btn-link break_input"
              >
                <small>
                  <em> (Xem file)</em>
                </small>
              </span>
            ) : (
              ""
            )
          }
          note={this.props.isPassport ? "  (Dung lượng file 6MB)" :(this.props.note ? ("  (Dung lượng file 5MB)"): (''))}

        />
        <div className={this.props.classFileUpload ? this.props.classFileUpload : "custom-file"}>
          <input
            disabled={isDisabled}
            onChange={this.onChangeFile}
            type="file"
            className="custom-file-input mb-1"
            id="inputGroupFile01"
            aria-describedby="inputGroupFileAddon01"
            name={this.props.name}
            accept={this.props.accept}
          />
          <label className="custom-file-label" htmlFor="inputGroupFile01">
            {this.state.labelOnInput ? this.state.labelOnInput : defaultLabel}
          </label>
          <Form.Item>
            {getFieldDecorator(name, {
              rules: [
                ...rules,
                {
                  required: isRequired,
                  message: `${this.props.label} không thể bỏ trống!`
                }
              ]
            })(<Input hidden={true} type="text" className="ant-input" name={name} />)}
          </Form.Item>
        </div>
      </div>
    );
  }
}
