import React, {useState} from "react";
import PageWrapper from "../wrapper/PageWrapper";
import Table from "../../components/common/table/Table";
import {onFailAction} from "helpers/SwalCommon";
import {RouteComponentProps} from "react-router";
import TableActionButton from "components/common/table/action-button/TableActionButton";
import {CustomerServices} from "../../services/customer/CustomerServices";
import SearchForm from "./search/SearchForm";
import {Tag} from "antd";

interface Props extends RouteComponentProps {
}

export const List: React.FC<Props> = props => {
    const [titleWarning, setTitleWarning] = useState('');
    const [numHoursValidLockToken, setNumHoursValidLockToken] = useState(24);
    const checkWarningAbleRecord = (record) => {
        if (Number(record.number_signed) === 1) {
            setTitleWarning(`Vui lòng ký DK02, sau ${numHoursValidLockToken} tiếng token sẽ tạm khóa!`);
            return true
        }
        return false
    };
    const renderActionButton = (text, record) => {

        return (
            <TableActionButton
                onClickPreviewButton={() =>
                    (record.passport)
                        ? props.history.push(`danh-sach-khach-hang/chi-tiet-ca-nhan/${record.id}`)
                        : props.history.push(`danh-sach-khach-hang/chi-tiet-to-chuc/${record.id}`)
                }
                showWarning={checkWarningAbleRecord(record)}
                titleWarning={titleWarning}
            />
        );
    };
    const columns = [
        {
            title: "Mã khách hàng",
            dataIndex: "uid"
        },
        {
            title: "Tên khách hàng",
            dataIndex: "fullname"
        },
        {
            title: 'Mã định danh',
            dataIndex: "",
            render: (e) => {
                if (e) {
                    if (e.passport) {
                        return <p>{e.passport}</p>;
                    } else {
                        return <p>{e.code}</p>;
                    }
                }
            }
        },
        {
            title: 'Loại khách hàng',
            dataIndex: "",
            render: (e) => {
                if (e) {
                    if (e.passport) {
                        return <Tag color="cyan">Cá nhân</Tag>;
                    } else {
                        return <Tag color="geekblue">Tổ chức</Tag>;
                    }
                }
            }
        },
        {
            title: "Email",
            dataIndex: "email",
        },
        {
            title: "Tác vụ",
            render: renderActionButton
        }
    ];
    const fetch = async (params = {}) => {
        try {
            const result = await CustomerServices.getListIndex(params);
            if (result && Number(result.status) === 200) {
                setNumHoursValidLockToken(result.numb_hours_valid_lock_token_dk02);
                return result
            }
        } catch (error) {
            console.log(error);
            onFailAction();
        }
    };

    return (
        <PageWrapper title="Danh sách khách hàng">
            <SearchForm />
            <Table columns={columns} onFetchData={fetch} />
        </PageWrapper>
    );
};
