import React from "react";
import PageWrapper from "../wrapper/PageWrapper";
import WrappedRequestSearchForm from "../../components/request-token/search/RequestTokenSearchForm";
import Table from "../../components/common/table/Table";
import {onFailAction, onSuccessAction} from "helpers/SwalCommon";
import {RequestTokenService} from "services/request-token/RequestTokenServices";
import {RouteComponentProps} from "react-router";
import {STATUS_CREATE_ACCOUNT, STATUS_REQUEST_TOKEN_LABEL} from "helpers/enum/request-token/RequestTokenEnums";
import {reloadPage} from "helpers/NewCaCrmHelper";
import TableActionButton from "components/common/table/action-button/TableActionButton";
import {Tag} from "antd";
import {AgencyPermission} from "../../helpers/enum/PermissionEnums";

interface Props extends RouteComponentProps {}

export const RequestToken: React.FC<Props> = props => {
  const onDeleteRequestToken = async id => {
    const { location, history } = props;
    try {
      await RequestTokenService.deleteRequest(id);
      reloadPage(location, history);
      onSuccessAction("Xóa đại lý thành công!");
    } catch (error) {
      onFailAction("Có lỗi xảy ra khi xóa đại lý!");
    }
  };
  const onClickUpdateToken = async (id)=> {
    props.history.push(`yeu-cau-token/cap-nhat/${id}`)
  }

  const renderActionButton = (text, record) => {
    return (
      <TableActionButton
        onClickUpdate={
          [1, 3, 6].indexOf(Number(record.status)) !== -1
            ? () => onClickUpdateToken(record.id)
            : null
        }
        onClickDelete={
          [1, 3, 6].indexOf(Number(record.status)) !== -1
            ? () => onDeleteRequestToken(record.id)
            : null
        }
        onClickPreviewButton={() =>
          props.history.push(`yeu-cau-token/xem/${record.id}`)
        }
        permissionDelete={AgencyPermission.DELETE}
        permissionUpdate={AgencyPermission.UPDATE}
      />
    );
  };

  const renderStatusColumn = (text) => {
    return (
      <Tag color={`${STATUS_REQUEST_TOKEN_LABEL[text].class}`}>
        {STATUS_REQUEST_TOKEN_LABEL[text].label}
      </Tag>
    );
  };
  const renderStatusAccountColumn = (text) => {
    return (
        <Tag color={`${STATUS_CREATE_ACCOUNT[text].class}`}>
          {STATUS_CREATE_ACCOUNT[text].label}
        </Tag>
    );
  };
  const renderReceiveTypeColumn = (text) => {
    let string = ""
    switch (text) {
      case 1:
        string = "Nhận tại văn phòng"
        break;
      case 2:
        string = "Nhận qua chuyển phát nhanh"
        break;
      default:
        string = "Nhận qua  Email";
    }
    return string
  }
  const renderReceiveFullnameColumn = (text, row) => {
    if(!text) { return (<div>{ row.acc_full_name }</div>) }
    return text
  }
  const renderFullNameColumn = (text) => {
    if (text.belong_to) {
      if (Number(text.belong_to) === 1) {
        return text.agency.fullname ? text.agency.fullname: "";
      }else if(Number(text.belong_to) === 2) {
        return text.contributor.fullname ? text.contributor.fullname : "";
      }else {
        return "";
      }
    }else {
      return text.agency.fullname ? text.agency.fullname: "";
    }
  }

  const renderTaxCodeColumn = (text) => {
    if (text.belong_to) {
      if (Number(text.belong_to) === 1) {
        return text.agency.tax_code ? text.agency.tax_code: "";
      }else if(Number(text.belong_to) === 2) {
        return text.contributor.passport ? text.contributor.passport : "";
      }else {
        return "";
      }
    }else {
      return text.agency.tax_code ? text.agency.tax_code: "";
    }
  }

  const columns = [
    { title: "Tên đại lý/CTV", dataIndex: "", render: renderFullNameColumn },
    { title: "Mã số thuế/HC/CMND", dataIndex: "", render: renderTaxCodeColumn },
    {
      title: "Hình thức nhận",
      dataIndex: "receive_type",
      render: renderReceiveTypeColumn
    },
    {
      title: "Hình thức thanh toán",
      dataIndex: "type_pay",
      render: (text) =>
        text === 1 ? "Chuyển khoản" : "Tiền mặt"
    },
    {
      title: "Tên người nhận",
      dataIndex: "receive_fullname",
      render: renderReceiveFullnameColumn
    },
    { title: "Ngày tạo", dataIndex: "created_at" },
    { title: "Trạng thái", dataIndex: "status", render: renderStatusColumn },
    {
      title: "Trạng thái tài khoản",
      dataIndex: "status_create_account",
      render: renderStatusAccountColumn
    },
    {
      title: "Tác vụ",
      render: renderActionButton
    }
  ];

  const fetch = async (params = {}) => {
    try {
      return await RequestTokenService.getListIndex(params);
    } catch (error) {
      console.log(error);
      onFailAction();
    }
  };

  return (
    <PageWrapper title="Danh sách yêu cầu tạo tài khoản">
      <WrappedRequestSearchForm />
      <Table columns={columns} onFetchData={fetch} />
    </PageWrapper>
  );
};
