import { setAuthDATA } from "actions/authAction";
import { Carousel, Col, Icon, Row } from "antd";
import { History } from "history";
import React, { Component } from "react";
import { connect } from "react-redux";
import { authenticationService } from "../../services/authentication/AuthenticationService";
import logonewca from "../login/images/logo_newca.png";
import "./css/login.scss";
import "./css/main.css";
import "./css/util.css";
// import { LOGO } from "./Enum";
import LoginForm from "./form/LoginForm";
import ResetPassForm from "./form/SendEmailChangePass";
import {onFailAction} from "../../helpers/SwalCommon";
import {BannerServices} from "../../services/banner/BannerServices";

interface Props {
  history: History;
}

interface reduxProps {
  setUserData: (userData) => {};
}

type combineProps = reduxProps & Props;

interface State { }

class Login extends Component<combineProps, State> {
  constructor(props) {
    super(props);
    if (authenticationService.currentUserValue) {
      this.props.history.push("/");
    }
  }

  state = {
    openResetPass: false,
    banners: [{ id: 0, base64_content: '', link: ''}]
  };
  fetch = async () => {
    try {
      const result = await BannerServices.getBannerActive();
      if  (Number(result.status) === 200 && result.data.length > 0) {
        this.setState({banners: result.data});
      }
    } catch (error) {
      console.log(error);
      onFailAction();
    }
  };
  componentDidMount() {
    this.fetch();
  }
  handleOpenResetPass = () => {
    this.setState({ openResetPass: !this.state.openResetPass });
  };

  render() {
    return (
      <div className="login">
        <div className="login-wrapper d-flex align-items-center">
          <div className="left-item w-50 h-100">
            <Carousel autoplay>
              {this.state.banners.map((banner) =>
                  (<a href={banner.link} target="_blank" rel="noopener noreferrer"  key={banner.id}>
                    <img
                        src={`data:image/jpeg;base64,${banner.base64_content}`}
                        height={"700px"}
                        alt=""
                    />
                  </a>))
              }
            </Carousel>
          </div>
          <div className="right-item w-50 px-5 d-flex flex-column justify-content-between h-100">
            <div className="header-right">
              <Row
                style={{
                  borderBottom: "1px solid #d1d1d1",
                  paddingTop: "32px",
                }}
              >
                <Col span={12}>
                  <span className="login100-form-title p-b-32 text-center">
                    <div className="text-center mt-1">
                      <img
                        src={logonewca}
                        width="auto"
                        height="48"
                        className="d-inline-block align-center"
                        alt=""
                      />
                    </div>
                  </span>
                </Col>
                <Col
                  span={12}
                  className="text-center"
                  style={{ fontSize: "small" }}
                >
                  <div>
                    <span className="mr-2" style={{color: "#b3b3b3"}}>Tổng đài hỗ trợ:</span>
                    <b>
                      <Icon type="phone" className="mr-1" style={{color:"#c61a1d"}} theme="filled"/>
                      1900.2066
                    </b>
                  </div>
                  <div>
                    <span className="mr-2" style={{color: "#b3b3b3"}}>Email:</span>
                    <span>
                      <Icon type="mail" className="mr-1" style={{color:"#c61a1d"}} theme="filled"/>
                      <a href="https://hotro@fastca.vn" style={{textDecoration: "none", color: "unset"}} target="_blank"  rel="noopener noreferrer">hotro@fastca.vn</a>
                    </span>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="body-right d-flex justify-content-center mt-4">
              {!this.state.openResetPass ? (
                <LoginForm
                  setUser={this.props.setUserData}
                  history={this.props.history}
                  onResetPass={this.state.openResetPass}
                  handleOpenResetPass={this.handleOpenResetPass}
                />
              ) : (
                <ResetPassForm
                  setUser={this.props.setUserData}
                  history={this.props.history}
                  onResetPass={this.state.openResetPass}
                  handleOpenResetPass={this.handleOpenResetPass}
                />
              )}
            </div>
            <div className="py-2">
              {/* <div
                style={{
                  borderBottom: "1px solid #d1d1d1",
                  fontSize: "14px",
                  fontStyle: "italic",
                }}
                className="pb-2"
              >
                Sản phẩm
              </div>
              <div className="d-flex justify-content-between pt-2">
                {LOGO.map((logo) => (
                  <a key={logo.id} href={logo.href} target="_blank" rel="noopener noreferrer">
                    <img alt="" src={logo.src} width="101" />
                  </a>
                ))}
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { authReducer } = state;
  return { userData: authReducer };
}

function mapDispatchToProps(dispatch) {
  return {
    setUserData: (userData) => {
      dispatch(setAuthDATA(userData));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
