export enum PERSONAL_TYPE_IDENTITY {
    CMND = 1,
    HC = 2,
    CCCD = 3,
    MBHXH = 4,
}
export class TypePersonal {
    static readonly TYPE_IDENTITY = {
        1: "Chứng minh nhân dân",
        2: "Hộ chiếu",
        3: "Căn cước công dân",
        4: "Mã bảo hiểm xã hội",
    };
}
