import React, {useEffect,useState} from "react";
import {Card, Checkbox, Form} from "antd";
import {FormComponentProps} from "antd/lib/form";
import PageWrapper from "pages/wrapper/PageWrapper";
import ButtonCancel from "components/common/form/button/ButtonCancel";
import InputWithLabel from "components/common/form/input-with-label/InputWithLabel";
import {match} from "react-router";
import { CustomerServices } from '../../services/customer/CustomerServices';
import InputFileUpload from '../../components/common/form/input-with-label/InputFileUpload';
import SelectWithLabel from "../../components/common/form/input-with-label/SelectWithLabel";
import { FILE_TYPE_OPTIONS } from "./Enum";
import {loading as loadingHelper} from "components/common/loading/Loading";
import ButtonOnSave from 'components/common/form/button/ButtonOnSave';
import {onFailAction, onSuccessAction} from "helpers/SwalCommon";
import _ from "lodash";
import {RequestDigitalCertificatePersonalServices} from "../../services/request-digital-certificate-personal/RequestDigitalCertificatePersonalServices";
import {RequestCertificateGroupService} from "../../services/request-certificate-group/RequestCertificateGroupServices";

interface Props extends FormComponentProps {
    match: match<{ id: string }>;
    history: any;
}

export const PreviewOrganization: React.FC<Props> = props => {
    const id = props.match.params.id;
    const [typeChange, setTypeChange] = useState(true);
    const [province, setProvince] = useState({});
    const [district, setDistrict] = useState({});
    const [labelCode, setLabelCode] = useState("Mã số doanh nghiệp");
    const [showEmail, setShowEmail] = useState(false);

    const fetchCustomer = async () => {
        const result = await CustomerServices.getCertToChange(id);
        const province = await RequestCertificateGroupService.getProvince();
        setProvince(_.mapValues(_.keyBy(province, "newtel_code"), "fullname"));
        if(Number(result.data.object) === 4 ){
            const district = await RequestCertificateGroupService.getDistrictByProvince(result.data.request_change_info.province_code);
            setDistrict(_.mapValues(_.keyBy(district, "newtel_district_code"), "fullname"));

            props.form.setFieldsValue({
                uid: result.data.customer_code.toString(),
                identity_type : result.data.request_change_info.identity_type.toString(),
                code: result.data.request_change_info.code,
                fullname: result.data.request_change_info.fullname,
                email: result.data.request_change_info.email,
                address: result.data.request_change_info.address,
                represent_fullname: result.data.request_change_info.represent_fullname,
                province_code: result.data.request_change_info.province_code.toString(),
                district_code: result.data.request_change_info.district_code.toString(),
            });
            if (Number(result.data.request_change_info.identity_type) === 1){
                setLabelCode("Mã số thuế");
            }if (Number(result.data.request_change_info.identity_type) === 2){
                setLabelCode("Mã ngân sách");
            }if (Number(result.data.request_change_info.identity_type) === 3){
                setLabelCode("Số quyết định");
            }if (Number(result.data.request_change_info.identity_type) === 4){
                setLabelCode("Mã bảo hiểm xã hội");
            }if (Number(result.data.request_change_info.identity_type) === 5){
                setLabelCode("Giáy phép đầu tư");
            }
        }else if (Number(result.data.object) === 1 || Number(result.data.object) === 2 || Number(result.data.object) === 3){
            const district = await RequestCertificateGroupService.getDistrictByProvince(result.data.requestcertificate.province_code);
            setDistrict(_.mapValues(_.keyBy(district, "newtel_district_code"), "fullname"));
            props.form.setFieldsValue({
                uid: result.data.customer_code.toString(),
                identity_type : result.data.request_organ.identity_type.toString(),
                code: result.data.request_organ.code,
                fullname: result.data.request_organ.fullname,
                email: result.data.request_organ.email,
                address: result.data.request_organ.address,
                represent_fullname: result.data.request_organ.represent_fullname,
                province_code: result.data.requestcertificate.province_code.toString(),
                district_code: result.data.requestcertificate.district_code.toString(),
            });
            if (Number(result.data.request_organ.identity_type) === 1){
                setLabelCode("Mã số thuế");
            }if (Number(result.data.request_organ.identity_type) === 2){
                setLabelCode("Mã ngân sách");
            }if (Number(result.data.request_organ.identity_type) === 3){
                setLabelCode("Số quyết định");
            }if (Number(result.data.request_organ.identity_type) === 4){
                setLabelCode("Mã bảo hiểm xã hội");
            }if (Number(result.data.request_organ.identity_type) === 5){
                setLabelCode("Giáy phép đầu tư");
            }
        }else {
            const district = await RequestCertificateGroupService.getDistrictByProvince(result.data.request_renew_and_warranty.province_code);
            setDistrict(_.mapValues(_.keyBy(district, "newtel_district_code"), "fullname"));
            props.form.setFieldsValue({
                uid: result.data.customer_code.toString(),
                identity_type : result.data.request_renew_and_warranty.identity_type.toString(),
                code: result.data.request_renew_and_warranty.identity,
                fullname: result.data.request_renew_and_warranty.fullname,
                email: result.data.request_renew_and_warranty.email,
                address: result.data.request_renew_and_warranty.address,
                represent_fullname: result.data.request_renew_and_warranty.represent_fullname,
                province_code: result.data.request_renew_and_warranty.province_code.toString(),
                district_code: result.data.request_renew_and_warranty.district_code.toString(),
            });
            if (Number(result.data.request_renew_and_warranty.identity_type) === 1){
                setLabelCode("Mã số thuế");
            }if (Number(result.data.request_renew_and_warranty.identity_type) === 2){
                setLabelCode("Mã ngân sách");
            }if (Number(result.data.request_renew_and_warranty.identity_type) === 3){
                setLabelCode("Số quyết định");
            }if (Number(result.data.request_renew_and_warranty.identity_type) === 4){
                setLabelCode("Mã bảo hiểm xã hội");
            }if (Number(result.data.request_renew_and_warranty.identity_type) === 5){
                setLabelCode("Giáy phép đầu tư");
            }
        }
    };

    const onChangeContractType = async value =>{
        if (value === "1") {
            setTypeChange(true);
        } else {
            setTypeChange(false);
        }
    };
    const getProvince = async () => {
        const province = await RequestDigitalCertificatePersonalServices.getProvince();
        setProvince(_.mapValues(_.keyBy(province, "newtel_code"), "fullname"));
    };
    const onChangeProvince = async (e) =>{
        if(e){
            const district = await RequestDigitalCertificatePersonalServices.getDistrictByProvince(e);
            setDistrict(_.mapValues(_.keyBy(district, "newtel_district_code"), "fullname"));
        }
    };
    const onChangeIdenType = async (e) => {
        if (e){
            props.form.setFieldsValue({
                code : '',
            });
            if (Number(e) === 1){
                setLabelCode("Mã số thuế");
            }if (Number(e) === 2){
                setLabelCode("Mã ngân sách");
            }if (Number(e) === 3){
                setLabelCode("Số quyết định");
            }if (Number(e) === 4){
                setLabelCode("Mã bảo hiểm xã hội");
            }if (Number(e) === 5){
                setLabelCode("Giấy phép đầu tư");
            }
        }

    };
    useEffect(() => {
        fetchCustomer();
        getProvince();
        // eslint-disable-next-line
    }, []);
    const onDisplayContractFile = async () => {
        // const agencyService = new AgencyService();
        // const valuesForm = this.props.form.getFieldsValue();
        // this.setState({ loading: true, visible: true });
        // try {
        //   let contract = await agencyService.getAgencyContract(valuesForm);
        //   this.setState({
        //     file: contract.data.file
        //   });
        // } catch (error) {
        //   onFailAction("Có lỗi xảy ra khi xem hợp đồng!");
        // } finally {
        //   this.setState({
        //     loading: false
        //   });
        // }
    };
    const update = status => {
        const {validateFields} = props.form;
        validateFields(async (errors, values) => {
            if (!errors) {
                try {
                    loadingHelper.runLoadingBlockUI();
                    const val= {
                        ...values,
                        status,
                        cert_id: id,
                        showEmail,
                        type: 1
                    };
                    const data = await CustomerServices.requestChangeInfoOrganization(val);
                    if (data && Number(data.status) === 422) {
                        onFailAction("Có lỗi xảy ra khi yêu cầu !");
                        _.forOwn(data.error, function (errors, key) {
                            props.form.setFields({
                                [key]: {
                                    errors: [new Error(errors.toString())]
                                }
                            });
                        });
                    } else {
                        onSuccessAction("Yêu cầu thành công", () => {
                            props.history.push("/danh-sach-dieu-chinh-thong-tin");
                        });
                    }
                } catch (error) {
                    console.log(error);
                    onFailAction("Có lỗi xảy ra!");
                } finally {
                    loadingHelper.stopRunLoading();
                }
            }else {
                onFailAction("Bạn chưa điền đủ thông tin!");
            }
        });
    };
    const onChangeCheckBox = e => {
        setShowEmail(e.target.checked)
    }

    return (
        <PageWrapper title="Yêu cầu điều chỉnh thông tin CTS tổ chức">
            <Form>
                <Card className="m-r-15-i m-l-15-i" title={<label>Thông tin điều chỉnh</label>} size="small">
                    <div className="input-group">
                        <InputWithLabel
                            form={props.form}
                            label="Mã khách hàng"
                            name="uid"
                            wrapClass="col-md-3"
                            maxLength={255}
                            isDisabled={true}
                        />
                        <SelectWithLabel
                            options={{
                                1: 'Mã số thuế',
                                2: 'Mã ngân sách',
                                3: 'Số quyết định',
                                4: 'Mã bảo hiểm xã hội',
                                5: 'Giấy phép đầu tư'
                            }}
                            name="identity_type"
                            wrappedClass="col-md-3"
                            form={props.form}
                            label={"Loại định danh"}
                            isRequired={true}
                            onChange={onChangeIdenType}
                        />
                        <InputWithLabel
                            form={props.form}
                            label={labelCode}
                            name="code"
                            wrapClass="col-md-3"
                            isDisabled={false}
                            isRequired={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Người đại diện"
                            name="represent_fullname"
                            wrapClass="col-md-3"
                            isDisabled={false}
                            maxLength={255}
                            isRequired={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Tên khách hàng"
                            name="fullname"
                            wrapClass="col-md-3"
                            maxLength={255}
                            isDisabled={false}
                            isRequired={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Email"
                            name="email"
                            wrapClass="col-md-3"
                            isDisabled={false}
                            isRequired={true}
                        />
                        <InputFileUpload
                            nameFiledSetValue={""}
                            classWrapped="col-md-3 overflow-hidden"
                            classFileUpload="custom-file-nonOverFlow"
                            label="File CMND/HC người đại diện"
                            name="file_deputy_passport"
                            form={props.form}
                            isRequired={true}
                            extentionsAllow={['pdf', 'PDF']}
                            accept={[".pdf", ".PDF"]}
                            note={true}
                        />
                        <InputFileUpload
                            nameFiledSetValue={""}
                            classWrapped="col-md-3 overflow-hidden"
                            classFileUpload="custom-file-nonOverFlow"
                            label="Tải file giấy tờ pháp lý"
                            name="file_legal"
                            form={props.form}
                            isRequired={true}
                            extentionsAllow={['pdf', 'PDF']}
                            accept={[".pdf", ".PDF"]}
                            note={true}
                        />
                        <SelectWithLabel
                            options={province}
                            name="province_code"
                            wrappedClass="col-md-3"
                            form={props.form}
                            label={"Tỉnh thành"}
                            isRequired={true}
                            isProvince={true}
                            onChange={onChangeProvince}
                        />
                        <SelectWithLabel
                            options={district}
                            name="district_code"
                            wrappedClass="col-md-3"
                            form={props.form}
                            label={"Quận huyện"}
                            isRequired={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Địa chỉ"
                            name="address"
                            wrapClass="col-md-6"
                            isDisabled={false}
                            maxLength={500}
                        />
                        <div className={'col-md-6'}>
                            <Form.Item>
                                <Checkbox onChange={onChangeCheckBox}>Hiển thi Email</Checkbox>
                                <span className={'text-danger font-italic'}>
                                ( Chú ý: Nếu chọn Email sẽ hiển thị trong thông tin CTS )
                            </span>
                            </Form.Item>
                        </div>
                    </div>
                </Card>
                <Card className="m-r-15-i m-l-15-i mt-1" title={<label>Thông tin liên hệ</label>} size="small">
                    <div className="input-group">
                        <InputWithLabel
                            form={props.form}
                            label="Họ và tên"
                            name="contact_name"
                            wrapClass="col-md-3"
                            maxLength={255}
                            isDisabled={false}
                            isRequired={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Chức vụ"
                            name="contact_position"
                            wrapClass="col-md-3"
                            maxLength={255}
                            isDisabled={false}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Số điện thoại"
                            name="contact_phone"
                            wrapClass="col-md-3"
                            maxLength={20}
                            minLength={9}
                            isRequired={true}
                            isDisabled={false}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Số di động"
                            name="contact_mobile"
                            wrapClass="col-md-3"
                            maxLength={20}
                            minLength={9}
                            isDisabled={false}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Email"
                            name="contact_email"
                            wrapClass="col-md-3"
                            maxLength={255}
                            isDisabled={false}
                            isRequired={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Fax"
                            name="contact_fax"
                            wrapClass="col-md-3"
                            maxLength={255}
                            isDisabled={false}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Địa chỉ"
                            name="contact_address"
                            wrapClass="col-md-6"
                            maxLength={255}
                            isDisabled={false}
                        />
                    </div>
                </Card>
                <Card className="m-r-15-i m-l-15-i mt-1" title={<label>File điều chỉnh thông tin</label>} size="small">
                    <div className="input-group">
                        <SelectWithLabel
                            options={FILE_TYPE_OPTIONS}
                            form={props.form}
                            label="Loại điều chỉnh"
                            name="change_type"
                            isRequired={true}
                            wrappedClass="col-md-2"
                            defaultValue={""}
                            isDisabled={false}
                            placeholder="Chọn loại điều chỉnh"
                            onChange={onChangeContractType}
                        />
                        {typeChange === true ?
                            <div className="form-group col-md-2">
                                <button
                                    onClick={onDisplayContractFile}
                                    className="btn ml-5 mt-4 btn-primary btn-small"
                                    disabled={!typeChange}
                                    hidden={typeChange}
                                >
                                    DC 01.01
                                </button>
                            </div>
                            :
                            <InputFileUpload
                                nameFiledSetValue={""}
                                classWrapped="col-md-4 overflow-hidden"
                                classFileUpload="custom-file-nonOverFlow"
                                label="File yêu cầu điều chỉnh thông tin"
                                name="file_change_info"
                                form={props.form}
                                isRequired={!typeChange}
                                extentionsAllow={['pdf', 'PDF']}
                                accept={[".pdf", ".PDF"]}
                                isDisabled={typeChange}
                                note={true}
                            />
                        }
                        <InputWithLabel
                            form={props.form}
                            label="Mã CTKM"
                            name="promotion_code"
                            wrapClass="col-md-3"
                            maxLength={255}
                            isDisabled={false}
                        />
                    </div>
                </Card>
            </Form>
            <div className="input-group d-flex justify-content-center p-5 mt-4">
                <div className="">
                    <ButtonOnSave
                        onClick={() => {
                            update(1);
                        }}
                        label="Trình duyệt"
                        className={"btn btn-success btn-sm"}
                    />
                </div>
                <div className="">
                    <ButtonCancel
                        onClick={() => {
                            props.history.goBack();
                        }}
                        className={"btn btn-default btn-sm"}
                    />
                </div>
            </div>
        </PageWrapper>
    );
};

const WrappedPreviewOrganization = Form.create<Props>({
    name: "PreviewOrganization"
})(PreviewOrganization);

export default WrappedPreviewOrganization;
