import React, {useState, useEffect} from "react";
import {Form} from "antd";
import {FormComponentProps} from "antd/lib/form";
import PageWrapper from "pages/wrapper/PageWrapper";
import ButtonCancel from "components/common/form/button/ButtonCancel";
import ButtonOnSave from "components/common/form/button/ButtonOnSave";
import {TextAreaWithLabel} from "components/common/form/input-with-label/TextAreaWithLabel";
import RadioWithLabel from "components/common/form/input-with-label/RadioWithLabel";
import InputWithLabel from "components/common/form/input-with-label/InputWithLabel";
import SelectWithLabel from "components/common/form/input-with-label/SelectWithLabel";
import {AgencyService} from "services/agency/AgencyServices";
import _ from "lodash";
import {RequestTokenService} from "services/request-token/RequestTokenServices";
import {match} from "react-router";
import {onSuccessAction, onFailAction} from "helpers/SwalCommon";
import {loading as loadingHelper} from "components/common/loading/Loading";
import SelectDateWithLabel from "components/common/form/input-with-label/SelectDateWithLabel";
import moment from "moment";
import AntModal from "components/common/modal/AntModal";
import {formatReadBase64, handleDateData, handleFormatString} from '../../helpers/NewCaCrmHelper';
import {Checkbox} from 'antd';
import {UserServices} from "../../services/user/UserServies";

const STATUS_TU_CHOI = 3;
const TYPE_SALE = 5;

interface Props extends FormComponentProps {
    match: match<{ id: string }>;
    history: any;
}

const objectDate = {
    1: 'receive_date',
};

export const RequestTokenUpdate: React.FC<Props> = props => {
    const [userType, setUserType] = useState(0);
    const [agencyDefault, setAgencyDefault] = useState("");
    const [agency, setAgency] = useState([]);
    const [agencyOption, setAgencyOption] = useState({});
    const [contributorDefault, setContributorDefault] = useState([]);
    const [contributor, setContributor] = useState([]);
    const [contributorOption, setContributorOption] = useState({});
    const [passport, setPassport] = useState("");
    const [receiveFullname, setReceiveFullname] = useState("");
    const [address, setAddress] = useState("");
    const [phone, setPhone] = useState("");
    const [loading, setLoading] = useState(true);
    const [receiveDate, setReceiveDate] = useState("");
    const [visibleModal, setVisibleModal] = useState(false);
    const [file, setFile] = useState("");
    const [loadingModal, setLoadingModal] = useState(false);
    const [status, setStatus] = useState(0);
    const [reason, setReason] = useState("");
    const [typeLogin, setTypeLogin] = useState("1");
    const [typeReceive, setTypeReceive] = useState("");
    const [checkBox, setCheckBox] = useState(false);
    const [tokenAdminNum, setTokenAdminNum] = useState("");
    const [boxNum, setBoxNum] = useState("");
    const [envelopeNum, setEnvelopeNum] = useState("");
    const [belongTo, setBelongTo] = useState('1');

    const id = props.match.params.id;

    const fetchAgencyOption = async () => {
        const agencyService = new AgencyService();
        const data = await agencyService.getAgency();
        setAgency(data);
        setAgencyOption(_.mapValues(_.keyBy(data, "id"), "fullname"));
    };

    const getListContributor = async () => {
        const agencyService = new AgencyService();
        const data = await agencyService.getContributor();
        setContributor(data);
        setContributorOption(_.mapValues(_.keyBy(data, "id"), "fullname"));
    };

    const onChangeAgency = async value => {
        const selectdAgency: any = _.find(agency, {id: parseInt(value)});
        props.form.setFieldsValue({tax_code: selectdAgency.tax_code});
    };

    const onChangeContributor = async value => {
        if (value) {
            const selectContributor: any = _.find(contributor, {id: parseInt(value)});
            props.form.setFieldsValue({tax_code: selectContributor.passport });
        }
    };

    const onChangeBeLongTo = e => {
        setBelongTo(e);
        if (Number(e) === 2) {
            getListContributor().then();
        }
        props.form.setFieldsValue({belong_to: e});
        props.form.setFieldsValue({owner_id: ""});
        props.form.setFieldsValue({tax_code: ""});
    };

    const onChangeTypeLogin = e => {
        setTypeLogin(e.target.value)
    }

    const onChangeTypeReceive = e => {
        setTypeReceive(e.target.value)
    }

    const onChangeCheckBox = e => {
        setCheckBox(e.target.checked)
    }

    const onChangeSelectDateReceive = (date) => {
        setReceiveDate(moment(date).format("YYYY-MM-DD"));
    };

    const onChangeUserName = e => {
        const userName = handleFormatString(e.target.value.replace(/ /g,''), 3);
        props.form.setFieldsValue({ acc_user_name : userName });
    }

    const fetchRequestToken = async () => {
        const requestToken = await RequestTokenService.getModelToUpdate(id);
        const data = requestToken.data;

        props.form.setFieldsValue({
            owner_id: data.owner_id.toString(),
            receive_type: data.receive_type ? data.receive_type.toString() : "",
            token_admin_num: data.token_admin_num,
            box_num: data.box_num,
            envelope_num: data.envelope_num,
            receive_fullname: data.receive_fullname,
            type_pay: data.type_pay.toString(),
            token_admin_note: data.token_admin_note,
            acc_full_name: data.acc_full_name,
            acc_user_name: data.acc_user_name,
            acc_email: data.acc_email,
            acc_phone: data.acc_phone,
            acc_type_login: data.acc_type_login ? data.acc_type_login.toString() : "1",
            level_agency: data.level_agency ? data.level_agency.toString() : "",
        });

        if (data.belong_to) {
            if (Number(data.belong_to) === 1) {
                props.form.setFieldsValue({tax_code: data.agency.tax_code, level_agency: data.level_agency.toString()});
                setAgencyDefault(data.agency.id)
            } else if (Number(data.belong_to) === 2) {
                props.form.setFieldsValue({tax_code: data.contributor.passport});
                setContributorDefault(data.contributor.id)
            } else {
                props.form.setFieldsValue({tax_code: ""});
            }
        }else {
            props.form.setFieldsValue({tax_code: data.agency.tax_code, level_agency: data.level_agency.toString()});
            setAgencyDefault(data.agency.id)
        }

        setReason(data.reason);
        setStatus(data.status);
        setPassport(data.receive_passport);
        setReceiveFullname(data.receive_fullname);
        setAddress(data.receive_address);
        setPhone(data.receive_phone);
        setReceiveDate(data.receive_date);
        setLoading(false);
        setTypeLogin(data.acc_type_login);
        setTypeReceive(data.receive_type ? data.receive_type.toString() : "");
        setCheckBox(data.resign_document);
        setTokenAdminNum(data.token_admin_num ? data.token_admin_num.toString() : "");
        setBoxNum(data.box_num ? data.box_num.toString() : "");
        setEnvelopeNum(data.envelope_num ? data.envelope_num.toString() : "");
        setBelongTo(data.belong_to ? data.belong_to.toString() : "");
    };

    useEffect(() => {
        fetchAgencyOption().then();
        getListContributor().then();
        fetchRequestToken().then();
        fetchUser().then();
        // eslint-disable-next-line
    }, []);

    const fetchUser = async () => {
        const user = new UserServices();
        const userInfo = await user.getUserAuth();
        const type = userInfo.data.type;
        setUserType(type);
    }

    const update = status => {
        const {validateFields} = props.form;
        validateFields(async (errors, values) => {
            if (!errors) {
                try {
                    loadingHelper.runLoadingBlockUI();
                    const val = {
                        ...values,
                        isUpdate: true,
                        status
                    };
                    let valuesConvert = val
                    if (val.acc_type_login === '1') {
                        valuesConvert = handleDateData(val, objectDate);
                    }
                    valuesConvert.receive_date = receiveDate;
                    const data = await RequestTokenService.update(id, valuesConvert);
                    if (data && Number(data.status) === 422) {
                        onFailAction("Có lỗi xảy ra khi cập nhật !");
                        _.forOwn(data.error, function (errors, key) {
                            props.form.setFields({
                                [key]: {
                                    errors: [new Error(errors.toString())]
                                }
                            });
                        });
                    } else {
                        onSuccessAction("Lưu yêu cầu thành công", () => {
                            props.history.push("/yeu-cau-token");
                        });
                    }
                } catch (error) {
                    onFailAction("Có lỗi xảy ra khi cập nhật !");
                } finally {
                    loadingHelper.stopRunLoading();
                }
            } else {
                onFailAction("Bạn chưa điền đủ thông tin!");
            }
        });
    };

    const onPreviewFile = () => {
        const {validateFields} = props.form;
        validateFields(async (errors, values) => {
            if (!errors) {
                try {
                    const valuesConvert = handleDateData(values, objectDate);
                    let dataBody = {...valuesConvert, isUpdate: true, receive_date: receiveDate}
                    const data = await RequestTokenService.previewFileCreate(dataBody);
                    if (data && Number(data.status) === 422) {
                        setVisibleModal(false);
                        setLoadingModal(false);
                        _.forOwn(data.error, function (errors, key) {
                            props.form.setFields({
                                [key]: {
                                    errors: [new Error(errors.toString())]
                                }
                            });
                        });
                    } else {
                        setVisibleModal(true);
                        setLoadingModal(true);
                        let contentRead = formatReadBase64(data);
                        setFile(contentRead)
                    }
                } catch (error) {
                    onFailAction("Có lỗi xảy ra khi xem trước file!");
                    setVisibleModal(false);
                } finally {
                    setLoadingModal(false);
                }
            } else {
                onFailAction("Bạn chưa điền đủ thông tin!");
            }
        });
    };

    const onOkModal = () => {
        setVisibleModal(false);
    };

    const {getFieldDecorator} = props.form;

    return (
        <PageWrapper title="Cập nhật yêu cầu tạo tài khoản" loading={loading}>
            <Form>
                {status === STATUS_TU_CHOI ? (
                    <div className="input-group">
                        {" "}
                        <InputWithLabel
                            label="Lý do từ chối"
                            form={props.form}
                            name="reason-deny"
                            isDisabled={true}
                            wrapClass="col-md"
                            labelClass="text-danger"
                            defaultValue={reason}
                        />{" "}
                    </div>
                ) : (
                    ""
                )}
                {
                    (Number(userType) === TYPE_SALE)
                        ? (
                            <div className="input-group">
                                <SelectWithLabel
                                    options={{ 1: "Đại lý", 2: "Cộng tác viên"}}
                                    name="belong_to"
                                    wrappedClass="col-md-2"
                                    form={props.form}
                                    placeholder="Chọn loại hình hợp tác"
                                    label="Loại hình hợp tác"
                                    isRequired={true}
                                    onChange={onChangeBeLongTo}
                                    defaultValue={belongTo}
                                />
                                {
                                    (Number(belongTo) === 1) ? (
                                        <SelectWithLabel
                                            options={agencyOption}
                                            name="owner_id"
                                            wrappedClass="col-md-2"
                                            form={props.form}
                                            placeholder="Chọn đại lý"
                                            label="Tên đại lý"
                                            isRequired={true}
                                            defaultValue={agencyDefault}
                                            onChange={onChangeAgency}
                                        />
                                    ) : (
                                        <SelectWithLabel
                                            options={contributorOption}
                                            name="owner_id"
                                            wrappedClass="col-md-2"
                                            form={props.form}
                                            placeholder="Chọn cộng tác viên"
                                            label="Tên cộng tác viên"
                                            isRequired={true}
                                            defaultValue={contributorDefault}
                                            onChange={onChangeContributor}
                                        />
                                    )
                                }
                                <InputWithLabel
                                    form={props.form}
                                    label={(Number(belongTo) === 1) ? "Mã số thuế" : "Hộ chiếu/CMND"}
                                    name="tax_code"
                                    wrapClass="col-md-2"
                                    isDisabled={true}
                                />
                                <RadioWithLabel
                                    options={{1: "Chuyển khoản", 2: "Tiền mặt"}}
                                    label="Hình thức thanh toán"
                                    name="type_pay"
                                    wrappedClass="col-md-3"
                                    form={props.form}
                                    isRequired={true}
                                />
                                <RadioWithLabel
                                    options={{1: "Token RA", 2: "Mật khẩu"}}
                                    label="Loại đăng nhập"
                                    defaultValue={typeLogin}
                                    name="acc_type_login"
                                    wrappedClass="col-md-3"
                                    form={props.form}
                                    onChange={onChangeTypeLogin}
                                    isRequired={true}
                                />
                            </div>
                        )
                        : (
                            <div className="input-group">
                                <SelectWithLabel
                                    options={agencyOption}
                                    name="owner_id"
                                    wrappedClass="col-md-3"
                                    form={props.form}
                                    placeholder="Chọn đại lý"
                                    label="Tên đại lý"
                                    isRequired={true}
                                    onChange={onChangeAgency}
                                />
                                <InputWithLabel
                                    form={props.form}
                                    label="Mã số thuế"
                                    name="tax_code"
                                    wrapClass="col-md-3"
                                    isDisabled={true}
                                />
                                <RadioWithLabel
                                    options={{1: "Chuyển khoản", 2: "Tiền mặt"}}
                                    label="Hình thức thanh toán"
                                    name="type_pay"
                                    wrappedClass="col-md-3"
                                    form={props.form}
                                    isRequired={true}
                                />
                                <RadioWithLabel
                                    options={{1: "Token RA", 2: "Mật khẩu"}}
                                    label="Loại đăng nhập"
                                    defaultValue={typeLogin}
                                    name="acc_type_login"
                                    wrappedClass="col-md-3"
                                    form={props.form}
                                    onChange={onChangeTypeLogin}
                                    isRequired={true}
                                />
                            </div>
                        )
                }
                <div className="input-group">
                    <InputWithLabel
                        form={props.form}
                        label="Họ và tên"
                        name="acc_full_name"
                        wrapClass="col-md-3"
                        type="text"
                        maxLength={255}
                        isRequired={true}
                    />
                    <InputWithLabel
                        form={props.form}
                        label="Email"
                        name="acc_email"
                        wrapClass="col-md-3"
                        type="text"
                        maxLength={100}
                        isRequired={true}
                        rules={[
                            {
                                type: 'email',
                                message: 'Email không đúng định dạng!',
                            },
                        ]}
                    />
                    <InputWithLabel
                        form={props.form}
                        label="Tên tài khoản"
                        onBlur={onChangeUserName}
                        name="acc_user_name"
                        wrapClass="col-md-2"
                        type="text"
                        maxLength={100}
                        isRequired={true}
                    />
                    <InputWithLabel
                        form={props.form}
                        label="Số điện thoại"
                        name="acc_phone"
                        isRequired={true}
                        wrapClass="col-md-2"
                        type="number"
                    />
                    {
                        (Number(belongTo) === 1)
                        ? (
                            <SelectWithLabel
                                options={{ 1: "Admin", 2: "Cấp 2" }}
                                name="level_agency"
                                wrappedClass="col-md-2"
                                form={props.form}
                                placeholder="Phân quyền"
                                label="Phân quyền"
                                isRequired={true}
                            />
                            )
                        : ("")
                    }
                </div>
                {(Number(typeLogin) === 2 && !checkBox) ? "" :
                    (
                        <div>
                            <div className="input-group">
                                <RadioWithLabel
                                    options={{1: "Tại FastCA", 2: "Chuyển phát nhanh"}}
                                    label="Hình thức nhận"
                                    name="receive_type"
                                    wrappedClass="col-md-3"
                                    form={props.form}
                                    defaultValue={typeReceive}
                                    isRequired={true}
                                    onChange={onChangeTypeReceive}
                                />
                            </div>
                            <div className="input-group">
                                <InputWithLabel
                                    form={props.form}
                                    label="Người nhận"
                                    name="receive_fullname"
                                    isRequired={true}
                                    wrapClass="col-md-3"
                                    type="text"
                                    defaultValue={receiveFullname}
                                />
                                <SelectDateWithLabel
                                    name="receive_date"
                                    form={props.form}
                                    isRequired={true}
                                    wrapClass="col-md-3"
                                    onChange={onChangeSelectDateReceive}
                                    defaultValue={receiveDate && moment(receiveDate)}
                                    label="Ngày nhận"
                                />
                                {typeReceive === "1" ? (
                                    <InputWithLabel
                                        form={props.form}
                                        label="Số CMND/Hộ chiếu"
                                        name="receive_passport"
                                        isRequired={true}
                                        wrapClass="col-md-2"
                                        type="text"
                                        defaultValue={passport}
                                    />
                                ) : (
                                    <React.Fragment>
                                        <InputWithLabel
                                            form={props.form}
                                            label="Địa chỉ nhận chuyển phát"
                                            name="receive_address"
                                            isRequired={true}
                                            wrapClass="col-md-4"
                                            type="text"
                                            defaultValue={address}
                                        />
                                        <InputWithLabel
                                            form={props.form}
                                            label="Số điện thoại người nhân"
                                            name="receive_phone"
                                            isRequired={true}
                                            wrapClass="col-md-2"
                                            type="text"
                                            defaultValue={phone}
                                        />
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                    )}
                <div className="input-group">
                    <div className={'col-md-4'}>
                        <Form.Item>
                            {getFieldDecorator('resign_document', {
                                valuePropName: 'checked',
                                initialValue: checkBox,
                            })(<Checkbox onChange={onChangeCheckBox}>Đăng ký tài liệu bán hàng</Checkbox>)}
                        </Form.Item>
                    </div>
                </div>
                {
                    checkBox ? (
                        <div className="input-group">
                            <InputWithLabel
                                form={props.form}
                                label="Số lượng token"
                                name="token_admin_num"
                                wrapClass="col-md-3"
                                type="number"
                                defaultValue={tokenAdminNum}
                                maxLength={4}
                                isRequired={true}
                            />
                            <InputWithLabel
                                form={props.form}
                                label="Số lượng vỏ hộp"
                                name="box_num"
                                wrapClass="col-md-3"
                                type="number"
                                defaultValue={boxNum}
                                maxLength={4}
                                isRequired={true}
                            />
                            <InputWithLabel
                                form={props.form}
                                label="Số lượng phong bì"
                                name="envelope_num"
                                wrapClass="col-md-3"
                                type="number"
                                defaultValue={envelopeNum}
                                maxLength={4}
                                isRequired={true}
                            />
                        </div>
                    ) : ""
                }
                <div className="input-group">
                    <TextAreaWithLabel
                        form={props.form}
                        label="Ghi chú"
                        name="token_admin_note"
                        wrapClass="col-md"
                        rows={4}
                    />
                </div>
            </Form>
            {(typeLogin === '1' || checkBox) ? (
                <div className="input-group pb-5 pt-2">
                    <div className="col-md-2">
                        <button onClick={onPreviewFile} className="btn btn-primary btn-sm">
                            Mẫu đề xuất
                        </button>

                        <AntModal
                            visible={visibleModal}
                            loading={loadingModal}
                            className="w-75 h-75"
                            bodyStyle={{height: "700px"}}
                            style={{top: "20px"}}
                            onCLickOk={onOkModal}
                        >
                            <iframe
                                title="Quản lý hợp đồng"
                                src={`${file}`}
                                height="100%"
                                width="100%"
                            />
                        </AntModal>
                    </div>
                </div>
            ) : ""}
            <div className="input-group d-flex justify-content-center p-5">
                <ButtonOnSave
                    onClick={() => {
                        update(1);
                    }}
                    label="Lưu nháp"
                    className={"btn btn-primary btn-sm"}
                />
                <ButtonOnSave
                    onClick={() => {
                        update(5);
                    }}
                    label="Trình duyệt"
                    className={"btn btn-success btn-sm"}
                />
                <ButtonCancel
                    onClick={() => {
                        props.history.goBack();
                    }}
                    className={"btn btn-default btn-sm"}
                />
            </div>
        </PageWrapper>
    );
};

const WrappedRequestTokenUpdate = Form.create<Props>({
    name: "RequestTokenUpdate"
})(RequestTokenUpdate);

export default WrappedRequestTokenUpdate;
