import BaseServices from "../base/BaseServices";

const PREFIX_SERVICES = "qlbh/yeu-cau-cap-lai-bao-hanh/";
const PUBLIC_PREFIX = "yeu-cau-cap-lai-bao-hanh/";

const getInfoCustomer = async (data) => {
    return await BaseServices.request(
        PREFIX_SERVICES + "get-info-customer",
        data,
        "GET"
    );
};

const saveRequestRenewAndWarranty = async (data) => {
    return await BaseServices.request(
        PREFIX_SERVICES + "save-request-renew-warranty",
        data,
        "POST"
    );
};
const getListIndex = async (data) => {
    return await BaseServices.request(
        PREFIX_SERVICES + "get-list-index",
        data,
        "POST"
    );
};


//url public
const getFileCustomerSign = async (data) => {
    return await BaseServices.request(
        PUBLIC_PREFIX + "get-file-customer-sign",
        data,
        "POST"
    );
};
const denySign = async (data) => {
    return await BaseServices.request(
        PUBLIC_PREFIX + "deny-sign",
        data,
        "POST"
    );
};
const updateSigned = async (data) => {
    return await BaseServices.request(
        PUBLIC_PREFIX + "update_signed",
        data,
        "POST"
    );
};
const viewDetail = async (data) => {
    return await BaseServices.request(
        PREFIX_SERVICES + "view-detail",
        data,
        "POST"
    );
};
const getTimeServer = async () => {
    return await BaseServices.request(
        PUBLIC_PREFIX + "get-time-server",
        {},
        "GET"
    );
};

//api plugin
const checkPlugin = async () =>{
    return await BaseServices.requestGenCert(
        "gettokenversion",
        {},
        "GET"
    );
};
const getInfoCertIntoken = async (pin) => {
    return await BaseServices.requestGenCert(
        'getcertificateinfo',
        {pin},
        "GET"
    );
};



export const RequestRenewAndWarrantyServices = {
    getInfoCustomer,
    saveRequestRenewAndWarranty,
    getFileCustomerSign,
    denySign,
    updateSigned,
    getListIndex,
    viewDetail,
    checkPlugin,
    getInfoCertIntoken,
    getTimeServer
};
