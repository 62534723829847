import React, {Component} from "react";
import {Modal} from 'antd';
import {Input} from 'antd';
import ValidateMessageError from "../form/validate-message-error/ValidateMessageError";

const {TextArea} = Input;

interface Props {
    handleCancel?: any;
    handleAction?: any;
    value?: string;
    visible: boolean;
    loading?: boolean;
    title?: string;
    onChange?: any;
    error?: string;
    okText?: string;
    cancelText?: string;
    placeholder?: string;
}

interface State {
    visible: boolean;
}

export default class ModalHandel extends Component<Props, State> {

    componentDidMount() {
    }

    render() {

        return (
            <div>
                <Modal
                    title={this.props.title}
                    visible={this.props.visible}
                    onOk={this.props.handleAction}
                    onCancel={this.props.handleCancel}
                    destroyOnClose={true}
                    okText={this.props.okText ? this.props.okText : 'Ok'}
                    cancelText={this.props.cancelText ? this.props.cancelText : 'Đóng'}
                    className="modal-lg"
                    width="auto"
                    closable={false}
                    // bodyStyle={}
                >
                    <TextArea
                        placeholder={this.props.placeholder}
                        autoSize={{minRows: 4, maxRows: 10}}
                        className="form-control"
                        required={true}
                        value={this.props.value}
                        onChange={this.props.onChange}
                    />
                    <ValidateMessageError error={this.props.error}/>
                </Modal>
            </div>
        );
    }
}
