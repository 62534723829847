import {
    DOC_CHANGE_RENEW_WARRANTY_MANAGER_PERMISSION_SALE
} from "../../../helpers/enum/PermissionEnums";
import {DocChangeRenewWarrantyManager} from "../../../pages/doc-change-renew-warranty-manager/DocChangeRenewWarrantyManager";

const DOC_CHANGE_RENEW_WARRANTY_MANAGER_ROUTE = [
    {
        component: DocChangeRenewWarrantyManager,
        link: "/quan-ly-ho-so-thay-doi-thong-tin-cap-lai-bao-hanh",
        permission: DOC_CHANGE_RENEW_WARRANTY_MANAGER_PERMISSION_SALE.LIST,
        isExact: true
    },
];

export default DOC_CHANGE_RENEW_WARRANTY_MANAGER_ROUTE;