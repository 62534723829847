import React, {useEffect, useState} from "react";
import {Card, Form, Tag, Dropdown, Menu, Icon, Tooltip} from "antd";
import {FormComponentProps} from "antd/lib/form";
import PageWrapper from "pages/wrapper/PageWrapper";
import ButtonCancel from "components/common/form/button/ButtonCancel";
import InputWithLabel from "components/common/form/input-with-label/InputWithLabel";
import {match} from "react-router";
import { CustomerServices } from '../../services/customer/CustomerServices';
import { STATUS_CERT_LABEL } from './Enum';
import {Table as AntTable} from "antd";
// import ModalDeny from './../../components/common/form/ModalDeny';
import { onSuccessAction,onFailAction } from 'helpers/SwalCommon';
import moment from "moment";
import {RequestDigitalCertificatePersonalServices} from "../../services/request-digital-certificate-personal/RequestDigitalCertificatePersonalServices";
import _ from "lodash";
import SelectWithLabel from "../../components/common/form/input-with-label/SelectWithLabel";
import ModalCheckBoxReason from "../../components/common/modal/check-box-modal/ModalCheckBoxReason";
import ModalHandel from "../../components/common/modal/HandleModal";
import {isNotEmpty} from "@amcharts/amcharts4/.internal/core/utils/Utils";
import { TYPE_DEVICE } from "helpers/enum/TypeDevice";
// import TableActionButton from "../../components/common/table/action-button/TableActionButton";

interface Props extends FormComponentProps {
    match: match<{ id: string }>;
    history: any;
}

export const PreviewOrganization: React.FC<Props> = props => {
    const id = props.match.params.id;
    const [customerCert, setCustomerCert] = useState([]);
    const [province, setProvince] = useState({});
    const [district, setDistrict] = useState({});
    const [labelCode, setLabelCode] = useState("Mã số doanh nghiệp");
    const [modalDestroy, setModalDestroy] = useState({
        visible_modal_deny : false,
        loading: false,
        title_modal: "",
        id: "",
        type: "",
        reason: "",
        reason_text: "",
        error_reason: "",
        requestToken: "",
        isUSBToken: false,
    });
    const [modalUnLockToken, setModalUnLockToken] = useState({
        visible: false,
        id: "",
        request_token: "",
        value: "",
        title: "Lý do mở khóa",
        error: "",
        okText: "Đồng ý",
        cancelText: "Hủy",
        placeholder: "Lý do mở khóa"
    });

    const fetchRequestPersonal = async () => {
        const result = await CustomerServices.getInfo(id,1);
        const province = await RequestDigitalCertificatePersonalServices.getProvince();
        setProvince(_.mapValues(_.keyBy(province, "newtel_code"), "fullname"));
        const districts = await RequestDigitalCertificatePersonalServices.getDistrictByProvince(result.data.request.province_code);
        setDistrict(_.mapValues(_.keyBy(districts, "newtel_district_code"), "fullname"));
        props.form.setFieldsValue({
            uid: result.data.uid.toString(),
            code: result.data.code,
            type: 'Tổ chức',
            represent_fullname: result.data.represent_fullname,
            represen_passport: result.data.represen_passport,
            represen_passport_place: result.data.represen_passport_place,
            represen_position: result.data.represen_position,
            represen_phone: result.data.represen_phone,
            identity_type: result.data.identity_type.toString(),
            fullname: result.data.fullname,
            email: result.data.email,
            phone: result.data.phone,
            address: result.data.address,
            province_code: result.data.request.province_code.toString(),
            district_code: result.data.request.district_code.toString(),
        });
        if (isNotEmpty(result.data.list_cert_by_user_create) && isNotEmpty(result.data.list_cert_by_user_create[0].request_change_info)) {
            props.form.setFieldsValue({
                identity_type: result.data.list_cert_by_user_create[0].request_change_info.identity_type.toString(),
                fullname: result.data.list_cert_by_user_create[0].request_change_info.fullname,
                email: result.data.list_cert_by_user_create[0].request_change_info.email,
                address: result.data.list_cert_by_user_create[0].request_change_info.address,
                province_code: result.data.list_cert_by_user_create[0].request_change_info.province_code.toString(),
                district_code: result.data.list_cert_by_user_create[0].request_change_info.district_code.toString(),
            });
        }
        setCustomerCert(result.data.list_cert_by_user_create);

        if (Number(result.data.identity_type) === 1){
            setLabelCode("Mã số thuế");
        }if (Number(result.data.identity_type) === 2){
            setLabelCode("Mã ngân sách");
        }if (Number(result.data.identity_type) === 3){
            setLabelCode("Số quyết định");
        }if (Number(result.data.identity_type) === 4){
            setLabelCode("Mã bảo hiểm xã hội");
        }if (Number(result.data.identity_type) === 5){
            setLabelCode("Giáy phép đầu tư");
        }
    };

    useEffect(() => {
        fetchRequestPersonal();
        // eslint-disable-next-line
    }, []);

    const renderRowClass = () => {
        return "table-extra-info";
    };

    const renderStatusColumn = (text, record, index) => {
        return (
            <Tag color={`${STATUS_CERT_LABEL[text].class}`}>
                {STATUS_CERT_LABEL[text].label}
            </Tag>
        );
    };

    const renderActionTypeDevice = (text) => {
        return (
            <Tag color={`${TYPE_DEVICE[text].class}`}>
                {TYPE_DEVICE[text].label}
            </Tag>
        );
    };

    const clickDestroy = (type,id,typeToken, requestToken) => {
        let title_modal = '';
        let typeTokenDefault = false;
        if(type === 1){
            title_modal = 'Lý do hủy chứng thư';
        }else if(type === 2){
            title_modal = 'Lý do thu hồi chứng thư';
        }else if(type === 3){
            title_modal = 'Lý do tạm dừng chứng thư';
        }else if (requestToken === "1"){
            title_modal = 'Lý do khoá token';
        }else if (requestToken === "2"){
            title_modal = 'Lý do mở khoá token';
        }else {
            title_modal = "";
        }

        if (Number(typeToken) === 1) {typeTokenDefault = true}
        if (requestToken === "2"){
            setModalUnLockToken({...modalUnLockToken, visible: true, id: id, request_token: requestToken})
        }else {
            setModalDestroy({...modalDestroy,
                'visible_modal_deny': true,
                'title_modal': title_modal,
                'type': type,
                'error_reason': '',
                'id': id ,
                'isUSBToken': typeTokenDefault,
                'requestToken': requestToken
            });
        }
    };

    const handleModalDenyCancel = () => {
        setModalDestroy({...modalDestroy, visible_modal_deny: false, reason: "", reason_text: ""})
    };

    const onHandleCancelModalUnLockToken = () => {
        setModalUnLockToken({...modalUnLockToken, visible: false, error: '', value: ''})
    };

    const onConfirmActionModalUnLockToken = async () => {
        if (modalUnLockToken.value === "") {
            setModalUnLockToken({...modalUnLockToken, error: "Lý do mở khóa không được bỏ trống !"});
            return false;
        }
        const data = {id: modalUnLockToken.id, reason_text: modalUnLockToken.value, request_token: modalUnLockToken.request_token};
        const result = await CustomerServices.denyRequestCert(data);
        await setModalUnLockToken({...modalUnLockToken, visible: false });

        if (result && Number(result.status) === 200) {
            onSuccessAction("Gửi yêu cầu thành công!", () => {
                window.location.reload();
            });
        } else if (result && Number(result.status) === 422) {
            onFailAction("Có lỗi xảy ra trong quá trình yêu cầu!");
        } else {
            onFailAction(result.error);
        }
    };

    const onChangeModalUnLockToken = (e) => {
        setModalUnLockToken({...modalUnLockToken,  value: e.target.value})
    };

    const confirmDeny = async () => {
        let error_reason = '';
        if(Number(modalDestroy.type) === 1){
            error_reason = 'Lý do hủy chứng thư';
        }else if(Number(modalDestroy.type) === 2){
            error_reason = 'Lý do thu hồi chứng thư';
        }else if(Number(modalDestroy.type) === 3){
            error_reason = 'Lý do tạm dừng chứng thư';
        }else{
            error_reason = 'Lý do khóa token';
        }
        error_reason = error_reason + ' không được trống';
        if (modalDestroy.reason.trim() === "") {
            setModalDestroy({...modalDestroy, 'error_reason': error_reason });
            return false;
        }if (Number(modalDestroy.reason) === 4 && modalDestroy.reason_text.trim() === "") {
            setModalDestroy({...modalDestroy, 'error_reason': error_reason });
            return false;
        }

        const data = {reason:modalDestroy.reason, id:modalDestroy.id, type:modalDestroy.type, reason_text:modalDestroy.reason_text, request_token: modalDestroy.requestToken};

        const result = await CustomerServices.denyRequestCert(data);
        await setModalDestroy({...modalDestroy, 'visible_modal_deny': false });

        if (result && Number(result.status) === 200) {
            onSuccessAction("Gửi yêu cầu thành công!", () => {
                window.location.reload();
            });
        } else if (result && Number(result.status) === 422) {
            onFailAction("Có lỗi xảy ra trong quá trình yêu cầu!");
        } else {
            onFailAction(result.error);
        }
    };

    const onChangeDataDeny = ({ target: { value } }) => {
        const reason = value;
        setModalDestroy({...modalDestroy, reason: reason, error_reason: ""});
    };

    const onChangeDataDenyText = ({ target: { value } }) => {
        let reasonDefault = "";
        if (value.trim !== "") {reasonDefault = '4'}
        setModalDestroy({...modalDestroy, 'reason_text': value, "error_reason":"", 'reason': reasonDefault});
    };

    const menu = (record) => (
        <Menu onClick={(e) => handleMenuClick(e, record)}>
            <Menu.Item key="destroy">
                <Icon type="delete" />
                Hủy chứng thư
            </Menu.Item>
            <Menu.Item key="revoke" >
                <Icon type="undo" />
                Thu hồi chứng thư
            </Menu.Item>
            <Menu.Item key="change-info">
                <Icon type="retweet" />
                Thay đổi thông tin
            </Menu.Item>
            <Menu.Item key="warranty">
                <Icon type="tool" />
                Bảo hành thiết bị
            </Menu.Item>
            <Menu.Item key="renew">
                <Icon type="pushpin" />
                Cấp lại thiết bị
            </Menu.Item>
            {record.tokenmanager && record.tokenmanager.token_status && Number(record.tokenmanager.token_status) === 1 ? (
                    <Menu.Item key="unlock">
                        <Icon type="unlock" />
                        Mở khóa thiết bị
                    </Menu.Item>
                )
                : (
                    <Menu.Item key="lock">
                        <Icon type="lock" />
                        Khóa thiết bị
                    </Menu.Item>
                )}
        </Menu>
    );
    const handleMenuClick = (e, record) =>{
        if (e.key === 'destroy'){
            return clickDestroy(1, record.id, record.type, "")
        }else if(e.key === 'revoke'){
            return clickDestroy(2, record.id, record.type, "")
        }else if(e.key === 'change-info'){
            if (record.customer_type === 2){
                props.history.push(`/thay-doi-thong-tin-ca-nhan/${record.id}`)
            }else if (record.customer_type === 1){
                props.history.push(`/thay-doi-thong-tin-to-chuc/${record.id}`)
            }
        }else if(e.key === 'renew'){
            if (record.customer_type === 2){
                props.history.push(`/yeu-cau-cap-lai-thiet-bi-ca-nhan/${record.id}`)
            }else if (record.customer_type === 1){
                props.history.push(`/yeu-cau-cap-lai-thiet-bi-to-chuc/${record.id}`)
            }
        }else if(e.key === 'warranty'){
            if (record.customer_type === 2){
                props.history.push(`/yeu-cau-bao-hanh-thiet-bi-ca-nhan/${record.id}`)
            }else if (record.customer_type === 1){
                props.history.push(`/yeu-cau-bao-hanh-thiet-bi-to-chuc/${record.id}`)
            }
        }else if (e.key === 'lock'){
            return clickDestroy("", record.id, record.type, "1")
        }else if (e.key === 'unlock'){
            return clickDestroy("", record.id, record.type, "2")
        }

    };

    const renderActionButton = (text, record, index) => {
        return (
            <React.Fragment>
                {Number(record.status) === 1 ? (
                        <div id="components-dropdown-demo-dropdown-button">
                            <Dropdown.Button onClick={e => e.preventDefault()} overlay={menu(record)} >
                                Lựa chọn
                            </Dropdown.Button>
                        </div>
                ): ''
                }
            </React.Fragment>
        );
    };

    const formatDateTime = (date: string) => {
        if (date) {
            return moment(date).format("DD/MM/YYYY");
        } else {
            return "";
        }
    };

    const columns = [
        {
            title: "SubjectDN",
            dataIndex: "subjectDN",
            width: "25%"
        },
        {
            title: "Serial CTS",
            dataIndex: "certificateSerial"
        },
        {
            title: 'Đối tượng',
            dataIndex: "",
            render: (e) => {
                if (e) {
                    if(Number(e.object) === 1){
                        return (<span className="badge badge-success">Cấp mới</span>);
                    }else if(Number(e.object) === 2){
                        return (<span className="badge badge-warning">Gia hạn</span>);
                    }else if(Number(e.object) === 3){
                        return (<span className="badge badge-primary">Chuyển đổi</span>);
                    }else if(Number(e.object) === 4){
                        return (<span className="badge badge-danger">Thay đổi thông tin</span>);
                    }else if(Number(e.object) === 5){
                        return (<span className="badge badge-secondary">Cấp lại</span>);
                    }else{
                        return (<span className="badge badge-info">Bảo hành</span>);
                    }
                }
            }
        },
        {
            title: 'Loại  thiết bị',
            dataIndex: "type",
            render: renderActionTypeDevice
        },
        {
            title: 'Trạng thái thiết bị',
            dataIndex: "",
            render: (e) => {
                if (e) {
                    if (e.tokenmanager){
                        if(e.tokenmanager.token_status && Number(e.tokenmanager.token_status) === 1){
                            return <Tag color="red">Lock</Tag>;
                        }else{
                            return <Tag color="green">UnLock</Tag>;
                        }
                    }else {
                        return <Tag color="green">UnLock</Tag>;
                    }
                }
            }
        },
        {
            title: 'Trạng thái yêu cầu Token',
            dataIndex: "",
            render: (e) => {
                if (e) {
                    if (e.tokenmanager) {
                        if(e.tokenmanager.request_status && Number(e.tokenmanager.request_status) === 2){
                            return (<Tag color="orange">Chờ duyệt khóa</Tag>);
                        }else if(e.tokenmanager.request_status && Number(e.tokenmanager.request_status) === 3){
                            return (
                                <Tooltip title={e.tokenmanager.reason_major_deny_token} placement="topLeft">
                                    <Tag color="red">Nghiệp vụ từ chối</Tag>
                                </Tooltip>
                            );
                        }else if( e.tokenmanager.request_status && Number(e.tokenmanager.request_status) === 4){
                            return (<Tag color="green">Nghiệp vụ đã duyệt</Tag>);
                        }else if(e.tokenmanager.request_status && Number(e.tokenmanager.request_status) === 5){
                            return (<Tag color="cyan">Chờ duyệt mở khóa</Tag>);
                        }else{
                            return (<Tag color="blue">Chưa tạo yêu cầu</Tag>);
                        }
                    }else {
                        return (<Tag color="blue">Chưa tạo yêu cầu</Tag>);
                    }
                }
            }
        },
        {
            title: "Ngày Hiệu lực",
            dataIndex: "certificateBegin",
            render: (text, record, index) => formatDateTime(text)
        },
        {
            title: "Ngày kết thúc",
            dataIndex: "certificateEnd",
            render: (text, record, index) => formatDateTime(text)
        },
        {
            title: "Trạng thái CTS", dataIndex: "status",
            render: renderStatusColumn
        },
        {
            title: "Tác vụ",
            render: renderActionButton,
        }
    ];

    return (
        <PageWrapper title="Xem chi tiết khách hàng tổ chức">
            <Form>
                <Card className="m-r-15-i m-l-15-i" title={<label>Thông tin doanh nghiệp</label>} size="small">
                    {/*line 1*/}
                    <div className="input-group">
                        <InputWithLabel
                            form={props.form}
                            label="Mã khách hàng"
                            name="uid"
                            wrapClass="col-md-3"
                            maxLength={255}
                            isDisabled={true}
                        />
                        <SelectWithLabel
                            options={{
                                1: 'Mã số thuế',
                                2: 'Mã ngân sách',
                                3: 'Số quyết định',
                                4: 'Mã bảo hiểm xã hội',
                                5: 'Giấy phép đầu tư'
                            }}
                            name="identity_type"
                            wrappedClass="col-md-3"
                            form={props.form}
                            label={"Loại định danh"}
                            isRequired={true}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label={labelCode}
                            name="code"
                            wrapClass="col-md-3"
                            maxLength={16}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Loại khách hàng"
                            name="type"
                            wrapClass="col-md-3"
                            isDisabled={true}
                            maxLength={255}
                        />

                    </div>
                    <div className="input-group">
                        <InputWithLabel
                            form={props.form}
                            label="Tên khách hàng"
                            name="fullname"
                            wrapClass="col-md-3"
                            maxLength={255}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Email"
                            name="email"
                            wrapClass="col-md-3"
                            isDisabled={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Số điện thoại"
                            name="phone"
                            wrapClass="col-md-3"
                            isDisabled={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Địa chỉ"
                            name="address"
                            wrapClass="col-md-3"
                            maxLength={255}
                            isDisabled={true}
                        />
                    </div>
                    <div className="input-group">
                        <SelectWithLabel
                            options={province}
                            name="province_code"
                            wrappedClass="col-md-3"
                            form={props.form}
                            label={"Tỉnh thành"}
                            isDisabled={true}
                            isProvince={true}
                        />
                        <SelectWithLabel
                            options={district}
                            name="district_code"
                            wrappedClass="col-md-3"
                            form={props.form}
                            label={"Quận huyện"}
                            isDisabled={true}
                        />
                    </div>
                </Card>
                <Card className="m-r-15-i m-l-15-i mt-4" title={<label>Người đại diện </label>} size="small">
                    <div className="input-group">
                        <InputWithLabel
                            form={props.form}
                            label="Họ và tên"
                            name="represent_fullname"
                            wrapClass="col-md-4"
                            isDisabled={true}
                            maxLength={255}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Số CMND"
                            name="represen_passport"
                            wrapClass="col-md-4"
                            isDisabled={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Nơi cấp"
                            name="represen_passport_place"
                            wrapClass="col-md-4"
                            isDisabled={true}
                            maxLength={255}
                        />
                    </div>
                    <div className="input-group">
                        <InputWithLabel
                            form={props.form}
                            label="Chức vụ"
                            name="represen_position"
                            wrapClass="col-md-4"
                            isDisabled={true}
                            maxLength={255}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Email"
                            name="represen_email"
                            wrapClass="col-md-4"
                            isDisabled={true}
                            maxLength={255}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Số điện thoại"
                            name="represen_phone"
                            wrapClass="col-md-4"
                            isDisabled={true}
                            maxLength={16}
                        />
                    </div>
                </Card>
                <Card className="m-r-15-i m-l-15-i mt-4" title={<label>Danh sách chứng thư số của khách hàng </label>} size="small">
                    <AntTable
                        columns={columns}
                        style={{width: "100%"}}
                        dataSource={customerCert}
                        pagination={false}
                        rowKey={'id'}
                        rowClassName={renderRowClass}
                        bordered
                    />
                </Card>
            </Form>

            <ModalCheckBoxReason
                visible={modalDestroy.visible_modal_deny}
                option={{1: "Mất token", 2: "Bảo hành", 3: "Hỏng", 4: "Khác"}}
                onChange={onChangeDataDeny}
                onChangeText={onChangeDataDenyText}
                okText={'Xác nhận'}
                error={modalDestroy.error_reason}
                title={modalDestroy.title_modal}
                handleCancel={handleModalDenyCancel}
                handleDeny={confirmDeny}
                value={modalDestroy.reason_text}
                isUSBToken={modalDestroy.isUSBToken}
                reason={modalDestroy.reason}
            />
            <ModalHandel
                visible={modalUnLockToken.visible}
                value={modalUnLockToken.value}
                error={modalUnLockToken.error}
                title={modalUnLockToken.title}
                okText={modalUnLockToken.okText}
                placeholder={modalUnLockToken.placeholder}
                handleCancel={onHandleCancelModalUnLockToken}
                handleAction={onConfirmActionModalUnLockToken}
                onChange={onChangeModalUnLockToken}
            />

            <div className="input-group d-flex justify-content-center p-5 mt-4">
                <div className="">
                    <ButtonCancel
                        onClick={() => {
                            props.history.goBack();
                        }}
                        className={"btn btn-default btn-sm"}
                    />
                </div>
            </div>
        </PageWrapper>
    );
};

const WrappedPreviewOrganization = Form.create<Props>({
    name: "PreviewOrganization"
})(PreviewOrganization);

export default WrappedPreviewOrganization;
