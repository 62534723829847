import React from "react";
import {Form} from "antd";
import {FormComponentProps} from "antd/lib/form";
import ButtonSearch from "../../../components/common/form/button/ButtonSearch";
import InputWithLabel from "../../../components/common/form/input-with-label/InputWithLabel";
import SelectWithLabel from "../../../components/common/form/input-with-label/SelectWithLabel";
import SelectDateWithLabel from "../../../components/common/form/input-with-label/SelectDateWithLabel";

interface Props extends FormComponentProps {
}

const DocChangeRenewWarrantyManagerSearchForm: React.FC<Props> = props => {
    return (
        <Form>
            <div className="input-group">
                <InputWithLabel
                    form={props.form}
                    label=""
                    name="uid"
                    placeholder={"Mã định danh"}
                    wrapClass="col-md-2 nopadding-left"
                />
                <InputWithLabel
                    form={props.form}
                    label=""
                    name="fullname"
                    placeholder={"Tên khách hàng"}
                    wrapClass="col-md-2 nopadding-left"
                />
                <SelectDateWithLabel
                    form={props.form}
                    label={""}
                    name="created_at"
                    placeholder="Ngày tạo"
                    wrapClass="col-md-2 nopadding-left"
                    isRequired={false}
                    isDisabled={false}
                />
                <SelectWithLabel
                    options={{1: "Tổ chức", 2: "Cá nhân"}}
                    name="object"
                    wrappedClass="col-md-2 nopadding-left"
                    form={props.form}
                    label={""}
                    isRequired={false}
                    placeholder="Đối tượng"
                />
                <SelectWithLabel
                    options={{4: "Thay đổi thông tin", 5: "Cấp lại", 6: "Bảo hành"}}
                    name="type_request"
                    wrappedClass="col-md-2 nopadding-left"
                    form={props.form}
                    label={""}
                    isRequired={false}
                    placeholder="Loại yêu cầu"
                />
                <SelectWithLabel
                    options={{
                        1: "Chờ xác nhận",
                        2: "Hợp lệ",
                        3: "Không hợp lệ",
                        4: "Chờ xác nhận yêu cầu cập nhật hồ sơ",
                        5: "Chờ phê duyệt hồ sơ"
                    }}
                    name="file_status"
                    wrappedClass="col-md-2 nopadding-left"
                    form={props.form}
                    label={""}
                    isRequired={false}
                    placeholder="Trạng thái hồ sơ"
                />
                <div className="col-md-2 nopadding-left">
                    <ButtonSearch data={props.form.getFieldsValue()}/>
                </div>
            </div>
        </Form>
    );
};

const WrappedDocLegalManagerSearchForm = Form.create<Props>({
    name: "WrappedDocLegalManagerSearchForm"
})(DocChangeRenewWarrantyManagerSearchForm);

export default WrappedDocLegalManagerSearchForm;
