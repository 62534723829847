import React, {Component} from 'react'

interface Props {
    onClick: any,
    btnClass?: string,
    btnText?: string,
}

interface State {

}

export default class ApproveButton extends Component<Props, State> {
    state = {}

    render() {
            return (
                <button type="button" onClick={this.props.onClick}
                        className={this.props.btnClass || 'btn btn-success btn-sm mx-1'}>
                    <i className="fa fa-save mr-1"/>
                    {this.props.btnText || "Trình Duyệt"}
                </button>
            )
    }
}
