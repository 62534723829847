import React, { useEffect, useState } from "react";
import { Card, Checkbox, Form } from "antd";
import { FormComponentProps } from "antd/lib/form";
import PageWrapper from "pages/wrapper/PageWrapper";
import ButtonCancel from "components/common/form/button/ButtonCancel";
import InputWithLabel from "components/common/form/input-with-label/InputWithLabel";
import SelectWithLabel from "../../../components/common/form/input-with-label/SelectWithLabel";
import { FILE_TYPE_OPTIONS } from "../Enum";
import { loading as loadingHelper } from "components/common/loading/Loading";
import ButtonOnSave from "components/common/form/button/ButtonOnSave";
import { onFailAction, onSuccessAction } from "helpers/SwalCommon";
import _ from "lodash";
import { RequestDigitalCertificatePersonalServices } from "../../../services/request-digital-certificate-personal/RequestDigitalCertificatePersonalServices";
import { RequestCertificateGroupService } from "../../../services/request-certificate-group/RequestCertificateGroupServices";
import RadioWithLabel from "../../../components/common/form/input-with-label/RadioWithLabel";
import { TextAreaWithLabel } from "../../../components/common/form/input-with-label/TextAreaWithLabel";
import SearchButton from "../../../components/common/form/button/SearchButton";
import { RequestRenewAndWarrantySupportSerialService } from "../../../services/request-renew-warranty-support-serial/RequestRenewAndWarrantySupportSerialService";
import InputFileUpload from "../../../components/common/form/input-with-label/InputFileUpload";

interface Props extends FormComponentProps {
    history: any;
}

export const CreateRequestWarrantyOrganization: React.FC<Props> = (props) => {
    /**
     * Defined
     */
    const TYPE_REQUEST_WARRANTY = 2;
    const TYPE_REQUEST_ORGANIZATION = 1;
    const OBJECT_TYPE_NEW = 1;
    const OBJECT_TYPE_EXTEND = 2;
    const OBJECT_TYPE_CONVERT = 3;
    const OBJECT_TYPE_CHANGE_INFO = 4;

    /**
     * States
     */
    const [province, setProvince] = useState({});
    const [district, setDistrict] = useState({});
    const [labelCode, setLabelCode] = useState("Mã số doanh nghiệp");
    const [showEmail, setShowEmail] = useState(false);
    const [serial, setSerial] = useState("");
    const [signManual, setSignManual] = useState(false);

    /**
     * Functions
     */
    const onChangeTypeSign = async (e) => {
        props.form.setFieldsValue({
            type_customer_sign: e,
        });
        if (Number(e) === 1) {
            setSignManual(false);
        }
        if (Number(e) === 2) {
            setSignManual(true);
        }
    };
    const onChangeProvince = async (e) => {
        if (e) {
            const district =
                await RequestDigitalCertificatePersonalServices.getDistrictByProvince(
                    e
                );
            setDistrict(
                _.mapValues(
                    _.keyBy(district, "newtel_district_code"),
                    "fullname"
                )
            );
        }
        props.form.setFieldsValue({
            district_code: undefined,
        });
    };
    const changeReason = async (e) => {
        props.form.setFieldsValue({
            reason_renew_warranty_type: e.target.value,
        });
    };
    const onChangeCheckBox = (e) => {
        setShowEmail(e.target.checked);
    };
    const storeRequest = () => {
        const { validateFields } = props.form;
        validateFields(async (errors, values) => {
            if (!errors) {
                try {
                    loadingHelper.runLoadingBlockUI();
                    const val = {
                        ...values,
                        showEmail,
                        type: TYPE_REQUEST_ORGANIZATION,
                        type_request: TYPE_REQUEST_WARRANTY,
                    };
                    const data =
                        await RequestRenewAndWarrantySupportSerialService.requestReNewAndWarrantySerial(
                            val
                        );
                    if (data && Number(data.status) === 422) {
                        onFailAction("Có lỗi xảy ra khi yêu cầu !");
                        _.forOwn(data.error, function (errors, key) {
                            props.form.setFields({
                                [key]: {
                                    errors: [new Error(errors.toString())],
                                },
                            });
                        });
                    } else {
                        onSuccessAction("Yêu cầu thành công", () => {
                            props.history.push(
                                "/danh-sach-yeu-cau-cap-lai-bao-hanh"
                            );
                        });
                    }
                } catch (error) {
                    console.log(error);
                    onFailAction("Có lỗi xảy ra!");
                } finally {
                    loadingHelper.stopRunLoading();
                }
            } else {
                onFailAction("Bạn chưa điền đủ thông tin!");
            }
        });
    };
    const onChangeSerial = (e) => {
        let serialFormat = e.target.value.replace(/\s/g, "").toUpperCase();
        setSerial(serialFormat);
    };

    const onBlurSerial = (e) => {
        let serialFormat = e.target.value.replace(/\s/g, "").toUpperCase();
        props.form.setFieldsValue({
            serial_search: serialFormat,
        });
    };
    const getCertBySerial = async () => {
        const result =
            await RequestRenewAndWarrantySupportSerialService.getCertBySerial({
                serial: serial,
                customerType: TYPE_REQUEST_ORGANIZATION,
            });
        if (Number(result.status) === 200) {
            if  (Number(result.typeCert) === 1){
                const province = await RequestCertificateGroupService.getProvince();
                setProvince(
                    _.mapValues(_.keyBy(province, "newtel_code"), "fullname")
                );

                if (
                    Number(result.data.object) === OBJECT_TYPE_NEW ||
                    Number(result.data.object) === OBJECT_TYPE_EXTEND ||
                    Number(result.data.object) === OBJECT_TYPE_CONVERT
                ) {
                    const district =
                        await RequestCertificateGroupService.getDistrictByProvince(
                            result.data.requestcertificate.province_code
                        );
                    setDistrict(
                        _.mapValues(
                            _.keyBy(district, "newtel_district_code"),
                            "fullname"
                        )
                    );
                    if (Number(result.data.request_organ.identity_type) === 1) {
                        setLabelCode("Mã số thuế");
                    }
                    if (Number(result.data.request_organ.identity_type) === 2) {
                        setLabelCode("Mã ngân sách");
                    }
                    if (Number(result.data.request_organ.identity_type) === 3) {
                        setLabelCode("Số quyết định");
                    }
                    if (Number(result.data.request_organ.identity_type) === 4) {
                        setLabelCode("Mã bảo hiểm xã hội");
                    }
                    if (Number(result.data.request_organ.identity_type) === 5) {
                        setLabelCode("Giáy phép đầu tư");
                    }
                    props.form.setFieldsValue({
                        uid: result.data.customer_code.toString(),
                        identity_type:
                            result.data.request_organ.identity_type.toString(),
                        identity: result.data.request_organ.code,
                        token_code: result.data.tokenmanager
                            ? result.data.tokenmanager.code
                            : "",
                        fullname: result.data.request_organ.fullname,
                        address: result.data.request_organ.address,
                        province_code:
                            result.data.requestcertificate.province_code.toString(),
                        district_code:
                            result.data.requestcertificate.district_code.toString(),
                    });
                } else if (Number(result.data.object) === OBJECT_TYPE_CHANGE_INFO) {
                    const district =
                        await RequestCertificateGroupService.getDistrictByProvince(
                            result.data.request_change_info.province_code
                        );
                    setDistrict(
                        _.mapValues(
                            _.keyBy(district, "newtel_district_code"),
                            "fullname"
                        )
                    );
                    if (
                        Number(result.data.request_change_info.identity_type) === 1
                    ) {
                        setLabelCode("Mã số thuế");
                    }
                    if (
                        Number(result.data.request_change_info.identity_type) === 2
                    ) {
                        setLabelCode("Mã ngân sách");
                    }
                    if (
                        Number(result.data.request_change_info.identity_type) === 3
                    ) {
                        setLabelCode("Số quyết định");
                    }
                    if (
                        Number(result.data.request_change_info.identity_type) === 4
                    ) {
                        setLabelCode("Mã bảo hiểm xã hội");
                    }
                    if (
                        Number(result.data.request_change_info.identity_type) === 5
                    ) {
                        setLabelCode("Giáy phép đầu tư");
                    }
                    props.form.setFieldsValue({
                        uid: result.data.customer_code.toString(),
                        identity_type:
                            result.data.request_change_info.identity_type.toString(),
                        identity: result.data.request_change_info.code,
                        token_code: result.data.tokenmanager
                            ? result.data.tokenmanager.code
                            : "",
                        fullname: result.data.request_change_info.fullname,
                        address: result.data.request_change_info.address,
                        province_code:
                            result.data.request_change_info.province_code.toString(),
                        district_code:
                            result.data.request_change_info.district_code.toString(),
                    });
                } else {
                    const district =
                        await RequestCertificateGroupService.getDistrictByProvince(
                            result.data.request_renew_and_warranty.province_code
                        );
                    setDistrict(
                        _.mapValues(
                            _.keyBy(district, "newtel_district_code"),
                            "fullname"
                        )
                    );
                    if (
                        Number(
                            result.data.request_renew_and_warranty.identity_type
                        ) === 1
                    ) {
                        setLabelCode("Mã số thuế");
                    }
                    if (
                        Number(
                            result.data.request_renew_and_warranty.identity_type
                        ) === 2
                    ) {
                        setLabelCode("Mã ngân sách");
                    }
                    if (
                        Number(
                            result.data.request_renew_and_warranty.identity_type
                        ) === 3
                    ) {
                        setLabelCode("Số quyết định");
                    }
                    if (
                        Number(
                            result.data.request_renew_and_warranty.identity_type
                        ) === 4
                    ) {
                        setLabelCode("Mã bảo hiểm xã hội");
                    }
                    if (
                        Number(
                            result.data.request_renew_and_warranty.identity_type
                        ) === 5
                    ) {
                        setLabelCode("Giáy phép đầu tư");
                    }
                    props.form.setFieldsValue({
                        uid: result.data.customer_code.toString(),
                        identity_type:
                            result.data.request_renew_and_warranty.identity_type.toString(),
                        identity: result.data.request_renew_and_warranty.identity,
                        fullname: result.data.request_renew_and_warranty.fullname,
                        address: result.data.request_renew_and_warranty.address,
                        token_code: result.data.tokenmanager
                            ? result.data.tokenmanager.code
                            : "",
                        province_code:
                            result.data.request_renew_and_warranty.province_code.toString(),
                        district_code:
                            result.data.request_renew_and_warranty.district_code.toString(),
                    });
                }
            }else  {
                props.form.setFieldsValue({
                    uid: result.data.uid.toString(),
                    identity_type: '1',
                    identity: result.data.uid,
                    fullname: result.data.compensation_request ? result.data.compensation_request.owner : '',
                    token_code: result.data.token_code,
                    contact_name: result.data.compensation_request ? result.data.compensation_request.contact_name : '',
                    contact_phone: result.data.compensation_request ? result.data.compensation_request.contact_phone : '',
                    contact_email: result.data.compensation_request ? result.data.compensation_request.contact_email : '',
                    contact_position: result.data.compensation_request ? result.data.compensation_request.contact_position : '',
                    contact_address: result.data.compensation_request ? result.data.compensation_request.contact_address : '',
                });
                setLabelCode("Mã số thuế");
            }
        } else {
            onFailAction(result.message);
            props.form.setFieldsValue({
                uid: "",
                identity_type: "",
                identity: "",
                token_code: "",
                fullname: "",
            });
        }
    };

    /**
     * Fetch data
     */
    useEffect(() => {
        getProvince();
        // eslint-disable-next-line
    }, []);
    const getProvince = async () => {
        const province =
            await RequestDigitalCertificatePersonalServices.getProvince();
        setProvince(_.mapValues(_.keyBy(province, "newtel_code"), "fullname"));
    };

    return (
        <PageWrapper title="Yêu cầu bảo hành thiết bị (Tổ chức)">
            <Form>
                <Card
                    className="m-r-15-i m-l-15-i"
                    title={<label>Tìm kiếm theo serial chứng thư số</label>}
                    size="small"
                >
                    <div className="input-group">
                        <InputWithLabel
                            onChange={onChangeSerial}
                            onBlur={onBlurSerial}
                            form={props.form}
                            label="Serial"
                            name="serial_search"
                            wrapClass="col-md-3"
                            maxLength={255}
                            isDisabled={false}
                        />
                        <SearchButton
                            btnClass="bg-primary text-white form-group ml-1 mt-4"
                            onClick={getCertBySerial}
                        />
                    </div>
                </Card>
                <Card
                    className="m-r-15-i m-l-15-i mt-1"
                    title={<label>Thông tin khách hàng</label>}
                    size="small"
                >
                    <div className="input-group">
                        <InputWithLabel
                            form={props.form}
                            label="Mã khách hàng"
                            name="uid"
                            wrapClass="col-md-3"
                            maxLength={255}
                            isDisabled={true}
                            isRequired={true}
                        />
                        <SelectWithLabel
                            options={{
                                1: "Mã số thuế",
                                2: "Mã ngân sách",
                                3: "Số quyết định",
                                4: "Mã bảo hiểm xã hội",
                                5: "Giấy phép đầu tư",
                            }}
                            name="identity_type"
                            wrappedClass="col-md-3"
                            form={props.form}
                            label={"Loại định danh"}
                            isDisabled={true}
                            isRequired={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label={labelCode}
                            name="identity"
                            wrapClass="col-md-3"
                            isDisabled={true}
                            isRequired={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Mã thiết bị"
                            name="token_code"
                            wrapClass="col-md-3"
                            isDisabled={true}
                            isRequired={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Tên khách hàng"
                            name="fullname"
                            wrapClass="col-md-6"
                            isDisabled={true}
                            isRequired={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Email"
                            name="email"
                            wrapClass="col-md-3"
                            isDisabled={false}
                            isRequired={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Số điện thoại"
                            name="phone"
                            wrapClass="col-md-3"
                            isDisabled={false}
                            isRequired={true}
                        />
                    </div>
                    <div className="input-group">
                        <SelectWithLabel
                            options={province}
                            name="province_code"
                            wrappedClass="col-md-3"
                            form={props.form}
                            label={"Tỉnh thành"}
                            onChange={onChangeProvince}
                            isRequired={true}
                            isProvince={true}
                        />
                        <SelectWithLabel
                            options={district}
                            name="district_code"
                            wrappedClass="col-md-3"
                            form={props.form}
                            label={"Quận huyện"}
                            isRequired={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Địa chỉ"
                            name="address"
                            wrapClass="col-md-6"
                            maxLength={255}
                            isRequired={true}
                        />
                    </div>
                    <div className="input-group">
                        <div className={"col-md-12"}>
                            <Form.Item>
                                <Checkbox onChange={onChangeCheckBox}>
                                    Hiển thị Email
                                </Checkbox>
                                <span className={"text-danger font-italic"}>
                                    ( Chú ý: Nếu chọn Email sẽ hiển thị trong
                                    thông tin CTS )
                                </span>
                            </Form.Item>
                        </div>
                    </div>
                </Card>
                <Card
                    className="m-r-15-i m-l-15-i mt-1"
                    title={<label>Thông tin liên hệ</label>}
                    size="small"
                >
                    <div className="input-group">
                        <InputWithLabel
                            form={props.form}
                            label="Họ và tên"
                            name="contact_name"
                            wrapClass="col-md-3"
                            maxLength={255}
                            isDisabled={false}
                            isRequired={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Chức vụ"
                            name="contact_position"
                            wrapClass="col-md-3"
                            maxLength={255}
                            isDisabled={false}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Số điện thoại"
                            name="contact_phone"
                            wrapClass="col-md-3"
                            maxLength={20}
                            minLength={9}
                            isDisabled={false}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Email"
                            name="contact_email"
                            wrapClass="col-md-3"
                            maxLength={255}
                            isDisabled={false}
                            isRequired={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Địa chỉ"
                            name="contact_address"
                            wrapClass="col-md-12"
                            maxLength={255}
                            isDisabled={false}
                        />
                    </div>
                </Card>
                <Card
                    className="m-r-15-i m-l-15-i mt-1"
                    title={<label>Thông tin bảo hành</label>}
                    size="small"
                >
                    <div className="input-group">
                        <RadioWithLabel
                            options={{ 2: "Hỏng", 3: "Khác" }}
                            label="Lý do cấp lại"
                            name="reason_renew_warranty_type"
                            wrappedClass="col-md-3"
                            form={props.form}
                            isRequired={true}
                            onChange={changeReason}
                        />
                        <SelectWithLabel
                            options={FILE_TYPE_OPTIONS}
                            form={props.form}
                            label="Phương thức ký"
                            name="type_customer_sign"
                            isRequired={true}
                            wrappedClass="col-md-2"
                            defaultValue={""}
                            isDisabled={false}
                            placeholder="Chọn phương thức ký"
                            onChange={onChangeTypeSign}
                        />
                        {signManual ? (
                            <InputFileUpload
                                nameFiledSetValue={""}
                                classWrapped="col-md-4 overflow-hidden"
                                classFileUpload="custom-file-nonOverFlow"
                                label="File yêu cầu cấp lại"
                                name="file"
                                form={props.form}
                                isRequired={signManual}
                                extentionsAllow={["pdf", "PDF"]}
                                accept={[".pdf", ".PDF"]}
                                isDisabled={!signManual}
                                note={signManual}
                            />
                        ) : (
                            ""
                        )}
                        <InputWithLabel
                            form={props.form}
                            label="Mã CTKM"
                            name="promotion_code"
                            wrapClass="col-md-3"
                            maxLength={255}
                            isDisabled={false}
                        />
                    </div>
                    {Number(
                        props.form.getFieldValue("reason_renew_warranty_type")
                    ) === 3 ? (
                        <div className="input-group">
                            <TextAreaWithLabel
                                label={"Lý do khác"}
                                name="reason_extend"
                                rows={0}
                                form={props.form}
                                wrapClass={"col-md-5"}
                                isRequired={
                                    Number(
                                        props.form.getFieldValue(
                                            "reason_renew_warranty_type"
                                        )
                                    ) === 3
                                }
                            />
                        </div>
                    ) : (
                        ""
                    )}
                </Card>
            </Form>
            <div className="input-group d-flex justify-content-center p-5 mt-4">
                <div className="">
                    <ButtonOnSave
                        onClick={() => {
                            storeRequest();
                        }}
                        label="Trình duyệt"
                        className={"btn btn-success btn-sm"}
                    />
                </div>
                <div className="">
                    <ButtonCancel
                        onClick={() => {
                            props.history.goBack();
                        }}
                        className={"btn btn-default btn-sm"}
                    />
                </div>
            </div>
        </PageWrapper>
    );
};

const WrappedCreateRequestWarrantyOrganization = Form.create<Props>({
    name: "CreateRequestWarrantyOrganization",
})(CreateRequestWarrantyOrganization);

export default WrappedCreateRequestWarrantyOrganization;
