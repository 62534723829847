import React, { useState } from "react";
import { match } from "react-router";
import Form, { FormComponentProps } from "antd/lib/form";
import {
  loading,
  loading as loadingHelper,
} from "../../components/common/loading/Loading";
import { onFailAction, onSuccessAction } from "../../helpers/SwalCommon";
import axios from "axios";
import { SignRevokeDestroyPauseServices } from "../../services/sign-file-revoke-destroy-pause/SignRevokeDestroyPauseServices";
import { formatReadBase64, randomString } from "helpers/NewCaCrmHelper";
import ModalEnterPinCaptcha from "../../components/common/form/ModalEnterPinCaptcha";
import { ReSignDk02Services } from "../../services/re-sign-dk02/ReSignDk02Services";
import BaseServices from "../../services/base/BaseServices";
import ModalUploadFile from "../../components/common/modal/upload-file/ModalUploadFile";
import { SignChangeInfoCertServices } from "../../services/sign-file-change-info-certificate/SignFileChangeInfoCertServices";
import { Button } from "antd";

interface Props extends FormComponentProps {
  match: match<{ secret: string }>;
  history: any;
  form: any;
}

const SignChangeInfoCertPage: React.FC<Props> = (props) => {
  const initialStateModalPin = {
    visible_modal_password: false,
    loading: false,
    title_modal: "",
    base64: "",
    type_file: "",
    password: "",
    captcha: "",
    error_captcha: "",
    error_pass: "",
    fileBase64Captcha: "",
    captchaString: "",
  };
  const initialStateModalImport = {
    visible_modal_deny: false,
    loading: false,
    text_deny: "",
    error_text_deny: "",
  };

  const dataSign = {
    fieldName: "SignatureB [002]",
    typeSign: "1",
    page: "4",
    px: "86",
    py: "61",
    pw: "180",
    ph: "10",
  };
  const secret = props.match.params.secret;

  const [file, setFile] = useState("");
  const [fileSign, setFileSign] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [canSign, setCanSign] = useState(false);
  const [modalPin, setModalPin] = useState(initialStateModalPin);
  const [modalImport, setModalImport] = useState(initialStateModalImport);
  const [codeUi, setCodeUi] = useState("");
  const [fileName, setFileName] = useState("");

  //Modal captcha
  const clearStateModalPin = () => {
    setModalPin({ ...initialStateModalPin });
  };
  const submitPin = async () => {
    const codeUi = randomString();
    const result = await getCaptcha(codeUi);
    setCodeUi(codeUi);
    setModalPin({
      ...modalPin,
      visible_modal_password: true,
      fileBase64Captcha: result.base64Img,
      password: "",
      captcha: "",
      error_captcha: "",
      error_pass: "",
    });
  };
  const reLoadCaptcha = async () => {
    const codeUi = randomString();
    const result = await getCaptcha(codeUi);
    setCodeUi(codeUi);
    setModalPin({
      ...modalPin,
      visible_modal_password: true,
      fileBase64Captcha: result.base64Img,
      captcha: "",
      error_captcha: "",
      error_pass: "",
    });
  };
  const onChangeDataPin = ({ target: { value } }) => {
    setModalPin({ ...modalPin, password: value });
  };
  const onChangeDataCaptcha = ({ target: { value } }) => {
    setModalPin({ ...modalPin, captcha: value });
  };
  const getCaptcha = async (codeUi) => {
    loadingHelper.runLoadingBlockUI();
    const result = await ReSignDk02Services.getCaptcha(codeUi);
    loadingHelper.stopRunLoading();
    return result;
  };
  const handleModalConnectCancel = () => {
    clearStateModalPin();
  };
  const confirmPin = async () => {
    if (modalPin.password.trim() === "") {
      setModalPin({
        ...modalPin,
        error_captcha: "",
        error_pass: "Mã xác thực không được bỏ trống.",
      });
      return false;
    }
    if (modalPin.captcha.trim() === "") {
      setModalPin({
        ...modalPin,
        error_pass: "",
        error_captcha: "Mã captcha không được bỏ trống.",
      });
      return false;
    }

    const check = await getFileSign();
    loading.runLoadingBlockUI();
    if (check.status === 200) {
      setConfirm(true);
      await setModalPin({ ...modalPin, visible_modal_password: false });
    } else if (check.status === 4001) {
      setModalPin({
        ...modalPin,
        error_pass: "",
        error_captcha: check.message,
      });
      setTimeout(() => {
        reLoadCaptcha();
      }, 1000 * 2);
    } else {
      setConfirm(false);
      onFailAction(check.message);
      clearStateModalPin();
    }
    loading.stopRunLoading();
  };
  const getFileSign = async () => {
    loadingHelper.runLoadingBlockUI();
    const data = {
      pass: modalPin.password,
      captcha: modalPin.captcha,
      codeUi: codeUi,
      secret: secret,
    };
    const result = await SignChangeInfoCertServices.getFileChangeInfoCert(data);
    if (result && result.status === 200) {
      let contentRead = formatReadBase64(result.data);
      setFile(contentRead);
      setFileSign(result.data);
      if (result.number_signed !== 2 && result.status_request === 1) {
        setCanSign(true);
      }
    }
    loadingHelper.stopRunLoading();
    return result;
  };

  //Modal import IMG
  const clearStateModalImport = () => {
    setModalImport({ ...initialStateModalImport });
  };
  const confirmImport = async () => {
    if (fileName.trim() === "") {
      setModalImport({
        ...modalImport,
        error_text_deny: "Bạn chưa tải file ảnh lên!",
      });
      return false;
    }
    const data = {
      secret: secret,
      FileDataSigned: fileName,
      typeSignRevoke: 1, // 1: upload File |else: signed by token
    };
    updateSignedContract(data).then();
  };
  const onChangeDataDeny = ({ target: { value } }) => {
    setModalImport({ ...modalImport, text_deny: value });
  };
  const onChangeFile = async (e) => {
    const files = e.target.files[0];
    if (files) {
      setModalImport({ ...modalImport, error_text_deny: "" });
      const fileSize = files.size / 1024 / 1024;
      if (fileSize > 5.1) {
        setModalImport({
          ...modalImport,
          error_text_deny: "File tải lên không thể lớn hơn 5MB",
        });
        return false;
      } else {
        const formData = new FormData();
        formData.append("file", files);
        loading.runLoadingBlockUI();
        const fileData = await BaseServices.axiosUpLoadFile(formData);
        if (fileData) {
          setFileName(fileData.file);
        }
        loading.stopRunLoading();
      }
    } else {
      setFileName("");
    }
  };
  const importClick = async () => {
    setModalImport({ ...modalImport, visible_modal_deny: true });
  };
  const handleModalImportCancel = () => {
    clearStateModalImport();
    setFileName("");
  };

  // @ts-ignore
  const onSignClick = async () => {
    let cert = await getCertificate();
    if (!cert.data) {
      onFailAction("Không tìm thấy chứng thư số");
      return;
    }
    const time = await SignRevokeDestroyPauseServices.getTimeServer();
    let allDataSign = {
      ...dataSign,
      CertSerial: cert.data,
      FileData: fileSign,
      signDate: time,
    };
    onSign(allDataSign);
  };
  const onDenyClick = async () => {
    const dataSend = {
      secret: secret,
    };
    const deny = await SignChangeInfoCertServices.denySignRevoke(dataSend);
    if (deny.status === 200) {
      onSuccessAction(deny.message);
      window.location.reload();
    } else {
      onFailAction(deny.message);
    }
  };
  const onSign = (allDataSign) => {
    axios
      .post(`http://localhost:6706/api/sign/signpdf`, allDataSign)
      .then((response) => {
        const data = response.data;
        if (!data.FileDataSigned) {
          onFailAction("Có lỗi xảy ra trong quá trình ký!");
          return;
        }
        updateSignedContract({ ...data, secret: secret }).then();
      })
      .catch((error) => {
        onFailAction(error);
      });
  };
  const updateSignedContract = async (data) => {
    const result = await SignChangeInfoCertServices.updateFileRevokeSigned(
      data
    );
    if (result && result.status === 200) {
      let textMessage = "Ký thành công!";
      if (data.typeSignRevoke && data.typeSignRevoke === 1) {
        textMessage = "Tải file ký chứng thư số thành công!";
      }
      onSuccessAction(textMessage, () => {
        window.location.reload();
      });
    } else {
      onFailAction("Có lỗi xảy ra !");
    }
  };
  const getCertificate = async () => {
    loadingHelper.runLoadingBlockUI();
    const dataSend = {
      secret: secret,
    };
    const result = await SignChangeInfoCertServices.getCertificate(dataSend);
    loadingHelper.stopRunLoading();
    return result;
  };

  const { REACT_APP_BASE_API_URL } = window["runConfig"];

  const downloadPlugin = () => {
    try {
      const link = `${REACT_APP_BASE_API_URL}gen-cts-customer/get-file-plugin`;
      window.open(link);
    } catch (error) {
      onFailAction("Có lỗi xảy ra!");
    }
  };

  return (
    <div>
      <nav className="navbar navbar-light bg-light">
        <div className="row">
          <div className="col-md-5">
            <a className="navbar-brand" href="/#">
              <img
                src={"/images/logo2.png"}
                width="auto"
                height="40"
                alt="logo"
              />
            </a>
          </div>
          <div className="position-center">
            <Button
              key="link"
              type="link"
              onClick={downloadPlugin}
              title="Vui lòng tải plugin trước khi ký (Nếu chưa có plugin)"
            >
              <span>Tải Plugin ký GCN (Tại đây)</span>
            </Button>
          </div>
        </div>
      </nav>
      <div className="container mt-5">
        <div className="card">
          <div className="card-header text-center text-uppercase">
            <h5>Đề nghị Điều chỉnh thông tin chứng thư số (DC-01)</h5>
          </div>
          <div className="card-body">
            <div className="col-md-12 text-center mt-3">
              <Form>
                <ModalEnterPinCaptcha
                  visible={modalPin.visible_modal_password}
                  handleCancel={handleModalConnectCancel}
                  handleDeny={confirmPin}
                  value={modalPin.password}
                  valueCaptcha={modalPin.captcha}
                  onChange={onChangeDataPin}
                  onChangeValueCaptcha={onChangeDataCaptcha}
                  errorPass={modalPin.error_pass}
                  errorCaptcha={modalPin.error_captcha}
                  fileBase64Captcha={modalPin.fileBase64Captcha}
                  title={"Nhập mã xác thực"}
                  isReload={reLoadCaptcha}
                />
                <ModalUploadFile
                  title={"Tải file ký thu hồi"}
                  label="Tải file ký thu hồi (Chỉ chấp nhận file ảnh và pdf)"
                  visible={modalImport.visible_modal_deny}
                  handleCancel={handleModalImportCancel}
                  handleImport={confirmImport}
                  value={modalImport.text_deny}
                  onChange={onChangeDataDeny}
                  error={modalImport.error_text_deny}
                  onchangeFileUpload={onChangeFile}
                  filename={fileName}
                  extentionsAllow={[
                    "pdf",
                    "PDF",
                    "png",
                    "PNG",
                    "jpg",
                    "jpeg",
                    "JPG",
                    "JPEG",
                  ]}
                  accept={".pdf,.png,.jpg,.jpeg"}
                />

                {confirm ? (
                  <iframe
                    title="Biên bản thanh lý"
                    src={`${file}`}
                    height="900px"
                    width="90%"
                  />
                ) : (
                  ""
                )}
              </Form>
            </div>

            {!confirm ? (
              <div className="text-center">
                <button className="btn btn-success" onClick={submitPin}>
                  <i className="fas fa-file-signature" />
                  Xác thực
                </button>
              </div>
            ) : (
              ""
            )}

            {canSign ? (
              <React.Fragment>
                <div className="input-group d-flex justify-content-center p-5">
                  <div className="text-center">
                    <button
                      className="btn btn-primary mr-3"
                      onClick={importClick}
                    >
                      <i className="fas fa-file-signature" /> upLoad file
                    </button>
                  </div>

                  <div className="text-center">
                    <button
                      className="btn btn-success mr-3"
                      onClick={onSignClick}
                    >
                      <i className="fas fa-file-signature" /> Ký file xác nhận
                    </button>
                  </div>

                  <div className="text-center">
                    <button className="btn btn-danger" onClick={onDenyClick}>
                      <i className="fas fa-file-signature" />
                      Từ chối ký
                    </button>
                  </div>
                </div>
              </React.Fragment>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignChangeInfoCertPage;
