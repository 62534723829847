import WrappedReNewAndWarrantyDetail from "../../../pages/request-renew-and-warranty/RequestRenewAndWarrantyDetail";


const REQUEST_RENEW_AND_WARRANTY_ROUTES = [
    {
        component: WrappedReNewAndWarrantyDetail,
        link: "/yeu-cau-cap-lai-bao-hanh/chi-tiet/:id",
        permission: "",
        isExact: true
    }
];

export default REQUEST_RENEW_AND_WARRANTY_ROUTES;
