import React, { Component } from "react";

interface Props {
  onClick?: any;
  icon?: any;
  style?: any;
  title?: String;
}
interface State {}

export default class ButtonTemplate extends Component<Props, State> {
  state = {};

  render() {
    return (
      <button type={"button"} className={this.props.style ? this.props.style : 'btn btn-primary mr-1 ml-1 font-btn ant-btn bg-primary text-white'} onClick={this.props.onClick ? this.props.onClick  : null}>
        <i className={this.props.icon ? this.props.icon : 'fas fa-search mr-1'}/> {this.props.title ? this.props.title : 'Tìm kiếm'}
      </button>
    );
  }
}
