import React, { useEffect, useState } from "react";
import { Card, Checkbox, Form } from "antd";
import { FormComponentProps } from "antd/lib/form";
import PageWrapper from "pages/wrapper/PageWrapper";
import ButtonCancel from "components/common/form/button/ButtonCancel";
import InputWithLabel from "components/common/form/input-with-label/InputWithLabel";
import InputFileUpload from "../../components/common/form/input-with-label/InputFileUpload";
import SelectWithLabel from "../../components/common/form/input-with-label/SelectWithLabel";
import { FILE_TYPE_OPTIONS } from "./Enum";
import { loading as loadingHelper } from "components/common/loading/Loading";
import ButtonOnSave from "components/common/form/button/ButtonOnSave";
import RadioWithLabel from "components/common/form/input-with-label/RadioWithLabel";
import { onFailAction, onSuccessAction } from "helpers/SwalCommon";
import _ from "lodash";
import { RequestDigitalCertificatePersonalServices } from "../../services/request-digital-certificate-personal/RequestDigitalCertificatePersonalServices";
import SearchButton from "../../components/common/form/button/SearchButton";
import { RequestChangeInfoSupportSerialService } from "../../services/request-change-info-support-serial/RequestChangeInfoSupportSerialService";
import { PERSONAL_TYPE_IDENTITY, TypePersonal } from "helpers/enum/ServiceEnum";

interface Props extends FormComponentProps {
    history: any;
}

export const CreateReqPersonal: React.FC<Props> = (props) => {
    /**
     * Defined
     */
    const [serial, setSerial] = useState("");
    const TYPE_REQUEST_PERSONAL = 2;

    /**
     * States
     */
    const [typeChange, setTypeChange] = useState(true);
    const [province, setProvince] = useState({});
    const [district, setDistrict] = useState({});
    const [labelCode, setLabelCode] = useState("Chứng minh nhân dân");
    const [showEmail, setShowEmail] = useState(false);

    /**
     * Functions
     */
    const onChangeContractType = async (value) => {
        if (value === "1") {
            setTypeChange(true);
        } else {
            setTypeChange(false);
        }
    };
    const getProvince = async () => {
        const province =
            await RequestDigitalCertificatePersonalServices.getProvince();
        setProvince(_.mapValues(_.keyBy(province, "newtel_code"), "fullname"));
    };
    const onChangeProvince = async (e) => {
        if (e) {
            const district =
                await RequestDigitalCertificatePersonalServices.getDistrictByProvince(
                    e
                );
            setDistrict(
                _.mapValues(
                    _.keyBy(district, "newtel_district_code"),
                    "fullname"
                )
            );
        }
    };
    const onChangeIdentityType = async (e) => {
        if (e) {
            props.form.setFieldsValue({
                code: "",
            });
            switch (Number(e)) {
                case PERSONAL_TYPE_IDENTITY.CMND:
                    setLabelCode(
                        TypePersonal.TYPE_IDENTITY[PERSONAL_TYPE_IDENTITY.CMND]
                    );
                    break;
                case PERSONAL_TYPE_IDENTITY.HC:
                    setLabelCode(
                        TypePersonal.TYPE_IDENTITY[PERSONAL_TYPE_IDENTITY.HC]
                    );
                    break;
                case PERSONAL_TYPE_IDENTITY.CCCD:
                    setLabelCode(
                        TypePersonal.TYPE_IDENTITY[PERSONAL_TYPE_IDENTITY.CCCD]
                    );
                    break;
                case PERSONAL_TYPE_IDENTITY.MBHXH:
                    setLabelCode(
                        TypePersonal.TYPE_IDENTITY[PERSONAL_TYPE_IDENTITY.MBHXH]
                    );
                    break;
            }
        }
    };
    const onDisplayContractFile = async () => {
        // const agencyService = new AgencyService();
        // const valuesForm = this.props.form.getFieldsValue();
        // this.setState({ loading: true, visible: true });
        // try {
        //   let contract = await agencyService.getAgencyContract(valuesForm);
        //   this.setState({
        //     file: contract.data.file
        //   });
        // } catch (error) {
        //   onFailAction("Có lỗi xảy ra khi xem hợp đồng!");
        // } finally {
        //   this.setState({
        //     loading: false
        //   });
        // }
    };
    const update = (status) => {
        const { validateFields } = props.form;
        validateFields(async (errors, values) => {
            if (!errors) {
                try {
                    loadingHelper.runLoadingBlockUI();
                    const val = {
                        ...values,
                        status,
                        showEmail,
                        type: TYPE_REQUEST_PERSONAL,
                    };
                    const data =
                        await RequestChangeInfoSupportSerialService.requestChangeInfoSerialPersonal(
                            val
                        );
                    if (data && Number(data.status) === 422) {
                        onFailAction("Có lỗi xảy ra khi yêu cầu !");
                        _.forOwn(data.error, function (errors, key) {
                            props.form.setFields({
                                [key]: {
                                    errors: [new Error(errors.toString())],
                                },
                            });
                        });
                    } else {
                        onSuccessAction("Yêu cầu thành công", () => {
                            props.history.push(
                                "/danh-sach-dieu-chinh-thong-tin"
                            );
                        });
                    }
                } catch (error) {
                    onFailAction("Có lỗi xảy ra!");
                } finally {
                    loadingHelper.stopRunLoading();
                }
            } else {
                onFailAction("Bạn chưa điền đủ thông tin!");
            }
        });
    };
    const onChangeCheckBox = (e) => {
        setShowEmail(e.target.checked);
    };
    const getCertBySerial = async () => {
        const result =
            await RequestChangeInfoSupportSerialService.getCertToChangeBySerial(
                {
                    serial: serial,
                    customerType: TYPE_REQUEST_PERSONAL,
                }
            );
        if (Number(result.status) === 200) {
            if (Number(result.typeCert) === 1) {
                const provinces =
                    await RequestDigitalCertificatePersonalServices.getProvince();
                setProvince(
                    _.mapValues(_.keyBy(provinces, "newtel_code"), "fullname")
                );
                props.form.setFieldsValue({
                    uid: result.data.customer_code.toString(),
                });
                if (
                    Number(result.data.object) === 4 &&
                    result.data.request_change_info
                ) {
                    const districts =
                        await RequestDigitalCertificatePersonalServices.getDistrictByProvince(
                            result.data.request_change_info.province_code
                        );
                    setDistrict(
                        _.mapValues(
                            _.keyBy(districts, "newtel_district_code"),
                            "fullname"
                        )
                    );
                    props.form.setFieldsValue({
                        identity_type:
                            result.data.request_change_info.identity_type.toString(),
                        code: result.data.request_change_info.code,
                        fullname: result.data.request_change_info.fullname,
                        province_code:
                            result.data.request_change_info.province_code.toString(),
                        district_code:
                            result.data.request_change_info.district_code.toString(),
                    });
                    switch (
                        Number(result.data.request_change_info.identity_type)
                    ) {
                        case PERSONAL_TYPE_IDENTITY.CMND:
                            setLabelCode(
                                TypePersonal.TYPE_IDENTITY[
                                    PERSONAL_TYPE_IDENTITY.CMND
                                ]
                            );
                            break;
                        case PERSONAL_TYPE_IDENTITY.HC:
                            setLabelCode(
                                TypePersonal.TYPE_IDENTITY[
                                    PERSONAL_TYPE_IDENTITY.HC
                                ]
                            );
                            break;
                        case PERSONAL_TYPE_IDENTITY.CCCD:
                            setLabelCode(
                                TypePersonal.TYPE_IDENTITY[
                                    PERSONAL_TYPE_IDENTITY.CCCD
                                ]
                            );
                            break;
                        case PERSONAL_TYPE_IDENTITY.MBHXH:
                            setLabelCode(
                                TypePersonal.TYPE_IDENTITY[
                                    PERSONAL_TYPE_IDENTITY.MBHXH
                                ]
                            );
                            break;
                    }
                }
                if (
                    (Number(result.data.object) === 1 ||
                        Number(result.data.object) === 2 ||
                        Number(result.data.object) === 3) &&
                    result.data.request_personal
                ) {
                    const districts =
                        await RequestDigitalCertificatePersonalServices.getDistrictByProvince(
                            result.data.requestcertificate.province_code
                        );
                    setDistrict(
                        _.mapValues(
                            _.keyBy(districts, "newtel_district_code"),
                            "fullname"
                        )
                    );
                    props.form.setFieldsValue({
                        identity_type:
                            result.data.request_personal.identity_type.toString(),
                        code: result.data.request_personal.passport,
                        fullname: result.data.request_personal.fullname,
                        province_code:
                            result.data.requestcertificate.province_code.toString(),
                        district_code:
                            result.data.requestcertificate.district_code.toString(),
                    });
                    switch (
                        Number(result.data.request_personal.identity_type)
                    ) {
                        case PERSONAL_TYPE_IDENTITY.CMND:
                            setLabelCode(
                                TypePersonal.TYPE_IDENTITY[
                                    PERSONAL_TYPE_IDENTITY.CMND
                                ]
                            );
                            break;
                        case PERSONAL_TYPE_IDENTITY.HC:
                            setLabelCode(
                                TypePersonal.TYPE_IDENTITY[
                                    PERSONAL_TYPE_IDENTITY.HC
                                ]
                            );
                            break;
                        case PERSONAL_TYPE_IDENTITY.CCCD:
                            setLabelCode(
                                TypePersonal.TYPE_IDENTITY[
                                    PERSONAL_TYPE_IDENTITY.CCCD
                                ]
                            );
                            break;
                        case PERSONAL_TYPE_IDENTITY.MBHXH:
                            setLabelCode(
                                TypePersonal.TYPE_IDENTITY[
                                    PERSONAL_TYPE_IDENTITY.MBHXH
                                ]
                            );
                            break;
                    }
                }
                if (
                    (Number(result.data.object) === 5 ||
                        Number(result.data.object) === 6) &&
                    result.data.request_renew_and_warranty
                ) {
                    const districts =
                        await RequestDigitalCertificatePersonalServices.getDistrictByProvince(
                            result.data.request_renew_and_warranty.province_code
                        );
                    setDistrict(
                        _.mapValues(
                            _.keyBy(districts, "newtel_district_code"),
                            "fullname"
                        )
                    );
                    props.form.setFieldsValue({
                        identity_type:
                            result.data.request_renew_and_warranty.identity_type.toString(),
                        code: result.data.request_renew_and_warranty.identity,
                        fullname:
                            result.data.request_renew_and_warranty.fullname,
                        province_code:
                            result.data.request_renew_and_warranty.province_code.toString(),
                        district_code:
                            result.data.request_renew_and_warranty.district_code.toString(),
                    });
                    switch (
                        Number(
                            result.data.request_renew_and_warranty.identity_type
                        )
                    ) {
                        case PERSONAL_TYPE_IDENTITY.CMND:
                            setLabelCode(
                                TypePersonal.TYPE_IDENTITY[
                                    PERSONAL_TYPE_IDENTITY.CMND
                                ]
                            );
                            break;
                        case PERSONAL_TYPE_IDENTITY.HC:
                            setLabelCode(
                                TypePersonal.TYPE_IDENTITY[
                                    PERSONAL_TYPE_IDENTITY.HC
                                ]
                            );
                            break;
                        case PERSONAL_TYPE_IDENTITY.CCCD:
                            setLabelCode(
                                TypePersonal.TYPE_IDENTITY[
                                    PERSONAL_TYPE_IDENTITY.CCCD
                                ]
                            );
                            break;
                        case PERSONAL_TYPE_IDENTITY.MBHXH:
                            setLabelCode(
                                TypePersonal.TYPE_IDENTITY[
                                    PERSONAL_TYPE_IDENTITY.MBHXH
                                ]
                            );
                            break;
                    }
                }
            } else {
                props.form.setFieldsValue({
                    uid: result.data.uid.toString(),
                    identity_type: "1",
                    code: result.data.uid,
                    fullname: result.data.compensation_request
                        ? result.data.compensation_request.owner
                        : "",
                    contact_name: result.data.compensation_request
                        ? result.data.compensation_request.contact_name
                        : "",
                    contact_phone: result.data.compensation_request
                        ? result.data.compensation_request.contact_phone
                        : "",
                    contact_email: result.data.compensation_request
                        ? result.data.compensation_request.contact_email
                        : "",
                    contact_position: result.data.compensation_request
                        ? result.data.compensation_request.contact_position
                        : "",
                    contact_address: result.data.compensation_request
                        ? result.data.compensation_request.contact_address
                        : "",
                });
                if (
                    result.data.compensation_request &&
                    Number(result.data.compensation_request.type_customer) === 1
                ) {
                    props.form.setFieldsValue({ is_organization: "1" });
                }
                if (
                    result.data.compensation_request &&
                    Number(result.data.compensation_request.type_customer) === 0
                ) {
                    props.form.setFieldsValue({ is_organization: "2" });
                }
            }
        } else {
            onFailAction(result.message);
            props.form.setFieldsValue({ uid: "" });
        }
    };
    const onChangeSerial = (e) => {
        let serialFormat = e.target.value.replace(/\s/g, "").toUpperCase();
        setSerial(serialFormat);
    };

    const onBlurSerial = (e) => {
        let serialFormat = e.target.value.replace(/\s/g, "").toUpperCase();
        props.form.setFieldsValue({
            serial_search: serialFormat,
        });
    };

    /**
     * Fetch data
     */
    useEffect(() => {
        getProvince();
        // eslint-disable-next-line
    }, []);

    return (
        <PageWrapper title="Yêu cầu điều chỉnh thông tin CTS cá nhân">
            <Form>
                <Card
                    className="m-r-15-i m-l-15-i"
                    title={<label>Tìm kiếm theo serial chứng thư số</label>}
                    size="small"
                >
                    <div className="input-group">
                        <InputWithLabel
                            onChange={onChangeSerial}
                            onBlur={onBlurSerial}
                            form={props.form}
                            label="Serial"
                            name="serial_search"
                            wrapClass="col-md-3"
                            maxLength={255}
                            isDisabled={false}
                        />
                        <SearchButton
                            btnClass="bg-primary text-white form-group ml-1 mt-4"
                            onClick={getCertBySerial}
                        />
                    </div>
                </Card>
                <Card
                    className="m-r-15-i m-l-15-i mt-1"
                    title={<label>Thông tin điều chỉnh</label>}
                    size="small"
                >
                    <div className="input-group">
                        <InputWithLabel
                            form={props.form}
                            label="Mã khách hàng"
                            name="uid"
                            wrapClass="col-md-3"
                            maxLength={255}
                            isDisabled={true}
                            isRequired={true}
                        />
                        <SelectWithLabel
                            options={TypePersonal.TYPE_IDENTITY}
                            name="identity_type"
                            wrappedClass="col-md-3"
                            form={props.form}
                            label={"Loại định danh"}
                            isRequired={true}
                            onChange={onChangeIdentityType}
                        />
                        <InputWithLabel
                            form={props.form}
                            label={labelCode}
                            name="code"
                            wrapClass="col-md-3"
                            isDisabled={false}
                            isRequired={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Tên khách hàng"
                            name="fullname"
                            wrapClass="col-md-3"
                            maxLength={255}
                            isDisabled={false}
                            isRequired={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Email"
                            name="email"
                            wrapClass="col-md-3"
                            isDisabled={false}
                            isRequired={true}
                        />
                        <SelectWithLabel
                            options={province}
                            name="province_code"
                            wrappedClass="col-md-3"
                            form={props.form}
                            label={"Tỉnh thành"}
                            isRequired={true}
                            isProvince={true}
                            onChange={onChangeProvince}
                        />
                        <SelectWithLabel
                            options={district}
                            name="district_code"
                            wrappedClass="col-md-3"
                            form={props.form}
                            label={"Quận huyện"}
                            isRequired={true}
                        />
                        <InputFileUpload
                            nameFiledSetValue={""}
                            classWrapped="col-md-3 overflow-hidden"
                            classFileUpload="custom-file-nonOverFlow"
                            label="Tải file CMND/Hộ chiếu"
                            name="file_passport"
                            form={props.form}
                            isRequired={true}
                            extentionsAllow={["pdf", "PDF"]}
                            accept={[".pdf", ".PDF"]}
                            note={true}
                        />
                        <RadioWithLabel
                            label={
                                "Trường hợp cá nhân thuộc tổ chức doanh nghiệp"
                            }
                            options={{ 1: "Có", 2: "Không" }}
                            name="is_organization"
                            wrappedClass="col-md-3 radio-to-chuc"
                            form={props.form}
                            isRequired={true}
                        />
                        {props.form.getFieldValue("is_organization") === "1" ? (
                            <React.Fragment>
                                <div className="input-group">
                                    <InputWithLabel
                                        form={props.form}
                                        label="Tên tổ chức"
                                        name="organization_name"
                                        wrapClass="col-md-3"
                                        isRequired={true}
                                        maxLength={255}
                                    />
                                    <InputWithLabel
                                        form={props.form}
                                        label="Tên phòng ban"
                                        name="organization_department"
                                        wrapClass="col-md-3"
                                        maxLength={255}
                                    />
                                    <InputWithLabel
                                        form={props.form}
                                        label="Chức vụ"
                                        name="organization_position"
                                        wrapClass="col-md-3"
                                        maxLength={255}
                                    />
                                    <InputFileUpload
                                        nameFiledSetValue={""}
                                        classWrapped="col-md-3 overflow-hidden"
                                        classFileUpload="custom-file-nonOverFlow"
                                        label="File xác nhận tổ chức"
                                        name="organization_file"
                                        form={props.form}
                                        isRequired={true}
                                        extentionsAllow={["pdf", "PDF"]}
                                        accept={[".pdf", ".PDF"]}
                                        note={true}
                                    />
                                </div>
                            </React.Fragment>
                        ) : (
                            ""
                        )}
                        <div className={"col-md-6"}>
                            <Form.Item>
                                <Checkbox onChange={onChangeCheckBox}>
                                    Hiển thi Email
                                </Checkbox>
                                <span className={"text-danger font-italic"}>
                                    ( Chú ý: Nếu chọn Email sẽ hiển thị trong
                                    thông tin CTS )
                                </span>
                            </Form.Item>
                        </div>
                    </div>
                </Card>
                <Card
                    className="m-r-15-i m-l-15-i mt-1"
                    title={<label>Thông tin liên hệ</label>}
                    size="small"
                >
                    <div className="input-group">
                        <InputWithLabel
                            form={props.form}
                            label="Họ và tên"
                            name="contact_name"
                            wrapClass="col-md-3"
                            maxLength={255}
                            isDisabled={false}
                            isRequired={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Chức vụ"
                            name="contact_position"
                            wrapClass="col-md-3"
                            maxLength={255}
                            isDisabled={false}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Số điện thoại"
                            name="contact_phone"
                            wrapClass="col-md-3"
                            maxLength={20}
                            minLength={9}
                            isRequired={true}
                            isDisabled={false}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Số di động"
                            name="contact_mobile"
                            wrapClass="col-md-3"
                            maxLength={20}
                            minLength={9}
                            isDisabled={false}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Email"
                            name="contact_email"
                            wrapClass="col-md-3"
                            maxLength={255}
                            isDisabled={false}
                            isRequired={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Fax"
                            name="contact_fax"
                            wrapClass="col-md-3"
                            maxLength={255}
                            isDisabled={false}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Địa chỉ"
                            name="contact_address"
                            wrapClass="col-md-6"
                            maxLength={255}
                            isDisabled={false}
                        />
                    </div>
                </Card>
                <Card
                    className="m-r-15-i m-l-15-i mt-1"
                    title={<label>File điều chỉnh thông tin</label>}
                    size="small"
                >
                    <div className="input-group">
                        <SelectWithLabel
                            options={FILE_TYPE_OPTIONS}
                            form={props.form}
                            label="Loại điều chỉnh"
                            name="change_type"
                            isRequired={true}
                            wrappedClass="col-md-2"
                            defaultValue={""}
                            isDisabled={false}
                            placeholder="Chọn loại điều chỉnh"
                            onChange={onChangeContractType}
                        />
                        {typeChange ? (
                            <div className="form-group col-md-2">
                                <button
                                    onClick={onDisplayContractFile}
                                    className="btn ml-5 mt-4 btn-primary btn-small"
                                    disabled={!typeChange}
                                    hidden={typeChange}
                                >
                                    DC 01.02
                                </button>
                            </div>
                        ) : (
                            <InputFileUpload
                                nameFiledSetValue={""}
                                classWrapped="col-md-4 overflow-hidden"
                                classFileUpload="custom-file-nonOverFlow"
                                label="File yêu cầu điều chỉnh thông tin"
                                name="file_change_info"
                                form={props.form}
                                isRequired={!typeChange}
                                extentionsAllow={["pdf", "PDF"]}
                                accept={[".pdf", ".PDF"]}
                                isDisabled={typeChange}
                                note={true}
                            />
                        )}
                        <InputWithLabel
                            form={props.form}
                            label="Mã CTKM"
                            name="promotion_code"
                            wrapClass="col-md-3"
                            maxLength={255}
                            isDisabled={false}
                        />
                    </div>
                </Card>
            </Form>
            <div className="input-group d-flex justify-content-center p-5 mt-4">
                <div className="">
                    <ButtonOnSave
                        onClick={() => {
                            update(1);
                        }}
                        label="Trình duyệt"
                        className={"btn btn-success btn-sm"}
                    />
                </div>
                <div className="">
                    <ButtonCancel
                        onClick={() => {
                            props.history.goBack();
                        }}
                        className={"btn btn-default btn-sm"}
                    />
                </div>
            </div>
        </PageWrapper>
    );
};

const WrappedCreateReqPersonal = Form.create<Props>({
    name: "CreateReqPersonal",
})(CreateReqPersonal);

export default WrappedCreateReqPersonal;
