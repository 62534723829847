import cyberbill from "../login/images/cyberbill.png";
import cyberbook from "../login/images/cyberbook.png";
import cybercare from "../login/images/cybercare.png";
import cybersign from "../login/images/cybersign.png";
import cybertax from "../login/images/cybertax.png";
export const LOGO = [
  {
    id: 1,
    src: cyberbill,
    href: "https://cyberbill.vn/",
  },
  {
    id: 2,
    src: cyberbook,
    href: "https://cyberbook.vn/",
  },
  {
    id: 3,
    src: cybercare,
    href: "https://cybercare.vn/",
  },
  {
    id: 4,
    src: cybertax,
    href: "https://cyberbill.vn/",
  },
  {
    id: 5,
    src: cybersign,
    href: "https://cybersign.vn/",
  },
];
