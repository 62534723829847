import { Col, Dropdown, Icon, Layout, Menu, Row } from "antd";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import store from "store/store";

const { Header } = Layout;

interface Props {}
interface State {}

export default class MyHeader extends Component<Props, State> {
    state = {};
    menu = (
        <Menu>
            <Menu.Item>
                <Link to="/doi-mat-khau" key={`changePassword`}>
                    <button className={"btn btn-link"}>Đổi mật khẩu</button>
                </Link>
            </Menu.Item>
            <Menu.Item>
                <Link to="/logout" key={`logout`}>
                    <button className="btn btn-link">Đăng xuất</button>
                </Link>
            </Menu.Item>
        </Menu>
    );

    render() {
        return (
            <Header className="header" style={{ padding: 0 }}>
                <Row className="d-flex">
                    <Col className="ml-auto text-right pr-3">
                        <Dropdown
                            overlay={this.menu}
                            trigger={["click", "hover"]}
                        >
                            <button className="btn btn-link text-white ">
                                {store.getState().authReducer.fullname}{" "}
                                <Icon type="down" />
                            </button>
                        </Dropdown>
                    </Col>
                </Row>
            </Header>
        );
    }
}
