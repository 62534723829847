import BaseServices from "../base/BaseServices";

const getFileChangeInfoCert = async (data) => {
    const result = await BaseServices.request(
        `sign-request-change-info-cert/get-file-change-info-cert`,
        data,
        "POST"
    );
    return result;
};
const denySignRevoke = async (data) => {
    const result = await BaseServices.request(
        `sign-request-change-info-cert/deny-sign-file-change`,
        data,
        "POST"
    );
    return result;
};

const getCertificate = async (data) => {
    const result = await BaseServices.request(
        `sign-request-change-info-cert/get-certificate-sign-change-info`,
        data,
        "POST"
    );
    return result;
};

const updateFileRevokeSigned = async (data) => {
    const result = await BaseServices.request(
        `sign-request-change-info-cert/update-file-sign-change-info`,
        data,
        "POST"
    );
    return result;
};
const getTimeServer = async () => {
    const result = await BaseServices.request(
        `get-time-server-sign`,
        {},
        "GET"
    );
    return result;
};

export const SignChangeInfoCertServices = {
    getFileChangeInfoCert,
    denySignRevoke,
    getCertificate,
    updateFileRevokeSigned,
    getTimeServer
};
