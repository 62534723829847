import React from "react";
import {Menu} from "antd";

interface Props {
    onClick: (type) => void;
    dataDoc?: any;
    link?: any;
}

export default function MenuDropDoc(props: Props) {
    const list =
        {
            file_passport: "CMND/Hộ chiếu",
            file_change: "Mẫu DK-01.01",
            file_legal: "File giấy tờ pháp lý",
            file_present_passport: "File cmnd/hộ chiếu người đại diện",
            file: "Mẫu DK-01.01",
            15: "Mẫu ký xác nhận DK-02",
            16: "Mẫu ký xác nhận DK-02",
            dk02: "Mẫu ký xác nhận DK-02",
        }

    const dataDoc = props.dataDoc;
    const listItems = dataDoc.map((value) =>
        <Menu.Item key={value} className="drop-contract">
            <a className={"drop-contract-link"}
               href={props.link} type="button"
               target={"_blank"}
               rel="noopener noreferrer"
               onClick={() => props.onClick(value)}
            >
                <button>{list[value]}</button>
            </a>
        </Menu.Item>
    );
    return (
        <Menu>
            {listItems}
        </Menu>
    );
}

