import React, {useEffect, useState} from "react";
import {Form} from "antd";
import {FormComponentProps} from "antd/lib/form";
import {match} from "react-router";
import ButtonCancel from "../../components/common/form/button/ButtonCancel";
import {History} from "history";
import PageWrapper from "./../wrapper/PageWrapper";
import InputWithLabel from '../../components/common/form/input-with-label/InputWithLabel';
import SelectWithLabel from "../../components/common/form/input-with-label/SelectWithLabel";
import {UserServices} from "../../services/user/UserServies";

interface Props extends FormComponentProps {
    history: History;
    location: Location;
    match: match<{
        id: string;
    }>;
    user: any;
}

const MemberPreview: React.FC<Props> = (props) => {
    const [loading, setLoading] = useState(false);
    const id = props.match.params.id;

    const fetchModel = async () => {
        setLoading(true);
        const data = {id: id}
        const userService = new UserServices();
        const result = await userService.getUserMemberPreview(data);
        const user = result.data;
        setLoading(false);

        props.form.setFieldsValue({
            fullname: user.fullname,
            username: user.username,
            email: user.email,
            phone: user.phone,
            role: user.type ? user.type.toString() : "",
            status: user.status,
        });
    };

    useEffect(() => {
        fetchModel().then();
        // eslint-disable-next-line
    }, []);

    return (
        <PageWrapper title="Thông tin người dùng" loading={loading}>
            <Form>
                <div className="input-group">
                    <InputWithLabel
                        form={props.form}
                        label="Họ và tên"
                        name="fullname"
                        wrapClass="col-md-4"
                        isRequired={true}
                        isDisabled={true}
                    />
                    <InputWithLabel
                        form={props.form}
                        label="Tên tài khoản"
                        name="username"
                        wrapClass="col-md-4"
                        isRequired={true}
                        isDisabled={true}
                    />
                    <InputWithLabel
                        form={props.form}
                        label="Email"
                        name="email"
                        wrapClass="col-md-4"
                        isRequired={true}
                        isDisabled={true}
                    />
                </div>
                <div className="input-group">
                    <InputWithLabel
                        form={props.form}
                        label="Số điện thoại"
                        name="phone"
                        wrapClass="col-md-4"
                        type="number"
                        isRequired={true}
                        isDisabled={true}
                    />
                    <SelectWithLabel
                        options={{1:"Admin", 2:"Giám đốc", 3:"Kế toán", 4:"Nghiệp vụ", 5:"Nhân viên kính doanh", 6:"Hỗ trợ kinh doanh", 7:"Đại lý", 8:"Cộng tác viên"}}
                        name="role"
                        wrappedClass="col-md-4"
                        form={props.form}
                        placeholder="Vai trò"
                        label="Vai trò"
                        isRequired={true}
                        isDisabled={true}
                    />
                    <SelectWithLabel
                        options={{0: 'Hoạt động', 1: "Tạm dừng", 2: 'Không hoạt động'}}
                        name="status"
                        wrappedClass="col-md-4"
                        form={props.form}
                        placeholder="Trạng thái"
                        label="Trạng thái"
                        isRequired={true}
                        isDisabled={true}
                    />
                </div>
            </Form>
            <div className="input-group pb-5 pt-2">
            </div>
            <div className="input-group d-flex justify-content-center p-5">
                <div className="">
                    <ButtonCancel
                        onClick={() => {
                            props.history.goBack();
                        }}
                        className={"btn btn-default btn-sm"}
                    />
                </div>
            </div>
        </PageWrapper>
    );
};

const MemberPreviewForm = Form.create<Props>({
    name: "MemberUpdateForm",
})(MemberPreview);

export default MemberPreviewForm;
