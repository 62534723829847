import React, { Component, ReactNode } from "react";

interface Props {
    nameFor: string;
    label: string;
    isRequired?: boolean;
    additionText?: ReactNode;
    class?: string;
    readonly?: boolean;
    note?: string;
    warning?: boolean;
}
interface State {}

class LabelInput extends Component<Props, State> {
    state = {};

    render() {
        return this.props.label ? (
            <label
                htmlFor={this.props.nameFor}
                className={`newca-label ${this.props.class}`}
            >
                {this.props.label}
                {this.props.isRequired ? (
                    <span className="text-danger ml-1">*</span>
                ) : (
                    ""
                )}
                {this.props.warning ? (
                    <span className="text-danger ml-1">*</span>
                ) : (
                    ""
                )}
                {this.props.note ? <i>{this.props.note}</i> : ""}
                {this.props.additionText}
                {this.props.readonly}
            </label>
        ) : (
            <React.Fragment></React.Fragment>
        );
    }
}

export default LabelInput;
