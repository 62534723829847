import React, { Component } from "react";
import {Modal, Radio} from 'antd';
import { Input } from 'antd';
import ValidateMessageError from "../../form/validate-message-error/ValidateMessageError";

const { TextArea } = Input;

interface Props {
    handleCancel?: any;
    handleDeny?: any;
    value?: string;
    visible: boolean;
    loading?: boolean;
    title?: string;
    error? : string;
    okText? : string;
    option? : any;
    label? : string;
    onChange?: any;
    onChangeText?: any;
    test?: boolean;
    isUSBToken?:boolean;
    reason?:any;
}
interface State { reason_extend: boolean; }

export default class ModalCheckBoxReason extends Component<Props, State> {
    state = { reason_extend : false };
    componentDidMount() {}
    change = async (number) => {
        if (this.props.reason && Number(this.props.reason) === 4){
            if ( Number(number.target.value) === 4 ){
                this.setState({
                    reason_extend: true
                });
            }
        }else {
            this.setState({
                reason_extend: false
            });
    }};

    render() {
        return (
            <div>
                <Modal
                    title={this.props.title ? this.props.title :'Lý do từ chối'}
                    visible={this.props.visible}
                    onOk={this.props.handleDeny}
                    onCancel={this.props.handleCancel}
                    destroyOnClose={true}
                    okText={this.props.okText ? this.props.okText :'Từ chối'}
                    cancelText="Đóng"
                    className="modal-lg"
                    width="auto"
                    closable={false}
                    // bodyStyle={}
                >
                    { this.props.isUSBToken ? (
                        <Radio.Group
                            disabled={false}
                            onChange={this.props.onChange}
                        >
                            {Object.keys(this.props.option).map((key, index) => (
                                    <Radio key={index} value={key} onChange={(index) => this.change(index)} className={"pr-5"}>
                                        {this.props.option[key]}
                                    </Radio>
                            ))}
                        </Radio.Group>
                    ) : ''

                    }

                    {(  this.state.reason_extend
                        || !this.props.isUSBToken
                        || (this.props.isUSBToken && Number(this.props.reason) === 4)
                    )
                        ? (
                        <div>
                            <TextArea
                                placeholder={this.props.title ? this.props.title :'Nhập Lý do'}
                                // autoSize={{ minRows: 4, maxRows: 10 }}
                                className="form-control mt-3"
                                required={true}
                                value={this.props.value}
                                onChange={this.props.onChangeText}
                            />
                            <ValidateMessageError error={this.props.error}/>
                        </div>
                    ) : ("")}
                </Modal>
            </div>
        );
    }
}
