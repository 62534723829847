import React from 'react'
import { authenticationService } from '../../services/authentication/AuthenticationService'
import store from 'store/store';
import { unsetAuthData } from 'actions/authAction';

interface Props {
}

export default function Logout(props): Props {

    const logout = ()=>{
        authenticationService.logout();
        store.dispatch(unsetAuthData());
        props.history.push('/');
        window.location.reload();
        return true;
    }

    return (
        <div>
           {logout()}
        </div>
    )
}
